import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../BASE_URL";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const SplashPage = () => {
  const { merchantId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const InsertBooking = async () => {
    const token = localStorage.getItem("userToken");
    const bookingId = localStorage.getItem("bookingId");

    try {
      const res = await fetch(`${BASE_URL}payment/updateHotelBooking/${bookingId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          status: "booked",
          transaction_id: merchantId,
        }),
      });

      const data = await res.json();

      if (data?.success) {
        localStorage.removeItem("bookingId");
        setTimeout(() => {
          navigate("/my_package");
        }, 1000);
      } else {
        toast.error(data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const carBooking = async () => {
    const token = localStorage.getItem("userToken");
    const bookingId = localStorage.getItem("bookingId");

    try {
      const res = await fetch(`${BASE_URL}payment/updateCarBooking/${bookingId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          status: "booked",
          transaction_id: merchantId,
        }),
      });

      const data = await res.json();

      if (data?.success) {
        localStorage.removeItem("bookingId");
        localStorage.removeItem("booking");
        setTimeout(() => {
          navigate("/my_package");
        }, 1000);
      } else {
        toast.error(data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const booking = localStorage.getItem("booking");
    if (booking === "car") {
      carBooking();
    } else {
      InsertBooking();
    }
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f5f5f5",
      }}
    >
      {loading ? (
        <>
          <CircularProgress />
          <p style={{ marginTop: "20px", fontSize: "18px", color: "#555" }}>
            Processing your booking, please wait...
          </p>
        </>
      ) : (
        <p style={{ fontSize: "18px", color: "#555" }}>
          Redirecting to your booking details...
        </p>
      )}
    </Box>
  );
};

export default SplashPage;
