import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import "./css/Hotal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIndianRupeeSign, faStar } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../BASE_URL";
import Footer from "./Footer";
import axios from "axios";
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"
import BookingModal from "./booking-hotel-modal/BookingHotelModal";
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Rating from "@mui/material/Rating"
import Carousel from 'react-bootstrap/Carousel';
import { Form, Modal, Row } from "react-bootstrap"
import countries from "../CountryStateCity.json"
import HotelHighlightsCarousel from "../Vendor/MultiCarousel";


function calculateAdjustedPrice(price, percentage) {
  // Convert the inputs to numbers
  const numericPrice = parseFloat(price);
  const numericPercentage = parseFloat(percentage);

  // Check if the conversions are valid numbers
  if (isNaN(numericPrice) || isNaN(numericPercentage)) {
    return "Invalid price or percentage input";
  }

  // Calculate the adjusted price
  const adjustedPrice = numericPrice + (numericPrice * numericPercentage / 100);
  return adjustedPrice;
}


function getMonthName(dateString) {
  const date = new Date(dateString);
  return date.toLocaleString('default', { month: 'long' });
}

// Function to get margin percentage for a specific month
function getMarginPercentage(dateString, monthAndMarginUser) {
  const monthName = getMonthName(dateString);

  const monthData = monthAndMarginUser && monthAndMarginUser?.find(item => item.month_name === monthName);

  return monthData ? Number(monthData.margin_percentage) : null;
}

function getPriceForDateRange(targetDate, priceArray, percentage) {

  // Convert targetDate to a Date object
  const target = new Date(targetDate);

  // Step 1: Try to find a matching price object within the date range
  const priceObj = priceArray?.find(item => {
    const startDate = new Date(item.price_start_date);
    const endDate = new Date(item.price_end_date);
    return target >= startDate && target <= endDate;
  });

  // Step 2: If no range match is found, find the closest future start date
  let closestPriceObj = priceObj;
  if (!closestPriceObj) {
    closestPriceObj = priceArray
      ?.filter(item => new Date(item?.price_start_date) > target) // Filter dates in the future
      ?.sort((a, b) => new Date(a?.price_start_date) - new Date(b?.price_start_date))[0]; // Get the closest future date
  }

  // Step 3: Calculate the adjusted price if a matching or closest date was found
  if (closestPriceObj) {
    const adjustedPrice = closestPriceObj.adult_price + (closestPriceObj.adult_price * (10 / 100));
    return Math.round(adjustedPrice);
  } else {
    // Return a message if no price object is available for or after the target date
    return "No price available for the given date or any future dates";
  }
}

function calculateAdjustedPricePercentage(price, percentage) {
  // Convert the inputs to numbers
  const numericPrice = parseFloat(price);
  const numericPercentage = parseFloat(percentage);

  // Check if the conversions are valid numbers
  if (isNaN(numericPrice) || isNaN(numericPercentage)) {
    return "Invalid price or percentage input";
  }

  // Calculate the adjusted price
  const adjustedPrice = numericPrice + (numericPrice * numericPercentage / 100);
  return adjustedPrice;
}

function getTodayDate() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

function calculateAdjustedPrice1(priceRangeArray, date, percentageArray, totalChild, totalAdult, checkOut, checkIn) {
  // Parse checkIn and checkOut dates
  const checkInDate = new Date(checkIn);
  const checkOutDate = new Date(checkOut);

  const timeDifference = checkOutDate - checkInDate;
  const numberOfNights = Math.max(0, Math.ceil(timeDifference / (1000 * 60 * 60 * 24)));


  // Step 1: Find the price for adults and children based on the date range
  const priceObj = priceRangeArray.find(
    item =>
      checkInDate >= new Date(item.price_start_date) &&
      checkOutDate <= new Date(item.price_end_date)
  );

  // if (!priceObj) {
  //   throw new Error("No price range found for the given dates.");
  // }

  const adultPrice = priceObj?.adult_price;
  const childPrice = priceObj?.child_price;

  // Step 2: Extract the month from the checkIn date to find the margin percentage
  const month = checkInDate.toLocaleString("default", { month: "long" });
  const percentageObj = percentageArray.find(item => item.month_name === month);

  // If no percentage found, use 0 as the default margin
  const marginPercentage = percentageObj ? parseFloat(percentageObj.margin_percentage) : 0;

  // Step 3: Calculate adjusted prices for adults and children
  const adjustedPriceAdult = adultPrice + (adultPrice * marginPercentage / 100);
  const adjustedPriceChild = childPrice + (childPrice * marginPercentage / 100);

  // Step 4: Calculate total adjusted price per night based on the number of adults and children
  const totalAdjustedPrice = (adjustedPriceAdult * totalAdult) + (adjustedPriceChild * totalChild);

  return Math.round(totalAdjustedPrice * numberOfNights);
}

function Hotel_Details(props) {

  const selectedStates = countries?.find((e) => e?.name === "India")

  const { id } = useParams();

  const navigate = useNavigate()

  const [hotelDetail, setHotelDetail] = useState([]);

  const [propertyDetail, setPropertyDetail] = useState('');
  const [amenities, setAmenities] = useState([]);

  const [showButton, setShowButton] = useState(false)

  const hotelList = async () => {
    const token = localStorage.getItem("userToken");
    const res = await fetch(`${BASE_URL}hotel_syt/details?_id=${id}`, {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    });
    const data = await res.json();

    setHotelDetail(data?.data?.[0]);
    setPropertyDetail(data?.data?.[0]?.property_policies[0])
    setAmenities(data?.data?.[0]?.amenities_and_facilities)
    const myId = localStorage.getItem('my_id')


    if (data?.data?.[0]?.hotel_reviews) {
      const reviews = data.data[0].hotel_reviews;

      const hasMatchingId = reviews?.some(review => review?.user_details?._id === myId);

      if (!hasMatchingId) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    } else {
      setShowButton(true);
    }
  };

  useEffect(() => {
    hotelList();
  }, []);

  const overRef = useRef(null);
  const roomsRef = useRef(null);
  const facilities = useRef(null);
  const location = useRef(null);
  const policies = useRef(null);
  const reviewRef = useRef(null);


  const scrollTo = (section) => {
    let targetRef;

    switch (section) {
      case 'overview':
        targetRef = overRef;
        break;
      case 'rooms':
        targetRef = roomsRef;
        break;
      case 'facilities':
        targetRef = facilities;
        break;
      case 'location':
        targetRef = location;
        break;
      case 'policies':
        targetRef = policies;
        break;
      case 'review':
        targetRef = reviewRef;
        break;
      default:
        targetRef = null;
    }

    if (targetRef) {
      window.scroll({
        top: targetRef.current.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  const [googleMapsEmbedUrl, setGoogleMapsEmbedUrl] = useState('');

  useEffect(() => {
    // Function to fetch coordinates based on the address
    const fetchCoordinates = async (address) => {
      try {
        const apiKey = 'YOUR_GOOGLE_MAPS_API_KEY'; // Replace with your actual API key
        const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`;

        const response = await axios.get(geocodeUrl);

        if (response.data.status === 'OK') {
          const location = response.data.results[0].geometry.location;
          const latitude = location.lat;
          const longitude = location.lng;

          // Construct the embed URL with the obtained coordinates
          const embedUrl = `https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${latitude},${longitude}`;
          setGoogleMapsEmbedUrl(embedUrl);
        } else {
          console.error('Geocode was not successful for the following reason:', response.data.status);
        }
      } catch (error) {
        console.error('Error fetching coordinates:', error);
      }
    };

    const myLocation = hotelDetail.hotel_name + "," + hotelDetail.hotel_address

    if (myLocation) {
      fetchCoordinates(myLocation);
    }
  }, [hotelDetail]);

  const totalStars = 5;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [room_id, setRoomId] = useState('');
  const [roomData, setRoomData] = useState([]);
  const [myArray, setMyArray] = useState([])
  const [reviewAverage, setReviewAverage] = useState(0);

  const myId = localStorage.getItem('my_id')

  useEffect(() => {
    const sortedReviews = hotelDetail && hotelDetail?.hotel_reviews?.sort((a, b) => {
      if (a?.user_details?._id === myId && b?.user_details?._id !== myId) return -1;
      if (a?.user_details?._id !== myId && b?.user_details?._id === myId) return 1;
      return 0;
    });
    let sum = 0;
    if (hotelDetail.hotel_reviews) {
      hotelDetail.hotel_reviews.map(ele => Number(ele.star)).forEach(function (item, idx) {
        sum += item;
      });
      const average = sum / hotelDetail.hotel_reviews.length;
      setReviewAverage(average)
    }
    setMyArray(sortedReviews)

  }, [hotelDetail]);

  const [alreadyCheckIn, setAlreadyCheckIn] = useState(null)
  const [alreadyCheckOut, setAlreadyCheckOut] = useState(null)
  const [alreadyAdults, setAlreadyAdults] = useState(0)
  const [alreadyChildren, setAlreadyChildren] = useState(0)
  const [alreadyTotalRooms, setAlreadyTotalRooms] = useState(null)

  const today = new Date().toISOString().split('T')[0];

  const handleAlreadyCheckin = (e) => {
    const checkInDate = e.target.value;
    setAlreadyCheckIn(checkInDate);

    // Ensure check-out date is always later than check-in date
    if (alreadyCheckOut && new Date(checkInDate) >= new Date(alreadyCheckOut)) {
      setAlreadyCheckOut('');
    }
  };

  const handleAlreadyCheckOut = (e) => {
    setAlreadyCheckOut(e.target.value);
  };

  const handleAlreadyAdults = (e) => {
    setAlreadyAdults(e.target.value);
  };

  const handleAlreadyChildren = (e) => {
    setAlreadyChildren(e.target.value);
  };

  const handleAlreadyTotalRooms = (e) => {
    setAlreadyTotalRooms(e.target.value);
  };

  const [roomDataAvailable, setRoomDataAvailable] = useState(false)
  useEffect(() => {
    setRoomDataAvailable(false)
  }, []);

  useEffect(() => {
    const adults = parseInt(alreadyAdults) || 0;
    const children = parseInt(alreadyChildren) || 0;
    const sum = adults;

    let rooms = Math.ceil(sum / 2);

    if (sum === 5) rooms = 3;
    else if (sum === 7) rooms = 4;
    else if (sum === 9) rooms = 5;

    setAlreadyTotalRooms(rooms.toString());
  }, [alreadyAdults, alreadyChildren]);


  const handleSearch = async () => {

    if (!alreadyCheckIn) {
      toast.error("Please Select Checkin Date", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      return;
    }
    if (!alreadyCheckOut) {
      toast.error("Please Select Checkout Date", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      return;
    }
    if (alreadyChildren + alreadyAdults === 0) {
      toast.error("Please Select Atleast 1 person", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      return;
    }
    if (!alreadyTotalRooms) {
      toast.error("Please Select Total Rooms!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      return;
    }
    const token = localStorage.getItem("userToken");
    const checkInDate = new Date(alreadyCheckIn);
    const res = await fetch(`${BASE_URL}hotel_syt/search`, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_checkin: alreadyCheckIn,
        user_checkout: alreadyCheckOut,
        number_of_rooms: alreadyTotalRooms,
        hotel_id: id
      })
    });
    const data = await res.json();

    // Step 1: Create an array to store the filtered price and date objects
    const filteredPriceDates = [];

    // Step 2: Iterate through each room
    data.data.forEach(room => {
      // Step 3: Filter the price_and_date array
      const matchingPrices = room.price_and_date.filter(priceDateObj => {
        const startDate = new Date(priceDateObj.price_start_date);
        const endDate = new Date(priceDateObj.price_end_date);
        // Step 4: Check if alreadyCheckIn is between startDate and endDate
        return checkInDate >= startDate && checkInDate <= endDate;
      });

      // If there are matching prices, store them in the filteredPriceDates array
      if (matchingPrices.length > 0) {
        filteredPriceDates.push({
          room_id: room._id,
          room_title: room.room_title,
          matching_prices: matchingPrices // Store the filtered prices
        });
      }
    });

    const allAdultPrices = filteredPriceDates.flatMap(room =>
      room.matching_prices.map(price => price.adult_price)
    );

    const minimumPrice = allAdultPrices.length > 0 ? Math.min(...allAdultPrices) : null;
    
    setStartingPrice(Math.round(minimumPrice + (minimumPrice * 10 / 100)))

    if (data.data?.length > 0) {
      setRoomData(data.data);
      setRoomDataAvailable(true)
    } else {
      setRoomData([]);
      setRoomDataAvailable(true)
    }
    scrollTo('rooms')
  }

  const { merchentId } = useParams();

  const InsertBooking = async () => {
    const token = localStorage.getItem("userToken");
    const bookingId = localStorage.getItem("bookingId");

    const res = await fetch(`${BASE_URL}payment/updateHotelBooking/${bookingId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        status: "booked",
        transaction_id: merchentId
      }),
    });

    const data = await res.json();

    if (data?.success) {
      localStorage.removeItem("bookingId")
      localStorage.removeItem("paymentId")
      localStorage.removeItem("paidAmount")
      localStorage.removeItem("paymentType")
      localStorage.removeItem("bookingId")
      toast.success("Your Booking Successfully Done!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      navigate("/my_package")
    } else {
      toast.error(data?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    }

  };

  useEffect(() => {
    if (merchentId !== undefined && merchentId !== "") {
      InsertBooking();
    } else {

    }
  }, [merchentId]);

  const [pricePerAdult, setPricePerAdult] = useState(null)
  const [pricePerChild, setPricePerChild] = useState(null)
  const [pricePerAdultWithMargin, setPricePerAdultWithMargin] = useState(null)
  const [pricePerChildWithMargin, setPricePerChildWithMargin] = useState(null)
  const [priceAmount, setTotalAmount] = useState(null)


  const calculateAdjustedPrice2 = (priceRangeArray, date, percentageArray, totalChild, totalAdult, checkOut, checkIn) => {
    // Parse checkIn and checkOut dates
    const checkInDate = new Date(checkIn);
    const checkOutDate = new Date(checkOut);

    const timeDifference = checkOutDate - checkInDate;
    const numberOfNights = Math.max(0, Math.ceil(timeDifference / (1000 * 60 * 60 * 24)));

    // Step 1: Find the price for adults and children based on the date range
    const priceObj = priceRangeArray.find(
      item =>
        checkInDate >= new Date(item.price_start_date) &&
        checkInDate <= new Date(item.price_end_date)
    );

    // if (!priceObj) {
    //   throw new Error("No price range found for the given dates.");
    // }

    setPricePerAdult(priceObj?.adult_price)
    setPricePerChild(priceObj?.child_price)
    const adultPrice = priceObj?.adult_price;
    const childPrice = priceObj?.child_price;

    // Step 2: Extract the month from the checkIn date to find the margin percentage
    const month = checkInDate.toLocaleString("default", { month: "long" });
    const percentageObj = percentageArray.find(item => item.month_name === month);

    // If no percentage found, use 0 as the default margin
    const marginPercentage = percentageObj ? parseFloat(percentageObj.margin_percentage) : 0;

    // Step 3: Calculate adjusted prices for adults and children
    const adjustedPriceAdult = adultPrice + (adultPrice * marginPercentage / 100);
    const adjustedPriceChild = childPrice + (childPrice * marginPercentage / 100);
    setPricePerAdultWithMargin(adjustedPriceAdult)
    setPricePerChildWithMargin(adjustedPriceChild)

    // Step 4: Calculate total adjusted price per night based on the number of adults and children
    const totalAdjustedPrice = (adjustedPriceAdult * totalAdult) + (adjustedPriceChild * totalChild);
    setTotalAmount(Math.round(totalAdjustedPrice * numberOfNights))

    return Math.round(totalAdjustedPrice * numberOfNights);
  }

  function getPriceWithMargin(prices, checkinDate, checkoutDate, adminMargin) {
    // Convert checkin and checkout dates to Date objects for comparison
    const checkin = new Date(checkinDate);
    const checkout = new Date(checkoutDate);

    // Find the price object that includes the checkin date within the date range
    const selectedPrice = prices.find((price) => {
      const priceStart = new Date(price.price_start_date);
      const priceEnd = new Date(price.price_end_date);

      // Check if the checkin date is within the price date range
      return checkin >= priceStart && checkin <= priceEnd;
    });

    // If no valid price object is found, return null
    if (!selectedPrice) {
      return null;
    }

    // Calculate the amount after adding the admin margin to the adult price
    const adultPriceWithMargin = selectedPrice.adult_price + (selectedPrice.adult_price * adminMargin) / 100;

    // Return the updated price (amount with margin)
    return Math.round(adultPriceWithMargin);
  }

  const [fullname, setFullname] = useState("")
  const [email, setEmail] = useState("")
  const [gender, setGender] = useState("")
  const [number, setNumber] = useState("")
  const [city, setCity] = useState("")
  const [state, setState] = useState("")
  const [Adult, setAdult] = useState(null)
  const [Child, setChild] = useState(null)

  const [cities, setCities] = useState([])

  const handleName = (e) => {
    setFullname(e.target.value)
  }

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const handleAdultChange = (e) => setAdult(parseInt(e.target.value) || 0);
  const handleChildChange = (e) => setChild(parseInt(e.target.value) || 0);

  const handleState = (e) => {
    const selected = e.target.value;
    setState(selected)
    const selectedCity = selectedStates?.states && selectedStates?.states?.find((e) => e?.name === selected)
    setCities(selectedCity?.cities)
  }

  const handleConfirm = () => {
    navigate(`/booking-hotel-payment/${id}`, {
      state: {
        pricePerNight: Math.round(pricePerAdultWithMargin),
        finalAmountWithMargin: Math.round(priceAmount),
        finalAmountAdultWithMargin: Math.round(pricePerAdultWithMargin),
        finalAmountChildWithMargin: Math.round(pricePerChildWithMargin),
        finalAmountAdult: Math.round(pricePerAdult),
        finalAmountChild: Math.round(pricePerChild),
        totalChild: alreadyChildren,
        totalAdult: alreadyAdults,
        checkIn: alreadyCheckIn,
        checkOut: alreadyCheckOut,
        totalRooms: alreadyTotalRooms,
        roomId: room_id?._id,
        fullname: fullname,
        email: email,
        number: number,
        gender: gender,
        state: state,
        city: city,
      },
    });
  }

  const Profile = async () => {
    const token = localStorage.getItem("userToken")
    const res = await fetch(`${BASE_URL}user/userprofile`, {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    })
    const data = await res.json();

    const detail = data?.data?.[0]?.user_details?.[0]

    const selectedCity = selectedStates?.states && selectedStates?.states?.find((e) => e?.name === detail?.state)
    setCities(selectedCity?.cities)

    setFullname(detail?.name)
    setEmail(detail?.email_address)
    setGender(detail?.gender)
    setNumber(data?.data?.[0]?.phone)
    setState(detail?.state)
    setCity(detail?.city)
  }

  useEffect(() => {
    Profile();
  }, []);

  const [startingPrice, setStartingPrice] = useState(null);

  useEffect(() => {
    const getStartingFromPrice = () => {
      const currentDate = new Date();
      let possiblePrices = [];

      hotelDetail?.rooms?.forEach(room => {
        let closestPrice = null;
        let closestDateDiff = Infinity;

        room?.price_and_date?.forEach(priceObj => {
          const startDate = new Date(priceObj.price_start_date);
          const endDate = new Date(priceObj.price_end_date);

          // Check if current date is within the date range
          if (currentDate >= startDate && currentDate <= endDate) {
            possiblePrices.push(priceObj.adult_price);
          } else {
            // Calculate date difference to find the closest range
            const diff = Math.min(Math.abs(currentDate - startDate), Math.abs(currentDate - endDate));
            if (diff < closestDateDiff) {
              closestDateDiff = diff;
              closestPrice = priceObj.adult_price;
            }
          }
        });

        // If no range includes current date, add the closest available price
        if (closestPrice !== null && !possiblePrices.includes(closestPrice)) {
          possiblePrices.push(closestPrice);
        }
      });

      // const month = checkInDate.toLocaleString("default", { month: "long" });
      // const percentageObj = percentageArray.find(item => item.month_name === month);

      // // If no percentage found, use 0 as the default margin
      // const marginPercentage = percentageObj ? parseFloat(percentageObj.margin_percentage) : 0;

      // Find the minimum price from possible prices
      if (possiblePrices.length > 0) {
        return Math.min(...possiblePrices);
      }
      return null;
    };

    // Calculate the starting price and update the state
    const price = getStartingFromPrice();
    setStartingPrice(Math.round(price + (price * 10 / 100)));

  }, [hotelDetail?.rooms]);

  const sectionRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  const [sectionTop, setSectionTop] = useState(0);

  useEffect(() => {
    // Set initial position of the section from the top of the document
    if (sectionRef.current) {
      setSectionTop(sectionRef.current.offsetTop);
    }

    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      // Make sticky if scroll position is beyond the section's initial top position
      if (scrollPosition >= sectionTop) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [sectionTop]);

  return (
    <>
      <Header />

      {/*  <!----------------------------------------------- image section ---------------------------------------------> */}

      <section class="pt-5">
        <div class="container-customes">
          <div class="row text-center">
            <div className="col-8">
              <div className="row h-100">
                <div className="col-5 h-100 display-none-mobile">
                  <div className="hotel-details-classname-header-images hotel-details-classname-sea-image h-100">
                    <img src={hotelDetail?.hotel_photo?.[0]} alt="Hotel Main" className="hotel-details-classname-main-image h-100" />
                  </div>
                </div>
                <div className="col-7 h-100 display-none-mobile">
                  <div className="row gy-1 h-100">
                    {[1, 2, 3, 4].map((index) => (
                      <div key={index} className="col-6 hotel-details-classname-img-wrapper h-50">
                        <img src={hotelDetail?.hotel_photo?.[index]} alt={`Hotel Thumbnail ${index}`} className="hotel-details-classname-thumbnail h-100 w-100" />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <div className="availibility">
                <h4 className="text18 font700">Check Availability</h4>
                <div className="d-flex align-items-center mb-3">
                  <label htmlFor="checkin-date" style={{ width: "55%" }} className="font600">
                    Check-In date :
                  </label>
                  <input
                    type="date"
                    id="checkin-date"
                    value={alreadyCheckIn}
                    min={today}
                    className="px-2"
                    onChange={handleAlreadyCheckin}
                  />
                </div>
                <div className="d-flex align-items-center mb-3">
                  <label htmlFor="checkout-date" style={{ width: "55%" }} className="font600">
                    Check-Out date :
                  </label>
                  <input
                    type="date"
                    id="checkout-date"
                    value={alreadyCheckOut}
                    min={alreadyCheckIn ? new Date(new Date(alreadyCheckIn).getTime() + 86400000).toISOString().split('T')[0] : today}
                    className="px-2"
                    onChange={handleAlreadyCheckOut}
                    disabled={!alreadyCheckIn}
                  />
                </div>
                <div className="row text-center mb-4">
                  <div className="col-4">
                    <label htmlFor="adults-select" className="font600">Adult</label>
                    <select
                      id="adults-select"
                      className="form-select"
                      aria-label="Default select example"
                      value={alreadyAdults}
                      onChange={handleAlreadyAdults}
                    >
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                    </select>
                  </div>
                  <div className="col-4">
                    <label htmlFor="children-select" className="font600">Children</label>
                    <select
                      id="children-select"
                      className="form-select"
                      aria-label="Default select example"
                      value={alreadyChildren}
                      onChange={handleAlreadyChildren}
                    >
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                    </select>
                  </div>
                  <div className="col-4">
                    <label htmlFor="rooms-select" className="font600">Total Rooms</label>
                    <select
                      id="rooms-select"
                      className="form-select"
                      aria-label="Default select example"
                      value={alreadyTotalRooms}
                      onChange={handleAlreadyTotalRooms}
                    >
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                    </select>
                  </div>
                </div>
                <button className="check-availibility-button mb-2" onClick={handleSearch}>
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section ref={sectionRef} className={`whyso ${isSticky ? 'sticky' : ''}`}>
        <div className="container-customes stick-link-bar">
          <div className={`row rounded px-4 pb-1 pt-2 text-center gx-2 ${isSticky ? 'hshshs' : ''}`}>
            <div className="col-6 Overview">
              <div className="d-flex justify-content-between align-items-center">
                <a onClick={() => scrollTo('overview')} style={{ cursor: "pointer" }} className="font700">Overview</a>
                <a onClick={() => scrollTo('rooms')} style={{ cursor: "pointer" }} className="d-lg-block d-md-none d-none font700">
                  Rooms
                </a>
                <a onClick={() => scrollTo('facilities')} style={{ cursor: "pointer" }} className="d-lg-block d-md-none d-none font700">
                  Facilities
                </a>
                <a onClick={() => scrollTo('location')} style={{ cursor: "pointer" }} className="d-lg-block d-md-none d-none font700">
                  Location
                </a>
                <a onClick={() => scrollTo('policies')} style={{ cursor: "pointer" }} className="d-lg-block d-md-none d-none font700">
                  Policies
                </a>
                <a className="d-lg-block d-md-none d-none font700">
                  {myArray && myArray?.length !== 0 && (
                    <span onClick={() => scrollTo('review')} style={{ cursor: "pointer" }}>Review</span>
                  )}
                </a>
              </div>
            </div>
            <div className="col-6 rupees text-end">
              <span>starting from</span>
              <h4 className="d-inline ms-1">
                <FontAwesomeIcon icon={faIndianRupeeSign} /> {startingPrice}
              </h4>
            </div>
          </div>
        </div>
      </section>

      {/* <!---------------------------------------- center ---------------------------------------------> */}

      <section ref={overRef} className={`${isSticky ? 'overrefcss' : ''}`}>
        <div class="container-customes" >
          <div class="row gx-3" >
            <div class="col-xl-12 col-lg-8 col-md-12 col-sm-12">
              <div class="rose-valley" >
                <div>
                  <div>
                    <div class="d-flex flex-xl-row flex-lg-row flex-md-row flex-sm-column flex-column">
                      <div class="me-4">
                        <h4 class="m-0 text-start font700 text18">{hotelDetail.hotel_name}</h4>
                      </div>
                      <div class="d-flex">
                        {[...Array(totalStars)].map((_, index) => {
                          return (
                            <FontAwesomeIcon
                              key={index}
                              icon={faStar}
                              style={{ color: index < reviewAverage ? 'yellow' : '#DDDDDD' }}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <p class="mt-2 pb-3 border-bot text14">
                    {hotelDetail.hotel_address},{hotelDetail.city},{hotelDetail.state}
                  </p>
                </div>
                <div class="rose-paragraph">
                  <p className="font500">{hotelDetail.hotel_description}</p>
                </div>
                <div>
                  <div className="font700 text16">Other Details</div>
                  <ul className="mt-2">
                    {hotelDetail.other?.map(e => (
                      <li className="text14">{e}</li>
                    ))}
                  </ul>
                </div>
              </div>

              <div class="highlights my-4">
                <div class="me-4 mb-4">
                  <h4 class="m-0 text18 font700">Highlights</h4>
                </div>
                {/* <div class="row text-center gy-4">
                  {hotelDetail?.Highlights && hotelDetail?.Highlights.map((e) => (
                    <div className="col-1">
                      <img src={e.icon} alt="" class="mb-2" style={{ height: "40px", width: "40px" }} />
                      <p className="font600">{e.title}</p>
                    </div>
                  ))}
                </div> */}
                <div>
                  <HotelHighlightsCarousel hotelDetail={hotelDetail} />
                </div>
              </div>

              <div className="highlights">
                <h4 className="text18 font700">Rooms</h4>
                {!roomDataAvailable &&
                  <div ref={roomsRef} class="delux-room">
                    {hotelDetail.rooms &&
                      hotelDetail.rooms?.map((ele) => {
                        return (
                          <div >
                            <div className="d-block d-sm-flex h-100" style={{ border: "1px solid #d6d4d4", padding: "10px", borderRadius: "20px" }}>
                              <div>
                                <Carousel style={{ height: "200px", width: "300px" }}>
                                  {ele.photos.map((e, index) => (
                                    <Carousel.Item key={index} interval={2000} style={{ position: "relative" }} >
                                      <img src={e} alt={`Slide ${index}`} className="inner-rooms-image" />
                                    </Carousel.Item>
                                  ))}
                                </Carousel>
                              </div>
                              <div className="h-100" >
                                <div className="facilities h-100 d-flex flex-column pb-0">
                                  <h5 className="font700 text17">
                                    {ele.room_title}
                                  </h5>
                                  <div>
                                    <ul className="mb-1 row">
                                      {ele?.room_highlights?.slice(0, 9)?.map((elem, index, array) => (
                                        <li className="col-12 mb-1 font500 text14" style={{ color: "#393939" }} key={elem} >
                                          {elem}
                                          {index === array.length - 1 && ele?.room_highlights?.length > 9 && (
                                            <span className="text-primary float-end text-end font600 text15" style={{ cursor: "pointer" }}> more...</span>
                                          )}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                  <div className="mt-auto">
                                    <h2 className="mb-xl-0 mb-lg-3 mb-md-2 mb-sm-1 mb-1  text20 font700">
                                      <FontAwesomeIcon icon={faIndianRupeeSign} />
                                      {getPriceForDateRange(getTodayDate(), ele?.price_and_date, getMarginPercentage(getTodayDate(), hotelDetail?.profitMargin?.[0]?.month_and_margin_user))} <span>per night</span>
                                    </h2>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    <BookingModal
                      isOpen={isModalOpen}
                      onClose={() => setIsModalOpen(false)}
                      hotelId={id}
                      hotelData={hotelDetail}
                      roomId={room_id}
                    />
                  </div>}

                {roomDataAvailable &&
                  <div ref={roomsRef} class="delux-room" >
                    {roomData &&
                      roomData.map((ele) => {
                        return (
                          <div >
                            <div className="d-block d-sm-flex h-100" style={{ border: "1px solid #d6d4d4", padding: "10px", borderRadius: "20px" }}>
                              <div>
                                <Carousel style={{ height: "200px", width: "300px" }}>
                                  {ele.photos.map((e, index) => (
                                    <Carousel.Item key={index} interval={2000} >
                                      <img src={e} alt={`Slide ${index}`} className="inner-rooms-image" />
                                    </Carousel.Item>
                                  ))}
                                </Carousel>
                              </div>
                              <div >
                                <div className="facilities d-flex flex-column pb-0 h-100">
                                  <h5 className="text16 font700">
                                    {ele.room_title}
                                    <span className={`badge ms-2 ${ele.status === 'available' ? 'bg-success' : ''} ${ele.status === 'sold out' ? 'bg-danger' : ''}`}>
                                      {ele.status}
                                    </span>
                                  </h5>
                                  <div>
                                    <ul className="mb-1 row">
                                      {ele?.room_highlights?.slice(0, 9)?.map((elem, index, array) => (
                                        <li className="col-12 mb-1 font600 text13" key={elem} >
                                          {elem}
                                          {index === array.length - 1 && ele?.room_highlights?.length > 9 && (
                                            <span className="text-primary float-end text-end font600 text15" style={{ cursor: "pointer" }}> more...</span>
                                          )}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                  <div className="mt-auto">
                                    <h2 className="mb-xl-3 mb-lg-3 mb-md-2 mb-sm-1 mb-1 text20 font700">
                                      <FontAwesomeIcon icon={faIndianRupeeSign} />
                                      {/* {calculateAdjustedPrice1(
                                      ele?.price_and_date, 
                                      ele?.price_and_date?.[0]?.price_start_date, 
                                      hotelDetail?.profitMargin?.[0]?.month_and_margin_user, 
                                      alreadyChildren, 
                                      alreadyAdults, 
                                      alreadyCheckOut, 
                                      alreadyCheckIn
                                      )} */}
                                      {getPriceWithMargin(ele?.price_and_date, alreadyCheckIn, alreadyCheckOut, Number(ele?.adminMarginPercentage) || 10)}
                                      <span>per night</span>
                                    </h2>
                                    <div className="">
                                      {ele.status !== "sold out" && (
                                        <button className="check-availibility-button text-20 border-0 "
                                          onClick={() => {
                                            // setIsModalOpen(true);
                                            setRoomId(ele);
                                            handleShow();
                                            calculateAdjustedPrice2(
                                              ele?.price_and_date,
                                              ele?.price_and_date?.[0]?.price_start_date,
                                              hotelDetail?.profitMargin?.[0]?.month_and_margin_user,
                                              alreadyChildren,
                                              alreadyAdults,
                                              alreadyCheckOut,
                                              alreadyCheckIn
                                            )
                                          }}
                                        >Book Now</button>
                                      )}
                                      {/* <button className="ms-2">View Detail</button> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    <BookingModal
                      isOpen={isModalOpen}
                      onClose={() => setIsModalOpen(false)}
                      hotelId={id}
                      roomId={room_id}
                      checkindate={alreadyCheckIn}
                      checkoutdate={alreadyCheckOut}
                      totalRooms={alreadyTotalRooms}
                      alreadyAdults={alreadyAdults}
                      alreadyChildren={alreadyChildren}
                      pricePerAdult={pricePerAdult}
                      pricePerChild={pricePerChild}
                      priceAmount={priceAmount}
                      pricePerChildWithMargin={pricePerChildWithMargin}
                      pricePerAdultWithMargin={pricePerAdultWithMargin}
                    />

                    <Modal show={show} onHide={handleClose} centered>
                      <Modal.Body>
                        <div>
                          <Row>
                            <div className='col-md-12 col-12'>
                              <form>
                                <div
                                  style={{
                                    background: "linear-gradient(#00363D, #005C63)",
                                    color: "#fff",
                                    borderRadius: "8px",
                                  }}
                                  className='p-1 d-flex justify-content-between align-items-center'
                                >
                                  <div>
                                    <h4 className="text18 font600 ms-2 mb-1" style={{ color: "white" }}>{room_id?.room_title}</h4>
                                    <span className="text12 font600 ms-2">{alreadyTotalRooms} Room ({Number(alreadyChildren) + Number(alreadyAdults)} Person)</span>
                                  </div>
                                  <div>
                                    <span className='cmnp textcm'>
                                      <FontAwesomeIcon
                                        icon={faIndianRupeeSign}
                                        className=''
                                      />{" "}
                                      {Math.round(pricePerAdultWithMargin * Number(alreadyTotalRooms))}
                                    </span>
                                    <p className='cmnp pb-1 textcm'>
                                      Per Night
                                    </p>
                                    <div className=' textcm'>
                                      <FontAwesomeIcon icon={faStar} className='inner_star' />
                                      <FontAwesomeIcon icon={faStar} className='inner_star' />
                                      <FontAwesomeIcon icon={faStar} className='inner_star' />
                                      <FontAwesomeIcon icon={faStar} className='inner_star' />
                                      <FontAwesomeIcon icon={faStar} className='inner_star' />
                                    </div>
                                  </div>
                                </div>
                                <div className='cmn p-1 mt-3 px-3'>
                                  <p className='text-center text20 font700 mb-3 mt-1'>Booking Details</p>
                                  <div>
                                    <div className="row gx-2">
                                      <div className="col-6">
                                        <p className="mb-1 text14 font600">Check In</p>
                                        <Form.Control
                                          type="date"
                                          value={alreadyCheckIn}
                                          // onChange={handleArrivalChange}
                                          // min={formattedTomorrow}
                                          // max={maxLimit}
                                          style={{
                                            borderLeft: "none",
                                            borderRight: "none",
                                            borderTop: "none",
                                            fontSize: "14px",
                                          }}
                                          placeholder='Infants'
                                          className='mt-1 my-2 py-2'
                                          name='infant'
                                          disabled
                                        />
                                      </div>
                                      <div className="col-6">
                                        <p className="mb-1 text14 font600">Check Out</p>
                                        <Form.Control
                                          type="date"
                                          value={alreadyCheckOut}
                                          // onChange={handleArrivalChange}
                                          // min={formattedTomorrow}
                                          // max={maxLimit}
                                          style={{
                                            borderLeft: "none",
                                            borderRight: "none",
                                            borderTop: "none",
                                            fontSize: "14px",
                                          }}
                                          placeholder='Infants'
                                          className='mt-1 my-2 py-2'
                                          name='infant'
                                          disabled
                                        />
                                      </div>
                                    </div>
                                    <p className="mb-1 text14 font600">Full Name</p>
                                    <Form.Control
                                      type='text'
                                      placeholder='Full Name'
                                      style={{
                                        borderLeft: "none",
                                        borderRight: "none",
                                        borderTop: "none",
                                        fontSize: "14px",
                                      }}
                                      value={fullname}
                                      onInput={(e) => {
                                        e.target.value = e.target.value.replace(
                                          /[^A-Za-z ]/g,
                                          "",
                                        )
                                        handleName(e)
                                      }}
                                      className='mt-1 my-2 py-2'
                                      name='fname'
                                    // readOnly
                                    />

                                    <p className="mb-1 text14 font600">Email Id</p>
                                    <Form.Control
                                      type='text'
                                      placeholder='Email id'
                                      style={{
                                        borderLeft: "none",
                                        borderRight: "none",
                                        borderTop: "none",
                                        fontSize: "14px",
                                      }}
                                      value={email}
                                      onChange={(e) => setEmail(e.target.value)}
                                      className='mt-1 my-2 py-2'
                                      name='email'
                                    // readOnly
                                    />

                                    <p className="mb-1 text14 font600">Mobile No.</p>
                                    <Form.Control
                                      type='text'
                                      placeholder='Mobile Number'
                                      style={{
                                        borderLeft: "none",
                                        borderRight: "none",
                                        borderTop: "none",
                                        fontSize: "14px",
                                      }}
                                      value={number}
                                      onInput={(e) => {
                                        // Remove any non-digit characters
                                        let value = e.target.value.replace(/[^0-9]/g, "");

                                        // Limit to 10 digits
                                        if (value.length > 10) {
                                          value = value.substring(0, 10);
                                        }

                                        // Update state with the formatted value
                                        // handleNumber(value);
                                        setNumber(value);
                                      }}
                                      className='mt-1 my-2 py-2'
                                      name='mobile'
                                    // readOnly
                                    />

                                    <div className="row gx-2">
                                      <div className="col-6">
                                        <p className="mb-1 text14 font600">Select State</p>
                                        <Form.Select
                                          style={{
                                            borderLeft: "none",
                                            borderRight: "none",
                                            borderTop: "none",
                                            fontSize: "14px",
                                          }}
                                          value={state}
                                          onChange={handleState}
                                          className='mt-1 my-2 py-2'
                                          name='Departure'
                                        >
                                          <option value="">Select State</option>
                                          {selectedStates && selectedStates?.states?.map((e) => (
                                            <option value={e?.name}>{e.name}</option>
                                          ))}
                                        </Form.Select>
                                      </div>

                                      <div className="col-6">
                                        <p className="mb-1 text14 font600">Select City</p>
                                        <Form.Select
                                          style={{
                                            borderLeft: "none",
                                            borderRight: "none",
                                            borderTop: "none",
                                            fontSize: "14px",
                                          }}
                                          value={city}
                                          onChange={(e) => setCity(e.target.value)}
                                          className='mt-1 my-2 py-2'
                                          name='Departure'
                                        >
                                          <option value="">Select City</option>
                                          {cities && cities?.map((e) => (
                                            <option value={e?.name}>{e.name}</option>
                                          ))}
                                        </Form.Select>
                                      </div>
                                    </div>

                                    <div className="row gx-2">
                                      <div className="col-6">
                                        <p className="mb-1 text14 font600">Adults</p>
                                        <Form.Control
                                          type="number"
                                          value={alreadyAdults}
                                          onChange={handleAdultChange}
                                          disabled
                                          min="0"
                                          style={{
                                            borderLeft: "none",
                                            borderRight: "none",
                                            borderTop: "none",
                                            fontSize: "14px",
                                          }}
                                          placeholder='Adult'
                                          className='mt-1 my-2 py-2'
                                          name='adult'
                                        />
                                      </div>
                                      <div className="col-6">
                                        <p className="mb-1 text14 font600">Childrens</p>
                                        <Form.Control
                                          type="number"
                                          value={alreadyChildren}
                                          onChange={handleChildChange}
                                          min="0"
                                          style={{
                                            borderLeft: "none",
                                            borderRight: "none",
                                            borderTop: "none",
                                            fontSize: "14px",
                                          }}
                                          placeholder='Kids'
                                          className='mt-1 my-2 py-2'
                                          name='kids'
                                          disabled
                                        />
                                      </div>
                                    </div>

                                    {/* <p className="mb-1 text14 font600">Destination Arrival Date</p>
                                <Form.Control
                                  type="date"
                                  value={arrivalDate}
                                  onChange={handleArrivalChange}
                                  min={formattedTomorrow}
                                  max={maxLimit}
                                  style={{
                                    borderLeft: "none",
                                    borderRight: "none",
                                    borderTop: "none",
                                    fontSize: "14px",
                                  }}
                                  placeholder='Infants'
                                  className='mt-1 my-2 py-2'
                                  name='infant'
                                /> */}

                                  </div>
                                </div>
                                <div className='text-center pt-2'>
                                  <p
                                    className='w-100 border-0 py-2 font700'
                                    style={{
                                      background: "#09646D",
                                      color: "#fff",
                                      borderRadius: "8px",
                                      cursor: "pointer",
                                    }}
                                    onClick={handleConfirm}
                                  >
                                    Proceed to Pay
                                  </p>
                                </div>
                              </form>
                            </div>
                          </Row>
                        </div>
                      </Modal.Body>
                    </Modal>

                    {/* <button onClick={handleSlidePrev} className="room-swipe-left-btn"> <FontAwesomeIcon icon={faLeftLong} /></button>
                  <button onClick={handleSlideNext} className="room-swipe-right-btn"><FontAwesomeIcon icon={faRightLong} /></button> */}
                  </div>}
              </div>

              {/* amenities  */}
              <div class="amenities my-4" ref={facilities}>
                <h5 class="text18 font700">Amenities and facilities</h5>
                <div class="row px-4">
                  {amenities && amenities.map((e) => (
                    <div class="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-12 antiviral">
                      <h6 className="font600">{e.title}</h6>
                      <ul class="mb-1">
                        {e.points.map((data) => (
                          <li className="mb-1 font500 text14" style={{ color: "#393939" }}>{data}</li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!---------------------------------------- map -------------------------------------------> */}

      <section class="py-4" ref={location}>
        <div class="container-customes">
          <div class="row">
            <div class="col-12">
              <h4 className="text18 font700">Location</h4>
              {/* <iframe
                src={googleMapsEmbedUrl}
                width="100%"
                height="450"
                style={{ borderRadius: "10px" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe> */}
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d394.7687421203096!2d72.6283177766194!3d23.1137653772653!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e84604595e899%3A0x3e7418c1b4e56140!2sWebEarl%20Technologies%20Private%20Limited!5e1!3m2!1sen!2sin!4v1730285566510!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </section>

      {/* <!------------------------------- footer ----------------------------------------> */}

      <section class="my-4 pb-4" ref={policies}>
        <div class="container-customes">
          <div class="row foot-center">
            <div class="col footer">
              <div class="foot">
                <div class="footer-head">
                  <h5 class="text18 font700">Property policies</h5>
                  <h6 class="mb-1">{propertyDetail?.policy_title}</h6>
                  <p>
                    {propertyDetail?.policy_description}
                  </p>
                </div>
                <div class="row gx-3">
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 h-100">
                    <div class="box" style={{ height: "275px" }}>
                      <div class="mb-3">
                        <h5 class="pb-2 text16 font700">Infant 0-2 year</h5>
                      </div>
                      <div>
                        <h6 className="font600">{propertyDetail?.infant}</h6>
                        <ul class="mb-1">
                          {propertyDetail && propertyDetail?.infant_points?.slice(0, 7)?.map((elem, index, array) => (
                            <li className="mb-1 font500 text14" style={{ color: "#393939" }}>
                              {elem}
                              {index === array.length - 1 && propertyDetail?.infant_points?.length > 9 && (
                                <span className="text-primary float-end text-end font600 text15" style={{ cursor: "pointer" }}> more...</span>
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 h-100">
                    <div class="box" style={{ height: "275px" }}>
                      <div class="mb-3">
                        <h5 class="pb-2 text16 font700">Children 3-11 year</h5>
                      </div>
                      <div>
                        <h6 className="font600">{propertyDetail?.children}</h6>
                        <ul class="mb-1">
                          {propertyDetail && propertyDetail?.childern_points?.slice(0, 7)?.map((elem, index, array) => (
                            <li className="mb-1 font500 text14" style={{ color: "#393939" }}>
                              {elem}
                              {index === array.length - 1 && propertyDetail?.childern_points?.length > 9 && (
                                <span className="text-primary float-end text-end font600 text15" style={{ cursor: "pointer" }}> more...</span>
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 h-100">
                    <div class="box" style={{ height: "275px" }}>
                      <div class="mb-3">
                        <h5 class="pb-2 text16 font700">Adults 12 & above</h5>
                      </div>
                      <div>
                        <h6 className="font600">{propertyDetail?.infant}</h6>
                        <ul class="mb-1">
                          {propertyDetail && propertyDetail?.adult_and_above_points?.slice(0, 7)?.map((elem, index, array) => (
                            <li className="mb-1 font500 text14" style={{ color: "#393939" }}>
                              {elem}
                              {index === array.length - 1 && propertyDetail?.adult_and_above_points?.length > 9 && (
                                <span className="text-primary float-end text-end font600 text15" style={{ cursor: "pointer" }}> more...</span>
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="others mt-3">
                  {propertyDetail?.policy_other && (
                    <h5 className="text18 font700">Others</h5>
                  )}
                  <ul>
                    {propertyDetail && propertyDetail?.policy_other.map((e) => (
                      <li className="mb-1 font500 text14" style={{ color: "#393939" }}>
                        {e}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {myArray && myArray?.length !== 0 && (
        <section className="my-4 pb-4" ref={reviewRef}>
          <div class="container-customes">
            <div class="row foot-center">
              <div class="col footer">
                <div class="foot">
                  <div class="footer-head hotel-review-box">
                    <div>
                      <h5 className="mb-1 text18 font700">Reviews</h5>
                      <div className="d-flex align-items-center gap-2">
                        {[...Array(totalStars)].map((_, index) => {
                          return (
                            <FontAwesomeIcon
                              key={index}
                              icon={faStar}
                              style={{ color: index < reviewAverage ? 'yellow' : '#DDDDDD' }}
                            />
                          );
                        })}
                        {reviewAverage || 0}
                      </div>
                    </div>
                  </div>
                  <hr className="mb-5" />
                  <div className="hotel-riviews">
                    {myArray && myArray?.map((e) => (
                      <div className="hotel-review-specific">
                        <div className="personal-review">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex hotel-review-header">
                              <div>
                                <img src="" alt="" />
                              </div>
                              <div>
                                <h5 className="mb-1">{e?.user_details?.name}</h5>
                                <p className="mb-0">{e?.createdAt?.slice(0, 10)}</p>
                              </div>
                            </div>
                            <div>
                              <Rating
                                name="simple-controlled"
                                value={parseInt(e?.star)}
                                readOnly
                              />
                            </div>
                          </div>
                          <hr />
                          <div>
                            <p>{e?.comment_box}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      <hr />


      <Footer />
    </>
  );
}

export default Hotel_Details;
