import React, { useState } from "react";

function ActivityContentServices({ activity, charCount }) {
    const [showFullContent, setShowFullContent] = useState(false);
    const toggleContent = () => setShowFullContent(!showFullContent);

    const truncatedContent = activity?.slice(0, charCount);
    const isTruncated = activity?.length > charCount;

    return (
        <div className="cmnp cmnclr font-weight-bolder">
            <div
                style={{ textAlign: "justify", fontSize: "14px" , color: "black" }}
                dangerouslySetInnerHTML={{
                    __html: showFullContent
                        ? activity
                        : isTruncated
                            ? `${truncatedContent}...`
                            : truncatedContent,
                }}
            />
            {isTruncated && (
                <div className="text-end">
                    <a
                        className="text16 font600"
                        onClick={toggleContent}
                        style={{
                            color: "black",
                            textDecoration: "none",
                            cursor: "pointer",
                            background: "none",
                            border: "none",
                        }}
                    >
                        {showFullContent ? "Show Less" : "Read More"}
                    </a>
                </div>
            )}
        </div>
    );
}

export default ActivityContentServices;