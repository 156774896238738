import React from "react"
import { useState } from "react"
import { NavLink } from "react-router-dom"
import { BASE_URL } from "../BASE_URL"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function Footer(props) {

  const [email, setEmail] = useState({
    email_id: "",
  })

  const txtEmail = (e) => {
    const { name, value } = e.target
    setEmail({ ...email, [name]: value })
  }

  const sendMail = async (e) => {
    e.preventDefault()

    const token = localStorage.getItem("userToken")

    const { email_id } = email

    if (!email.email_id) {
      toast.error("Please enter email address!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      return
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(email.email_id)) {
      toast.error("Invalid email address!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      return
    }

    const res = await fetch(`${BASE_URL}subscribe`, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email_id,
      }),
    })
    const data = await res.json()
    if (data.code == 200) {
      toast.success("Successfully Subscribed!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      setEmail({ email_id: "" })
    }

    if (data.code == 403) {
      toast.error("You are already Subscriber!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      setEmail({ email_id: "" })
    }
  }

  return (
    <>
      <footer>
        <div className='submission footer_list py-5'>
          <div className='container'>
            <div className='row submission-text'>
              <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6'>
                <ul>
                  <li>
                    <ToastContainer />
                    <NavLink to='/' style={{ textDecoration: "none" }}>
                      <h6 className="font700 mb-0">START YOUR TOUR</h6>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/aboutus' className="font600" style={{ textDecoration: "none" }}>
                      About Us
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/career' className="font600" style={{ textDecoration: "none" }}>
                      Careers
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/blog' className="font600" style={{ textDecoration: "none" }}>
                      Blog
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='' className="font600" style={{ textDecoration: "none" }}>
                      Testimonials
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='' className="font600" style={{ textDecoration: "none" }}>
                      Sitemap
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/contactus' className="font600" style={{ textDecoration: "none" }}>
                      Contact Us
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6'>
                <ul className='ay-2'>
                  <li>
                    <h6 className="font700 mb-0">Policy</h6>
                  </li>
                  <li>
                    <NavLink to='/faqs' className="font600" style={{ textDecoration: "none" }}>
                      Frequently Asked Questions
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/cancellation-policy' className="font600">
                      Cancellation Policy
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/privacy-policy' className="font600">Privacy Policy</NavLink>
                  </li>
                  <li>
                    <NavLink to='/payment-policy' className="font600">Payment Policy</NavLink>
                  </li>
                </ul>
              </div>
              <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 order-sm-1  order-2'>
                <ul className='email_image'>
                  <li>
                    <h6 className="font700 mb-0">Talk to us</h6>
                  </li>
                  <li>
                    <a className="font600" href='mailto:info@startourtour.com'>
                      <img src='/email.png' alt='' className='img-fluid' />
                      info@startourtour.com
                    </a>
                  </li>
                  <li>
                    <a className="font600" href='tel:+91 90332 5190'>
                      <img
                        src='/phone_number.png'
                        alt=''
                        className='img-fluid'
                      />
                      +91 90332 51903
                    </a>
                  </li>
                  <li>
                    <a className="font600" href='tel:+91 96623 99609'>
                      <img src='/whatsapp.png' alt='' className='img-fluid' />
                      +91 96623 99609
                      <i class='fa-brands fa-facebook'></i>
                    </a>
                  </li>
                  <ul className='mt-5 ps-0'>
                    <li className="pt-2">
                      <h6 className="">Subscribe For Travel Ideas!</h6>
                    </li>
                    <li>
                      <div className='travel_ideas'>
                        <input
                          type='email'
                          name='email_id'
                          value={email.email_id}
                          id=''
                          placeholder='Your email'
                          className='px-3 py-2 rounded'
                          onChange={txtEmail}
                        />
                        <img
                          src='/sendimage1.jpg'
                          alt=''
                          style={{
                            height: "36.4px",
                            backgroundColor: "transparent",
                          }}
                          onClick={sendMail}
                        />
                      </div>
                    </li>
                  </ul>
                </ul>
              </div>
              <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 order-sm-2  order-1'>
                <ul className=''>
                  <li>
                    <h6 className="font700 mb-0">Social Media</h6>
                  </li>
                  <li>
                    <a
                      href='https://www.facebook.com/StartY0urT0ur/'
                      target='_blank'
                       className="font600"
                      rel='noreferrer'
                    >
                      <img
                        src='/facebook-icon.png'
                        alt=''
                        className='img-fluid'
                      />
                    </a>
                    <a
                      href='https://www.facebook.com/StartY0urT0ur/'
                      target='_blank'
                      className='ms-2 font600'
                      rel='noreferrer'
                    >
                      facebook
                    </a>
                  </li>

                  <li className='mt-2'>
                    <a
                      href='https://www.instagram.com/startyourtour_/'
                      target='_blank'
                       className="font600"
                      rel='noreferrer'
                    >
                      <img src='/insta-icon.png' alt='' className='img-fluid' />
                    </a>
                    <a
                      href='https://www.instagram.com/startyourtour_/'
                      target='_blank'
                      className='ms-2 font600'
                      rel='noreferrer'
                    >
                      instagram
                    </a>
                  </li>
                  <li className='mt-2'>
                    <a
                      href='https://twitter.com/startyourtour'
                      target='_blank'
                      className='font600'
                      rel='noreferrer'
                    >
                      <img
                        src='/twitter-icon.png'
                        alt=''
                        className='img-fluid'
                      />
                    </a>
                    <a
                      href='https://twitter.com/startyourtour'
                      target='_blank'
                      className='ms-2 font600'
                      rel='noreferrer'
                    >
                      twitter
                    </a>
                  </li>
                  <li className='mt-2'>
                    <a
                      href='https://www.linkedin.com/company/start-your-tour/'
                      target='_blank'
                      className='font600'
                      rel='noreferrer'
                    >
                      <img src='/link-icon.png' alt='' className='img-fluid' />
                    </a>
                    <a
                      href='https://www.linkedin.com/company/start-your-tour/'
                      target='_blank'
                      className='ms-2 font600'
                      rel='noreferrer'
                    >
                      linkedin
                    </a>
                  </li>
                  <li className='mt-3'>
                    <p
                      style={{
                        color: "#09646d",
                        fontWeight: "600",
                        fontSize: "18px",
                      }}
                    >
                      SYT TravelTech pvt Ltd
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
