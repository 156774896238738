import React, { useEffect, useState } from "react";
import Header from "./Header";
import { Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faIndianRupeeSign,
    faStar,
    faLocationPin,
} from "@fortawesome/free-solid-svg-icons";
import "./css/index1.css";
import { BASE_URL } from "../BASE_URL";
import { useNavigate } from "react-router-dom";

function AllCars() {
    const navigate = useNavigate();

    const [car, setCar] = useState([]);
    const [filterCriteria, setFilterCriteria] = useState({
        sort: "",
        carName: "",
        priceRange: "",
    });

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem("userToken");
            const res = await fetch(`${BASE_URL}vendor_car_syt/getAll/`, {
                method: "GET",
                headers: { Authorization: token, "Content-Type": "application/json" },
            });
            const data = await res.json();
            setCar(data?.data || []);
        };
        fetchData();
    }, []);

    const handleNavigate = (carData) => {
        const token = localStorage.getItem("userToken");
        if (token) {
            navigate("/car-detail", { state: { carData } });
        } else {
            setShow(true);
        }
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilterCriteria((prev) => ({ ...prev, [name]: value }));
    };

    const filteredCars = car?.filter((car) => {
        if (filterCriteria.carName) {
            return car.car_details[0]?.car_name
                .toLowerCase()
                .includes(filterCriteria.carName.toLowerCase());
        }
        return true;
    })
        ?.filter((car) => {
            if (filterCriteria.priceRange) {
                const price = parseInt(car.price_per_km);
                switch (filterCriteria.priceRange) {
                    case "lessThan100":
                        return price < 100;
                    case "100To200":
                        return price >= 100 && price <= 200;
                    case "200To300":
                        return price >= 200 && price <= 300;
                    case "300To400":
                        return price >= 300 && price <= 400;
                    case "400To500":
                        return price >= 400 && price <= 500;
                    case "above500":
                        return price > 500;
                    default:
                        return true;
                }
            }
            return true;
        })
        .sort((a, b) => {
            if (filterCriteria.sort === "highToLow") {
                return b.price_per_km - a.price_per_km;
            } else if (filterCriteria.sort === "lowToHigh") {
                return a.price_per_km - b.price_per_km;
            }
            return 0;
        });

    const [show, setShow] = useState(false);

    return (
        <>
            <Header setShow={setShow} show={show} />

            <section>
                <div className="container-customes">
                    <div className="pt-4 pb-2 text-center">
                        <h2 className="mb-0 text22 font700">Book Your Perfect Car with Start Your Tour</h2>
                    </div>

                    <div className="row">
                        <div
                            className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-6 d-xl-block d-lg-none d-md-none d-sm-none d-none  text-start"
                            style={{
                                position: "sticky",
                                top: "0px",
                            }}
                        >
                            <div className="sort-list filter px-3 py-3">
                                <div>
                                    <ul className="list-none ps-0">
                                        <li className="d-flex justify-content-between">
                                            <h6 className="filter-headers text16 font700">Sort by</h6>
                                            <div className="reset-filter-button">
                                                <button className="text14" onClick={() => setFilterCriteria({
                                                    sort: "",
                                                    carName: "",
                                                    priceRange: "",
                                                })}>RESET</button>
                                            </div>
                                        </li>
                                        <li className="text14">
                                            <input
                                                type="radio"
                                                name="sort"
                                                value="highToLow"
                                                className="input-margin"
                                                checked={filterCriteria.sort === "highToLow"}
                                                onChange={handleFilterChange}
                                            />
                                            High To Low
                                        </li>
                                        <li className="text14">
                                            <input
                                                type="radio"
                                                name="sort"
                                                value="lowToHigh"
                                                className="input-margin"
                                                checked={filterCriteria.sort === "lowToHigh"}
                                                onChange={handleFilterChange}
                                            />
                                            Low To High
                                        </li>
                                    </ul>
                                </div>
                                <div className="mb-3 ">
                                    <label htmlFor="" className="filter-headers mb-1 text16 font700">
                                        Enter Car Name
                                    </label>
                                    <input
                                        type="text"
                                        name="carName"
                                        value={filterCriteria.carName}
                                        onChange={handleFilterChange}
                                        className="px-2 py-1 focus-outline"
                                        style={{
                                            borderRadius: "10px",
                                            border: "1px solid lightgrey",
                                        }}
                                    />
                                </div>
                                <div className="mb-3">
                                    <h6 className="filter-headers text18 font700">Filters</h6>
                                </div>
                                <div>
                                    <ul className="list-none ps-0">
                                        <li>
                                            <h6 className="filters-inner text16 font700">Price Per KM Range</h6>
                                        </li>
                                        <li className="text14">
                                            <input
                                                type="radio"
                                                name="priceRange"
                                                value="lessThan100"
                                                className="input-margin"
                                                checked={filterCriteria.priceRange === "lessThan100"}
                                                onChange={handleFilterChange}
                                            />
                                            Less than ₹100
                                        </li>
                                        <li className="text14">
                                            <input
                                                type="radio"
                                                name="priceRange"
                                                value="100To200"
                                                className="input-margin"
                                                checked={filterCriteria.priceRange === "100To200"}
                                                onChange={handleFilterChange}
                                            />
                                            ₹100 to ₹200
                                        </li>
                                        <li className="text14">
                                            <input
                                                type="radio"
                                                name="priceRange"
                                                value="200To300"
                                                className="input-margin"
                                                checked={filterCriteria.priceRange === "200To300"}
                                                onChange={handleFilterChange}
                                            />
                                            ₹200 to ₹300
                                        </li>
                                        <li className="text14">
                                            <input
                                                type="radio"
                                                name="priceRange"
                                                value="300To400"
                                                className="input-margin"
                                                checked={filterCriteria.priceRange === "300To400"}
                                                onChange={handleFilterChange}
                                            />
                                            ₹300 to ₹400
                                        </li>
                                        <li className="text14">
                                            <input
                                                type="radio"
                                                name="priceRange"
                                                value="400To500"
                                                className="input-margin"
                                                checked={filterCriteria.priceRange === "400To500"}
                                                onChange={handleFilterChange}
                                            />
                                            ₹400 to ₹500
                                        </li>
                                        <li className="text14">
                                            <input
                                                type="radio"
                                                name="priceRange"
                                                value="above500"
                                                className="input-margin"
                                                checked={filterCriteria.priceRange === "above500"}
                                                onChange={handleFilterChange}
                                            />
                                            Above ₹500
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-9">
                            {filteredCars &&
                                [...filteredCars]?.reverse()?.map((ele) => {
                                    return (
                                        <div key={ele._id} className="top-border p-4 mb-2">
                                            <div className="row gx-5">
                                                <div className="col-lg-4 col-md-5 col-sm-6 text-center d-flex align-items-center">
                                                    <img
                                                        src={ele.photos[0]}
                                                        className="img-fluid w-100"
                                                        alt="car"
                                                        style={{ height: "200px" }}
                                                    />
                                                </div>
                                                <div className="col-lg-8 col-md-7 col-sm-6 d-flex flex-column justify-content-start pe-4">
                                                    <div className="">
                                                        <div className="align-items-top top_hotels_name mt-sm-0 mt-2 d-flex justify-content-between mb-3">
                                                            <h3 className="text18 font700">{ele.car_details[0]?.car_name} {ele.car_details[0]?.model_number} ({ele?.car_details[0]?.fuel_type})</h3>
                                                            <button
                                                                onClick={() => handleNavigate(ele)}
                                                                className="check-availibility-button text-20 border-0 "
                                                            >
                                                                View Details
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex top_hotels_map">
                                                        <a href="" className="top-a text14">
                                                            <FontAwesomeIcon icon={faLocationPin} />{" "}
                                                            {ele.city}, {ele.state}
                                                        </a>
                                                    </div>
                                                    <div className="pt-3 top_hotels_price_night">
                                                        <p className="text18 font700" style={{ color: "#09646D" }}>
                                                            <FontAwesomeIcon
                                                                icon={faIndianRupeeSign}
                                                                className="me-1"
                                                            />
                                                            {Math.round(ele.price_per_km)} / KM
                                                        </p>
                                                    </div>
                                                    <div className="top_hotels_star pt-2">
                                                        {Array.from({ length: 5 }, (_, i) => (
                                                            <FontAwesomeIcon key={i} icon={faStar} className="top_font_crl" />
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AllCars;