import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import { Carousel, Modal, Form, Row, Col } from 'react-bootstrap';
import { faIndianRupeeSign, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import countries from "../CountryStateCity.json"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from '../BASE_URL';

const CarDetail = () => {
    const location = useLocation();
    const carData = location.state?.carData;
    const [showModal, setShowModal] = useState(false);
    const states = countries?.find((e) => e?.name === "India")?.states
    const [cities, setCities] = useState("")
    const [totalDays, setTotalDays] = useState(0);
    const [amount, setAmount] = useState(0);
    const [formData, setFormData] = useState({
        pickup_address: '',
        drop_address: '',
        pickup_date: '',
        pickup_time: '',
        return_date: '',
        one_way_two_way: false,
        name: '',
        gender: '',
        email: '',
        mobile_number: '',
        pincode: '',
        city: '',
        state: '',

        price_type: '',
        total_days: '',
        amount: '',

        price_per_km: carData?.price_per_km,
        price_per_day: carData?.min_price_per_day,
        car_condition: carData?.car_condition,
        model_year: carData?.model_year,
        insurance: carData?.insurance,
        registration_number: carData?.registration_number,
        color: carData?.color,
        outStateAllowed: carData?.outStateAllowed,
        AC: carData?.AC,
        vendor_car_id: carData?._id,
    });

    useEffect(() => {
        if (formData.pickup_date && formData.return_date) {
            const start = new Date(formData.pickup_date);
            const end = new Date(formData.return_date);
            const diffTime = Math.abs(end - start);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
            setTotalDays(diffDays);
        }
    }, [formData.pickup_date, formData.return_date]);

    useEffect(() => {
        if (formData.price_type === 'day') {
            setAmount(carData.min_price_per_day * totalDays);
        } else if (formData.price_type === 'km') {
            // 25km per day assumption
            setAmount(carData.price_per_km * 25 * totalDays);
        }
    }, [totalDays, formData.price_type, carData]);

    if (!carData) {
        return <h1>No Car Data Available</h1>;
    }

    const handleClose = () => {
        setShowModal(false)
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "state") {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
            const selectedCities = states?.find((e) => e?.name === value)?.cities
            setCities(selectedCities)
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }

    };

    const handleBookNow = () => {
        if (!formData?.price_type) {
            toast.error("Please select a ride type (Per KM or Per Day)", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        setShowModal(true);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const myId = localStorage.getItem("mySytUserId")

        // Validations
        if (!formData.pickup_address || !formData.drop_address ||
            !formData.pickup_date || !formData.return_date ||
            !formData.name || !formData.mobile_number ||
            !formData.email || !formData.pincode) {
            toast.error("Please fill all required fields", { position: toast.POSITION.BOTTOM_RIGHT });
            return;
        }

        if (formData.mobile_number.length !== 10) {
            toast.error("Invalid mobile number", { position: toast.POSITION.BOTTOM_RIGHT });
            return;
        }

        const payload = {
            ...formData,
            total_days: totalDays,
            amount: 1,
            // amount: amount,
            MERCHANT_USER_ID: myId,
        };

        try {
            const response = await fetch(`${BASE_URL}payment/pay`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('userToken')}`
                },
                body: JSON.stringify(payload)
            });

            const data = await response.json();
            console.log(data)
            if (response.ok) {
                localStorage.setItem('bookingId', data.data.booking_id)
                localStorage.setItem('booking', "car")
                if (data?.data?.url) {
                    window.location.href = data?.data?.url
                }
                toast.success("Payment initiated successfully!");
            } else {
                toast.error(data.message || "Payment failed");
            }
        } catch (error) {
            toast.error("An error occurred. Please try again.");
        }
    };


    return (
        <>
            <Header />
            <ToastContainer />
            <div className="container car-details-page">
                <div className="row">
                    <div className="col-md-6">
                        <Carousel>
                            {carData.photos.map((photo, index) => (
                                <Carousel.Item key={index}>
                                    <img
                                        src={photo}
                                        alt={`Car ${index + 1}`}
                                        className="d-block w-100"
                                        style={{ height: '400px', objectFit: 'cover' }}
                                    />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </div>
                    <div className="col-md-6">
                        <h1>
                            {carData.car_details[0].car_name} {carData.car_details[0].model_number}
                        </h1>
                        <p><strong>Model Year:</strong> {carData.model_year}</p>
                        <p><strong>Color:</strong> {carData.color}</p>
                        <p><strong>Registration Number:</strong> {carData.registration_number}</p>
                        <p><strong>Price per KM:</strong> ₹{carData.price_per_km}</p>
                        <p><strong>Price per Day:</strong> ₹{carData.min_price_per_day}</p>
                        <p><strong>Location:</strong> {carData.city}, {carData.state}</p>
                        <p><strong>Out State Allowed:</strong> {carData.outStateAllowed ? 'Yes' : 'No'}</p>
                        <p><strong>AC:</strong> {carData.AC ? 'Yes' : 'No'}</p>
                        <p><strong>Insurance:</strong> {carData.insurance ? 'Included' : 'Not Included'}</p>
                        <p><strong>Fuel Type:</strong> {carData.car_details[0].fuel_type}</p>
                        <p><strong>Car Type:</strong> {carData.car_details[0].car_type}</p>
                        <p><strong>Seats:</strong> {carData.car_details[0].car_seats}</p>
                        <div className='d-flex align-items-center gap-4'>
                            <div className="col-4">
                                <Form.Select
                                    style={{
                                        borderLeft: "none",
                                        borderRight: "none",
                                        borderTop: "none",
                                        fontSize: "14px",
                                    }}
                                    value={formData?.price_type}
                                    name='price_type'
                                    onChange={handleChange}
                                    className='py-2'
                                >
                                    <option value="">Select</option>
                                    <option value="km">Per KM</option>
                                    <option value="day">Per Day</option>

                                </Form.Select>
                            </div>
                            <button
                                className="btn w-25"
                                style={{ backgroundColor: 'rgb(9, 100, 109)', color: 'white' }}
                                onClick={handleBookNow}
                            >
                                Book Now
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <Footer />

            {/* Booking Modal */}
            <Modal show={showModal} onHide={handleClose} size='lg'>
                <Modal.Body>
                    <div>
                        <Row>
                            <div className='col-md-12 col-12'>
                                <form>
                                    <div
                                        style={{
                                            background: "linear-gradient(#00363D, #005C63)",
                                            color: "#fff",
                                            borderRadius: "8px",
                                        }}
                                        className='p-1 d-flex justify-content-between align-items-center'
                                    >
                                        <h4 className="text18 font600 ms-2" style={{ color: "white" }}>{carData.car_details[0].car_name}</h4>
                                        <div>
                                            <span className='cmnp textcm'>
                                                <FontAwesomeIcon icon={faIndianRupeeSign} />
                                                {formData.price_type === "km"
                                                    ? ` ${carData.price_per_km}/km`
                                                    : ` ${carData.min_price_per_day}/day`}
                                            </span>
                                            <p className='cmnp pb-1 textcm'>
                                                Total: ₹{amount} ({totalDays} days)
                                            </p>
                                        </div>
                                    </div>
                                    <div className='cmn p-1 mt-3 px-3'>
                                        <p className='text-center text20 font700 mb-3 mt-1'>Booking Details</p>
                                        <div>
                                            <div className="row gx-2">
                                                <div className="col-6">
                                                    <p className="mb-2 text14 font600">Pickup Address :</p>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={3}
                                                        style={{
                                                            borderLeft: "none",
                                                            borderRight: "none",
                                                            borderTop: "none",
                                                            fontSize: "14px",
                                                            width: "100%"
                                                        }}
                                                        value={formData?.pickup_address}
                                                        onChange={handleChange}
                                                        className="mt-1 my-2 py-2"
                                                        name="pickup_address"
                                                        placeholder="Enter Pickup Address"
                                                    />
                                                </div>

                                                <div className="col-6">
                                                    <p className="mb-2 text14 font600">Drop Address :</p>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={3}
                                                        style={{
                                                            borderLeft: "none",
                                                            borderRight: "none",
                                                            borderTop: "none",
                                                            fontSize: "14px",
                                                            width: "100%"
                                                        }}
                                                        value={formData?.drop_address}
                                                        onChange={handleChange}
                                                        className="mt-1 my-2 py-2"
                                                        name="drop_address"
                                                        placeholder="Enter Drop Address"
                                                    />
                                                </div>

                                                <div className="col-6">
                                                    <p className="mb-1 text14 font600">Pick Up Date</p>
                                                    <Form.Control
                                                        type='date'
                                                        placeholder='Pick Up Date'
                                                        style={{
                                                            borderLeft: "none",
                                                            borderRight: "none",
                                                            borderTop: "none",
                                                            fontSize: "14px",
                                                            width: "100%"
                                                        }}
                                                        value={formData?.pickup_date}
                                                        onChange={handleChange}
                                                        className='mt-1 my-2 py-2 w-100'
                                                        name='pickup_date'
                                                    />
                                                </div>

                                                <div className="col-6">
                                                    <p className="mb-1 text14 font600">Pick Up Time</p>
                                                    <Form.Control
                                                        type="time"
                                                        placeholder="Pick Up Time"
                                                        style={{
                                                            borderLeft: "none",
                                                            borderRight: "none",
                                                            borderTop: "none",
                                                            fontSize: "14px",
                                                            width: "100%",
                                                        }}
                                                        value={formData?.pickup_time}
                                                        onChange={handleChange}
                                                        className="mt-1 my-2 py-2 w-100"
                                                        name="pickup_time"
                                                    />
                                                </div>

                                                <div className="col-6">
                                                    <p className="mb-1 text14 font600">Return Date</p>
                                                    <Form.Control
                                                        type='date'
                                                        placeholder='Return Date'
                                                        style={{
                                                            borderLeft: "none",
                                                            borderRight: "none",
                                                            borderTop: "none",
                                                            fontSize: "14px",
                                                            width: "100%"
                                                        }}
                                                        value={formData?.return_date}
                                                        onChange={handleChange}
                                                        className='mt-1 my-2 py-2 w-100'
                                                        name='return_date'
                                                    />
                                                </div>

                                                <div className="col-6">
                                                    <p className="mb-1 text14 font600">One Way</p>
                                                    <Form.Select
                                                        style={{
                                                            borderLeft: "none",
                                                            borderRight: "none",
                                                            borderTop: "none",
                                                            fontSize: "14px",
                                                        }}
                                                        value={formData?.one_way_two_way}
                                                        onChange={handleChange}
                                                        className='mt-1 my-2 py-2'
                                                        name='one_way_two_way'
                                                    >
                                                        <option value="">Select</option>
                                                        <option value={true}>Yes</option>
                                                        <option value={false}>No</option>
                                                    </Form.Select>
                                                </div>

                                                <div className="col-6">
                                                    <p className="mb-1 text14 font600">Fullname</p>
                                                    <Form.Control
                                                        type='text'
                                                        placeholder='Fullname'
                                                        style={{
                                                            borderLeft: "none",
                                                            borderRight: "none",
                                                            borderTop: "none",
                                                            fontSize: "14px",
                                                        }}
                                                        value={formData?.name}
                                                        onChange={handleChange}
                                                        className='mt-1 my-2 py-2 w-100'
                                                        name='name'
                                                    />
                                                </div>

                                                <div className="col-6">
                                                    <p className="mb-1 text14 font600">Gender</p>
                                                    <Form.Select
                                                        style={{
                                                            borderLeft: "none",
                                                            borderRight: "none",
                                                            borderTop: "none",
                                                            fontSize: "14px",
                                                        }}
                                                        value={formData?.gender}
                                                        onChange={handleChange}
                                                        className='mt-1 my-2 py-2'
                                                        name='gender'
                                                    >
                                                        <option value="">Select Gender</option>
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                    </Form.Select>
                                                </div>

                                                <div className="col-6">
                                                    <p className="mb-1 text14 font600">Email</p>
                                                    <Form.Control
                                                        type='text'
                                                        placeholder='Email Id'
                                                        style={{
                                                            borderLeft: "none",
                                                            borderRight: "none",
                                                            borderTop: "none",
                                                            fontSize: "14px",
                                                        }}
                                                        value={formData?.email}
                                                        onChange={handleChange}
                                                        className='mt-1 my-2 py-2 w-100'
                                                        name='email'
                                                    />
                                                </div>

                                                <div className="col-6">
                                                    <p className="mb-1 text14 font600">Contact No.</p>
                                                    <Form.Control
                                                        type='text'
                                                        placeholder='Contact Number'
                                                        style={{
                                                            borderLeft: "none",
                                                            borderRight: "none",
                                                            borderTop: "none",
                                                            fontSize: "14px",
                                                        }}
                                                        value={formData?.mobile_number}
                                                        onChange={handleChange}
                                                        onInput={(e) => {
                                                            let value = e.target.value.replace(/[^0-9]/g, "");

                                                            if (value.length > 10) {
                                                                value = value.substring(0, 10);
                                                            }
                                                            // setNumber(value);
                                                        }}
                                                        className='mt-1 my-2 py-2 w-100'
                                                        name='mobile_number'
                                                    />
                                                </div>

                                                <div className="col-4">
                                                    <p className="mb-1 text14 font600">Pincode</p>
                                                    <Form.Control
                                                        type='text'
                                                        placeholder='Pincode'
                                                        style={{
                                                            borderLeft: "none",
                                                            borderRight: "none",
                                                            borderTop: "none",
                                                            fontSize: "14px",
                                                        }}
                                                        value={formData?.pincode}
                                                        onChange={handleChange}
                                                        onInput={(e) => {
                                                            let value = e.target.value.replace(/[^0-9]/g, "");

                                                            if (value.length > 10) {
                                                                value = value.substring(0, 10);
                                                            }
                                                            // setNumber(value);
                                                        }}
                                                        className='mt-1 my-2 py-2 w-100'
                                                        name='pincode'
                                                    />
                                                </div>

                                                <div className="col-4">
                                                    <p className="mb-1 text14 font600">State</p>
                                                    <Form.Select
                                                        style={{
                                                            borderLeft: "none",
                                                            borderRight: "none",
                                                            borderTop: "none",
                                                            fontSize: "14px",
                                                        }}
                                                        value={formData?.state}
                                                        onChange={handleChange}
                                                        className='mt-1 my-2 py-2'
                                                        name='state'
                                                    >
                                                        <option value="">Select State</option>
                                                        {states && states?.map((e) => (
                                                            <option value={e?.name}>{e.name}</option>
                                                        ))}
                                                    </Form.Select>
                                                </div>

                                                <div className="col-4">
                                                    <p className="mb-1 text14 font600">City</p>
                                                    <Form.Select
                                                        style={{
                                                            borderLeft: "none",
                                                            borderRight: "none",
                                                            borderTop: "none",
                                                            fontSize: "14px",
                                                        }}
                                                        value={formData?.city}
                                                        onChange={handleChange}
                                                        className='mt-1 my-2 py-2'
                                                        name='city'
                                                    >
                                                        <option value="">Select State</option>
                                                        {cities && cities?.map((e) => (
                                                            <option value={e?.name}>{e.name}</option>
                                                        ))}
                                                    </Form.Select>
                                                </div>

                                            </div>
                                        </div>


                                    </div>
                                    <div className='text-center pt-2 px-3 mt-2'>
                                        <button
                                            type='button'
                                            className='w-100 border-0 py-2 mb-0'
                                            style={{
                                                background: "#09646D",
                                                color: "#fff",
                                                borderRadius: "8px",
                                                cursor: "pointer",
                                            }}
                                            onClick={handleSubmit}
                                        >
                                            Pay Now (₹{amount})
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default CarDetail;
