import React, { useState, useEffect, useContext } from "react"
import Hearer from "./Header"
import { Container, Form, Modal, Row } from "react-bootstrap"
import "../Project/css/index1.css"
import "../Project/css/custom_package.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBrush,
  faCalendar,
  faCheck,
  faHandHoldingDroplet,
  faHandsHoldingCircle,
  faHandshakeAlt,
  faHotel,
  faIndianRupeeSign,
  faLocationPinLock,
  faPaperclip,
  faStar,
  faUserDoctor,
  faUsers,
  faStarHalfAlt,
  faDoorClosed,
  faBowlFood,
  faCookie,
  faXmark,
  faUser,
  faCalendarDays
} from "@fortawesome/free-solid-svg-icons"
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-regular-svg-icons"
import { useParams } from "react-router-dom"
import { bidContext } from "../App"
import { BASE_URL } from "../BASE_URL"
import Footer from "./Footer"
import { faTrainSubway } from "@fortawesome/free-solid-svg-icons"
import { faBinoculars } from "@fortawesome/free-solid-svg-icons"
import { faUtensils } from "@fortawesome/free-solid-svg-icons"
import { faBus } from "@fortawesome/free-solid-svg-icons"
import { faPlane } from "@fortawesome/free-solid-svg-icons"
import { faTaxi } from "@fortawesome/free-solid-svg-icons"
import { useRef } from "react"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import dayjs from "dayjs"

import { useLocation } from "react-router-dom"
import StarRating from "./StarRating"
import ActivityContentOtherTraveller from "./ActivityContentOtherTraveller.jsx";
import ActivityContentOther from "./ActivityContentOther.jsx"
import ActivityContentHotel from "./ActivityContentHotel.jsx"
import ActivityContentItinerary from "./ActivityContentItinerary.jsx"
import ActivityContentServices from "./ActivityContentServices.jsx"
import PrivacyExtraData from "./PrivacyExtraData.jsx"

function getStatus(date) {

  if (date === undefined) {
    return "Not Expired";
  }

  // Parse the input date in dd/mm/yyyy format
  const [day, month, year] = date.split("/").map(Number);
  const inputDate = new Date(year, month - 1, day); // Month is 0-indexed

  // Get the current date
  const currentDate = new Date();

  inputDate.setHours(0, 0, 0, 0);
  currentDate.setHours(0, 0, 0, 0);

  // Compare the dates
  if (inputDate >= currentDate) {
    return "Not Expired";
  } else {
    return "Expired";
  }
}

function Package_Details({
  despature,
  destination,
  adult,
  scrollTo,
  overRef,
  services,
  handleClose1,
  show1,
  itenarary,
  hotels,
  handleShow1,
  bidData,
  handleReject,
  handleShow,
  privacy,
  Infant,
  kids,
  total_traveller,
  totalDays,
  totalNights,
  end_date,
  start_date,
  hotel_types,
  meal,
  meal_types,
  mealRequired,
  sightseeing,
  travel_by,
  include_services,
  exclude_services,
  itineries,
  personal_care,
  additional_req,
  custom_requirement_status,
  agency_name,
  agencyAverageStar,
  agencyTotalReview,
  priceAdult,
  priceChild,
  priceInfants,
  room_sharing,
  total_amount,
  hotel_itienrary,
  otherServicesByAgency,
  packageName,
  packageType,
  destinationCategory,
  bidStatus,
  appEndDate,
  appStartDate
}) {

  console.log(hotel_itienrary)

  const firstWord = travel_by?.split(',')[0];

  const [stars, setStars] = useState([]);

  const location = useLocation()

  useEffect(() => {
    const fullStars = Math.floor(agencyAverageStar);
    const hasHalfStar = agencyAverageStar % 1 !== 0;
    const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

    const starElements = [];
    for (let i = 0; i < fullStars; i++) {
      starElements.push(
        <FontAwesomeIcon
          key={`full-${i}`}
          icon={faStar}
          className='CuStom_package_icon'
        />
      );
    }
    if (hasHalfStar) {
      starElements.push(
        <FontAwesomeIcon
          key='half'
          icon={faStarHalfAlt}
          className='CuStom_package_icon'
        />
      );
    }
    for (let i = 0; i < emptyStars; i++) {
      starElements.push(
        <FontAwesomeIcon
          key={`empty-${i}`}
          icon={faStar}
          className='CuStom_package_icon'
          style={{ color: '#B8B8B8' }}
        />
      );
    }

    setStars(starElements);
  }, [agencyAverageStar]);

  console.log(hotel_itienrary)

  const totalHotelPrice = hotel_itienrary?.reduce((sum, hotel) => {
    
    const roomPrice = hotel.selected_rooms?.[0]?.room_type_price || 0;

    const breakfastPrice = hotel.itinerary_breakfast ? hotel.breakfast_price : 0;
    const lunchPrice = hotel.itinerary_lunch ? hotel.lunch_price : 0;
    const dinnerPrice = hotel.itinerary_dinner ? hotel.dinner_price : 0;

    const totalPrice = roomPrice + breakfastPrice + lunchPrice + dinnerPrice;

    const numOfDays = hotel.days ? hotel.days.split(",").length : 1;

    return sum + totalPrice * numOfDays;
  }, 0);


  return (
    <>
      <>
        <section className=''>
          <div className='container-customes padding-costume-container py-xl-4 py-sm-4 py-2'>
            <div className=''>
              <Row className=''>
                <div className='col-xl-8 col-lg-6 col-sm-12 col-12 text-center m-0  posi-first'>
                  <div className='CuStom_package_image'>
                    <img
                      src='/C-packega.png'
                      className='booking-detail-header-image'
                      alt=''
                    />
                  </div>
                  <div className='mt-3 hover-buttons-media'>
                    <div className='me-3 mb-1'>
                      <button
                        class='button-47'
                        role='button'
                        onClick={() => scrollTo("overview")}
                      >
                        Overview
                      </button>
                    </div>
                    <div className='me-3 mb-1'>
                      <button
                        class='button-47'
                        role='button'
                        onClick={() => scrollTo("services")}
                      >
                        Services
                      </button>
                    </div>
                    <div className='me-3 mb-1'>
                      <button
                        class='button-47'
                        role='button'
                        onClick={() => scrollTo("itenarary")}
                      >
                        Itinerary
                      </button>
                    </div>
                    {hotel_itienrary?.length > 0 && (
                      <div className='me-3 mb-1'>
                        <button
                          class='button-47'
                          role='button'
                          onClick={() => scrollTo("hotels")}
                        >
                          Hotels
                        </button>
                      </div>
                    )}
                    <div className='me-3 mb-1'>
                      <button
                        class='button-47'
                        role='button'
                        onClick={() => scrollTo("privacy")}
                      >
                        Policy
                      </button>
                    </div>
                  </div>

                  <section className='mb-3 mt-3' ref={overRef}>
                    <div className=''>
                      <div>
                        <button className='w-100 over-btn text18 lh19'>
                          <img src='/c-icon.png' className='me-3' />
                          Overview
                        </button>
                      </div>
                    </div>
                  </section>

                  <section className='text-start mb-4'>
                    <div className=''>
                      <Row>
                        <div className='col-xl-6 col-lg-12 mb-3'>
                          <div className='amh_to_hima'>
                            <div>
                              <h6 className='pb-2 mb-0 cmntext text-capitalize font700 text18'>
                                {despature} to {destination}
                              </h6>
                            </div>
                            <div className=''>
                              <div className=''>
                                <div className=''>
                                  <div className='cmn px-2 px-sm-4 px-md-4 px-lg-4 px-xl-4 py-2'>
                                    <p className='cmnp py-1 text14'>
                                      <FontAwesomeIcon
                                        icon={faUser}
                                        className='cmnclr '
                                      />{" "}
                                      <b>Adult (12+yrs)</b> : {adult}
                                    </p>
                                    <p className='cmnp py-1 text14'>
                                      <FontAwesomeIcon
                                        icon={faUser}
                                        className='cmnclr'
                                      />{" "}
                                      <b>Children(2 to 12 years)</b> : {kids}
                                    </p>
                                    <p className='cmnp py-1 text14'>
                                      <FontAwesomeIcon
                                        icon={faUser}
                                        className='cmnclr'
                                      />{" "}
                                      <b>Infant (0 to 2 years)</b> : {Infant}
                                    </p>
                                    <p className='cmnp py-1 text14'>
                                      <FontAwesomeIcon
                                        icon={faUsers}
                                        className='cmnclr'
                                      />{" "}
                                      <b>Total Travellers</b> :{" "}
                                      {total_traveller}
                                    </p>
                                    <p className='cmnp py-1 text14'>
                                      <FontAwesomeIcon
                                        icon={faHandsHoldingCircle}
                                        className='cmnclr'
                                      />{" "}
                                      <b>Any Traveller Needs Personal Care?</b>{" "}
                                      : {personal_care}
                                    </p>
                                    <p className='cmnp py-1 text14'>
                                      <FontAwesomeIcon
                                        icon={faCalendar}
                                        className='cmnclr'
                                      />{" "}
                                      <b>Total Days & Night</b> : {totalDays}D/{totalNights}N
                                    </p>
                                    <p className='cmnp py-1 text14'>
                                      <FontAwesomeIcon
                                        icon={faCalendar}
                                        className='cmnclr'
                                      />{" "}
                                      <b>Approx Departure Between</b> : {appStartDate} to {appEndDate}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className='mt-3 mb-2'>
                                <h6 className='cmntext font700 text18'>
                                  <FontAwesomeIcon
                                    icon={faCalendarDays}
                                    className='cmnclr'
                                  />{" "}
                                  Package Valid Between{" "}
                                </h6>
                              </div>
                              <div className='cmn'>
                                <p className='cmnp ms-4 py-2 text14'>
                                  {start_date} to {end_date}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='col-xl-6 col-lg-12 mb-3'>
                          <div className='amh_to_hima'>
                            <div>
                              <h6 className=' pb-2 mb-0 cmntext font700 text18'>
                                <FontAwesomeIcon
                                  icon={faHotel}
                                  className='cmnclr'
                                />{" "}
                                Hotel & Food
                              </h6>
                            </div>
                            <div className=''>
                              <div className=''>
                                <div className=''>
                                  <div className='cmn px-2 px-sm-4 px-md-4 px-lg-4 px-xl-4 py-2'>
                                    <p className='cmnp py-1 text14'>
                                      <FontAwesomeIcon
                                        icon={faHotel}
                                        className='cmnclr'
                                      />{" "}
                                      <b>Hotel Type</b> : {hotel_types === "" ? "N/A" : hotel_types}
                                    </p>
                                    {room_sharing !== "" && (
                                      <p className='cmnp py-1 text14'>
                                        <FontAwesomeIcon
                                          icon={faDoorClosed}
                                          className='cmnclr'
                                        />{" "}
                                        <b>Room Sharing</b> : {room_sharing}
                                      </p>
                                    )}
                                    <p className='cmnp py-1 text14'>
                                      <FontAwesomeIcon
                                        icon={faBowlFood}
                                        className='cmnclr'
                                      />{" "}
                                      <b>Meal</b> : {meal_types !== "" ? meal : "N/A"}
                                    </p>
                                    <p className='cmnp py-1 text14'>
                                      <FontAwesomeIcon
                                        icon={faCookie}
                                        className='cmnclr'
                                      />{" "}
                                      <b>Meal Type</b> : {meal_types !== "" ? meal_types : "N/A"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='mt-3 mb-2'>
                              {travel_by?.length > 10 ? (
                                <h6 className='cmntext font700 text18'>
                                  <FontAwesomeIcon
                                    icon={firstWord?.toLowerCase() === "train" ? faTrainSubway : firstWord?.toLowerCase() === "flight" ? faPlane : faTaxi}
                                    className='cmnclr'
                                  />{" "}
                                  Travel By
                                </h6>
                              ) : (
                                <h6 className='cmntext font700 text18'>
                                  <FontAwesomeIcon
                                    icon={travel_by?.toLowerCase() === "train" ? faTrainSubway : travel_by?.toLowerCase() === "flight" ? faPlane : faTaxi}
                                    className='cmnclr'
                                  />{" "}
                                  Travel By
                                </h6>
                              )}
                            </div>
                            <div className='cmn'>
                              <p className='cmnp ms-4 py-2 text14'>{travel_by}</p>
                            </div>
                          </div>
                        </div>
                      </Row>


                      <div className='amh_to_hima text-start'>
                        <div className='mb-2 text-start cmnp'>
                          <h6 className='cmntext font700 text18'>
                            <FontAwesomeIcon
                              icon={faPaperclip}
                              className='cmnclr'
                            />{" "}
                            Additional Requirements By Traveller
                          </h6>
                        </div>
                        {/* <p className='cmnp py-2 text14' key={req.id}>{req}</p> */}
                        {additional_req && additional_req.map(req => (
                          <ActivityContentOtherTraveller activity={req} charCount={700} />
                        ))
                        }
                      </div>

                      <div className='amh_to_hima text-start mt-3'>
                        <div className='mb-2 text-start cmnp'>
                          <h6 className='cmntext font700 text18'>
                            <FontAwesomeIcon
                              icon={faPaperclip}
                              className='cmnclr'
                            />{" "}
                            Other Services By Agency
                          </h6>
                        </div>
                        {/* Use dangerouslySetInnerHTML to display HTML content */}
                        <ActivityContentOther activity={otherServicesByAgency} charCount={700} />
                        {/* <div
                          className='cmnp pt-2 text14'
                          dangerouslySetInnerHTML={{ __html: otherServicesByAgency }}
                        /> */}
                      </div>

                    </div>
                  </section>

                  <div className='details-service ' ref={services}>
                    <button className='mb-3 font700 text18 lh19'><FontAwesomeIcon className='me-3' icon={faBrush} /> Services</button>
                    <div className='media-services IE-border'>
                      <div className='row gx-0 padding-inside-row mx-2 mx-sm-4 mt-2 mt-sm-3 mb-2 mb-sm-4 '>
                        <div className='col-xl-6 col-12'>
                          <div className='hotel-Included me-0 me-sm-3'>
                            <h4 className="font700 text18 text-start">
                              <FontAwesomeIcon icon={faCircleCheck} />{" "}
                              Included
                            </h4>
                            <div class='content-green'>
                              {include_services.map((e) => {
                                return (
                                  <>
                                    <p className="text14 margin-bottom-p d-flex align-items-start mb-0 text-start">
                                      <FontAwesomeIcon icon={faCheck} className="text16 me-2" />
                                      <ActivityContentServices activity={e.include_services_value} charCount={150} />
                                      {/* <div className="mb-0" dangerouslySetInnerHTML={{ __html: e.include_services_value }}>
                                      </div> */}
                                    </p>
                                  </>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                        <div className='col-xl-6 col-12'>
                          <div className='hotel-excluded ms-0 ms-sm-3'>
                            <h4 className="font700 text18 text-start">
                              <FontAwesomeIcon icon={faCircleXmark} />{" "}
                              Excluded
                            </h4>
                            <div className='content-red'>
                              {exclude_services.map((e1) => {
                                return (
                                  <>
                                    <p className="text14 margin-bottom-p d-flex align-items-start mb-0 text-start">
                                      <FontAwesomeIcon icon={faXmark} className="me-2 text16" />
                                      <ActivityContentServices activity={e1.exclude_services_value} charCount={150} />
                                      {/* <span dangerouslySetInnerHTML={{ __html: e1.exclude_services_value }}>
                                      </span> */}
                                    </p>
                                  </>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <section className='text-start' ref={itenarary}>
                    <div className=''>
                      <div
                        className='details-service mt-4'
                        ref={itenarary}
                      >
                        <button className='mb-3 font700 text18 lh19'><FontAwesomeIcon
                          icon={faHandshakeAlt}
                          className='me-2'
                        />Itinerary</button>
                      </div>
                      <div className='amh_to_hima'>
                        {itineries.map((e3, i) => {
                          return (
                            <>
                              <div className="py-2">
                                <h6 className="cmntext mb-2 font700 text18">Day {i + 1}</h6>
                                <div className='cmn p-2'>

                                  <Row className='align-items-start'>
                                    <div className='col-lg-10'>
                                      <div className='py-1'>
                                        <h6 className='cmnp cmnclr1 text14 font700'>Title</h6>
                                        <p className='cmnp cmnclr font-weight-bolder text14'>
                                          {e3.title}{" "}
                                        </p>
                                      </div>
                                      <div className='py-1'>
                                        <h6 className='cmnp cmnclr1 text14 font700'>Activity</h6>
                                        <ActivityContentItinerary activity={e3.activity} charCount={600} />
                                      </div>
                                    </div>
                                    {e3.photo && <div className='col-lg-2'>
                                      <div className='text-start text-sm-end py-2'>
                                        <img src={e3.photo} className='img-fluid' style={{ height: "100%" }} />
                                      </div>
                                    </div>}
                                  </Row>
                                </div>
                              </div>
                            </>
                          )
                        })}
                      </div>
                    </div>
                  </section>

                  {hotel_itienrary?.length > 0 && (
                    <div
                      className='details-service mt-4'
                      ref={hotels}
                    >
                      <button className='mb-3 font700 text18 lh19'><FontAwesomeIcon
                        icon={faHotel}
                        className='me-2'
                      />Hotels</button>
                    </div>
                  )}

                  {hotel_itienrary?.length > 0 && (
                    <section className="">
                      <div className="hotels-section">
                        <div className="last-hotels">
                          {/* Map through each hotel */}
                          {hotel_itienrary
                            .sort((a, b) => {
                              // Convert the first value in the days string to a number and sort by that value
                              const firstDayA = Math.min(...a.days.split(',').map(Number));
                              const firstDayB = Math.min(...b.days.split(',').map(Number));
                              return firstDayA - firstDayB;
                            })
                            .map((e, index) => (
                              <div key={e._id} className={`cmn ${index !== hotel_itienrary.length - 1 ? 'mb-4' : ''}`}>
                                <div className="row gx-5 p-2 second-changes">
                                  {/* Hotel Photos Carousel */}
                                  <div className="col-xl-4 col-12">
                                    <div id={`carouselExampleCaptions-${index}`} className="carousel slide" data-bs-ride="carousel">
                                      <div className="carousel-indicators">
                                        {e?.hotel_photo?.map((_, idx) => (
                                          <button
                                            key={idx}
                                            type="button"
                                            data-bs-target={`#carouselExampleCaptions-${index}`}
                                            data-bs-slide-to={idx}
                                            className={idx === 0 ? "active" : ""}
                                            aria-current={idx === 0 ? "true" : "false"}
                                            aria-label={`Slide ${idx + 1}`}
                                          ></button>
                                        ))}
                                      </div>
                                      <div className="carousel-inner">
                                        {e?.hotel_photo?.map((ele, idx) => (
                                          <div key={idx} className={`carousel-item ${idx === 0 ? "active" : ""}`}>
                                            <img
                                              src={ele}
                                              className="d-block"
                                              alt="..."
                                              style={{ width: "240px", height: "150px", objectFit: "cover", borderRadius: "7px" }}
                                            />
                                          </div>
                                        ))}
                                      </div>
                                      <button className="carousel-control-prev" type="button" data-bs-target={`#carouselExampleCaptions-${index}`} data-bs-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Previous</span>
                                      </button>
                                      <button className="carousel-control-next" type="button" data-bs-target={`#carouselExampleCaptions-${index}`} data-bs-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Next</span>
                                      </button>
                                    </div>
                                  </div>

                                  {/* Hotel Details */}
                                  <div className="col-xl-8 col-12 first-change">
                                    <div className="mb-md-2">
                                      <span className="d-flex day1-2 text-left align-items-center">
                                        <div className="day-1-2 py-1 font-changes">
                                          <h6 className="text18 font700">Day {e?.days}</h6>
                                        </div>
                                        <div className="text-start font-changes">
                                          <h6 className="mb-1 text18 font700">{e?.hotel_name}</h6>
                                          <p className="mb-0 text-start text14">
                                            {e?.hotel_address}, {e?.hotel_city}, {e?.hotel_state}
                                          </p>
                                        </div>
                                      </span>
                                    </div>

                                    {/* Star Rating */}
                                    <div className="text-start hotels-star">
                                      {Array.from({ length: parseInt(e.hotel_type) }, (_, i) => (
                                        <FontAwesomeIcon
                                          key={i}
                                          icon={faStar}
                                          className="p-1"
                                          style={{ color: "yellow" }}
                                        />
                                      ))}
                                    </div>

                                    {/* Hotel Description */}
                                    <div className="text-start hotels-footer">
                                      <p>
                                        <div dangerouslySetInnerHTML={{ __html: e?.hotel_description }}></div>
                                      </p>
                                    </div>

                                    <div style={{ display: "grid", gridTemplateColumns: "1fr" }}>
                                      {/* Meal Prices */}
                                      <div className="text-start ">
                                        <ul className="list-unstyled text14 mb-1" style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr", alignItems: "center" }}>
                                          <h6 className="text16 font700 mb-1">Meals :</h6>
                                          {e.itinerary_breakfast && (
                                            <li>Breakfast</li>
                                          )}
                                          {e.itinerary_lunch && (
                                            <li>Lunch</li>
                                          )}
                                          {e.itinerary_dinner && (
                                            <li>Dinner</li>
                                          )}
                                        </ul>
                                      </div>

                                      {/* Room Details */}
                                      <div className="text-start">
                                        <ul className="list-unstyled text14" style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr", alignItems: "center" }}>
                                          <h6 className="text16 font700 mb-1">Room:</h6>
                                          {e.rooms.map((room) => (
                                            <li key={room._id}>
                                              {room.room_type}
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </section>
                  )}
                </div>

                <div className='col-xl-4 col-lg-6 col-sm-12 col-12 posi-second'>
                  <div className='CuStom_package px-3 pb-3 pb-sm-3 pt-4 pt-sm-3 book_now_box position_fixed'>
                    <div className='inner_CuStom_package'>
                      <p className='CuStom_package_header text-capitalize py-2 font700 text18'>
                        Agency : {agency_name}
                      </p>
                      <div className='CuStom_package_content'>
                        <p className='text-19 mb-1 font700 text18 mb-3'>{packageName}</p>
                        <div className="mb-2 d-flex align-items-center">
                          <p className='fw-bold pt-1 me-2 text14 mb-1'>
                            {destination}
                          </p>
                          <p className='mb-0'>
                            <button class='button-13' role='button'>
                              {totalDays}D/{totalNights}N
                            </button>
                          </p>
                        </div>
                        <div className="mb-3 d-flex align-items-center">
                          <p
                            className='text15 font700 mb-0 me-2'
                          >
                            Package Type :
                          </p>
                          <button
                            class='button-17 me-1'
                            role='button'
                          >
                            {packageType}
                          </button>
                        </div>
                        <div className='mb-4 d-flex align-items-center flex-wrap gap-2'>
                          <p
                            className='mb-0 text15 font700'
                          >
                            Package Best For :
                          </p>
                          {destinationCategory?.map((e) => {
                            return (
                              <>
                                <button
                                  class='button-17 me-1'
                                  role='button'
                                >
                                  {e?.category_name}
                                </button>
                              </>
                            )
                          })}
                        </div>
                        <div className='CuStom_package_images mt-4 mb-2'>
                          {hotel_types !== "" && (
                            <div className='text-center' style={{ width: "40px" }}>
                              <FontAwesomeIcon
                                icon={faHotel}
                                className='media-for-icon text20'
                              />
                              <p className="text12 font500 mt-1">{hotel_types}</p>
                            </div>
                          )}
                          {meal_types !== "" && (
                            <div className='mx-2 text-center'>
                              <FontAwesomeIcon
                                icon={faUtensils}
                                className='media-for-icon text20'
                              />
                              <p className="text12 font500 mb-0 mt-1">Meals</p>
                              <p className="text12 font500 mb-0">{meal_types}</p>
                              <p className="text12 font500 mb-0" title={mealRequired?.split(', ')?.map(meal => meal).join(',')}>({mealRequired?.split(', ')?.map(meal => meal[0]).join(',')})</p>
                            </div>
                          )}
                          <div className='text-center'>
                            <FontAwesomeIcon
                              icon={faBinoculars}
                              className='media-for-icon text20'
                            />
                            <p className="text12 font500 mt-1 mb-0">Sightseeing</p>
                            <p className="text12 font500 mb-0">{sightseeing}</p>
                          </div>
                          {travel_by?.length > 10 && (
                            <div className='mx-2 text-center'>
                              <div>
                                {firstWord?.toLowerCase() ===
                                  "train" && (
                                    <>
                                      <FontAwesomeIcon
                                        icon={faTrainSubway}
                                        className='media-for-icon text20'
                                      />
                                    </>
                                  )}
                                {firstWord?.toLowerCase() ===
                                  "flight" && (
                                    <>
                                      <FontAwesomeIcon
                                        icon={faPlane}
                                        className='media-for-icon text20'
                                      />
                                    </>
                                  )}
                                {firstWord?.toLowerCase() === "cab/car" && (
                                  <>
                                    <FontAwesomeIcon
                                      icon={faTaxi}
                                      className='media-for-icon text20'
                                    />
                                  </>
                                )}
                                {firstWord?.toLowerCase() === "bus" && (
                                  <>
                                    <FontAwesomeIcon
                                      icon={faBus}
                                      className='media-for-icon text20'
                                    />
                                  </>
                                )}
                              </div>
                              <p style={{ width: "60px" }} className="text12 font500 mt-1">{travel_by}</p>
                            </div>
                          )}
                          {travel_by?.length < 10 && (
                            <div className='mx-2 text-center'>
                              <div>
                                {travel_by?.toLowerCase() ===
                                  "train" && (
                                    <>
                                      <FontAwesomeIcon
                                        icon={faTrainSubway}
                                        className='media-for-icon text20'
                                      />
                                    </>
                                  )}
                                {travel_by?.toLowerCase() ===
                                  "flight" && (
                                    <>
                                      <FontAwesomeIcon
                                        icon={faPlane}
                                        className='media-for-icon text20'
                                      />
                                    </>
                                  )}
                                {travel_by?.toLowerCase() === "cab/car" && (
                                  <>
                                    <FontAwesomeIcon
                                      icon={faTaxi}
                                      className='media-for-icon text20'
                                    />
                                  </>
                                )}
                              </div>
                              <p className="text12 font500 mt-1">{travel_by}</p>
                            </div>
                          )}
                        </div>
                        {/* <p className='mb-1 text15 font500'>
                          {" "}
                          <span className="text14 font700">Date </span> <span className='mx-1 text14 font500'>:</span>{" "}
                          {start_date} to {end_date}
                        </p> */}
                        <p className="mb-1 font500 text14"><span className="text14 font700">Adult :</span> {adult} * <FontAwesomeIcon className='me-1' icon={faIndianRupeeSign} style={{ fontSize: "11px" }} />{Math.round(priceAdult)} = <FontAwesomeIcon className='me-1' icon={faIndianRupeeSign} style={{ fontSize: "11px" }} />{Math.round(Number(adult) * Number(priceAdult))}</p>
                        {Number(kids) > 0 && (
                          <p className="mb-1 font500 text14"><span className="text14 font700">Children :</span> {kids} * <FontAwesomeIcon className='me-1' icon={faIndianRupeeSign} style={{ fontSize: "11px" }} />{Math.round(priceChild)} = <FontAwesomeIcon className='me-1' icon={faIndianRupeeSign} style={{ fontSize: "11px" }} />{Math.round(Number(kids) * Number(priceChild))}</p>
                        )}
                        {Number(Infant) > 0 && (
                          <p className="mb-2 font500 text14"><span className="text14 font700">Infants :</span> {Infant} * <FontAwesomeIcon className='me-1' icon={faIndianRupeeSign} style={{ fontSize: "11px" }} />{Math.round(priceInfants)} = <FontAwesomeIcon className='me-1' icon={faIndianRupeeSign} style={{ fontSize: "11px" }} />{Math.round(Number(Infant) * Number(priceInfants))}</p>
                        )}
                        {/* <p className="mb-2 font500 text14"><span className="text14 font700">Meal Price :</span> <FontAwesomeIcon className='me-1' icon={faIndianRupeeSign} style={{ fontSize: "11px" }} />{totalBreakfastPrice + totalLunchPrice + totalDinnerPrice }</p>
                        <p className="mb-2 font500 text14"><span className="text14 font700">Room Price :</span> <FontAwesomeIcon className='me-1' icon={faIndianRupeeSign} style={{ fontSize: "11px" }} /></p> */}
                        <div className='d-flex'>
                          <p className='mb-1 text14 font500'>
                            <span className="text14 font700">Total Amount</span>
                            <span className='ms-1 me-2 text14 font500'>:</span>
                            <FontAwesomeIcon className='me-1' icon={faIndianRupeeSign} />
                            {Math.round(total_amount + totalHotelPrice)}
                          </p>
                        </div>
                        <div
                          className='mt-2'
                          style={{
                            display: "grid",
                            gridAutoColumns: "1fr 1fr",
                          }}
                        >
                          <p className='mb-0 text14 font500'>
                            {" "}
                            <span className=" text14 font700"> Agency Reviews </span>{" "}
                            <span className='mx-1  text15 font700'>:</span> {agencyTotalReview}
                          </p>
                          <StarRating averageStarRating={agencyAverageStar || 0} />
                        </div>

                        {bidStatus !== "reject" && bidStatus !== "booked" && bidStatus !== "booked another package" && getStatus(end_date) !== "Expired" && (
                          <div className='row'>
                            <div className='col-sm-6 col-12 d-flex justify-content-center mt-3'>
                              <button
                                className='CuStom_package_btn'
                                onClick={handleShow1}
                              >
                                Reject
                              </button>
                            </div>

                            <div className='col-sm-6 col-12 d-flex justify-content-center mt-3'>
                              <button
                                className='CuStom_package_btn CuStom_package_btn_2'
                                onClick={handleShow}
                              >
                                Book Now
                              </button>
                            </div>
                          </div>
                        )}
                        {(bidStatus === "booked" || bidStatus === "booked another package" || bidStatus === "reject") ? (
                          <div className='col-sm-8 col-12 d-flex mt-3'>
                            <button
                              style={{
                                color: '#09646D',
                                border: 'solid',
                                borderColor: "#09646D",
                                padding: '10px 20px',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                fontSize: '16px',
                                fontWeight: 'bold',
                              }}
                            >
                              {bidStatus === "booked" && "Package"} {bidStatus?.slice(0, 1)?.toUpperCase() + bidStatus?.slice(1)}
                            </button>
                          </div>
                        ) : getStatus(end_date) === "Expired" ? (
                          <div className='col-sm-8 col-12 d-flex mt-3'>
                            <button
                              style={{
                                color: '#09646D',
                                border: 'solid',
                                borderColor: "#09646D",
                                padding: '10px 20px',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                fontSize: '16px',
                                fontWeight: 'bold',
                              }}
                            >
                              Expired
                            </button>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-12 posi-third' ref={privacy}>
                  <div className='mt-5'>
                    <PrivacyExtraData activity={privacy.term_and_condition_content} charCount={1200} />
                  </div>
                </div>
              </Row>
            </div>
          </div>
        </section>
      </>
    </>
  )
}

export default Package_Details
