import React, { useEffect, useRef, useState } from "react";
// import "./css/Hotal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faIndianRupeeSign, faPlus, faStar } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../BASE_URL";
import axios from "axios";
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"
import BookingModal from "../Project/booking-hotel-modal/BookingHotelModal";
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Rating from "@mui/material/Rating"
import Carousel from 'react-bootstrap/Carousel';
import { Form, Modal, Row, Button } from "react-bootstrap"
import countries from "../CountryStateCity.json"
import My_pannel from "./My_pannel";
import Header2 from "./Header2";
import ProfileSection from "./SecondNavbar";
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

function getTomorrowDate() {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1); // Set date to tomorrow

    const year = tomorrow.getFullYear();
    const month = String(tomorrow.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(tomorrow.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

function calculateAdjustedPrice(price, percentage) {
    // Convert the inputs to numbers
    const numericPrice = parseFloat(price);
    const numericPercentage = parseFloat(percentage);

    // Check if the conversions are valid numbers
    if (isNaN(numericPrice) || isNaN(numericPercentage)) {
        return "Invalid price or percentage input";
    }

    // Calculate the adjusted price
    const adjustedPrice = numericPrice + (numericPrice * numericPercentage / 100);
    return adjustedPrice;
}

const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
};

function getMonthName(dateString) {
    const date = new Date(dateString);
    return date.toLocaleString('default', { month: 'long' });
}

// Function to get margin percentage for a specific month
function getMarginPercentage(dateString, monthAndMarginUser) {
    const monthName = getMonthName(dateString);

    const monthData = monthAndMarginUser && monthAndMarginUser?.find(item => item.month_name === monthName);

    return monthData ? Number(monthData.margin_percentage) : null;
}

function getPriceForDateRange(targetDate, priceArray, percentage) {

    // Convert targetDate to a Date object
    const target = new Date(targetDate);

    // Step 1: Try to find a matching price object within the date range
    const priceObj = priceArray?.find(item => {
        const startDate = new Date(item.price_start_date);
        const endDate = new Date(item.price_end_date);
        return target >= startDate && target <= endDate;
    });

    // Step 2: If no range match is found, find the closest future start date
    let closestPriceObj = priceObj;
    if (!closestPriceObj) {
        closestPriceObj = priceArray
            ?.filter(item => new Date(item?.price_start_date) > target) // Filter dates in the future
            ?.sort((a, b) => new Date(a?.price_start_date) - new Date(b?.price_start_date))[0]; // Get the closest future date
    }

    // Step 3: Calculate the adjusted price if a matching or closest date was found
    if (closestPriceObj) {
        const adjustedPrice = closestPriceObj.adult_price + (closestPriceObj.adult_price * (10 / 100));
        return adjustedPrice;
    } else {
        // Return a message if no price object is available for or after the target date
        return "No price available for the given date or any future dates";
    }
}


function calculateAdjustedPricePercentage(price, percentage) {
    // Convert the inputs to numbers
    const numericPrice = parseFloat(price);
    const numericPercentage = parseFloat(percentage);

    // Check if the conversions are valid numbers
    if (isNaN(numericPrice) || isNaN(numericPercentage)) {
        return "Invalid price or percentage input";
    }

    // Calculate the adjusted price
    const adjustedPrice = numericPrice + (numericPrice * numericPercentage / 100);
    return adjustedPrice;
}

function getTodayDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

function calculateAdjustedPrice1(priceRangeArray, date, percentageArray, totalChild, totalAdult, checkOut, checkIn) {
    // Parse checkIn and checkOut dates
    const checkInDate = new Date(checkIn);
    const checkOutDate = new Date(checkOut);

    const timeDifference = checkOutDate - checkInDate;
    const numberOfNights = Math.max(0, Math.ceil(timeDifference / (1000 * 60 * 60 * 24)));


    // Step 1: Find the price for adults and children based on the date range
    const priceObj = priceRangeArray.find(
        item =>
            checkInDate >= new Date(item.price_start_date) &&
            checkOutDate <= new Date(item.price_end_date)
    );

    if (!priceObj) {
        throw new Error("No price range found for the given dates.");
    }

    const adultPrice = priceObj?.adult_price;
    const childPrice = priceObj?.child_price;

    // Step 2: Extract the month from the checkIn date to find the margin percentage
    const month = checkInDate.toLocaleString("default", { month: "long" });
    const percentageObj = percentageArray.find(item => item.month_name === month);

    // If no percentage found, use 0 as the default margin
    const marginPercentage = percentageObj ? parseFloat(percentageObj.margin_percentage) : 0;

    // Step 3: Calculate adjusted prices for adults and children
    const adjustedPriceAdult = adultPrice + (adultPrice * marginPercentage / 100);
    const adjustedPriceChild = childPrice + (childPrice * marginPercentage / 100);

    // Step 4: Calculate total adjusted price per night based on the number of adults and children
    const totalAdjustedPrice = (adjustedPriceAdult * totalAdult) + (adjustedPriceChild * totalChild);

    return Math.round(totalAdjustedPrice * numberOfNights);
}

function HotelDetailById(props) {

    const locationData = useLocation();
    const { source } = locationData.state || {};

    const selectedStates = countries?.find((e) => e?.name === "India")

    const { id } = useParams();

    const navigate = useNavigate()

    const [hotelDetail, setHotelDetail] = useState([]);
    const [propertyDetail, setPropertyDetail] = useState('');
    const [amenities, setAmenities] = useState([]);

    const [showButton, setShowButton] = useState(false)

    const hotelList = async () => {
        let url;

        if (source === "edit") {
            url = `${BASE_URL}hotel_syt/details?_id=${id}`
        } else {
            url = `${BASE_URL}hotel_syt/hotelDetailsAgency?_id=${id}`
        }
        if (!url) {
            return;
        }
        const token = localStorage.getItem("vendorToken");
        const res = await fetch(url, {
            method: "GET",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
        });
        const data = await res.json();
        
        setHotelDetail(data?.data?.[0]);
        setPropertyDetail(data?.data?.[0]?.property_policies[0])
        setAmenities(data?.data?.[0]?.amenities_and_facilities)
        const myId = localStorage.getItem('my_id')


        if (data?.data?.[0]?.hotel_reviews) {
            const reviews = data.data[0].hotel_reviews;

            const hasMatchingId = reviews?.some(review => review?.user_details?._id === myId);

            if (!hasMatchingId) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        } else {
            setShowButton(true);
        }
    };

    useEffect(() => {
        hotelList();
    }, [source]);

    const overRef = useRef(null);
    const roomsRef = useRef(null);
    const facilities = useRef(null);
    const location = useRef(null);
    const policies = useRef(null);
    const reviewRef = useRef(null);


    const scrollTo = (section) => {
        let targetRef;

        switch (section) {
            case 'overview':
                targetRef = overRef;
                break;
            case 'rooms':
                targetRef = roomsRef;
                break;
            case 'facilities':
                targetRef = facilities;
                break;
            case 'location':
                targetRef = location;
                break;
            case 'policies':
                targetRef = policies;
                break;
            case 'review':
                targetRef = reviewRef;
                break;
            default:
                targetRef = null;
        }

        if (targetRef) {
            window.scroll({
                top: targetRef.current.offsetTop,
                behavior: 'smooth',
            });
        }
    };



    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            setIsSticky(offset > 0);
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [googleMapsEmbedUrl, setGoogleMapsEmbedUrl] = useState('');

    useEffect(() => {
        // Function to fetch coordinates based on the address
        const fetchCoordinates = async (address) => {
            try {
                const apiKey = 'YOUR_GOOGLE_MAPS_API_KEY'; // Replace with your actual API key
                const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`;

                const response = await axios.get(geocodeUrl);

                if (response.data.status === 'OK') {
                    const location = response.data.results[0].geometry.location;
                    const latitude = location.lat;
                    const longitude = location.lng;

                    // Construct the embed URL with the obtained coordinates
                    const embedUrl = `https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${latitude},${longitude}`;
                    setGoogleMapsEmbedUrl(embedUrl);
                } else {
                    console.error('Geocode was not successful for the following reason:', response.data.status);
                }
            } catch (error) {
                console.error('Error fetching coordinates:', error);
            }
        };

        const myLocation = hotelDetail.hotel_name + "," + hotelDetail.hotel_address

        if (myLocation) {
            fetchCoordinates(myLocation);
        }
    }, [hotelDetail]);

    const totalStars = 5;

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [room_id, setRoomId] = useState('');
    const [roomData, setRoomData] = useState([]);

    const [myArray, setMyArray] = useState([])
    const [reviewAverage, setReviewAverage] = useState(0);

    const myId = localStorage.getItem('my_id')

    useEffect(() => {
        const sortedReviews = hotelDetail && hotelDetail?.hotel_reviews?.sort((a, b) => {
            if (a?.user_details?._id === myId && b?.user_details?._id !== myId) return -1;
            if (a?.user_details?._id !== myId && b?.user_details?._id === myId) return 1;
            return 0;
        });
        let sum = 0;
        if (hotelDetail.hotel_reviews) {
            hotelDetail.hotel_reviews.map(ele => Number(ele.star)).forEach(function (item, idx) {
                sum += item;
            });
            const average = sum / hotelDetail.hotel_reviews.length;
            setReviewAverage(average)
        }
        setMyArray(sortedReviews)

    }, [hotelDetail]);

    const [alreadyCheckIn, setAlreadyCheckIn] = useState(null)
    const [alreadyCheckOut, setAlreadyCheckOut] = useState(null)
    const [alreadyAdults, setAlreadyAdults] = useState(null)
    const [alreadyChildren, setAlreadyChildren] = useState(null)
    const [alreadyTotalRooms, setAlreadyTotalRooms] = useState(null)

    useEffect(() => {
        const adults = parseInt(alreadyAdults) || 0;
        const children = parseInt(alreadyChildren) || 0;
        const sum = adults + children;

        let rooms = Math.ceil(sum / 2);

        if (sum === 5) rooms = 3;
        else if (sum === 7) rooms = 4;
        else if (sum === 9) rooms = 5;

        setAlreadyTotalRooms(rooms.toString());
    }, [alreadyAdults, alreadyChildren]);

    const { merchentId } = useParams();

    const InsertBooking = async () => {
        const token = localStorage.getItem("userToken");
        const bookingId = localStorage.getItem("bookingId");

        const res = await fetch(`${BASE_URL}payment/updateHotelBooking/${bookingId}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
            body: JSON.stringify({
                status: "booked",
                transaction_id: merchentId
            }),
        });

        const data = await res.json();

        if (data?.success) {
            localStorage.removeItem("bookingId")
            localStorage.removeItem("paymentId")
            localStorage.removeItem("paidAmount")
            localStorage.removeItem("paymentType")
            localStorage.removeItem("bookingId")
            toast.success("Your Booking Successfully Done!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            navigate("/my_package")
        } else {
            toast.error(data?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
        }

    };

    useEffect(() => {
        if (merchentId !== undefined && merchentId !== "") {
            InsertBooking();
        } else {

        }
    }, [merchentId]);

    const [pricePerAdult, setPricePerAdult] = useState(null)
    const [pricePerChild, setPricePerChild] = useState(null)
    const [pricePerAdultWithMargin, setPricePerAdultWithMargin] = useState(null)
    const [pricePerChildWithMargin, setPricePerChildWithMargin] = useState(null)
    const [priceAmount, setTotalAmount] = useState(null)


    const calculateAdjustedPrice2 = (priceRangeArray, date, percentageArray, totalChild, totalAdult, checkOut, checkIn) => {
        // Parse checkIn and checkOut dates
        const checkInDate = new Date(checkIn);
        const checkOutDate = new Date(checkOut);

        // Step 1: Find the price for adults and children based on the date range
        const priceObj = priceRangeArray.find(
            item =>
                checkInDate >= new Date(item.price_start_date) &&
                checkOutDate <= new Date(item.price_end_date)
        );

        if (!priceObj) {
            throw new Error("No price range found for the given dates.");
        }

        setPricePerAdult(priceObj?.adult_price)
        setPricePerChild(priceObj?.child_price)
        const adultPrice = priceObj?.adult_price;
        const childPrice = priceObj?.child_price;

        // Step 2: Extract the month from the checkIn date to find the margin percentage
        const month = checkInDate.toLocaleString("default", { month: "long" });
        const percentageObj = percentageArray.find(item => item.month_name === month);

        // If no percentage found, use 0 as the default margin
        const marginPercentage = percentageObj ? parseFloat(percentageObj.margin_percentage) : 0;

        // Step 3: Calculate adjusted prices for adults and children
        const adjustedPriceAdult = adultPrice + (adultPrice * marginPercentage / 100);
        const adjustedPriceChild = childPrice + (childPrice * marginPercentage / 100);
        setPricePerAdultWithMargin(adjustedPriceAdult)
        setPricePerChildWithMargin(adjustedPriceChild)

        // Step 4: Calculate total adjusted price per night based on the number of adults and children
        const totalAdjustedPrice = (adjustedPriceAdult * totalAdult) + (adjustedPriceChild * totalChild);
        setTotalAmount(Math.round(totalAdjustedPrice))

        return Math.round(totalAdjustedPrice);
    }

    const [fullname, setFullname] = useState("")
    const [email, setEmail] = useState("")
    const [gender, setGender] = useState("")
    const [number, setNumber] = useState("")
    const [city, setCity] = useState("")
    const [state, setState] = useState("")
    const [Adult, setAdult] = useState(null)
    const [Child, setChild] = useState(null)

    const [cities, setCities] = useState([])

    const handleName = (e) => {
        setFullname(e.target.value)
    }

    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    const handleAdultChange = (e) => setAdult(parseInt(e.target.value) || 0);
    const handleChildChange = (e) => setChild(parseInt(e.target.value) || 0);

    const handleState = (e) => {
        const selected = e.target.value;
        setState(selected)
        const selectedCity = selectedStates?.states && selectedStates?.states?.find((e) => e?.name === selected)
        setCities(selectedCity?.cities)
    }

    const handleConfirm = () => {
        navigate(`/booking-hotel-payment/${id}`, {
            state: {
                finalAmountWithMargin: Math.round(priceAmount),
                finalAmountAdultWithMargin: Math.round(pricePerAdultWithMargin),
                finalAmountChildWithMargin: Math.round(pricePerChildWithMargin),
                finalAmountAdult: Math.round(pricePerAdult),
                finalAmountChild: Math.round(pricePerChild),
                totalChild: alreadyChildren,
                totalAdult: alreadyAdults,
                checkIn: alreadyCheckIn,
                checkOut: alreadyCheckOut,
                totalRooms: alreadyTotalRooms,
                roomId: room_id?._id,
                fullname: fullname,
                email: email,
                number: number,
                gender: gender,
                state: state,
                city: city,
            },
        });
    }

    const Profile = async () => {
        const token = localStorage.getItem("userToken")
        const res = await fetch(`${BASE_URL}user/userprofile`, {
            method: "GET",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
        })
        const data = await res.json();

        const detail = data?.data?.[0]?.user_details?.[0]

        const selectedCity = selectedStates?.states && selectedStates?.states?.find((e) => e?.name === detail?.state)
        setCities(selectedCity?.cities)

        setFullname(detail?.name)
        setEmail(detail?.email_address)
        setGender(detail?.gender)
        setNumber(data?.data?.[0]?.phone)
        setState(detail?.state)
        setCity(detail?.city)
    }

    useEffect(() => {
        Profile();
    }, []);


    const [startingPrice, setStartingPrice] = useState(null);

    useEffect(() => {
        const getStartingFromPrice = () => {
            const currentDate = new Date();
            let possiblePrices = [];

            hotelDetail?.rooms?.forEach(room => {
                let closestPrice = null;
                let closestDateDiff = Infinity;

                room?.price_and_date?.forEach(priceObj => {
                    const startDate = new Date(priceObj.price_start_date);
                    const endDate = new Date(priceObj.price_end_date);

                    // Check if current date is within the date range
                    if (currentDate >= startDate && currentDate <= endDate) {
                        possiblePrices.push(priceObj.adult_price);
                    } else {
                        // Calculate date difference to find the closest range
                        const diff = Math.min(Math.abs(currentDate - startDate), Math.abs(currentDate - endDate));
                        if (diff < closestDateDiff) {
                            closestDateDiff = diff;
                            closestPrice = priceObj.adult_price;
                        }
                    }
                });

                // If no range includes current date, add the closest available price
                if (closestPrice !== null && !possiblePrices.includes(closestPrice)) {
                    possiblePrices.push(closestPrice);
                }
            });

            // Find the minimum price from possible prices
            if (possiblePrices.length > 0) {
                return Math.min(...possiblePrices);
            }
            return null;
        };

        // Calculate the starting price and update the state
        const price = getStartingFromPrice();
        setStartingPrice(price);

    }, [hotelDetail?.rooms]);

    const handleUpdate = () => {
        navigate(`/vendor/myhotels/${id}`)
    }

    const [showRoomsModal, setShowRoomsModal] = useState(false);

    const [priceDetails, setPriceDetails] = useState([]);
    const [roomDetail, setRoomDetail] = useState("")

    const [openRangeModal, setOpenRangeModal] = useState(false);
    const [pricePerPerson, setPricePerPerson] = useState('');
    const [pricePerPersonForChild, setPricePerPersonForChild] = useState('');
    const [priceStartDate, setPriceStartDate] = useState('');
    const [priceEndDate, setPriceEndDate] = useState('');

    const handleCloseRangeModal = () => {
        setOpenRangeModal(false)
    }

    const handleSave = () => {

        if (!pricePerPerson || !pricePerPersonForChild || !priceStartDate || !priceEndDate) {
            toast.error("Please Fill All Fields!", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }

        // Validation for prices (Child price cannot be more than adult, etc.)
        if (Number(pricePerPerson) < Number(pricePerPersonForChild)) {
            toast.error("Childrens Price cannot be bigger than Adult Price!", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }

        // Convert the date strings to Date objects
        const startDate = new Date(priceStartDate);
        const endDate = new Date(priceEndDate);

        // Check for overlapping date ranges in priceDetails
        const overlappingRanges = priceDetails?.filter((item) => {
            const priceStart = new Date(item.price_start_date);
            const priceEnd = new Date(item.price_end_date);

            return (
                (startDate >= priceStart && startDate <= priceEnd) ||
                (endDate >= priceStart && endDate <= priceEnd)
            );
        });

        if (overlappingRanges?.length > 0) {
            toast.error("Dates are mismatched with other dates!", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }

        // If all validations pass, create the new price detail
        const newDetail = {
            adult_price: Number(pricePerPerson),
            child_price: Number(pricePerPersonForChild),
            price_start_date: priceStartDate,
            price_end_date: priceEndDate,
        };

        // Add the new detail to the array of priceDetails
        if (Array.isArray(priceDetails)) {
            setPriceDetails([...priceDetails, newDetail]);
        } else {
            setPriceDetails([newDetail]);
        }

        // Reset the form fields
        setPricePerPerson('');
        setPricePerPersonForChild('');
        setPriceStartDate('');
        setPriceEndDate('');
        setOpenRangeModal(false);
        setShowRoomsModal(true)
    }

    const handleDelete = (index) => {
        const updatedPriceRanges = priceDetails?.filter((_, i) => i !== index);
        setPriceDetails(updatedPriceRanges);
    }

    const [roomServices, setRoomServices] = useState("")

    const handleSubmit = async () => {

        if (priceDetails?.length === 0) {
            toast.error("Please Enter Date And Price!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }

        if (!roomServices) {
            toast.error("Please Enter Other Services!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }

        try {
            const token = localStorage.getItem("vendorToken");
            const res = await fetch(`${BASE_URL}hotel_booking_syt/cancel_status_by_user/${id}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            });

            const data = await res.json();
            // navigate(-1)
            // toast.success("Room Added Successfully!", {
            //     position: toast.POSITION.BOTTOM_RIGHT,
            //     autoClose: 1000,
            // });
        } catch (error) {
            toast.error("There Is Some Issue In Add Room!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
        }
    }

    return (
        <>
            <Header2 />
            <div className="costum_container">
                <My_pannel />
                <ProfileSection title="Hotel Detail" />
                <div className="booked_packega_margin p-0">
                    {/*  <!----------------------------------------------- image section ---------------------------------------------> */}

                    <section class="pt-4">
                        <div class="container-customes">
                            <div class="row text-center">
                                <div className="col-8">
                                    <div className="row h-100">
                                        <div className="col-5 h-100 display-none-mobile">
                                            <div className="hotel-details-classname-header-images hotel-details-classname-sea-image h-100">
                                                <img src={hotelDetail?.hotel_photo?.[0]} alt="Hotel Main" className="hotel-details-classname-main-image h-100" />
                                            </div>
                                        </div>
                                        <div className="col-7 h-100 display-none-mobile">
                                            <div className="row gy-1 h-100">
                                                {[1, 2, 3, 4].map((index) => (
                                                    <div key={index} className="col-6 hotel-details-classname-img-wrapper h-50">
                                                        <img src={hotelDetail?.hotel_photo?.[index]} alt={`Hotel Thumbnail ${index}`} className="hotel-details-classname-thumbnail h-100 w-100" />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                    <div className="availibility">
                                        <h4 className="text18 font700">{hotelDetail.hotel_name}</h4>
                                        {source === "edit" && (
                                            <button className="check-availibility-button mb-2" onClick={handleUpdate}>
                                                Update
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="whyso">
                        <div class="container-customes stick-link-bar">
                            <div class={`row  rounded px-4 pb-1 pt-2 text-center gx-2 ${isSticky ? 'hshshs' : ''}`}>
                                <div class="col-6 Overview">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <a onClick={() => scrollTo('overview')} style={{ cursor: "pointer" }} className="font700">Overview</a>
                                        <a onClick={() => scrollTo('rooms')} style={{ cursor: "pointer" }} class="d-lg-block d-md-none d-none font700">
                                            Rooms
                                        </a>
                                        <a onClick={() => scrollTo('facilities')} style={{ cursor: "pointer" }} class="d-lg-block d-md-none d-none font700">
                                            Facilities
                                        </a>
                                        <a onClick={() => scrollTo('location')} style={{ cursor: "pointer" }} class="d-lg-block d-md-none d-none font700">
                                            Location
                                        </a>
                                        <a onClick={() => scrollTo('policies')} style={{ cursor: "pointer" }} class="d-lg-block d-md-none d-none font700">
                                            Policies
                                        </a>

                                        {myArray && myArray?.length > 0 && (
                                            <a onClick={() => scrollTo('review')} style={{ cursor: "pointer" }} class="d-lg-block d-md-none d-none font700">
                                                Review
                                            </a>
                                        )}
                                    </div>
                                </div>
                                <div class="col-6 rupees text-end">
                                    <span>starting from</span>
                                    <h4 class="d-inline ms-1">
                                        <FontAwesomeIcon icon={faIndianRupeeSign} /> {startingPrice + (startingPrice * 10 / 100)}
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* <!---------------------------------------- center ---------------------------------------------> */}

                    <section ref={overRef} className={`${isSticky ? 'overrefcss' : ''}`}>
                        <div class="container-customes" >
                            <div class="row gx-3" >
                                <div class="col-xl-12 col-lg-8 col-md-12 col-sm-12">
                                    <div class="rose-valley" >
                                        <div>
                                            <div>
                                                <div class="d-flex flex-xl-row flex-lg-row flex-md-row flex-sm-column flex-column">
                                                    <div class="me-4">
                                                        <h4 class="m-0 text-start font700 text18">{hotelDetail.hotel_name}</h4>
                                                    </div>
                                                    <div class="d-flex">
                                                        {[...Array(totalStars)].map((_, index) => {
                                                            return (
                                                                <FontAwesomeIcon
                                                                    key={index}
                                                                    icon={faStar}
                                                                    style={{ color: index < reviewAverage ? 'yellow' : '#DDDDDD' }}
                                                                />
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                            <p class="mt-2 pb-3 border-bot text14">
                                                {hotelDetail.hotel_address},{hotelDetail.city},{hotelDetail.state}
                                            </p>
                                        </div>
                                        <div class="rose-paragraph">
                                            <p className="font500">{hotelDetail.hotel_description}</p>
                                        </div>
                                        <div>
                                            <div className="font700 text16">Other Details</div>
                                            <ul className="mt-2">
                                                {hotelDetail.other?.map(e => (
                                                    <li className="text14">{e}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="highlights my-4">
                                        <div class="me-4 mb-4">
                                            <h4 class="m-0 text18 font700">Highlights</h4>
                                        </div>
                                        <div class="row text-center">
                                            {hotelDetail?.Highlights && hotelDetail?.Highlights.map((e) => (
                                                <div className="col-2">
                                                    <img src={e.icon} alt="" class="mb-2" style={{ height: "40px", width: "40px" }} />
                                                    <p className="font600">{e.title}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="highlights pb-4">
                                        <div class="me-4 mb-3">
                                            <h4 class="m-0 text18 font700">Rooms</h4>
                                        </div>

                                        <Modal show={openRangeModal} onHide={handleCloseRangeModal}>
                                            <Modal.Body>
                                                <Modal.Title>
                                                    <h4 className="font700 text20">Add Price</h4>
                                                </Modal.Title>
                                                <div className="mt-3">
                                                    <p htmlFor="" className="mb-1 vendoraddpackage-label text12" style={{ fontWeight: "bold", fontSize: "12px" }}>Price Per Person For Adult</p>
                                                    <input
                                                        type="text"
                                                        placeholder="price"
                                                        name="price"
                                                        value={pricePerPerson}
                                                        onChange={(e) => setPricePerPerson(e.target.value)}
                                                        className="vendoraddpackage-input"
                                                    />
                                                </div>
                                                <div className="mt-3">
                                                    <p htmlFor="" className="mb-1 vendoraddpackage-label text12" style={{ fontWeight: "bold", fontSize: "12px" }}>Price Per Person For Children</p>
                                                    <input
                                                        type="text"
                                                        placeholder="price"
                                                        name="price"
                                                        value={pricePerPersonForChild}
                                                        onChange={(e) => setPricePerPersonForChild(e.target.value)}
                                                        className="vendoraddpackage-input"
                                                    />
                                                </div>
                                                <div className="mt-3">
                                                    <p htmlFor="" className="mb-1 vendoraddpackage-label text12" style={{ fontWeight: "bold", fontSize: "12px" }}>Select Dates For This Price Aplicable</p>
                                                    <div className="d-flex justify-content-between gap-2">
                                                        <input
                                                            type="date"
                                                            name="start_date"
                                                            placeholder="Enter Start Date"
                                                            value={priceStartDate}
                                                            onChange={(e) => setPriceStartDate(e.target.value)}
                                                            min={getTomorrowDate()}
                                                            className="vendoraddpackage-input"
                                                        />

                                                        <input
                                                            type="date"
                                                            name="end_date"
                                                            placeholder="Enter End Date"
                                                            value={priceEndDate}
                                                            onChange={(e) => setPriceEndDate(e.target.value)}
                                                            min={priceStartDate}
                                                            className="vendoraddpackageitinerary-input"
                                                            disabled={!priceStartDate}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-between mt-4">
                                                    <Button variant="secondary" onClick={() => (handleCloseRangeModal(), setShowRoomsModal(true))}>
                                                        Close
                                                    </Button>
                                                    <div className="d-flex align-items-center">
                                                        <Button className="vendoraddpackage-button" onClick={handleSave}>
                                                            Save
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                        </Modal>

                                        <Modal show={showRoomsModal} onHide={() => { setShowRoomsModal(false); }}>
                                            <Modal.Body>
                                                <div className="hotel-model-title-container">
                                                    <div>
                                                        <Modal.Title closeButton> <p className="font700 text20 mb-0">Add Room</p></Modal.Title>
                                                    </div>
                                                    <div><FontAwesomeIcon icon={faClose} className="cross-icon" onClick={() => { setShowRoomsModal(false) }} /></div>
                                                </div>


                                                <div className="mt-2 d-flex justify-content-between align-items-center mb-2">
                                                    <p className='mb-0 font600 text14'>Add Price</p>
                                                    <FontAwesomeIcon icon={faPlus} className="plus-icon" onClick={() => (setOpenRangeModal(true), setShowRoomsModal(false))} />
                                                </div>
                                                <div>
                                                    <table className="table table-striped table-hover">
                                                        <thead className="table-dark">
                                                            <tr>
                                                                <th scope="col"></th>
                                                                <th scope="col">
                                                                    <p className="vendoraddpackage-label text12 font600 mb-0">Adult</p>
                                                                </th>
                                                                <th scope="col">
                                                                    <p className="vendoraddpackage-label text12 font600 mb-0">Children</p>
                                                                </th>
                                                                <th scope="col">
                                                                    <p className="vendoraddpackage-label text12 font600 mb-0">Start Date</p>
                                                                </th>
                                                                <th scope="col">
                                                                    <p className="vendoraddpackage-label text12 font600 mb-0">End Date</p>
                                                                </th>
                                                                <th scope="col">
                                                                    <p className="vendoraddpackage-label text12 font600 mb-0">Actions</p>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {priceDetails?.length > 0 ? (
                                                                priceDetails
                                                                    ?.sort((a, b) => {
                                                                        const startDateA = new Date(a.price_start_date);
                                                                        const startDateB = new Date(b.price_start_date);

                                                                        // If the start dates are the same, sort by end date
                                                                        if (startDateA.getTime() === startDateB.getTime()) {
                                                                            const endDateA = new Date(a.price_end_date);
                                                                            const endDateB = new Date(b.price_end_date);
                                                                            return endDateA - endDateB;
                                                                        }

                                                                        return startDateA - startDateB;
                                                                    })
                                                                    ?.map((range, index) => (
                                                                        <tr key={index}>
                                                                            <td className="text14">{index + 1}.</td>
                                                                            <td className="text14">{range?.adult_price}</td>
                                                                            <td className="text14">{range?.child_price}</td>
                                                                            <td className="text14">{formatDate(range?.price_start_date)}</td>
                                                                            <td className="text14">{formatDate(range?.price_end_date)}</td>
                                                                            <td>
                                                                                <Button
                                                                                    variant="danger"
                                                                                    onClick={() => handleDelete(index)}
                                                                                    className="btn btn-sm"
                                                                                >
                                                                                    Delete
                                                                                </Button>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan="7" className="text-center text-muted">
                                                                        No price ranges added yet.
                                                                    </td>
                                                                </tr>
                                                            )}

                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="mt-2">
                                                    <p className='mb-1 font600 text14'>Other Services</p>
                                                    <div>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={roomServices}
                                                            onChange={(event, editor) => setRoomServices(editor.getData())}
                                                        />
                                                    </div>
                                                </div>
                                            </Modal.Body>

                                            <div className="hotel-model-footer">
                                                <button className="hotel-model-footer-button" variant="primary" onClick={handleSubmit} >
                                                    Submit
                                                </button>
                                            </div>
                                        </Modal>

                                        {roomData.length === 0 &&
                                            <div ref={roomsRef} class="delux-room" >
                                                {hotelDetail.rooms &&
                                                    hotelDetail.rooms.map((ele) => {
                                                        return (
                                                            <div style={{ position: "relative" }}>
                                                                {source === "room" && (
                                                                    <button
                                                                        className="check-availibility-button"
                                                                        style={{
                                                                            position: "absolute",
                                                                            top: "10px",
                                                                            right: "10px",
                                                                            padding: "5px 20px",
                                                                            color: "white",
                                                                            border: "none",
                                                                            borderRadius: "3px",
                                                                            cursor: "pointer",
                                                                            fontSize: "12px"
                                                                        }}
                                                                        onClick={() => {
                                                                            setShowRoomsModal(true);
                                                                            setRoomDetail(ele)
                                                                        }}
                                                                    >
                                                                        ADD
                                                                    </button>
                                                                )}
                                                                <div className="d-block d-sm-flex" style={{ border: "2px solid #d6d4d4", padding: "10px", borderRadius: "20px" }}>
                                                                    <div>
                                                                        <Carousel style={{ height: "200px", width: "300px" }}>
                                                                            {ele.photos.map((e, index) => (
                                                                                <Carousel.Item key={index} interval={2000}>
                                                                                    <img src={e} alt={`Slide ${index}`} className="inner-rooms-image" />
                                                                                </Carousel.Item>
                                                                            ))}
                                                                        </Carousel>
                                                                    </div>
                                                                    <div>
                                                                        <div className="facilities">
                                                                            <h5 className="font700 text17">{ele.room_title}</h5>
                                                                            <div>
                                                                                <ul className="mb-1 row">
                                                                                    {ele.room_highlights.map((elem) => (
                                                                                        <li className="col-7 font600 text13" key={elem}>{elem}</li>
                                                                                    ))}
                                                                                </ul>
                                                                            </div>
                                                                            {source === "edit" && (
                                                                                <div>
                                                                                    <h2 className="mb-xl-3 mb-lg-3 mb-md-2 mb-sm-1 mb-1 text20 font700">
                                                                                        <FontAwesomeIcon icon={faIndianRupeeSign} />
                                                                                        {getPriceForDateRange(
                                                                                            getTodayDate(),
                                                                                            ele?.price_and_date,
                                                                                            getMarginPercentage(getTodayDate(), hotelDetail?.profitMargin?.[0]?.month_and_margin_user)
                                                                                        )}

                                                                                        <span> per night</span>
                                                                                    </h2>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                <BookingModal
                                                    isOpen={isModalOpen}
                                                    onClose={() => setIsModalOpen(false)}
                                                    hotelId={id}
                                                    hotelData={hotelDetail}
                                                    roomId={room_id}
                                                />
                                            </div>
                                        }
                                    </div>

                                    <div class="amenities my-4" ref={facilities}>
                                        <h5 class="ps-2 text18 font700">Amenities and facilities</h5>
                                        <div class="row px-4">
                                            {amenities && amenities.map((e) => (
                                                <div class="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-12 antiviral">
                                                    <h6 className="font600">{e.title}</h6>
                                                    <ul class="mb-1">
                                                        {e.points.map((data) => (
                                                            <li class="mb-1">{data}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>

                    {/* <!---------------------------------------- map -------------------------------------------> */}

                    <section class="py-4" ref={location}>
                        <div class="container-customes">
                            <div class="row">
                                <div class="col-12">
                                    <h4 className="text18 font700">Location</h4>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d281.38951493294434!2d72.62833295728745!3d23.11379047764994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e84604595e899%3A0x3e7418c1b4e56140!2sWebEarl%20Technologies%20Private%20Limited!5e1!3m2!1sen!2sin!4v1731331171850!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* <!------------------------------- footer ----------------------------------------> */}

                    <section class="my-4 pb-4" ref={policies}>
                        <div class="container-customes">
                            <div class="row foot-center">
                                <div class="col footer">
                                    <div class="foot">
                                        <div class="footer-head">
                                            <h5 class="text18 font700">Property policies</h5>
                                            <h6 class="mb-1">{propertyDetail?.policy_title}</h6>
                                            <p>
                                                {propertyDetail?.policy_description}
                                            </p>
                                        </div>
                                        <div class="row gx-3">
                                            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 pt-3">
                                                <div class="box">
                                                    <div class="mb-3">
                                                        <h5 class="pb-2 text16 font700">Infant 0-2 year</h5>
                                                    </div>
                                                    <div>
                                                        <h6 class="mb-3">
                                                            {propertyDetail?.infant}
                                                        </h6>
                                                        {propertyDetail && propertyDetail?.infant_points?.map((e) => (
                                                            <p>{e}</p>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 pt-3">
                                                <div class="box">
                                                    <div class="mb-3">
                                                        <h5 class="pb-2 text16 font700">Children 3-11 year</h5>
                                                    </div>
                                                    <div class="pb-3">
                                                        <h6 class="mb-3">{propertyDetail?.children}</h6>
                                                        {propertyDetail && propertyDetail?.childern_points?.map((e) => (
                                                            <p>{e}</p>))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 pt-3">
                                                <div class="box">
                                                    <div class="mb-3">
                                                        <h5 class="pb-2 text16 font700">Adults 12 & above</h5>
                                                    </div>
                                                    <div class="pb-5">
                                                        <h6 class="mb-3">{propertyDetail?.infant}</h6>
                                                        {propertyDetail && propertyDetail?.infant_points?.map((e) => (
                                                            <p>{e}</p>))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="others mt-3">
                                            <h5 className="text18 font700">Others</h5>
                                            <ul>
                                                {propertyDetail && propertyDetail?.policy_other.map((e) => (
                                                    <li>
                                                        {e}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {myArray && myArray?.length > 0 && (
                        <section className="my-4 pb-4" ref={reviewRef}>
                            <div class="container-customes">
                                <div class="row foot-center">
                                    <div class="col footer">
                                        <div class="foot">
                                            <div class="footer-head hotel-review-box">
                                                <div>
                                                    <h5 className="mb-1 text18 font700">Reviews</h5>
                                                    <div className="d-flex align-items-center gap-2">
                                                        {[...Array(totalStars)].map((_, index) => {
                                                            return (
                                                                <FontAwesomeIcon
                                                                    key={index}
                                                                    icon={faStar}
                                                                    style={{ color: index < reviewAverage ? 'yellow' : '#DDDDDD' }}
                                                                />
                                                            );
                                                        })}
                                                        {reviewAverage}
                                                    </div>
                                                </div>
                                            </div>
                                            <hr className="mb-5" />
                                            <div className="hotel-riviews">
                                                {myArray && myArray?.map((e) => (
                                                    <div className="hotel-review-specific">
                                                        <div className="personal-review">
                                                            <div className="d-flex justify-content-between">
                                                                <div className="d-flex hotel-review-header">
                                                                    <div>
                                                                        <img src="" alt="" />
                                                                    </div>
                                                                    <div>
                                                                        <h5 className="mb-1">{e?.user_details?.name}</h5>
                                                                        <p className="mb-0">{e?.createdAt?.slice(0, 10)}</p>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <Rating
                                                                        name="simple-controlled"
                                                                        value={parseInt(e.star)}
                                                                        readOnly
                                                                    />
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div>
                                                                <p>{e?.comment_box}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}

                    <hr />

                </div>
            </div>
        </>
    );
}

export default HotelDetailById;
