import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBell,
    faEnvelopeOpen,
    faUser,
    faPlus,
    faTrash,
    faClose,
    faEdit,
    faMinus
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Header2 from "./Header2";
import My_pannel from "./My_pannel";
import { BASE_URL } from "../BASE_URL";
import "./css/Hotels.css";
import { Button, Modal, Form } from 'react-bootstrap';
import MapComponent from "./MapComponent";
import RoomCard from "./RoomCard";
import addressdata from ".././CountryStateCity.json"
import { Policy } from "@mui/icons-material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from "./Loading";
import { Rating } from "@mui/material";
import ProfileSection from "./SecondNavbar";

const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
};

function getTomorrowDate() {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1); // Set date to tomorrow

    const year = tomorrow.getFullYear();
    const month = String(tomorrow.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(tomorrow.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

function PrePurchaseHotelDetail() {
    const navigate = useNavigate();
    const { hotelId } = useParams();

    const [loading, setLoading] = useState(true)

    const [editedata, setEditedData] = useState("");

    const [editing1, setEditing1] = useState(false);
    const [editing2, setEditing2] = useState(false);
    const [editing3, setEditing3] = useState(false);
    const [editing4, setEditing4] = useState(false);


    const Call = async () => {
        const token = localStorage.getItem("vendorToken");
        const res = await fetch(`${BASE_URL}agency/profile`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });
        const data = await res.json();
        setEditedData(data?.data?.[0]);
    };

    useEffect(() => {
        Call();
    }, []);

    const handleNav = () => {
        navigate("/vendor/myprofile");
    };

    const [allData, setAllData] = useState('')


    const [hotelData, setHotelData] = useState({
        hotel_name: "",
        hotel_address: "",
        hotel_description: "",
        hotel_type: "",
    });

    const [hotelData2, setHotelData2] = useState({
        hotel_name: "",
        hotel_address: "",
        hotel_description: "",
        hotel_type: "",
    });


    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])

    const [selectedState, setSelectedState] = useState("")
    const [selectedState2, setSelectedState2] = useState("")
    const [selectedCity, setSelectedCity] = useState("")
    const [selectedCity2, setSelectedCity2] = useState("")
    const [otherDetails, setOtherDetails] = useState(['']);
    const [otherDetails2, setOtherDetails2] = useState(['']);


    useEffect(() => {
        const city = states.find((e) => e.name === selectedState)
        setCities(city?.cities)
        setSelectedCity(allData.city)
    }, [selectedState, allData])

    const getHotelData = async () => {
        const token = localStorage.getItem("vendorToken");
        setLoading(true);
        try {
            const response = await fetch(`${BASE_URL}hotel_syt/details?_id=${hotelId}`, {
                method: 'GET',
                headers: {
                    Authorization: token,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {

                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data1 = await response.json();
            setAllData(data1.data[0])
            const city = states.find((e) => e.name === data1.data[0].state)
            setCities(city?.cities)
            const data = data1.data[0];

            setHotelReviews(data.hotel_reviews);

            setSelectedState(data.state)
            setSelectedState2(data.state)
            setSelectedCity(data.city)
            setSelectedCity2(data.city)

            setOtherDetails(data.other)
            setOtherDetails2(data.other)

            setHotelData({
                hotel_name: data.hotel_name,
                hotel_address: data.hotel_address,
                hotel_description: data.hotel_description,
                hotel_type: data.hotel_type,
            });
            setHotelData2({
                hotel_name: data.hotel_name,
                hotel_address: data.hotel_address,
                hotel_description: data.hotel_description,
                hotel_type: data.hotel_type,
            });

            setHighlightArray2(data.Highlights);
            setHighlightArray3(data.Highlights);


            const images = data.hotel_photo.map(url => ({ url }));
            setImages(images);
            setImages3(images)

            setAmenities(data.amenities_and_facilities);
            setAmenities2(data.amenities_and_facilities);

            if (!data.amenities_and_facilities[0]) {
                setAmenitieslength(false)
            }

            if (!data.rooms[0]) {
                setRoomslength(false)
            }

            const rooms = data.rooms.map(room => ({
                ...room,
                photos: room.photos.map(photo => ({ url: photo }))
            }));

            setRooms(rooms);
            setRooms2(rooms);

            const policy = data.property_policies[0];
            if (!policy) {
                setPolicylength(false);
            }

            setPolicy1({
                policy_title: policy.policy_title,
                policy_description: policy.policy_description
            });
            setPolicy1a({
                policy_title: policy.policy_title,
                policy_description: policy.policy_description
            });

            setPolicy2({
                infant: policy.infant,
                infant_points: policy.infant_points
            });

            setPolicy2a({
                infant: policy.infant,
                infant_points: policy.infant_points
            });

            setPolicy3({
                children: policy.children,
                childern_points: policy.childern_points
            });

            setPolicy3a({
                children: policy.children,
                childern_points: policy.childern_points
            });

            setPolicy4({
                adult_and_above: policy.adult_and_above,
                adult_and_above_points: policy.adult_and_above_points
            });

            setPolicy4a({
                adult_and_above: policy.adult_and_above,
                adult_and_above_points: policy.adult_and_above_points
            });

            setPolicy5({
                policy_other: policy.policy_other
            });

            setPolicy5a({
                policy_other: policy.policy_other
            });

        } catch (error) {
            console.error('Error fetching hotel details:', error);
        }
        finally {
            setLoading(false); // End loading
        }

    };

    useEffect(() => {
        getHotelData();
    }, []);


    useEffect(() => {
        if (addressdata) {
            const country = addressdata && addressdata.find((e) => e.name === "India")
            setStates(country.states)
        }
    }, [addressdata]);

    const handleChange = (e) => {


        const { name, value } = e.target;
        if (name === "state") {
            const selected = e.target.value;
            setSelectedState(e.target.value)
            const cities = states && states.find((e) => e.name === selected)
            setCities(cities.cities)
            setSelectedCity("")
        } else if (name === "city") {
            setSelectedCity(value)
        } else {
            setHotelData({
                ...hotelData,
                [name]: value,
            });
        }
    };


    const handleOtherDetailChange = (index, event) => {
        const newOtherDetails = [...otherDetails];
        newOtherDetails[index] = event.target.value;
        setOtherDetails(newOtherDetails);
        handleChange({
            target: {
                name: 'hotel_others',
                value: newOtherDetails
            }
        });
    };

    const inputRef = useRef(null);
    const addOtherDetailField = () => {
        if (!otherDetails) {
            setOtherDetails(['']);
        }
        else if (otherDetails[otherDetails.length - 1] !== '') {
            setOtherDetails([...otherDetails, '']);
        }
        else {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    };

    const subOtherDetailField = (index) => {
        if (otherDetails.length > 1) {
            const other = otherDetails.slice(0, index).concat(otherDetails.slice(index + 1));
            setOtherDetails(other)
        }
    }

    const handleCancelEditHotelDetails = () => {
        setSelectedState(selectedState2)
        setSelectedCity(selectedCity2)

        setOtherDetails(otherDetails2)


        setHotelData(hotelData2);


        setHighlightArray2(highlightArray3);
        setImages(images3);

    }



    const [highlightlist, setHighlightlist] = useState([]);
    const [highlightArray, setHighlightArray] = useState([]);
    const [highlightArray2, setHighlightArray2] = useState([]);
    const [highlightArray3, setHighlightArray3] = useState([]);
    const [showSection3, setShowSection3] = useState(true);
    const [showHighlightsModal, setShowHighlightsModal] = useState(false);

    // const countries = addressdata.find((obj) => obj.name === "India")


    const getHotelHighlights = async () => {
        const token = localStorage.getItem("vendorToken");
        const res = await fetch(`${BASE_URL}highlight/`, {
            method: "GET",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
        });
        const data = await res.json();
        setHighlightlist(data?.data);
    };

    useEffect(() => {
        getHotelHighlights();
    }, []);

    const handleHighlightChange = (event) => {
        const { value, checked } = event.target;
        // const selectedOptions = Array.from(event.target.selectedOptions).map(option => option.value);
        setHighlightArray(prevState => {
            if (checked) {
                return [...prevState, value];
            } else {
                return prevState.filter(option => option !== value);
            }
        });
    };

    const handleSaveChanges = async () => {
        const filteredHighlights = highlightlist.filter(highlight =>
            highlightArray.includes(highlight._id)
        );
        setHighlightArray2(filteredHighlights);
        setShowHighlightsModal(false);
    };


    const [images, setImages] = useState([]);
    const [images2, setImages2] = useState([]);
    const [images3, setImages3] = useState([]);
    const fileInputRef = useRef(null);

    const handleAddImageClick = () => {
        fileInputRef.current.click();
    };

    const handleImageChange = (event) => {
        const files = Array.from(event.target.files);
        const fileObjects = files.map(file => ({ file, url: URL.createObjectURL(file) }));
        setImages2(prevImages => [...prevImages, ...fileObjects]);
    };

    const handleRemoveImage = (indexToRemove) => {
        setImages(prevImages => prevImages.filter((_, index) => index !== indexToRemove));
    };

    const handleRemoveImage2 = (indexToRemove) => {
        setImages2(prevImages => prevImages.filter((_, index) => index !== indexToRemove));
    };



    // submit 1

    const handleSubmit = async (e) => {
        e.preventDefault();



        if (!hotelData.hotel_name) {
            toast.error("Hotel name is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!hotelData.hotel_address) {
            toast.error("Hotel address is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!hotelData.hotel_description) {
            toast.error("Hotel description is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!hotelData.hotel_type) {
            toast.error("Hotel type is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!selectedState) {
            toast.error("State is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!selectedCity) {
            toast.error("City is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (highlightArray2.length === 0) {
            toast.error("At least one highlight is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (images.length + images2.length < 5) {
            toast.error("At least five image is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        const formData = new FormData();

        let links = images.map(image => image.url);

        // Append hotel data to FormData
        formData.append("hotel_name", hotelData.hotel_name);
        formData.append("hotel_address", hotelData.hotel_address);
        formData.append("hotel_description", hotelData.hotel_description);
        formData.append("hotel_type", hotelData.hotel_type);
        formData.append("state", selectedState);
        formData.append("city", selectedCity);

        const highlightArrayIds = highlightArray2.map(item => item._id);

        highlightArrayIds.forEach((highlight, index) => {
            formData.append("hotel_highlights", highlight)
        });

        links.forEach((image, index) => {
            formData.append("previmages", image)
        });
        images2.forEach(({ file }, index) => {
            formData.append("hotel_photo", file);
        });
        otherDetails.forEach((other, index) => {
            if (other !== '')
                formData.append("other", other);
        });

        const token = localStorage.getItem("vendorToken");
        const res = await fetch(`${BASE_URL}hotel_syt?_id=${hotelId}`, {
            method: "PUT",
            headers: {
                Authorization: token,
            },
            body: formData,
        });

        const data = await res.json();
        if (res.ok) {
            // setHotelId(data.data._id);
            // navigate to another page if needed
            setImages2([]);
            setImages([]);
            getHotelData();
            setEditing1(false);
            toast.success("Hotel Details Updated", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
        } else {
            alert(`Failed to update hotel: ${data.message}`);
        }
    };



    // aminities section

    const [showAminitiesModal, setShowAminitiesModal] = useState(false);

    const [amenitieslength, setAmenitieslength] = useState(true);


    const [amenities, setAmenities] = useState([]);
    const [amenities2, setAmenities2] = useState([]);

    const [newAmenity, setNewAmenity] = useState({ title: '', points: [''] });

    const [currentAmenity, setCurrentAmenity] = useState(null); // New state for editing amenity
    const [deletedAmenities, setDeletedAmenities] = useState([]); // New state for tracking deleted amenities

    const afsubpointRef = useRef(null);
    const handleAddPoint = () => {
        if (newAmenity.points[newAmenity.points.length - 1] !== '') {
            setNewAmenity({ ...newAmenity, points: [...newAmenity.points, ''] });
        }
        else {
            if (afsubpointRef.current) {
                afsubpointRef.current.focus();
            }
        }
    };

    const handleSubPoint = (index) => {
        const amenity = newAmenity.points.slice(0, index).concat(newAmenity.points.slice(index + 1));
        setNewAmenity({ ...newAmenity, points: amenity })
    }

    const handlePointChange = (index, value) => {
        const newPoints = newAmenity.points.map((point, i) => i === index ? value : point);
        setNewAmenity({ ...newAmenity, points: newPoints });
    };

    const handleTitleChange = (e) => {
        setNewAmenity({ ...newAmenity, title: e.target.value });
    };

    const handleEditAmenity = (index) => {
        const amenityToEdit = amenities[index];
        setNewAmenity(amenityToEdit);
        setCurrentAmenity(index);
        setShowAminitiesModal(true);
    };

    const handleDeleteAmenity = (index) => {
        const amenityToDelete = amenities[index];
        if (amenityToDelete._id) {
            setDeletedAmenities([...deletedAmenities, amenityToDelete._id]);
        }
        const updatedAmenities = amenities.filter((_, i) => i !== index);
        setAmenities(updatedAmenities);
    };

    const handleSaveChanges2 = () => {

        if (!newAmenity.title) {
            toast.error("Add Amenity Title", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        const amenityTitle = newAmenity.title;
        const amenityPoints = newAmenity.points.filter(x => x.trim() !== '');

        if (amenityPoints.length === 0) {
            toast.error("Add Atleast one Amenity Detail", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        const updatedAmenity = { ...newAmenity, title: amenityTitle, points: amenityPoints };


        if (currentAmenity !== null) {
            const updatedAmenities = amenities.map((amenity, index) =>
                index === currentAmenity ? updatedAmenity : amenity
            );
            setAmenities(updatedAmenities);
            setCurrentAmenity(null);
        } else {
            setAmenities([...amenities, updatedAmenity]);
        }

        setNewAmenity({ title: '', points: [''] });
        setShowAminitiesModal(false);
    };

    const handleSubmit2 = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem("vendorToken");

        if (amenities.length === 0) {
            toast.error("At least one amenity is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }

        const addPromises = amenities.filter(amenity => !amenity._id).map(async (amenity) => {
            const response = await fetch(`${BASE_URL}amenities_and_facilities`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token,
                },
                body: JSON.stringify({
                    title: amenity.title,
                    points: amenity.points,
                    hotel_id: hotelId,
                }),
            });

            if (!response.ok) {
                throw new Error(`Failed to add amenity: ${amenity.title}`);
            }

            return response.json();
        });

        const editPromises = amenities.filter(amenity => amenity._id).map(async (amenity) => {
            const response = await fetch(`${BASE_URL}amenities_and_facilities?_id=${amenity._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token,
                },
                body: JSON.stringify({
                    title: amenity.title,
                    points: amenity.points,
                    hotel_id: hotelId,
                }),
            });

            if (!response.ok) {
                throw new Error(`Failed to edit amenity: ${amenity.title}`);
            }

            return response.json();
        });

        const deletePromises = deletedAmenities.map(async (amenityId) => {
            const response = await fetch(`${BASE_URL}amenities_and_facilities?_id=${amenityId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token,
                },
            });

            if (!response.ok) {
                throw new Error(`Failed to delete amenity: ${amenityId}`);
            }

            return response.json();
        });

        try {
            const results = await Promise.all([...addPromises, ...editPromises, ...deletePromises]);
            // Handle results if necessary
            getHotelData();
            setDeletedAmenities([]);
            setEditing2(false);
            toast.success("Hotel Amenities Updated Succesfully", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
        } catch (error) {
            console.error('Error handling amenities:', error);
        }
    };






    // rooms section

    const [showRoomsModal, setShowRoomsModal] = useState(false);


    const [roomslength, setRoomslength] = useState(true);


    const [rooms, setRooms] = useState([]);
    const [rooms2, setRooms2] = useState([]);
    const [roomImages, setRoomImages] = useState([]);
    const [roomImages2, setRoomImages2] = useState([]);
    const [newRoom, setNewRoom] = useState({
        room_title: '',
        room_highlights: [''],
        price: '',
        new_photos: [],
        photos: [],
        total_rooms: '',
        start_date: '',
        end_date: '',
        status: '',
    });

    const [currentRoom, setCurrentRoom] = useState(null); // New state for editing room
    const [deletedRooms, setDeletedRooms] = useState([]);

    const rhsubpointRef = useRef(null);
    const handleAddRoomHighlight = () => {
        if (newRoom.room_highlights[newRoom.room_highlights.length - 1] !== '') {
            setNewRoom({ ...newRoom, room_highlights: [...newRoom.room_highlights, ''] });
        }
        else {
            if (rhsubpointRef.current) {
                rhsubpointRef.current.focus();
            }
        }
    };

    const handleSubRoomHighlight = (index) => {
        const room = newRoom.room_highlights.slice(0, index).concat(newRoom.room_highlights.slice(index + 1));
        setNewRoom({ ...newRoom, room_highlights: room })
    }

    const handleRoomHighlightChange = (index, value) => {
        const newPoints = newRoom.room_highlights.map((point, i) => i === index ? value : point);
        setNewRoom({ ...newRoom, room_highlights: newPoints });
    };

    const handleRoomTitleChange = (e) => {
        setNewRoom({ ...newRoom, room_title: e.target.value });
    };

    const handleRoomStartDateChange = (e) => {
        setNewRoom({ ...newRoom, start_date: e.target.value });
    };

    const handleRoomEndDateChange = (e) => {
        setNewRoom({ ...newRoom, end_date: e.target.value });
    };

    const handleRoomTotalRoomsChange = (e) => {
        setNewRoom({ ...newRoom, total_rooms: e.target.value });
    };
    const handleRoomStatusChange = (e) => {
        setNewRoom({ ...newRoom, status: e.target.value });
    };

    const handleRoomPriceChange = (e) => {
        setNewRoom({ ...newRoom, price: e.target.value });
    };

    const RoomfileInputRef = useRef(null);

    const handleAddRoomImageClick = () => {
        RoomfileInputRef.current.click();
    };


    const handleRoomImageChange = (event) => {
        // const newPhotos = newRoom.photos.map((photo, i) => i === index ? url : photo);
        // setNewRoom({ ...newRoom, photos: newPhotos });
        const files = Array.from(event.target.files);
        const fileObjects = files.map(file => ({ file, url: URL.createObjectURL(file) }));
        if (roomImages2) {
            setRoomImages2(prevImages => [...prevImages, ...fileObjects]);
        }
        else {
            setRoomImages2(fileObjects);
        }
    };



    const handleRemoveRoomImage = (indexToRemove) => {
        setRoomImages(prevImages => prevImages.filter((_, index) => index !== indexToRemove));
    };

    const handleRemoveRoomImage2 = (indexToRemove) => {
        setRoomImages2(prevImages => prevImages.filter((_, index) => index !== indexToRemove));
    };

    const handleEditRoom = (index) => {
        const roomToEdit = rooms[index];
        setNewRoom(roomToEdit);
        setCurrentRoom(index);
        setRoomImages(roomToEdit.photos);
        setRoomImages2(roomToEdit.new_photos)
        setShowRoomsModal(true);
    };

    const handleDeleteRoom = (index) => {
        const roomToDelete = rooms[index];
        if (roomToDelete._id) {
            setDeletedRooms([...deletedRooms, roomToDelete._id]);
        }
        const updatedRooms = rooms.filter((_, i) => i !== index);
        setRooms(updatedRooms);
    };

    const today = new Date().toISOString().split('T')[0];

    const handleSaveChanges3 = () => {


        if (!newRoom.room_title) {
            toast.error("Add Room Title", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }

        if (!newRoom.total_rooms) {
            toast.error("Add Total Rooms", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }

        if (priceDetails?.length === 0) {
            toast.error("Enter Price Ranges According Dates", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }

        if (newRoom.status !== "booked" && newRoom.status !== "available" && newRoom.status !== "sold out") {
            toast.error("Add Room Status", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        const roomTitle = newRoom.room_title;
        const roomHighlights = newRoom.room_highlights.filter(x => x.trim() !== '');
        const roomtr = newRoom.total_rooms;
        const roomstatus = newRoom.status;
        const priceRanges = priceDetails;

        if (roomHighlights.length === 0) {
            toast.error("Add Atleast one Room Highlight", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }

        const length1 = roomImages ? roomImages.length : 0;
        const length2 = roomImages2 ? roomImages2.length : 0;

        if (length1 + length2 < 3) {
            toast.error("Add Atleast 3 Room Image", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }

        const updatedRoom = {
            ...newRoom,
            room_title: roomTitle,
            room_highlights: roomHighlights,
            new_photos: roomImages2,
            photos: roomImages,
            total_rooms: roomtr,
            status: roomstatus,
            priceRange: priceRanges,
        };

        if (currentRoom !== null) {
            const updatedRooms = rooms.map((room, index) =>
                index === currentRoom ? updatedRoom : room
            );
            setRooms(updatedRooms);
            setCurrentRoom(null);
        } else {
            setRooms([...rooms, updatedRoom]);
        }

        setNewRoom({
            room_title: '',
            room_highlights: [''],
            price: '',
            new_photos: [],
            photos: [],
            total_rooms: '',
            start_date: '',
            end_date: '',
            status: '',
        })
        setRoomImages([]);
        setRoomImages2([]);
        setShowRoomsModal(false);
    };


    // submit 3

    const handleSubmit3 = async (e) => {
        e.preventDefault();

        if (rooms.length === 0) {
            toast.error("At least one room is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }

        const token = localStorage.getItem("vendorToken");
        
        const addPromises = rooms.filter(room => !room._id).map(async (room) => {
            const formData = new FormData();

            formData.append("room_title", room.room_title);
            formData.append("hotel_id", hotelId);
            formData.append("status", room.status);
            formData.append("total_rooms", room.total_rooms);

            rooms?.priceRange?.forEach((item, index) => {
                formData.append(`price_and_date[${index}][adult_price]`, item.adult_price);
                formData.append(`price_and_date[${index}][child_price]`, item.child_price);
                formData.append(`price_and_date[${index}][price_start_date]`, item.price_start_date);
                formData.append(`price_and_date[${index}][price_end_date]`, item.price_end_date);
            });

            room.room_highlights.forEach(highlight => {
                formData.append("room_highlights", highlight);
            });

            let links = room.photos.map(image => image.url);


            links.forEach(image => {
                formData.append("previmages", image);
            });

            room.new_photos.forEach(({ file }) => {
                formData.append("photos", file);
            });

            const response = await fetch(`${BASE_URL}room_syt`, {
                method: 'POST',
                headers: {
                    Authorization: token,
                },
                body: formData
            });

            if (!response.ok) {
                throw new Error(`Failed to add room: ${room.room_title}`);
            }

            return response.json();
        });

        const editPromises = rooms.filter(room => room._id).map(async (room) => {
            const formData = new FormData();

            formData.append("room_title", room.room_title);
            formData.append("hotel_id", hotelId);
            formData.append("status", room.status);
            formData.append("total_rooms", room.total_rooms);

            rooms?.priceRange?.forEach((item, index) => {
                formData.append(`price_and_date[${index}][adult_price]`, item.adult_price);
                formData.append(`price_and_date[${index}][child_price]`, item.child_price);
                formData.append(`price_and_date[${index}][price_start_date]`, item.price_start_date);
                formData.append(`price_and_date[${index}][price_end_date]`, item.price_end_date);
            });

            room.room_highlights.forEach(highlight => {
                formData.append("room_highlights", highlight);
            });

            let links = room.photos.map(image => image.url);
            if (links) {
                links.forEach(image => {
                    formData.append("previmages", image);
                });
            }
            if (room.new_photos) {
                room.new_photos.forEach(({ file }) => {
                    formData.append("photos", file);
                });
            }

            const response = await fetch(`${BASE_URL}room_syt?_id=${room._id}`, {
                method: 'PUT',
                headers: {
                    Authorization: token,
                },
                body: formData
            });

            if (!response.ok) {
                throw new Error(`Failed to edit room: ${room.room_title}`);
            }

            return response.json();
        });

        const deletePromises = deletedRooms.map(async (roomId) => {
            const response = await fetch(`${BASE_URL}room_syt?_id=${roomId}`, {
                method: 'DELETE',
                headers: {
                    Authorization: token,
                },
            });

            if (!response.ok) {
                throw new Error(`Failed to delete room: ${roomId}`);
            }

            return response.json();
        });

        try {
            const results = await Promise.all([...addPromises, ...editPromises, ...deletePromises]);
            // Handle results if necessary
            setRoomImages([]);
            setRoomImages2([]);
            setDeletedRooms([]);
            getHotelData();
            setEditing3(false);
            toast.success("Hotel Rooms Updated Successfully", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
        } catch (error) {
            console.error('Error handling rooms:', error);
        }
    };


    const handleLocationSubmit = (success) => {
        if (success) {
            toast.success("Location Submitted", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
        } else {
        }
    };




    // policy section

    const [showPolicyModal1, setShowPolicyModal1] = useState(false);
    const [showPolicyModal2, setShowPolicyModal2] = useState(false);
    const [showPolicyModal3, setShowPolicyModal3] = useState(false);
    const [showPolicyModal4, setShowPolicyModal4] = useState(false);
    const [showPolicyModal5, setShowPolicyModal5] = useState(false);

    const [policylength, setPolicylength] = useState(true);

    const [newPolicy1, setNewPolicy1] = useState({
        policy_title: '',
        policy_description: '',
    });

    const [newPolicy2, setNewPolicy2] = useState({
        infant: '',
        infant_points: [''],
    });
    const [newPolicy3, setNewPolicy3] = useState({
        children: '',
        childern_points: [''],
    });
    const [newPolicy4, setNewPolicy4] = useState({
        adult_and_above: '',
        adult_and_above_points: [''],
    });
    const [newPolicy5, setNewPolicy5] = useState({
        policy_other: ['']
    });
    const [policy1, setPolicy1] = useState({
        policy_title: '',
        policy_description: '',
    });
    const [policy2, setPolicy2] = useState({
        infant: '',
        infant_points: [''],
    });
    const [policy3, setPolicy3] = useState({
        children: '',
        childern_points: [''],
    });
    const [policy4, setPolicy4] = useState({
        adult_and_above: '',
        adult_and_above_points: [''],
    });
    const [policy5, setPolicy5] = useState({
        policy_other: ['']
    });
    const [policy1a, setPolicy1a] = useState({
        policy_title: '',
        policy_description: '',
    });
    const [policy2a, setPolicy2a] = useState({
        infant: '',
        infant_points: [''],
    });
    const [policy3a, setPolicy3a] = useState({
        children: '',
        childern_points: [''],
    });
    const [policy4a, setPolicy4a] = useState({
        adult_and_above: '',
        adult_and_above_points: [''],
    });
    const [policy5a, setPolicy5a] = useState({
        policy_other: ['']
    });

    const handlePolicyTitleChange = (e) => {
        setNewPolicy1({ ...newPolicy1, policy_title: e.target.value });
    };

    const handlePolicyDescriptionChange = (e) => {
        setNewPolicy1({ ...newPolicy1, policy_description: e.target.value });
    };
    const handleInfantTitleChange = (e) => {
        setNewPolicy2({ ...newPolicy2, infant: e.target.value });
    };
    const handlechildrenTitleChange = (e) => {
        setNewPolicy3({ ...newPolicy3, children: e.target.value });
    };
    const handleAdultsTitleChange = (e) => {
        setNewPolicy4({ ...newPolicy4, adult_and_above: e.target.value });
    };

    const pisubpointRef = useRef(null);
    const handleAddInfant = () => {
        if (newPolicy2.infant_points[newPolicy2.infant_points.length - 1] !== '') {
            setNewPolicy2({ ...newPolicy2, infant_points: [...newPolicy2.infant_points, ''] });
        }
        else {
            if (pisubpointRef.current) {
                pisubpointRef.current.focus();
            }
        }
    };

    const handleSubInfant = (index) => {
        const point = newPolicy2.infant_points.slice(0, index).concat(newPolicy2.infant_points.slice(index + 1));
        setNewPolicy2({ ...newPolicy2, infant_points: point })
    }

    const handlePolicyInfantDetailChange = (index, value) => {
        const newPoints = newPolicy2.infant_points.map((point, i) => i === index ? value : point);
        setNewPolicy2({ ...newPolicy2, infant_points: newPoints });
    };

    const pcsubpointRef = useRef(null);
    const handleAddchildren = () => {
        if (!newPolicy3.childern_points) {
            setNewPolicy3({ ...newPolicy3, childern_points: [''] });
        }
        else if (newPolicy3.childern_points[newPolicy3.childern_points.length - 1] !== '') {
            setNewPolicy3({ ...newPolicy3, childern_points: [...newPolicy3.childern_points, ''] });
        }
        else {
            if (pcsubpointRef.current) {
                pcsubpointRef.current.focus();
            }
        }
    };

    const handleSubchildren = (index) => {
        const point = newPolicy3.childern_points.slice(0, index).concat(newPolicy3.childern_points.slice(index + 1));
        setNewPolicy3({ ...newPolicy3, childern_points: point })
    }

    const handlePolicychildrenDetailChange = (index, value) => {
        const newPoints = newPolicy3.childern_points.map((point, i) => i === index ? value : point);
        setNewPolicy3({ ...newPolicy3, childern_points: newPoints });
    };

    const pasubpointRef = useRef(null);
    const handleAddAdults = () => {
        if (newPolicy4.adult_and_above_points[newPolicy4.adult_and_above_points.length - 1] !== '') {
            setNewPolicy4({ ...newPolicy4, adult_and_above_points: [...newPolicy4.adult_and_above_points, ''] });
        }
        else {
            if (pasubpointRef.current) {
                pasubpointRef.current.focus();
            }
        }
    };

    const handleSubAdults = (index) => {
        const point = newPolicy4.adult_and_above_points.slice(0, index).concat(newPolicy4.adult_and_above_points.slice(index + 1));
        setNewPolicy4({ ...newPolicy4, adult_and_above_points: point })
    }

    const handlePolicyAdultsDetailChange = (index, value) => {
        const newPoints = newPolicy4.adult_and_above_points.map((point, i) => i === index ? value : point);
        setNewPolicy4({ ...newPolicy4, adult_and_above_points: newPoints });
    };

    const posubpointRef = useRef(null);
    const handleAddOthers = () => {
        if (newPolicy5.policy_other[newPolicy5.policy_other.length - 1] !== '') {
            setNewPolicy5({ ...newPolicy5, policy_other: [...newPolicy5.policy_other, ''] });
        }
        else {
            if (posubpointRef.current) {
                posubpointRef.current.focus();
            }
        }
    };

    const handleSubOthers = (index) => {
        const point = newPolicy5.policy_other.slice(0, index).concat(newPolicy5.policy_other.slice(index + 1));
        setNewPolicy5({ ...newPolicy5, policy_other: point })
    }

    const handlePolicyOthersChange = (index, value) => {
        const newPoints = newPolicy5.policy_other.map((point, i) => i === index ? value : point);
        setNewPolicy5({ ...newPolicy5, policy_other: newPoints });
    };


    const handleEditPolicy1 = () => {

        setNewPolicy1(policy1);
        setShowPolicyModal1(true);
    }

    const handleEditPolicy2 = () => {

        setNewPolicy2(policy2);
        setShowPolicyModal2(true);
    }

    const handleEditPolicy3 = () => {

        setNewPolicy3(policy3);
        setShowPolicyModal3(true);
    }

    const handleEditPolicy4 = () => {

        setNewPolicy4(policy4);
        setShowPolicyModal4(true);
    }

    const handleEditPolicy5 = () => {

        setNewPolicy5(policy5);
        setShowPolicyModal5(true);
    }


    const handleSaveChanges41 = () => {

        if (!newPolicy1.policy_title) {
            toast.error("Add Policy Title", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }

        if (!newPolicy1.policy_description) {
            toast.error("Add Policy Descripiton", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        setPolicy1(newPolicy1);
        setShowPolicyModal1(false);
    };

    const handleSaveChanges42 = () => {

        const infant = newPolicy2.infant;
        const infantpoints = newPolicy2.infant_points;
        const infantpoints2 = infantpoints.filter(x => x.trim() !== '');

        if (!newPolicy2.infant) {
            toast.error("Add Infant Policy Title", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }

        if (infantpoints2.length === 0) {
            toast.error("Add Atleast one Infant Policy Details", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }

        const newPolicyx = {
            infant: infant,
            infant_points: infantpoints2,
        }
        setPolicy2(newPolicyx);
        setShowPolicyModal2(false);
    };

    const handleSaveChanges43 = () => {
        const children = newPolicy3.children;
        const childrenpoints = newPolicy3.childern_points;
        const childrenpoints2 = childrenpoints.filter(x => x.trim() !== '');

        if (!newPolicy3.children) {
            toast.error("Add children Policy Title", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }

        if (childrenpoints2.length === 0) {
            toast.error("Add Atleast one children policy detail", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }


        const newPolicy2 = {
            children: children,
            childern_points: childrenpoints2,

        }
        setPolicy3(newPolicy2);
        setShowPolicyModal3(false);
    };

    const handleSaveChanges44 = () => {
        const adults = newPolicy4.adult_and_above;
        const adultspoints = newPolicy4.adult_and_above_points;
        const adultspoints2 = adultspoints.filter(x => x.trim() !== '');


        if (!newPolicy4.adult_and_above) {
            toast.error("Add adults Policy Title", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }

        if (adultspoints2.length === 0) {
            toast.error("Add Atleast one adults policy detail", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }


        const newPolicy2 = {
            adult_and_above: adults,
            adult_and_above_points: adultspoints2,
        }
        setPolicy4(newPolicy2);
        setShowPolicyModal4(false);
    };

    const handleSaveChanges45 = () => {
        const policyothers = newPolicy5.policy_other;
        const policyothers2 = policyothers.filter(x => x.trim() !== '');

        if (policyothers2.length === 0) {
            toast.error("Add Atleast one adults policy detail", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }

        const newPolicy2 = {
            policy_other: policyothers2,

        }
        setPolicy5(newPolicy2);
        setShowPolicyModal5(false);
    };

    const handleCancelPolicyEditing = () => {
        setPolicy1(policy1a);
        setPolicy2(policy2a);
        setPolicy3(policy3a);
        setPolicy4(policy4a);
        setPolicy5(policy5a);
    }





    // submit 4


    const handleSubmit4 = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem("vendorToken");



        const response = await fetch(`${BASE_URL}property_policies?_id=${hotelId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify({
                hotel_id: hotelId,
                policy_title: policy1.policy_title,
                policy_description: policy1.policy_description,
                infant: policy2.infant,
                children: policy3.children,
                adult_and_above: policy4.adult_and_above,
                infant_points: policy2.infant_points,
                childern_points: policy3.childern_points,
                adult_and_above_points: policy4.adult_and_above_points,
                policy_other: policy5.policy_other
            }),
        });

        if (!response.ok) {
            throw new Error(`Failed to add property policy `);
        }
        else {
            getHotelData();
            setEditing4(false);
            toast.success("Property Policy Updated", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return response.json();
        }
    };

    const handleSubmit5 = async (e) => {
        e.preventDefault();


        const token = localStorage.getItem("vendorToken");

        const response = await fetch(`${BASE_URL}property_policies`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify({
                // hotel_id: '66752ce95c7b675212598634',
                hotel_id: hotelId,
                policy_title: policy1.policy_title,
                policy_description: policy1.policy_description,
                infant: policy2.infant,
                children: policy3.children,
                adult_and_above: policy4.adult_and_above,
                infant_points: policy2.infant_points,
                childern_points: policy3.childern_points,
                adult_and_above_points: policy4.adult_and_above_points,
                policy_other: policy5.policy_other
            }),
        });

        if (!response.ok) {
            throw new Error(`Failed to add property policy `);
        }
        else {
            toast.success("All Details Added Successfully", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            navigate('/vendor/myhotels')
            return response.json();
        }
    };
    const handleCancelEditing = () => {
        setEditing1(false);
        setEditing2(false);
        setEditing3(false);
        setEditing4(false);
    }

    // reviews
    const [hotelreviews, setHotelReviews] = useState();

    const [priceDetails, setPriceDetails] = useState([]);

    const [openRangeModal, setOpenRangeModal] = useState(false);
    const [pricePerPerson, setPricePerPerson] = useState('');
    const [pricePerPersonForChild, setPricePerPersonForChild] = useState('');
    const [priceStartDate, setPriceStartDate] = useState('');
    const [priceEndDate, setPriceEndDate] = useState('');

    const handleCloseRangeModal = () => {
        setOpenRangeModal(false)
    }

    const handleSave = () => {

        if (!pricePerPerson || !pricePerPersonForChild || !priceStartDate || !priceEndDate) {
            toast.error("Please Fill All Fields!", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }

        // Validation for prices (Child price cannot be more than adult, etc.)
        if (Number(pricePerPerson) < Number(pricePerPersonForChild)) {
            toast.error("Childrens Price cannot be bigger than Adult Price!", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }

        // Convert the date strings to Date objects
        const startDate = new Date(priceStartDate);
        const endDate = new Date(priceEndDate);

        // Check for overlapping date ranges in priceDetails
        const overlappingRanges = priceDetails?.filter((item) => {
            const priceStart = new Date(item.price_start_date);
            const priceEnd = new Date(item.price_end_date);

            return (
                (startDate >= priceStart && startDate <= priceEnd) ||
                (endDate >= priceStart && endDate <= priceEnd)
            );
        });

        if (overlappingRanges?.length > 0) {
            toast.error("Dates are mismatched with other dates!", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }

        // If all validations pass, create the new price detail
        const newDetail = {
            adult_price: Number(pricePerPerson),
            child_price: Number(pricePerPersonForChild),
            price_start_date: priceStartDate,
            price_end_date: priceEndDate,
        };

        // Add the new detail to the array of priceDetails
        if (Array.isArray(priceDetails)) {
            setPriceDetails([...priceDetails, newDetail]);
        } else {
            setPriceDetails([newDetail]);
        }

        // Reset the form fields
        setPricePerPerson('');
        setPricePerPersonForChild('');
        setPriceStartDate('');
        setPriceEndDate('');
        setOpenRangeModal(false);
        setShowRoomsModal(true)
    }

    const handleDelete = (index) => {
        const updatedPriceRanges = priceDetails?.filter((_, i) => i !== index);
        setPriceDetails(updatedPriceRanges);
    }



    return (
        <>
            <Header2 />
            <div className="costum_container">
                <My_pannel />
                <ToastContainer />
                <div >

                    <ProfileSection title="My Hotels" />

                    {loading ? <Loading /> :
                        <div style={{ paddingBottom: "30px" }}>
                            {/* hotel details */}
                            {!editing1 && <>
                                <section className="hotel_section_margin p-4">
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <h5 className="font700">Hotel Details</h5>
                                        {/* <FontAwesomeIcon icon={faEdit} className="edit-icon" onClick={() => setEditing1(true)} /> */}
                                    </div>
                                    <div className="hotel-details-container">
                                        <div className="form-group-container">
                                            <div className="form-group">
                                                <p className='mb-1 font600 text14'>Hotel Name</p>
                                                <input
                                                    type="text"
                                                    id="hotel_name"
                                                    name="hotel_name"
                                                    value={hotelData.hotel_name}
                                                    placeholder="Hotel Name"
                                                    autocomplete="off"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <p className='mb-1 font600 text14'>Hotel Type</p>
                                                <input
                                                    id="hotel_type"
                                                    name="hotel_type"
                                                    value={hotelData.hotel_type + " Stars"}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group-container">
                                            <div className="form-group">
                                                <p className='mb-1 font600 text14'>Choose State</p>
                                                <input
                                                    id="state"
                                                    name="state"
                                                    value={selectedState}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <p className='mb-1 font600 text14'>Choose City</p>
                                                <input
                                                    id="city"
                                                    name="city"
                                                    value={selectedCity}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group-container">
                                            <div className="form-group">
                                                <p className='mb-1 font600 text14'>Address</p>
                                                <textarea
                                                    id="hotel_address"
                                                    name="hotel_address"
                                                    placeholder="Address"
                                                    value={hotelData.hotel_address}
                                                    rows="2"
                                                    require
                                                />
                                                {/* <input
                                                    type="text"
                                                    id="hotel_address"
                                                    name="hotel_address"
                                                    placeholder="Address"
                                                    value={hotelData.hotel_address}
                                                /> */}
                                            </div>
                                            <div className="form-group">
                                                <p className='mb-1 font600 text14'>Description</p>
                                                <textarea
                                                    id="hotel_description"
                                                    name="hotel_description"
                                                    value={hotelData.hotel_description}
                                                    rows="2"
                                                    require placeholder="Hotel Description"
                                                />
                                            </div>
                                        </div>
                                        {otherDetails && <div className="form-group">
                                            <div className="other-form-group">
                                                <label className="font600 text18" style={{ marginRight: "20px", marginBottom: "10px" }}>Other Details</label>
                                                {/* <FontAwesomeIcon icon={faPlus} className="plus-icon" onClick={addOtherDetailField} /> */}
                                            </div>
                                            {otherDetails.map((detail, index) => (
                                                <input
                                                    key={index}
                                                    type="text"
                                                    value={detail}
                                                    placeholder="Other Detail"
                                                />
                                            ))}
                                        </div>}
                                    </div>
                                </section>
                                <section className="hotel_section_margin p-4">
                                    <div className="highlights-container">
                                        <div className="highlights-title-container">
                                            <h5 className="font700">Highlights</h5>
                                        </div>
                                        <div className="highlights-section">
                                            {highlightArray2.length !== 0 && highlightArray2.map((highlight) => {
                                                return (
                                                    <div key={highlight._id} className="highlight-item">
                                                        <img src={highlight.icon} alt={highlight.title} className="highlights-icon" />
                                                        <div className="highlights-title">{highlight.title}</div>
                                                    </div>)
                                            })}
                                        </div>
                                    </div>
                                </section>
                                <section className="hotel_section_margin p-4">
                                    <div className="hotel-pictures-container">
                                        <div className="hotel-pictures-title-container">
                                            <h5 className="font700">Hotel Pictures</h5>
                                        </div>
                                        <div className="hotel-pictures-section">
                                            {images.map(({ url }, index) => (
                                                <div key={index} className="hotel-pictures-item">
                                                    <img src={url} alt={`Hotel pic ${index + 1}`} className="hotel-image" />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </section>
                            </>
                            }
                            {editing1 && <>
                                <section className="hotel_section_margin p-4">
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <h5 className="font700">Hotel Details</h5>
                                        <button className="cancel-edit-button" onClick={() => { handleCancelEditing(); handleCancelEditHotelDetails() }}>Cancel</button>
                                    </div>
                                    <div className="hotel-details-container">
                                        <div className="form-group-container">
                                            <div className="form-group">
                                                <p className='mb-1 font600 text14'>Hotel Name</p>
                                                <input
                                                    type="text"
                                                    id="hotel_name"
                                                    name="hotel_name"
                                                    value={hotelData.hotel_name}
                                                    onChange={handleChange}
                                                    required
                                                    placeholder="Hotel Name"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <p className='mb-1 font600 text14'>Hotel Type</p>
                                                <select
                                                    id="hotel_type"
                                                    name="hotel_type"
                                                    value={hotelData.hotel_type}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value="" disabled selected>Select Hotel Type</option>
                                                    <option value="1">1 Star</option>
                                                    <option value="2">2 Stars</option>
                                                    <option value="3">3 Stars</option>
                                                    <option value="4">4 Stars</option>
                                                    <option value="5">5 Stars</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group-container">
                                            <div className="form-group">
                                                <p className='mb-1 font600 text14'>Choose State</p>
                                                <select
                                                    id="state"
                                                    name="state"
                                                    value={selectedState}
                                                    onChange={handleChange}
                                                    required
                                                    placeholder="Choose State"
                                                >
                                                    <option value="" disabled selected>Select State</option>
                                                    {states &&
                                                        states.map((e) => {
                                                            return (
                                                                <option value={e.name}>{e.name}</option>
                                                            )
                                                        })}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <p className='mb-1 font600 text14'>Choose City</p>
                                                <select
                                                    id="city"
                                                    name="city"
                                                    value={selectedCity}
                                                    onChange={handleChange}
                                                    required
                                                    placeholder="Choose City"
                                                >
                                                    <option value="" disabled selected>Select City</option>
                                                    {cities && cities.map((city) => (
                                                        <option key={city.id} value={city.name}>
                                                            {city.name}
                                                        </option>
                                                    ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group-container">
                                            <div className="form-group">
                                                <p className='mb-1 font600 text14'>Address</p>
                                                {/* <input
                                                    type="text"
                                                    id="hotel_address"
                                                    name="hotel_address"
                                                    value={hotelData.hotel_address}
                                                    onChange={handleChange}
                                                    placeholder="Address"
                                                    required
                                                /> */}
                                                <textarea
                                                    id="hotel_address"
                                                    name="hotel_address"
                                                    value={hotelData.hotel_address}
                                                    onChange={handleChange}
                                                    placeholder="Address"
                                                    rows="2"
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <p className='mb-1 font600 text14'>Description</p>
                                                <textarea
                                                    id="hotel_description"
                                                    name="hotel_description"
                                                    value={hotelData.hotel_description}
                                                    onChange={handleChange}
                                                    rows="2"
                                                    required
                                                    placeholder="Hotel Description"
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="other-form-group">
                                                <label className="font600 text18" style={{ marginRight: "20px", marginBottom: "20px" }}>Other Details</label>
                                                <FontAwesomeIcon icon={faPlus} className="plus-icon" onClick={addOtherDetailField} />
                                            </div>
                                            {otherDetails && otherDetails.map((detail, index) => (
                                                <div className="input-and-minus">
                                                    <input
                                                        key={index}
                                                        type="text"
                                                        value={detail}
                                                        onChange={(e) => handleOtherDetailChange(index, e)}
                                                        placeholder="Other Detail"
                                                        required
                                                        ref={index === otherDetails.length - 1 ? inputRef : null}
                                                    />
                                                    <FontAwesomeIcon icon={faMinus} className="minus-icon" onClick={() => subOtherDetailField(index)} />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    {/* </section> */}
                                    {/* <section className="hotel_section_margin p-4"> */}
                                    <div className="highlights-container">
                                        <div className="highlights-title-container">
                                            <h5 className="font700">Highlights</h5>
                                            <FontAwesomeIcon icon={faPlus} className="plus-icon" onClick={() => setShowHighlightsModal(true)} />
                                        </div>
                                        <div className="highlights-section">
                                            {highlightArray2.length !== 0 && highlightArray2.map((highlight) => {
                                                return (
                                                    <div key={highlight._id} className="highlight-item">
                                                        <img src={highlight.icon} alt={highlight.title} className="highlights-icon" />
                                                        <div className="highlights-title">{highlight.title}</div>
                                                    </div>)
                                            })}
                                        </div>
                                    </div>
                                    <Modal show={showHighlightsModal} onHide={() => setShowHighlightsModal(false)}>
                                        <Modal.Body>
                                            <div className="hotel-model-title-container">
                                                <div>
                                                    <Modal.Title closeButton>Choose Highlights</Modal.Title>
                                                </div>
                                                <div><FontAwesomeIcon icon={faClose} className="cross-icon" onClick={() => setShowHighlightsModal(false)}></FontAwesomeIcon></div>
                                            </div>
                                            <Form>
                                                <Form.Group>
                                                    <div className="highlight-model-section">
                                                        {highlightlist.map((highlight) => (
                                                            <div className="highlight-model-form-check">
                                                                <input
                                                                    type="checkbox"
                                                                    id={`highlight-${highlight._id}`}
                                                                    value={highlight._id}
                                                                    onChange={handleHighlightChange}
                                                                    checked={highlightArray.length !== 0 ? highlightArray.includes(highlight._id) : false}
                                                                />
                                                                <label htmlFor={`highlight-${highlight._id}`}>
                                                                    <div className="highlight-model-single">
                                                                        <div>
                                                                            <img src={highlight.icon} alt={highlight.title} className="highlight-model-image" />
                                                                        </div>
                                                                        <div className="highlight-model-highlight-title">
                                                                            {highlight.title}
                                                                        </div>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </Form.Group>
                                            </Form>
                                        </Modal.Body>
                                        <div className="hotel-model-footer">
                                            <button className="hotel-model-footer-button" variant="primary" onClick={handleSaveChanges}>
                                                Add Highlights
                                            </button>
                                        </div>
                                    </Modal>
                                    {/* </section> */}
                                    {/* <section className="hotel_section_margin p-4"> */}
                                    <div className="hotel-pictures-container">
                                        <div className="hotel-pictures-title-container">
                                            <h5 className="font700">Hotel Pictures</h5>
                                            <FontAwesomeIcon
                                                icon={faPlus}
                                                className="plus-icon"
                                                onClick={handleAddImageClick}
                                            />
                                            <input
                                                type="file"
                                                ref={fileInputRef}
                                                style={{ display: 'none' }}
                                                onChange={handleImageChange}
                                                multiple
                                            />
                                        </div>
                                        <div className="hotel-pictures-section">
                                            {images && images.map((img, index) => (
                                                <div key={index} className="hotel-pictures-item">
                                                    <img src={img.url} alt={`Hotel pic ${index + 1}`} className="hotel-image" />
                                                    <FontAwesomeIcon
                                                        icon={faTrash}
                                                        className="trash-icon"
                                                        onClick={() => handleRemoveImage(index)}
                                                    />
                                                </div>
                                            ))}
                                            {images2 && images2.map(({ url }, index) => (
                                                <div key={index} className="hotel-pictures-item">
                                                    <img src={url} alt={`Hotel pic ${index + 1}`} className="hotel-image" />
                                                    <FontAwesomeIcon
                                                        icon={faTrash}
                                                        className="trash-icon"
                                                        onClick={() => handleRemoveImage2(index)}
                                                    />
                                                </div>
                                            ))}

                                        </div>
                                    </div>
                                </section>
                                <section className="submit-button-section">
                                    <button className="submit-button" onClick={handleSubmit}>
                                        Submit
                                    </button>
                                </section>
                            </>
                            }

                            {/* amenities */}


                            {!editing2 && <>
                                <section className="hotel_section_margin p-4">
                                    <div className="af-container">
                                        <div className="af-title-container">
                                            <h5 className="font700">Amenities and Facilities</h5>
                                            {/* <FontAwesomeIcon icon={faEdit} className="edit-icon" onClick={() => setEditing2(true)} /> */}
                                        </div>
                                        <div className="grid-container">
                                            {amenities.map((section, index) => (
                                                <div key={index} className="grid-item">
                                                    <div className="grid-item-title">
                                                        <h6>{section.title}</h6>
                                                    </div>
                                                    <ul>
                                                        {section.points.map((point, index) => (
                                                            <li key={index}>{point}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </section>
                            </>
                            }
                            {editing2 && <>
                                <section className="hotel_section_margin p-4">
                                    <div className="af-container">
                                        <div className="af-title-container">
                                            <h5 className="font700">Amenities and Facilities</h5>
                                            <div style={{ display: "flex" }}>
                                                <FontAwesomeIcon icon={faPlus} className="plus-icon" onClick={() => setShowAminitiesModal(true)} />
                                                <button className="cancel-edit-button" onClick={() => { handleCancelEditing(); setAmenities(amenities2) }}>Cancel</button>
                                            </div>
                                        </div>
                                        <div className="grid-container">
                                            {amenities.map((section, index) => (
                                                <div key={index} className="grid-item">
                                                    <div className="grid-item-title">
                                                        <h6>{section.title}</h6>
                                                        {/* <div>
                                                            <FontAwesomeIcon
                                                                icon={faEdit}
                                                                className="edit2-icon"
                                                                onClick={() => handleEditAmenity(index)}
                                                            />
                                                            <FontAwesomeIcon
                                                                icon={faTrash}
                                                                className="delete-icon"
                                                                onClick={() => handleDeleteAmenity(index)}
                                                            />
                                                        </div> */}
                                                    </div>
                                                    <ul>
                                                        {section.points.map((point, index) => (
                                                            <li key={index}>{point}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <Modal show={showAminitiesModal} onHide={() => { setShowAminitiesModal(false); setNewAmenity({ title: '', points: [''] }); setCurrentAmenity(null) }}>
                                        <Modal.Body>
                                            <div className="hotel-model-title-container">
                                                <div>
                                                    <Modal.Title closeButton>{currentAmenity !== null ? 'Edit Amenity' : 'Add Amenity'}</Modal.Title>
                                                </div>
                                                <div>
                                                    <FontAwesomeIcon icon={faClose} className="cross-icon" onClick={() => {
                                                        setShowAminitiesModal(false); setNewAmenity({ title: '', points: [''] }); setCurrentAmenity(null)
                                                    }} />
                                                </div>
                                            </div>
                                            <div className="af-model-body">
                                                <input
                                                    type="text"
                                                    placeholder="Title"
                                                    value={newAmenity.title}
                                                    onChange={handleTitleChange}
                                                    className="af-model-input1"
                                                />
                                                <div className="af-model-subpoint">
                                                    <div className="af-model-subpoint-container">
                                                        {newAmenity.points.map((point, index) => (
                                                            <div className="input-and-minus">
                                                                <input
                                                                    key={index}
                                                                    type="text"
                                                                    placeholder="Subpoint"
                                                                    value={point}
                                                                    className="af-model-input2"
                                                                    onChange={(e) => handlePointChange(index, e.target.value)}
                                                                    ref={index === newAmenity.points.length - 1 ? afsubpointRef : null}
                                                                />
                                                                <FontAwesomeIcon icon={faMinus} className="minus-icon" onClick={() => handleSubPoint(index)} />
                                                            </div>

                                                        ))}
                                                    </div>
                                                    <div className="plus-icon-container">
                                                        <FontAwesomeIcon icon={faPlus} className="plus-icon" onClick={handleAddPoint} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                        <div className="hotel-model-footer">
                                            <button className="hotel-model-footer-button" variant="primary" onClick={handleSaveChanges2}>
                                                {currentAmenity !== null ? 'Save Changes' : 'Add Amenity'}
                                            </button>
                                        </div>
                                    </Modal>

                                </section>
                                <section className="submit-button-section">
                                    <button className="submit-button" onClick={handleSubmit2}>
                                        Submit
                                    </button>
                                </section>
                            </>
                            }

                            {/* location */}


                            {
                                showSection3 &&
                                <section className="hotel_section_margin p-4">
                                    <div className="location-container">
                                        <div className="map-title-container">
                                            <h5 className="font700">Location</h5>
                                        </div>
                                        <MapComponent />
                                    </div>
                                </section>
                            }

                            {/* rooms */}

                            {/* {amenitieslength && <> */}

                            {/*  */}

                            {!editing3 && <>
                                <section className="hotel_section_margin p-4">
                                    <div className="rooms-container">
                                        <div className="rooms-title-container">
                                            <h5>Rooms</h5>
                                        </div>
                                        <div className="rooms-card-section">
                                            {rooms.map((room, index) => (
                                                <div style={{ position: "relative" }}>
                                                    <div style={{ position: "absolute", top: "25px", right: "25px" }}>
                                                        <FontAwesomeIcon
                                                            icon={faEdit}
                                                            className="edit2-icon"
                                                            onClick={() => handleEditRoom(index)}
                                                        />
                                                    </div>
                                                    {/* <RoomCard room={room} data="not" /> */}

                                                    <div className="room-card">
                                                        <img src={room.photos.length !== 0 ? room.photos[0].url : room.new_photos[0].url} alt="Room" className="room-image" />
                                                        <div className="room-details">
                                                            <div className="room-title">{room.room_title}</div>
                                                            <div className="room-total-rooms" style={{ fontSize: "11px", marginBottom: "5px" }}>Total Rooms:{" " + room.total_rooms}</div>
                                                            <div className='room-highlight-container'>
                                                                {room.room_highlights.map((highlight, index) => (
                                                                    <>
                                                                        <div className="room-highlight" key={index}><span style={{ margin: "0px 3px" }}>•</span>{highlight}</div>
                                                                    </>
                                                                ))}
                                                            </div>
                                                            <div style={{ fontSize: "10px" }}><span>Status: </span>{" " + room.status}</div>
                                                            {/* <div className="room-price">₹ {room.price} <span className="room-per-night">per night</span></div> */}
                                                        </div>

                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                    </div>
                                    <Modal show={showRoomsModal} onHide={() => {
                                        setShowRoomsModal(false); setNewRoom({
                                            room_title: '',
                                            room_highlights: [''],
                                            price: '',
                                            new_photos: [],
                                            photos: [],
                                            total_rooms: '',
                                            start_date: '',
                                            end_date: '',
                                            status: '',

                                        }); setCurrentRoom(null); setRoomImages([]);
                                        setRoomImages2([]);
                                    }}>
                                        <Modal.Body>
                                            <div className="hotel-model-title-container">
                                                <div>
                                                    <Modal.Title closeButton> <p className="font700 text20 mb-0">{currentRoom !== null ? 'Edit Room' : 'Add Room'}</p></Modal.Title>
                                                </div>
                                                <div><FontAwesomeIcon icon={faClose} className="cross-icon" onClick={() => {
                                                    setShowRoomsModal(false); setNewRoom({
                                                        room_title: '',
                                                        room_highlights: [''],
                                                        price: '',
                                                        new_photos: [],
                                                        photos: [],
                                                        total_rooms: '',
                                                        start_date: '',
                                                        end_date: '',
                                                        status: '',
                                                    }); setCurrentRoom(null); setRoomImages([]);
                                                    setRoomImages2([]);
                                                }} /></div>
                                            </div>
                                            
                                            
                                            <div className="mt-2 d-flex justify-content-between align-items-center mb-2">
                                                <p className='mb-0 font600 text14'>Add Price</p>
                                                <FontAwesomeIcon icon={faPlus} className="plus-icon" onClick={() => (setOpenRangeModal(true), setShowRoomsModal(false))} />
                                            </div>
                                            <div>
                                                <table className="table table-striped table-hover">
                                                    <thead className="table-dark">
                                                        <tr>
                                                            <th scope="col"></th>
                                                            <th scope="col">
                                                                <p className="vendoraddpackage-label text12 font600 mb-0">Adult</p>
                                                            </th>
                                                            <th scope="col">
                                                                <p className="vendoraddpackage-label text12 font600 mb-0">Children</p>
                                                            </th>
                                                            <th scope="col">
                                                                <p className="vendoraddpackage-label text12 font600 mb-0">Start Date</p>
                                                            </th>
                                                            <th scope="col">
                                                                <p className="vendoraddpackage-label text12 font600 mb-0">End Date</p>
                                                            </th>
                                                            <th scope="col">
                                                                <p className="vendoraddpackage-label text12 font600 mb-0">Actions</p>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {priceDetails?.length > 0 ? (
                                                            priceDetails
                                                                ?.sort((a, b) => {
                                                                    const startDateA = new Date(a.price_start_date);
                                                                    const startDateB = new Date(b.price_start_date);

                                                                    // If the start dates are the same, sort by end date
                                                                    if (startDateA.getTime() === startDateB.getTime()) {
                                                                        const endDateA = new Date(a.price_end_date);
                                                                        const endDateB = new Date(b.price_end_date);
                                                                        return endDateA - endDateB;
                                                                    }

                                                                    return startDateA - startDateB;
                                                                })
                                                                ?.map((range, index) => (
                                                                    <tr key={index}>
                                                                        <td className="text14">{index + 1}.</td>
                                                                        <td className="text14">{range?.adult_price}</td>
                                                                        <td className="text14">{range?.child_price}</td>
                                                                        <td className="text14">{formatDate(range?.price_start_date)}</td>
                                                                        <td className="text14">{formatDate(range?.price_end_date)}</td>
                                                                        <td>
                                                                            <Button
                                                                                variant="danger"
                                                                                onClick={() => handleDelete(index)}
                                                                                className="btn btn-sm"
                                                                            >
                                                                                Delete
                                                                            </Button>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="7" className="text-center text-muted">
                                                                    No price ranges added yet.
                                                                </td>
                                                            </tr>
                                                        )}

                                                    </tbody>
                                                </table>
                                            </div>
                                        </Modal.Body>

                                        <div className="hotel-model-footer">
                                            <button className="hotel-model-footer-button" variant="primary" onClick={handleSaveChanges3}>
                                                {currentRoom !== null ? 'Save Changes' : 'Add Room'}
                                            </button>
                                        </div>
                                    </Modal>

                                    <Modal show={openRangeModal} onHide={handleCloseRangeModal}>
                                        <Modal.Body>
                                            <Modal.Title>
                                                <h4 className="font700 text20">Add Price</h4>
                                            </Modal.Title>
                                            <div className="mt-3">
                                                <p htmlFor="" className="mb-1 vendoraddpackage-label text12" style={{ fontWeight: "bold", fontSize: "12px" }}>Price Per Person For Adult</p>
                                                <input
                                                    type="text"
                                                    placeholder="price"
                                                    name="price"
                                                    value={pricePerPerson}
                                                    onChange={(e) => setPricePerPerson(e.target.value)}
                                                    className="vendoraddpackage-input"
                                                />
                                            </div>
                                            <div className="mt-3">
                                                <p htmlFor="" className="mb-1 vendoraddpackage-label text12" style={{ fontWeight: "bold", fontSize: "12px" }}>Price Per Person For Children</p>
                                                <input
                                                    type="text"
                                                    placeholder="price"
                                                    name="price"
                                                    value={pricePerPersonForChild}
                                                    onChange={(e) => setPricePerPersonForChild(e.target.value)}
                                                    className="vendoraddpackage-input"
                                                />
                                            </div>
                                            <div className="mt-3">
                                                <p htmlFor="" className="mb-1 vendoraddpackage-label text12" style={{ fontWeight: "bold", fontSize: "12px" }}>Select Dates For This Price Aplicable</p>
                                                <div className="d-flex justify-content-between gap-2">
                                                    <input
                                                        type="date"
                                                        name="start_date"
                                                        placeholder="Enter Start Date"
                                                        value={priceStartDate}
                                                        onChange={(e) => setPriceStartDate(e.target.value)}
                                                        min={getTomorrowDate()}
                                                        className="vendoraddpackage-input"
                                                    />

                                                    <input
                                                        type="date"
                                                        name="end_date"
                                                        placeholder="Enter End Date"
                                                        value={priceEndDate}
                                                        onChange={(e) => setPriceEndDate(e.target.value)}
                                                        min={priceStartDate}
                                                        className="vendoraddpackageitinerary-input"
                                                        disabled={!priceStartDate}
                                                    />
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between mt-4">
                                                <Button variant="secondary" onClick={() => (handleCloseRangeModal(), setShowRoomsModal(true))}>
                                                    Close
                                                </Button>
                                                <div className="d-flex align-items-center">
                                                    <Button className="vendoraddpackage-button" onClick={handleSave}>
                                                        Save
                                                    </Button>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                </section>
                            </>
                            }
                            {/* </>
                            } */}

                            {/* policy */}

                            {policylength && <>
                                {!editing4 &&
                                    <section className="hotel_section_margin p-4">
                                        <div className="pp-container">
                                            <div className="pp-title-container">
                                                <h5>Property Policies</h5>
                                                {/* <FontAwesomeIcon icon={faEdit} className="edit-icon" onClick={() => setEditing4(true)} /> */}
                                            </div>
                                            {policy1 && (
                                                <div>
                                                    <div style={{ display: "flex" }}>
                                                        <div style={{ marginRight: "20px" }}>
                                                            <div className="policy-title">{policy1.policy_title}</div>
                                                            <div className="policy-description">{policy1.policy_description}</div>
                                                        </div>
                                                    </div>
                                                    <div className="policy-age-container">
                                                        <div className="policy-card">
                                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                <div className="policy-card-title">Infant 0-2 year</div>
                                                            </div>
                                                            <hr className="policy-card_hr"></hr>
                                                            <div className="policy-card-info">{policy2.infant}</div>
                                                            <ul>
                                                                {policy2.infant_points.map(point =>
                                                                (
                                                                    <li className="policy-card-point">{point}</li>
                                                                )
                                                                )}
                                                            </ul>
                                                        </div>
                                                        <div className="policy-card">
                                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                <div className="policy-card-title">children 3-11 year</div>
                                                            </div>
                                                            <hr className="policy-card_hr"></hr>
                                                            <div className="policy-card-info">{policy3.children}</div>
                                                            <ul>
                                                                {policy3.childern_points && policy3.childern_points.map(point =>
                                                                (
                                                                    <li className="policy-card-point">{point}</li>
                                                                )
                                                                )}
                                                            </ul>
                                                        </div>
                                                        <div className="policy-card">
                                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                <div className="policy-card-title">Adults 12 & Above</div>
                                                            </div>
                                                            <hr className="policy-card_hr"></hr>
                                                            <div className="policy-card-info">{policy4.adult_and_above}</div>
                                                            <ul>
                                                                {policy4.adult_and_above_points.map(point =>
                                                                (
                                                                    <li className="policy-card-point">{point}</li>
                                                                )
                                                                )}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div style={{ display: "flex" }}>
                                                            <div className="policy-others">Others</div>
                                                        </div>
                                                        <ul>
                                                            {policy5.policy_other.map(point =>
                                                            (
                                                                <li className="policy-other-point">{point}</li>
                                                            )
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>)}
                                        </div>
                                    </section>}
                                {editing4 && <>

                                    <section className="hotel_section_margin p-4">
                                        <div className="pp-container">
                                            <div className="pp-title-container">
                                                <h5>Property Policies</h5>
                                                <button className="cancel-edit-button" onClick={() => { handleCancelEditing(); handleCancelPolicyEditing() }}>Cancel</button>
                                            </div>
                                            {policy1 && (
                                                <div>
                                                    <div style={{ display: "flex" }}>
                                                        <div style={{ marginRight: "20px" }}>
                                                            <div className="policy-title">{policy1.policy_title}</div>
                                                            <div className="policy-description">{policy1.policy_description}</div>
                                                        </div>
                                                        {/* <FontAwesomeIcon icon={faEdit} className="edit-icon" onClick={handleEditPolicy1} /> */}
                                                    </div>
                                                    <div className="policy-age-container">
                                                        <div className="policy-card">
                                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                <div className="policy-card-title">Infant 0-2 year</div>
                                                                {/* <FontAwesomeIcon icon={faEdit} className="edit-icon" onClick={handleEditPolicy2} /> */}
                                                            </div>
                                                            <hr className="policy-card_hr"></hr>
                                                            <div className="policy-card-info">{policy2.infant}</div>
                                                            <ul>
                                                                {policy2.infant_points.map(point =>
                                                                (
                                                                    <li className="policy-card-point">{point}</li>
                                                                )
                                                                )}
                                                            </ul>
                                                        </div>
                                                        <div className="policy-card">
                                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                <div className="policy-card-title">children 3-11 year</div>
                                                                {/* <FontAwesomeIcon icon={faEdit} className="edit-icon" onClick={handleEditPolicy3} /> */}
                                                            </div>
                                                            <hr className="policy-card_hr"></hr>
                                                            <div className="policy-card-info">{policy3.children}</div>
                                                            <ul>
                                                                {policy3.childern_points && policy3.childern_points.map(point =>
                                                                (
                                                                    <li className="policy-card-point">{point}</li>
                                                                )
                                                                )}
                                                            </ul>
                                                        </div>
                                                        <div className="policy-card">
                                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                <div className="policy-card-title">Adults 12 & Above</div>
                                                                {/* <FontAwesomeIcon icon={faEdit} className="edit-icon" onClick={handleEditPolicy4} /> */}
                                                            </div>
                                                            <hr className="policy-card_hr"></hr>
                                                            <div className="policy-card-info">{policy4.adult_and_above}</div>
                                                            <ul>
                                                                {policy4.adult_and_above_points.map(point =>
                                                                (
                                                                    <li className="policy-card-point">{point}</li>
                                                                )
                                                                )}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div style={{ display: "flex" }}>
                                                            <div className="policy-others">Others</div>
                                                            {/* <FontAwesomeIcon icon={faEdit} className="edit-icon" onClick={handleEditPolicy5} /> */}
                                                        </div>
                                                        <ul>
                                                            {policy5.policy_other.map(point =>
                                                            (
                                                                <li className="policy-other-point">{point}</li>
                                                            )
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>)}
                                        </div>
                                        <Modal show={showPolicyModal1} onHide={() => setShowPolicyModal1(false)}>
                                            <Modal.Body>
                                                <div className="hotel-model-title-container">
                                                    <div>
                                                        <Modal.Title closeButton>Add Policy Title And Descripiton</Modal.Title>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faClose} className="cross-icon" onClick={() => setShowPolicyModal1(false)} />
                                                    </div>
                                                </div>
                                                <input
                                                    type="text"
                                                    placeholder="Policy Title"
                                                    value={newPolicy1.policy_title}
                                                    onChange={handlePolicyTitleChange}
                                                    className="af-model-input1"
                                                />
                                                <input
                                                    type="textarea"
                                                    placeholder="Policy Description"
                                                    value={newPolicy1.policy_description}
                                                    onChange={handlePolicyDescriptionChange}
                                                    className="af-model-input2"
                                                />
                                            </Modal.Body>
                                            <div className="hotel-model-footer">
                                                <button className="hotel-model-footer-button" variant="primary" onClick={handleSaveChanges41}>
                                                    Save Changes
                                                </button>
                                            </div>
                                        </Modal>
                                        <Modal show={showPolicyModal2} onHide={() => setShowPolicyModal2(false)}>
                                            <Modal.Body>
                                                <div className="hotel-model-title-container">
                                                    <div>
                                                        <Modal.Title closeButton>Add Infant Policies</Modal.Title>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faClose} className="cross-icon" onClick={() => setShowPolicyModal2(false)} />
                                                    </div>
                                                </div>
                                                <input
                                                    type="text"
                                                    placeholder="Infant Policy Title"
                                                    value={newPolicy2.infant}
                                                    onChange={handleInfantTitleChange}
                                                    className="af-model-input1"
                                                />
                                                <div className="af-model-subpoint">
                                                    <div className="af-model-subpoint-container">
                                                        {newPolicy2.infant_points.map((infantpoint, index) => (
                                                            <div key={index} className="input-and-minus">
                                                                <input
                                                                    type="text"
                                                                    placeholder="Infant detail"
                                                                    value={infantpoint}
                                                                    onChange={(e) => handlePolicyInfantDetailChange(index, e.target.value)}
                                                                    className="af-model-input2"
                                                                    ref={index === newPolicy2.infant_points.length - 1 ? pisubpointRef : null}
                                                                />
                                                                <FontAwesomeIcon icon={faMinus} className="minus-icon" onClick={() => handleSubInfant(index)} />
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="plus-icon-container">
                                                        <FontAwesomeIcon icon={faPlus} className="plus-icon" onClick={handleAddInfant} />
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                            <div className="hotel-model-footer">
                                                <button className="hotel-model-footer-button" variant="primary" onClick={handleSaveChanges42}>
                                                    Save Changes
                                                </button>
                                            </div>
                                        </Modal>
                                        <Modal show={showPolicyModal3} onHide={() => setShowPolicyModal3(false)}>
                                            <Modal.Body>
                                                <div className="hotel-model-title-container">
                                                    <div>
                                                        <Modal.Title closeButton>Add children Policies</Modal.Title>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faClose} className="cross-icon" onClick={() => setShowPolicyModal3(false)} />
                                                    </div>
                                                </div>
                                                <input
                                                    type="text"
                                                    placeholder="children Policy Title"
                                                    value={newPolicy3.children}
                                                    onChange={handlechildrenTitleChange}
                                                    className="af-model-input1"
                                                />
                                                <div className="af-model-subpoint">
                                                    <div className="af-model-subpoint-container">
                                                        {newPolicy3.childern_points && newPolicy3.childern_points.map((childrenpoint, index) => (
                                                            <div key={index} className="input-and-minus">
                                                                <input
                                                                    type="text"
                                                                    placeholder="children detail"
                                                                    value={childrenpoint}
                                                                    onChange={(e) => handlePolicychildrenDetailChange(index, e.target.value)}
                                                                    className="af-model-input2"
                                                                    ref={index === newPolicy3.childern_points.length - 1 ? pcsubpointRef : null}
                                                                />
                                                                <FontAwesomeIcon icon={faMinus} className="minus-icon" onClick={() => handleSubchildren(index)} />
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="plus-icon-container">
                                                        <FontAwesomeIcon icon={faPlus} className="plus-icon" onClick={handleAddchildren} />
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                            <div className="hotel-model-footer">
                                                <button className="hotel-model-footer-button" variant="primary" onClick={handleSaveChanges43}>
                                                    Save Changes
                                                </button>
                                            </div>
                                        </Modal>
                                        <Modal show={showPolicyModal4} onHide={() => setShowPolicyModal4(false)}>
                                            <Modal.Body>
                                                <div className="hotel-model-title-container">
                                                    <div>
                                                        <Modal.Title closeButton>Add Adults and Above Policies</Modal.Title>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faClose} className="cross-icon" onClick={() => setShowPolicyModal4(false)} />
                                                    </div>
                                                </div>
                                                <input
                                                    type="text"
                                                    placeholder="Adults Policy Title"
                                                    value={newPolicy4.adult_and_above}
                                                    onChange={handleAdultsTitleChange}
                                                    className="af-model-input1"
                                                />
                                                <div className="af-model-subpoint">
                                                    <div className="af-model-subpoint-container">
                                                        {newPolicy4.adult_and_above_points.map((adultpoint, index) => (
                                                            <div key={index} className="input-and-minus">
                                                                <input
                                                                    type="text"
                                                                    placeholder="Adults detail"
                                                                    value={adultpoint}
                                                                    onChange={(e) => handlePolicyAdultsDetailChange(index, e.target.value)}
                                                                    className="af-model-input2"
                                                                    ref={index === newPolicy4.adult_and_above_points.length - 1 ? pasubpointRef : null}
                                                                />
                                                                <FontAwesomeIcon icon={faMinus} className="minus-icon" onClick={() => handleSubAdults(index)} />
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="plus-icon-container">
                                                        <FontAwesomeIcon icon={faPlus} className="plus-icon" onClick={handleAddAdults} />
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                            <div className="hotel-model-footer">
                                                <button className="hotel-model-footer-button" variant="primary" onClick={handleSaveChanges44}>
                                                    Save Changes
                                                </button>
                                            </div>
                                        </Modal>
                                        <Modal show={showPolicyModal5} onHide={() => setShowPolicyModal5(false)}>
                                            <Modal.Body>
                                                <div className="hotel-model-title-container">
                                                    <div>
                                                        <Modal.Title closeButton>Add Other Policies</Modal.Title>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faClose} className="cross-icon" onClick={() => setShowPolicyModal5(false)} />
                                                    </div>
                                                </div>
                                                <div className="af-model-subpoint">
                                                    <div className="af-model-subpoint-container">
                                                        {newPolicy5.policy_other.map((other, index) => (
                                                            <div key={index} className="input-and-minus">
                                                                <input
                                                                    type="text"
                                                                    placeholder="Other detail"
                                                                    value={other}
                                                                    onChange={(e) => handlePolicyOthersChange(index, e.target.value)}
                                                                    className="af-model-input1"
                                                                    ref={index === newPolicy5.policy_other.length - 1 ? posubpointRef : null}
                                                                />
                                                                <FontAwesomeIcon icon={faMinus} className="minus-icon" onClick={() => handleSubOthers(index)} />
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="plus-icon-container">
                                                        <FontAwesomeIcon icon={faPlus} className="plus-icon" onClick={handleAddOthers} />
                                                    </div>
                                                </div>

                                            </Modal.Body>
                                            <div className="hotel-model-footer">
                                                <button className="hotel-model-footer-button" variant="primary" onClick={handleSaveChanges45}>
                                                    Save Changes
                                                </button>
                                            </div>
                                        </Modal>
                                    </section>
                                    <section className="submit-button-section">
                                        <button className="submit-button" onClick={handleSubmit4}>
                                            Submit
                                        </button>
                                    </section>
                                </>
                                }
                            </>
                            }
                            {!policylength && roomslength &&
                                <>
                                    <section className="hotel_section_margin p-4">
                                        <div className="pp-container">
                                            <div className="pp-title-container">
                                                <h5>Property Policies</h5>
                                                <FontAwesomeIcon icon={faPlus} className="plus-icon" onClick={() => setShowPolicyModal1(true)} />
                                            </div>
                                            {policy1.policy_title && (
                                                <div>
                                                    <div className="policy-title">{policy1.policy_title}</div>
                                                    <div className="policy-description">{policy1.policy_description}</div>
                                                    <div className="policy-age-container">
                                                        <div className="policy-card">
                                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                <div className="policy-card-title">Infant 0-2 year</div>
                                                                <FontAwesomeIcon icon={faPlus} className="plus-icon" onClick={() => setShowPolicyModal2(true)} />
                                                            </div>
                                                            <hr className="policy-card_hr"></hr>
                                                            {policy2 &&
                                                                <>
                                                                    <div className="policy-card-info">{policy2.infant}</div>
                                                                    {policy2.infant_points[0] && <ul>
                                                                        {policy2.infant_points.map(point =>
                                                                        (
                                                                            <li className="policy-card-point">{point}</li>
                                                                        )
                                                                        )}
                                                                    </ul>}
                                                                </>
                                                            }
                                                        </div>
                                                        <div className="policy-card">
                                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                <div className="policy-card-title">children 3-11 year</div>
                                                                <FontAwesomeIcon icon={faPlus} className="plus-icon" onClick={() => setShowPolicyModal3(true)} />
                                                            </div>
                                                            <hr className="policy-card_hr"></hr>
                                                            {policy3 && <>
                                                                <div className="policy-card-info">{policy3.children}</div>
                                                                {policy3.childern_points[0] &&
                                                                    <ul>
                                                                        {policy3.childern_points && policy3.childern_points.map(point =>
                                                                        (
                                                                            <li className="policy-card-point">{point}</li>
                                                                        )
                                                                        )}
                                                                    </ul>
                                                                }
                                                            </>
                                                            }
                                                        </div>
                                                        <div className="policy-card">
                                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                <div className="policy-card-title">Adults 12 & Above</div>
                                                                <FontAwesomeIcon icon={faPlus} className="plus-icon" onClick={() => setShowPolicyModal4(true)} />
                                                            </div>
                                                            <hr className="policy-card_hr"></hr>
                                                            {policy3 && <>
                                                                <div className="policy-card-info">{policy4.adult_and_above}</div>
                                                                {policy4.adult_and_above_points[0] && <ul>
                                                                    {policy4.adult_and_above_points.map(point =>
                                                                    (
                                                                        <li className="policy-card-point">{point}</li>
                                                                    )
                                                                    )}
                                                                </ul>
                                                                }
                                                            </>}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div style={{ display: "flex" }}>
                                                            <div className="policy-others">Others</div>
                                                            <FontAwesomeIcon icon={faPlus} className="plus-icon" onClick={() => setShowPolicyModal5(true)} />
                                                        </div>
                                                        {policy5.policy_other[0] &&
                                                            <ul>
                                                                {policy5.policy_other.map(point =>
                                                                (
                                                                    <li className="policy-other-point">{point}</li>
                                                                )
                                                                )}
                                                            </ul>
                                                        }
                                                    </div>
                                                </div>)}
                                        </div>
                                        <Modal show={showPolicyModal1} onHide={() => setShowPolicyModal1(false)}>
                                            <Modal.Body>
                                                <div className="hotel-model-title-container">
                                                    <div>
                                                        <Modal.Title closeButton>Add Policy Title And Descripiton</Modal.Title>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faClose} className="cross-icon" onClick={() => setShowPolicyModal1(false)} />
                                                    </div>
                                                </div>
                                                <input
                                                    type="text"
                                                    placeholder="Policy Title"
                                                    value={newPolicy1.policy_title}
                                                    onChange={handlePolicyTitleChange}
                                                    className="af-model-input1"
                                                />
                                                <input
                                                    type="textarea"
                                                    placeholder="Policy Description"
                                                    value={newPolicy1.policy_description}
                                                    onChange={handlePolicyDescriptionChange}
                                                    className="af-model-input2"
                                                />
                                            </Modal.Body>
                                            <div className="hotel-model-footer">
                                                <button className="hotel-model-footer-button" variant="primary" onClick={handleSaveChanges41}>
                                                    Save Changes
                                                </button>
                                            </div>
                                        </Modal>
                                        <Modal show={showPolicyModal2} onHide={() => setShowPolicyModal2(false)}>
                                            <Modal.Body>
                                                <div className="hotel-model-title-container">
                                                    <div>
                                                        <Modal.Title closeButton>Add Infant Policies</Modal.Title>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faClose} className="cross-icon" onClick={() => setShowPolicyModal2(false)} />
                                                    </div>
                                                </div>
                                                <input
                                                    type="text"
                                                    placeholder="Infant Policy Title"
                                                    value={newPolicy2.infant}
                                                    onChange={handleInfantTitleChange}
                                                    className="af-model-input1"
                                                />
                                                <div className="af-model-subpoint">
                                                    <div className="af-model-subpoint-container">
                                                        {newPolicy2.infant_points.map((infantpoint, index) => (
                                                            <div key={index} className="input-and-minus">
                                                                <input
                                                                    type="text"
                                                                    placeholder="Infant detail"
                                                                    value={infantpoint}
                                                                    onChange={(e) => handlePolicyInfantDetailChange(index, e.target.value)}
                                                                    className="af-model-input2"
                                                                    ref={index === newPolicy2.infant_points.length - 1 ? pisubpointRef : null}
                                                                />
                                                                <FontAwesomeIcon icon={faMinus} className="minus-icon" onClick={() => handleSubInfant(index)} />
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="plus-icon-container">
                                                        <FontAwesomeIcon icon={faPlus} className="plus-icon" onClick={handleAddInfant} />
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                            <div className="hotel-model-footer">
                                                <button className="hotel-model-footer-button" variant="primary" onClick={handleSaveChanges42}>
                                                    Save Changes
                                                </button>
                                            </div>
                                        </Modal>
                                        <Modal show={showPolicyModal3} onHide={() => setShowPolicyModal3(false)}>
                                            <Modal.Body>
                                                <div className="hotel-model-title-container">
                                                    <div>
                                                        <Modal.Title closeButton>Add children Policies</Modal.Title>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faClose} className="cross-icon" onClick={() => setShowPolicyModal3(false)} />
                                                    </div>
                                                </div>
                                                <input
                                                    type="text"
                                                    placeholder="children Policy Title"
                                                    value={newPolicy3.children}
                                                    onChange={handlechildrenTitleChange}
                                                    className="af-model-input1"
                                                />
                                                <div className="af-model-subpoint">
                                                    <div className="af-model-subpoint-container">
                                                        {newPolicy3.childern_points && newPolicy3.childern_points.map((childrenpoint, index) => (
                                                            <div key={index} className="input-and-minus">
                                                                <input
                                                                    type="text"
                                                                    placeholder="children detail"
                                                                    value={childrenpoint}
                                                                    onChange={(e) => handlePolicychildrenDetailChange(index, e.target.value)}
                                                                    className="af-model-input2"
                                                                    ref={index === newPolicy3.childern_points.length - 1 ? pcsubpointRef : null}
                                                                />
                                                                <FontAwesomeIcon icon={faMinus} className="minus-icon" onClick={() => handleSubchildren(index)} />
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="plus-icon-container">
                                                        <FontAwesomeIcon icon={faPlus} className="plus-icon" onClick={handleAddchildren} />
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                            <div className="hotel-model-footer">
                                                <button className="hotel-model-footer-button" variant="primary" onClick={handleSaveChanges43}>
                                                    Save Changes
                                                </button>
                                            </div>
                                        </Modal>
                                        <Modal show={showPolicyModal4} onHide={() => setShowPolicyModal4(false)}>
                                            <Modal.Body>
                                                <div className="hotel-model-title-container">
                                                    <div>
                                                        <Modal.Title closeButton>Add Adults and Above Policies</Modal.Title>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faClose} className="cross-icon" onClick={() => setShowPolicyModal4(false)} />
                                                    </div>
                                                </div>
                                                <input
                                                    type="text"
                                                    placeholder="Adults Policy Title"
                                                    value={newPolicy4.adult_and_above}
                                                    onChange={handleAdultsTitleChange}
                                                    className="af-model-input1"
                                                />
                                                <div className="af-model-subpoint">
                                                    <div className="af-model-subpoint-container">
                                                        {newPolicy4.adult_and_above_points.map((adultpoint, index) => (
                                                            <div key={index} className="input-and-minus">
                                                                <input
                                                                    type="text"
                                                                    placeholder="Adults detail"
                                                                    value={adultpoint}
                                                                    onChange={(e) => handlePolicyAdultsDetailChange(index, e.target.value)}
                                                                    className="af-model-input2"
                                                                    ref={index === newPolicy4.adult_and_above_points.length - 1 ? pasubpointRef : null}
                                                                />
                                                                <FontAwesomeIcon icon={faMinus} className="minus-icon" onClick={() => handleSubAdults(index)} />
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="plus-icon-container">
                                                        <FontAwesomeIcon icon={faPlus} className="plus-icon" onClick={handleAddAdults} />
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                            <div className="hotel-model-footer">
                                                <button className="hotel-model-footer-button" variant="primary" onClick={handleSaveChanges44}>
                                                    Save Changes
                                                </button>
                                            </div>
                                        </Modal>
                                        <Modal show={showPolicyModal5} onHide={() => setShowPolicyModal5(false)}>
                                            <Modal.Body>
                                                <div className="hotel-model-title-container">
                                                    <div>
                                                        <Modal.Title closeButton>Add Other Policies</Modal.Title>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faClose} className="cross-icon" onClick={() => setShowPolicyModal5(false)} />
                                                    </div>
                                                </div>
                                                <div className="af-model-subpoint">
                                                    <div className="af-model-subpoint-container">
                                                        {newPolicy5.policy_other.map((other, index) => (
                                                            <div key={index} className="input-and-minus">
                                                                <input
                                                                    type="text"
                                                                    placeholder="Other detail"
                                                                    value={other}
                                                                    onChange={(e) => handlePolicyOthersChange(index, e.target.value)}
                                                                    className="af-model-input1"
                                                                    ref={index === newPolicy5.policy_other.length - 1 ? posubpointRef : null}
                                                                />
                                                                <FontAwesomeIcon icon={faMinus} className="minus-icon" onClick={() => handleSubOthers(index)} />
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="plus-icon-container">
                                                        <FontAwesomeIcon icon={faPlus} className="plus-icon" onClick={handleAddOthers} />
                                                    </div>
                                                </div>

                                            </Modal.Body>
                                            <div className="hotel-model-footer">
                                                <button className="hotel-model-footer-button" variant="primary" onClick={handleSaveChanges45}>
                                                    Save Changes
                                                </button>
                                            </div>
                                        </Modal>
                                    </section>
                                    {policy1.policy_title && policy2.infant && policy3.children && policy4.adult_and_above && policy5.policy_other[0] && <section className="submit-button-section">
                                        <button className="submit-button" onClick={handleSubmit5}>
                                            Submit
                                        </button>
                                    </section>
                                    }
                                </>
                            }

                            {/* reviews */}

                            <section className="hotel_section_margin review-container p-4">
                                <div className="pp-title-container">
                                    <h5 className="font700">Hotel Reviews</h5>
                                </div>
                                {hotelreviews ? (Object.keys(hotelreviews?.[0])?.length !== 0 ?
                                    <div className="reviews_list">
                                        <div><span style={{ margin: "0px 10px", fontSize: "18px", fontWeight: "bold" }}>Hotel Title:</span>{hotelData.hotel_name}</div>
                                        <div style={{ borderBottom: "2px solid #09646D" }}><span style={{ margin: "0px 10px", fontSize: "18px", fontWeight: "bold" }}>Hotel Address:</span>{hotelData.hotel_address}</div>
                                        {hotelreviews.map((review, index) => (
                                            <div key={index} className="review-card p-3 mb-3 bg-white">
                                                <div>
                                                    <div>
                                                        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                            {review.user_details.photo && <img src={review.user_details.photo} alt='photos' style={{ height: "40px", width: "40px", borderRadius: "50%" }}></img>}
                                                            <div><span style={{ margin: "0px 10px 0px 10px", fontSize: "16px", fontWeight: "bold" }}>Customer Name: </span>{review.user_details.name}</div>
                                                        </div>
                                                        <div><span style={{ margin: "0px 10px", fontSize: "14px", fontWeight: "bold" }}>Customer Email:</span> {review.user_details.email_address}</div>
                                                        <div className="review-box">
                                                            <div style={{ display: "flex" }}>
                                                                <div>Rating:{" "}</div>
                                                                <div style={{ marginTop: "1px" }}>
                                                                    <Rating
                                                                        name={`review-rating-${index}`}
                                                                        value={parseInt(review.star) || 0}
                                                                        readOnly
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div><span style={{ margin: "0px 10px 0px 0px", fontSize: "14px", fontWeight: "bold" }}>Comment:</span> {review.comment_box}</div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        ))}
                                    </div> : "No Reviews") : "No Reviews"}
                            </section>
                        </div>}
                </div>
            </div>
        </>
    );
}

export default PrePurchaseHotelDetail;
