import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faLocationPin, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import My_pannel from "./My_pannel";
import Header2 from "./Header2";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../BASE_URL";
import "./css/Hotels.css";
import ProfileSection from "./SecondNavbar";
import countries from "../CountryStateCity.json";
import StarRating from "../Project/StarRating";
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const SelectItineraryHotel = () => {
    const states = countries.find((e) => e.name === "India")?.states || [];
    const navigate = useNavigate();
    const location = useLocation();

    const [hotels, setHotels] = useState([]);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedState, setSelectedState] = useState("");
    const [selectedCity, setSelectedCity] = useState("");
    const [cities, setCities] = useState([]);
    const [selectedStar, setSelectedStar] = useState(""); // Add state for star filter

    const updatedHotelName = localStorage.getItem("updatedHotelId")
    const updatedRoomId = localStorage.getItem("updatedRoomId")
    const updatedMeals = JSON.parse(localStorage.getItem("updatedMeals"))

    // Handle state change and set cities dynamically
    const handleStateChange = (e) => {
        const state = e.target.value;
        setSelectedState(state);
        const filteredCities = states.find((s) => s.name === state)?.cities || [];
        setCities(filteredCities);
        setSelectedCity("");
    };

    // Handle Star Filter Change
    const handleStarChange = (e) => {
        setSelectedStar(e.target.value);
    };

    const getStarRatingFromHotelType = (hotelType) => {
        const hotelTypeToStars = {
            "5 star": 5,
            "4 star": 4,
            "3 star": 3,
            "2 star": 2,
            "1 star": 1,
        };
        return hotelTypeToStars[hotelType.toLowerCase()] || 0;
    };

    // Filter hotels based on search term, state, city, and star rating
    const filteredHotels = hotels.filter((hotel) => {
        const starRating = getStarRatingFromHotelType(hotel.hotel_type);
        return (
            hotel.hotel_name.toLowerCase().includes(searchTerm.toLowerCase()) &&
            (selectedState ? hotel.hotel_state === selectedState : true) &&
            (selectedCity ? hotel.hotel_city === selectedCity : true) &&
            (selectedStar ? starRating === parseInt(selectedStar) : true)
        );
    });

    const [isRotating, setIsRotating] = useState(false);

    const handleClearFilters = () => {
        setIsRotating(true);
        setTimeout(() => setIsRotating(false), 500);
        setSearchTerm("");
        setSelectedState("");
        setSelectedCity("");
        setCities([]);
        setSelectedStar("");
    };

    const [selectedHotel, setSelectedHotel] = useState(null);
    const [selectedRoomId, setSelectedRoomId] = useState(null);
    const [selectedMeals, setSelectedMeals] = useState({
        breakfast: false,
        breakfast_price: 0,
        lunch: false,
        lunch_price: 0,
        dinner: false,
        dinner_price: 0,
    });

    // Handle hotel selection
    const handleHotelSelection = (hotelId) => {
        setSelectedHotel(hotelId);
        setSelectedRoomId(null);

        setSelectedMeals({
            breakfast: false,
            breakfast_price: 0,
            lunch: false,
            lunch_price: 0,
            dinner: false,
            dinner_price: 0,
        });

    };

    // Handle room selection
    const handleRoomSelection = (event) => {
        setSelectedRoomId(event.target.value);
    };

    const handleMealSelection = (event) => {
        const { name, checked } = event.target;
        const mealPrice = parseFloat(event.target.dataset.price); // Ensure price is a number

        setSelectedMeals((prevState) => ({
            ...prevState,  // Preserve existing state
            [name]: checked,
            [`${name}_price`]: checked ? mealPrice : 0,
        }));
    };

    const handleNavigate = () => {
        if (!selectedHotel) {
            toast.error("Please select a hotel!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }

        if (!selectedRoomId) {
            toast.error("Please select a room!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }

        const isAnyMealSelected = selectedMeals.breakfast || selectedMeals.lunch || selectedMeals.dinner;
        if (!isAnyMealSelected) {
            toast.error("Please select at least one meal!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }

        // selectedMeals
        // selectedHotel
        // selectedRoomId



        // Navigate back
        localStorage.setItem("selectedHotelId", selectedHotel)
        localStorage.setItem("selectedMeals", selectedMeals)
        localStorage.setItem("selectedRoomId", selectedRoomId)
        navigate(-1);
    };

    console.log(selectedMeals)
    console.log(selectedHotel)
    console.log(selectedRoomId)

   

    useEffect(() => {
        if (updatedHotelName !== null) {
            const selectedHotel = hotels?.find((e) => e?.hotel_name === updatedHotelName)
            setSelectedHotel(selectedHotel?._id)
            setSelectedRoomId(updatedRoomId)
            const meals = JSON.parse(localStorage.getItem("updatedMeals"))

            if (meals && Array.isArray(meals)) {
                const updatedMeals = {
                    breakfast: false,
                    breakfast_price: 0,
                    lunch: false,
                    lunch_price: 0,
                    dinner: false,
                    dinner_price: 0,
                };

                meals.forEach(({ name, price }) => {
                    const mealKey = name.toLowerCase();
                    if (updatedMeals.hasOwnProperty(mealKey)) {
                        updatedMeals[`${mealKey}_price`] = price;
                        updatedMeals[mealKey] = true;
                    }
                });
                
                setSelectedMeals(updatedMeals);
            }

        }
    }, [hotels]);

    const fetchHotels = async () => {
        try {
            const token = localStorage.getItem("vendorToken");
            const response = await fetch(
                `${BASE_URL}api/hotel_itienrary/displayAgencyById`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                    },
                }
            );
            if (!response.ok) throw new Error("Network response was not ok");
            const data = await response.json();
            setHotels(data.data);
        } catch (error) {
            setError(error.message);
        }
    };

    useEffect(() => {
        fetchHotels();
    }, []);


    return (
        <>
            <Header2 />
            <div className="costum_container">
                <My_pannel />
                <ProfileSection title="Select Hotel" />
                <ToastContainer />
                <div style={{ backgroundColor: "white", minHeight: "100vh" }}>
                    <section className="new-vendor-container">
                        <section>
                            <div className="">
                                <div className="row">
                                    {/* Hotel Name Search */}
                                    <div className="col-3 mb-3">
                                        <p className="mb-1 font600 text14 hotels-filter-label-css">Search Hotel Name</p>
                                        <input
                                            type="text"
                                            className="hotels-filter-input-css"
                                            placeholder="Search by hotel name..."
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                    </div>
                                    {/* State Filter */}
                                    <div className="col-2 mb-3">
                                        <p className="mb-1 font600 text14 hotels-filter-label-css">State</p>
                                        <select
                                            className="hotels-filter-input-css"
                                            onChange={handleStateChange}
                                            value={selectedState}
                                        >
                                            <option value="">Select State</option>
                                            {states.map((state, index) => (
                                                <option key={index} value={state.name}>
                                                    {state.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    {/* City Filter */}
                                    <div className="col-2 mb-3">
                                        <p className="mb-1 font600 text14 hotels-filter-label-css">City</p>
                                        <select
                                            className="hotels-filter-input-css"
                                            onChange={(e) => setSelectedCity(e.target.value)}
                                            value={selectedCity}
                                        >
                                            <option value="">Select City</option>
                                            {cities.map((city, index) => (
                                                <option key={index} value={city.name}>
                                                    {city.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    {/* Star Filter */}
                                    <div className="col-2 mb-3">
                                        <p className="mb-1 font600 text14 hotels-filter-label-css">Star</p>
                                        <select
                                            className="hotels-filter-input-css"
                                            onChange={handleStarChange}
                                            value={selectedStar}
                                        >
                                            <option value="">Select Star</option>
                                            <option value="1">1 Star</option>
                                            <option value="2">2 Star</option>
                                            <option value="3">3 Star</option>
                                            <option value="4">4 Star</option>
                                            <option value="5">5 Star</option>
                                        </select>
                                    </div>
                                    <div className="col-1 mb-3">
                                        <div className="d-flex align-items-end h-100">
                                            <button
                                                className={`reset-button-css text-20 border-0 px-3`}
                                                onClick={handleClearFilters}
                                            >
                                                <FontAwesomeIcon icon={faSyncAlt} className={`${isRotating ? "rotate-icon" : ""}`} size="lg" title="Clear Filters" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-2 mb-3">
                                        <div className="d-flex justify-content-end align-items-end h-100 ">
                                            <button
                                                className="check-availibility-button text-20 border-0"
                                                onClick={handleNavigate}
                                            >
                                                Go To Itinerary
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {/* Hotel List */}
                                <div className="col-12">
                                    {filteredHotels.length > 0 ? (
                                        [...filteredHotels].reverse().map((ele) => (
                                            <div key={ele._id} className="top-border p-4 mb-2">
                                                <div className="row gx-5">
                                                    <div className="col-lg-4 col-md-5 col-sm-6 text-center d-flex align-items-center">
                                                        <img
                                                            src={ele.hotel_photo[0]}
                                                            className="img-fluid w-100"
                                                            alt="hotel"
                                                            style={{ height: "200px" }}
                                                        />
                                                    </div>
                                                    <div className="col-lg-8 col-md-7 col-sm-6 d-flex flex-column justify-content-start pe-4">
                                                        <div className="align-items-top top_hotels_name d-flex justify-content-between mb-1">
                                                            <h3 className="text18 font700 mb-0">{ele.hotel_name}</h3>
                                                            <input
                                                                type="radio"
                                                                name="selectedHotel"
                                                                value={ele._id}
                                                                checked={selectedHotel === ele._id}
                                                                onChange={() => handleHotelSelection(ele._id)}
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <StarRating
                                                                averageStarRating={getStarRatingFromHotelType(ele.hotel_type)}
                                                            />
                                                        </div>
                                                        <div className="top_hotels_map">
                                                            <p className="top-a text14">
                                                                <FontAwesomeIcon icon={faLocationPin} />{" "}
                                                                {ele.hotel_city}, {ele.hotel_state}
                                                            </p>
                                                            <p className="top-a text14">{ele.hotel_address}</p>
                                                        </div>
                                                        {ele?.hotel_review && (
                                                            <div className="top_hotels_star pt-2">
                                                                {Array.from(
                                                                    { length: Math.floor(ele.hotel_review) },
                                                                    (_, i) => (
                                                                        <FontAwesomeIcon
                                                                            key={i}
                                                                            icon={faStar}
                                                                            className="top_font_crl"
                                                                        />
                                                                    )
                                                                )}
                                                            </div>
                                                        )}

                                                        {/* Room Data */}
                                                        {selectedHotel === ele._id && ele?.rooms && ele?.rooms?.length > 0 && (
                                                            <div>
                                                                <h5 style={{ fontSize: "18px", fontWeight: "600" }}>Rooms:</h5>
                                                                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gap: "10px" }} className="mb-3">
                                                                    {ele.rooms.map((room) => (
                                                                        <div
                                                                            key={room._id}
                                                                            style={{
                                                                                border: "1px solid #ddd",
                                                                                borderRadius: "8px",
                                                                                padding: "16px",
                                                                                marginBottom: "16px",
                                                                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                                                                backgroundColor: "#fff",
                                                                            }}
                                                                        >
                                                                            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                                                <input
                                                                                    type="radio"
                                                                                    id={`room-${room._id}`}
                                                                                    name="room-selection"
                                                                                    value={room._id}
                                                                                    style={{ cursor: "pointer" }}
                                                                                    onChange={handleRoomSelection}
                                                                                    checked={selectedRoomId === room._id}
                                                                                />
                                                                                <div>
                                                                                    <p className="text14" style={{ margin: "0" }}>
                                                                                        <strong>Room Type:</strong> {room.room_type}
                                                                                    </p>
                                                                                    <p className="text14" style={{ margin: "0" }}>
                                                                                        <strong>Price:</strong> ₹{room.room_type_price}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        )}

                                                        {/* Meal Data */}
                                                        {selectedHotel === ele._id && (ele.breakfast_price || ele.lunch_price || ele.dinner_price) && (
                                                            <div style={{ display: "grid", gridTemplateColumns: "1fr 8fr", gap: "10px", alignItems: "center" }}>
                                                                <h5 style={{ fontSize: "18px", fontWeight: "600" }} className="mb-0">Meals:</h5>
                                                                <div className="meal-prices" style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gap: "10px" }}>
                                                                    {ele.breakfast_price !== 0 && (
                                                                        <p className="mb-0 text14 d-flex align-items-center gap-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                id="breakfast"
                                                                                name="breakfast"
                                                                                value="breakfast"
                                                                                data-price={ele.breakfast_price}
                                                                                checked={selectedMeals.breakfast}
                                                                                onChange={handleMealSelection}
                                                                            />
                                                                            <label htmlFor="breakfast">
                                                                                <strong>Breakfast Price:</strong> ₹{ele.breakfast_price}
                                                                            </label>
                                                                        </p>
                                                                    )}
                                                                    {ele.lunch_price !== 0 && (
                                                                        <p className="mb-0 text14 d-flex align-items-center gap-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                id="lunch"
                                                                                name="lunch"
                                                                                value="lunch"
                                                                                data-price={ele.lunch_price}
                                                                                checked={selectedMeals.lunch}
                                                                                onChange={handleMealSelection}
                                                                            />
                                                                            <label htmlFor="lunch">
                                                                                <strong>Lunch Price:</strong> ₹{ele.lunch_price}
                                                                            </label>
                                                                        </p>
                                                                    )}
                                                                    {ele.dinner_price !== 0 && (
                                                                        <p className="mb-0 text14 d-flex align-items-center gap-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                id="dinner"
                                                                                name="dinner"
                                                                                value="dinner"
                                                                                data-price={ele.dinner_price}
                                                                                checked={selectedMeals.dinner}
                                                                                onChange={handleMealSelection}
                                                                            />
                                                                            <label htmlFor="dinner">
                                                                                <strong>Dinner Price:</strong> ₹{ele.dinner_price}
                                                                            </label>
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <p>No hotels found matching your criteria.</p>
                                    )}
                                </div>
                            </div>
                        </section>
                    </section>
                </div>
            </div>
        </>
    );
};

export default SelectItineraryHotel;


