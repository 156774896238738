import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { BASE_URL } from "../BASE_URL";

const Cars = () => {
    const [car, setCar] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem("userToken");
            const res = await fetch(`${BASE_URL}vendor_car_syt/getAll/`, {
                method: "GET",
                headers: { Authorization: token, "Content-Type": "application/json" },
            });
            const data = await res.json();
            setCar(data?.data || []);
        };
        fetchData();
    }, []);

    const handleSendData = (carData) => {
        navigate("/car-detail", { state: { carData } });
    };

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h1 className="py-3 text22 font700">Cars</h1>
                <h6 className="mb-0" style={{ cursor: "pointer" }}>View All</h6>
            </div>
            <Carousel
                responsive={{
                    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 4.5 },
                    tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
                    mobile: { breakpoint: { max: 464, min: 0 }, items: 2 },
                }}
                infinite
                draggable
                swipeable
                autoPlay
                autoPlaySpeed={3000}
                keyBoardControl
                showDots={false}
                customLeftArrow={<button className="custom-arrow left-arrow"><FontAwesomeIcon icon={faChevronLeft} /></button>}
                customRightArrow={<button className="custom-arrow right-arrow"><FontAwesomeIcon icon={faChevronRight} /></button>}
            >
                {car?.map((ele) => (
                    <div key={ele?._id} onClick={() => handleSendData(ele)} style={{ cursor: "pointer" }}>
                        <img src={ele?.car_details?.[0]?.photo} alt="Car" className="w-100 mobile_image_set" />
                        <p className="name_c text-center w-100">{ele?.car_details?.[0]?.car_name}</p>
                    </div>
                ))}
            </Carousel>
        </div>
    );
};

export default Cars;
