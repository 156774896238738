import React, { useRef } from "react"
// import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBell,
  faGift,
  faMobileScreenButton,
  faPhoneVolume,
} from "@fortawesome/free-solid-svg-icons"
// import axios from "axios";
import { faUser } from "@fortawesome/free-regular-svg-icons"
import "./css/index1.css"
import { useEffect, useState } from "react"
import Modal from "react-bootstrap/Modal"
// import { FormCheck, FormControl, Row } from "react-bootstrap"
import { useLocation, useNavigate } from "react-router-dom"
import { NavLink } from "react-router-dom"
// import Button from "@mui/material/Button"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { BASE_URL } from "../BASE_URL"
// 
// 
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons"
import Login from "./Login"

import io from 'socket.io-client';

function Header({ setShow, show, setNewNotification }) {
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const [name, setName] = useState("")

  const token = localStorage.getItem("userToken")

  const handleShow = () => setShow(true)

  const vendorToken = localStorage.getItem("vendorToken")

  const goingTravel = () => {
    vendorToken ? navigate("/vendor/home-page") : navigate("/vendor/login")
  }

  const [updateName, setUpdateName] = useState(false)

  const profile = async () => {
    const res = await fetch(`${BASE_URL}user/userprofile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
    const data = await res.json()

    setName(data?.data?.[0]?.user_details?.[0]?.name)
    localStorage.setItem('my_id', data?.data?.[0]?.user_details?.[0]?._id)
  }

  useEffect(() => {
    profile()
  }, [show])

  useEffect(() => {
    profile()
    setUpdateName(false)
  }, [updateName])

  const [show11, setShow11] = useState(false)

  const handleClose11 = () => setShow11(false)
  const handleShow11 = () => setShow11(true)

  const handleLogout = () => {
    localStorage.clear();
    localStorage.clear("userToken")
    handleClose11()
    navigate("/")
  }

  const handleLogoutNo = () => {
    handleClose11()
  }

  const [socket, setSocket] = useState(null);
  const [yuvraj, setYuvraj] = useState([])

  useEffect(() => {
    const socketInstance = io("https://start-your-tour-api-1.onrender.com", {
      query: { userId: localStorage.getItem("mySytUserId") },
    });
    setSocket(socketInstance);

    socketInstance.on("newNotification", (newMessage) => {
      console.log(newMessage);
      setYuvraj((prev) => [newMessage, ...prev]);
    });

    return () => socketInstance.disconnect();
  }, []);

  useEffect(() => {
    socket?.on("newNotification", (newMessage) => {
      console.log(newMessage);
      setNewNotification(newMessage)
    });

    return () => socket?.off("newNotification");
  }, [socket]);

  const fetchNotifications = async () => {
    try {
      const token = localStorage.getItem("userToken");
      const res = await fetch(`${BASE_URL}notification/customer`, {
        method: "GET",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (!res.ok) throw new Error("Failed to fetch notifications");

      const data = await res.json();
      setYuvraj(data?.data);
    } catch (err) {

    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []); // Fetch on component mount

  return (
    <>
      <div className='' style={{ borderBottom: "1px solid #000" }} bg='light'>
        <Navbar expand='lg' className='px-lg-4 px-md-4 px-sm-2 px-2 nav_width'>
          <NavLink
            to='/'
            style={{
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Navbar.Brand className='logo'>
              <img
                src='/syt_logo.png'
                alt=''
                className='me-2'
                style={{ height: "40px" }}
              />
              START YOUR TOUR
            </Navbar.Brand>
          </NavLink>
          <Navbar.Toggle aria-controls='navbarScroll' />
          <Navbar.Collapse id='navbarScroll'>
            <Nav
              className='m-right my-2 my-lg-0'
              style={{ maxHeight: "200px", marginLeft: "auto" }}
              navbarScroll
            >
              <Nav.Link href='tel:+91 96623 99609' className='text-dark'>
                <FontAwesomeIcon icon={faPhoneVolume} className='me-1' />
                +91 96623 99609
              </Nav.Link>
              <Nav.Link href='' className='text-dark' onClick={goingTravel}>
                <FontAwesomeIcon icon={faPhoneVolume} className='me-1' />
                Travel Agent? Join Us
              </Nav.Link>
              <Nav.Link href='' className='text-dark'>
                <FontAwesomeIcon icon={faGift} className='me-1' />
                Offers
              </Nav.Link>
              <Nav.Link href='' className='text-dark'>
                <FontAwesomeIcon icon={faMobileScreenButton} className='me-1' />
                Download App
              </Nav.Link>
            </Nav>
            {token ? (
              <>
                <div
                  className="cmn p-2 d-flex align-items-center justify-content-between position-relative"
                  style={{
                    backgroundColor: "#09646d",
                    color: "white",
                    cursor: "pointer",
                  }}
                  onClick={handleClickMenu}
                >
                  <div>
                    <FontAwesomeIcon icon={faUser} />{" "}
                    {name?.length > 6 ? `${name?.slice(0, 6)}...` : name}
                  </div>
                  {/* Notification Badge */}
                  {yuvraj.filter(item => item.status === "unread").length !== 0 && (
                    <span
                      style={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        transform: "translate(50%, -50%)",
                        backgroundColor: "red",
                        color: "white",
                        borderRadius: "50%",
                        padding: "4px 8px",
                        fontSize: "12px",
                        fontWeight: "bold",
                        zIndex: 1,
                      }}
                    >
                      {yuvraj.filter(item => item.status === "unread").length}
                    </span>
                  )}
                </div>
              </>
            ) : (
              <a
                href="#"
                className="log_btn w-0"
                onClick={handleShow}
                variant="primary"
              >
                Login
              </a>
            )}


            <Menu
              id='basic-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseMenu}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <NavLink
                to='/profile'
                style={{ textDecoration: "none", color: "black" }}
              >
                <MenuItem onClick={handleCloseMenu}>My Profile</MenuItem>
              </NavLink>
              <NavLink
                to='/custom_requirement'
                style={{ textDecoration: "none", color: "black" }}
              >
                <MenuItem onClick={handleCloseMenu}>My Requirements</MenuItem>
              </NavLink>
              <NavLink
                to='/notifications'
                style={{ textDecoration: "none", color: "black" }}
              >
                <MenuItem onClick={handleCloseMenu}>Notifications{" "}
                  <span
                    style={{
                      marginLeft: "10px",
                      backgroundColor: "red",
                      color: "white",
                      borderRadius: "50%",
                      padding: "2px 6px",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    {yuvraj.filter(item => item.status === "unread").length}
                  </span></MenuItem>
              </NavLink>
              <NavLink
                to='/my_package'
                style={{ textDecoration: "none", color: "black" }}
              >
                <MenuItem onClick={handleCloseMenu}>My Booking</MenuItem>
              </NavLink>
              <MenuItem
                onClick={() => {
                  handleCloseMenu()
                  handleShow11()
                  // navigate("/");
                }}
              >
                Logout
              </MenuItem>
            </Menu>
          </Navbar.Collapse>
        </Navbar>
      </div>

      <div>
        <Modal
          show={show11}
          onHide={handleClose11}
          backdrop='static'
          keyboard={false}
          style={{ zIndex: "10101010" }}
          centered
        >
          <Modal.Body>
            <div className='pt-3 pb-2 px-3 text-center'>
              <FontAwesomeIcon
                icon={faRightFromBracket}
                className='mb-3'
                style={{ fontSize: "50px" }}
              />
              <h5 className='text-center'>Are You Sure You Want To Logout?</h5>
              <div className='logout-btn d-flex justify-content-center mt-3'>
                <button
                  className='logout-btn-1'
                  variant='secondary'
                  onClick={handleLogoutNo}
                >
                  No
                </button>
                <button
                  className='logout-btn-2 ms-2'
                  variant='primary'
                  onClick={handleLogout}
                >
                  Yes
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>



      <Login setName={setName} setShow={setShow} show={show} />

      <ToastContainer />
    </>
  )
}

export default Header
