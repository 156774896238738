import React, { useState, useEffect, useContext } from "react"
import Header from "./Header"
import { Container, Table } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBrush,
  faCircle,
  faCircleCheck,
  faCircleRadiation,
  faCircleXmark,
  faHotel,
  faIndianRupeeSign,
  faLayerGroup,
  faStar,
  faTag,
} from "@fortawesome/free-solid-svg-icons"
import { ids } from "../App"
import { bidContext } from "../App"
import { hotelName } from "../App"
import { BASE_URL } from "../BASE_URL"
import Footer from "./Footer"
import DOMPurify from "dompurify"
import { useLocation } from "react-router-dom"
import Carousel from 'react-bootstrap/Carousel';
import StarRating from "./StarRating"
import ActivityContentServices from "./ActivityContentServices"
import ActivityContentOther from "./ActivityContentOther"

function sortDays(days) {
  return days
    .split(",")                // Split the string by commas
    .map(Number)                // Convert each part to a number
    .sort((a, b) => a - b)      // Sort the array of numbers
    .join(",");                 // Join back to a comma-separated string
}

function Compare_packega(props) {

  const location = useLocation();
  const ids = location.state.selectedPackageIds;

  const [firstBid, setFirstBid] = useState(ids[0] || "")
  const [secondBid, setSecondBid] = useState(ids[1] || "")

  useEffect(() => {
    if (ids.length > 0) {
      setFirstBid(ids[0])
      setSecondBid(ids[1])
    }
  }, [ids]);

  const { bidData, setBidData } = useContext(bidContext)
  const [bidDetails, setBidDetails] = useState([])
  const [bidDetails1, setBidDetails1] = useState([])
  const { hotel, setHotel } = useContext(hotelName)
  const [margin, setMargin] = useState(null)


  const DetailsBid = async () => {
    const res = await fetch(`${BASE_URL}bidpackage/biddetails_jaydev?_id=${firstBid}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    const data = await res.json()
    setBidDetails(data?.data?.[0])
    const margin = data?.data?.[0]?.admin_margin?.margin_percentage
    setMargin(Number(margin) / 100)
  }
  const DetailsBid1 = async () => {
    const res = await fetch(`${BASE_URL}bidpackage/biddetails_jaydev?_id=${secondBid}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    const data = await res.json()
    setBidDetails1(data?.data?.[0])
  }

  useEffect(() => {
    DetailsBid()
    DetailsBid1()
  }, [])

  const itenerary1Length = bidDetails?.itineries?.length;
  const itenerary2Length = bidDetails1?.itineries?.length;

  const [intinerary, setItineraryMap] = useState([])

  useEffect(() => {
    const iteneraryToMap = itenerary1Length >= itenerary2Length ? bidDetails?.itineries : bidDetails1?.itineries;
    setItineraryMap(iteneraryToMap)
  }, [bidDetails && bidDetails1]);

  const hotels1Length = bidDetails?.hotel_itienrary?.length;
  const hotels2Length = bidDetails1?.hotel_itienrary?.length;

  const [hotels, setHotels] = useState([])

  useEffect(() => {
    const iteneraryToMap = hotels1Length >= hotels2Length ? bidDetails?.hotel_itienrary : bidDetails1?.hotel_itienrary;
    setHotels(iteneraryToMap)
  }, [bidDetails && bidDetails1]);

  return (
    <>
      <Header />

      {bidDetails1.length === 0 && bidDetails.length === 0 ? (
        <p>Not a any compare package</p>
      ) : (
        <section className='py-3'>
          <div className='container px-xl-5 px-lg-4 px-md-3 px-sm-2 '>
            <div bordered>
              <div className='text-center compare_packages_header py-2'>
                <h3 className='cmnp font600 mb-2 text22'>Compare Packages of <span className=" font700" style={{color: "#09646d"}}>{bidDetails.departure} to {bidDetails.destination}</span></h3>
                {/* <p style={{ color: "#B8B8B8" }}>
                  Compare packages and find your perfect match!
                </p> */}
                {/* <h5 style={{ color: "#09646d", fontWeight: "600" }}></h5> */}
              </div>
              <div>
                <table className="compare-packages-table" width={"100%"}>
                  <thead>
                    <tr>
                      <th className="compare-package-first-column"></th>
                      <th style={{ textTransform: "capitalize" }} className="compare-package-second-column">
                        <p className="mb-0">
                          {bidDetails?.agency_name}
                        </p>
                        <p className="text14 mb-0"><StarRating averageStarRating={bidDetails?.averageStarRating || 0} /></p>
                      </th>
                      <th style={{ textTransform: "capitalize" }} className="compare-package-third-column">
                        <p className="mb-0">
                          {bidDetails1?.agency_name}
                        </p>
                        <p className="mb-0 text14"><StarRating averageStarRating={bidDetails1?.averageStarRating || 0} /></p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='size-500 text16 font600'>Package Name</td>
                      <td className=" text16 font500">
                        {bidDetails?.name === "" ? "N/A" : bidDetails?.name}
                        {/* Include */}
                      </td>
                      <td className=" text16 font500">
                        {bidDetails1?.name === "" ? "N/A" : bidDetails1?.name}
                      </td>
                    </tr>
                    <tr>
                      <td className='size-500 text16 font600'>Package Types </td>
                      <td className=" text16 font500">{bidDetails?.package_type}</td>
                      <td className=" text16 font500">{bidDetails1?.package_type}</td>
                    </tr>
                    <tr>
                      <td className='size-500 text16 font600'>
                        <FontAwesomeIcon icon={faCircleRadiation} /> Trip Duration
                      </td>
                      <td className=" text16 font500">
                        {bidDetails.start_date1} to {bidDetails.end_date1}
                      </td>
                      <td className=" text16 font500">
                        {bidDetails1.start_date1} to {bidDetails1.end_date1}
                      </td>
                      {/* <td>22/08/2023 to 26/08/2023</td> */}
                    </tr>
                    <tr>
                      <td className='size-500 text16 font600'>Total Days/Nights </td>
                      <td className=" text16 font500">{bidDetails?.total_days}D/{bidDetails?.total_nights}N</td>
                      <td className=" text16 font500">{bidDetails1?.total_days}D/{bidDetails1?.total_nights}N</td>
                    </tr>

                    <tr>
                      <td className='size-500 text16 font600'>
                        <FontAwesomeIcon icon={faTag} /> Price Per Person
                      </td>
                      <td style={{ color: "#00B707" }}>
                        <p className="text16 font500">
                          <FontAwesomeIcon icon={faIndianRupeeSign} />{" "}
                          {Math.round(bidDetails.price_per_person_adult + (bidDetails.price_per_person_adult * (margin || 0)))}{" "}
                          <span style={{ color: "#B8B8B8" }}>| per person (Adults({bidDetails?.total_adult}))</span>
                        </p>
                        <p className="text16 font500">
                          <FontAwesomeIcon icon={faIndianRupeeSign} />{" "}
                          {Math.round(bidDetails.price_per_person_child + (bidDetails.price_per_person_child * (margin || 0)))}{" "}
                          <span style={{ color: "#B8B8B8" }}>| per person (Children({bidDetails?.total_child}))</span>
                        </p>
                        <p className="text16 font500 mb-0">
                          <FontAwesomeIcon icon={faIndianRupeeSign} />{" "}
                          {Math.round(bidDetails.price_per_person_infant + (bidDetails.price_per_person_infant * (margin || 0)))}{" "}
                          <span style={{ color: "#B8B8B8" }}>| per person (Infants({bidDetails?.Infant}))</span>
                        </p>
                      </td>
                      <td style={{ color: "#00B707" }}>
                        <p className="text16 font500">
                          <FontAwesomeIcon icon={faIndianRupeeSign} />{" "}
                          {Math.round(bidDetails1.price_per_person_adult + (bidDetails1.price_per_person_adult * (margin || 0)))}{" "}
                          <span style={{ color: "#B8B8B8" }}>| per person (Adults({bidDetails1?.total_adult}))</span>
                        </p>
                        <p className="text16 font500">
                          <FontAwesomeIcon icon={faIndianRupeeSign} />{" "}
                          {Math.round(bidDetails1.price_per_person_child + (bidDetails1.price_per_person_child * (margin || 0)))}{" "}
                          <span style={{ color: "#B8B8B8" }}>| per person (Children({bidDetails1?.total_child}))</span>
                        </p>
                        <p className="text16 font500 mb-0">
                          <FontAwesomeIcon icon={faIndianRupeeSign} />{" "}
                          {Math.round(bidDetails1.price_per_person_infant + (bidDetails1.price_per_person_infant * (margin || 0)))}{" "}
                          <span style={{ color: "#B8B8B8" }}>| per person (Infants({bidDetails1?.Infant}))</span>
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <td className='size-500 text16 font600'>
                        <FontAwesomeIcon icon={faTag} /> Total
                      </td>
                      <td className="text16 font500" style={{ color: "#00B707" }}>
                        <FontAwesomeIcon icon={faIndianRupeeSign} />{" "}
                        {Math.round(bidDetails?.total_amount + (bidDetails?.total_amount * (margin || 0)))}
                      </td>
                      <td className="text16 font500" style={{ color: "#00B707" }}>
                        <FontAwesomeIcon icon={faIndianRupeeSign} />{" "}
                        {Math.round(bidDetails1?.total_amount + (bidDetails1?.total_amount * (margin || 0)))}
                      </td>
                    </tr>

                    <tr>
                      <td colspan='3' style={{ background: "#09646D" }}>
                        <p className='w-100 text-white cmnp ps-2 text20 font700'>
                          <FontAwesomeIcon icon={faLayerGroup} /> Overview
                        </p>
                      </td>
                    </tr>

                    {/* <tr>
                      <td className='size-500 text16 font600'>Total Reviews</td>
                      <td className="text16 font500">
                        <StarRating averageStarRating={bidDetails?.averageStarRating || 0} />
                      </td>
                      <td className="text16 font500">
                        <StarRating averageStarRating={bidDetails1?.averageStarRating || 0} />
                      </td>
                    </tr> */}
                    <tr>
                      <td className='size-500 text16 font600'>Meals</td>
                      <td className=" text16 font500">
                        {bidDetails.meal_required === "" ? "N/A" : bidDetails.meal_required}
                        {/* Include */}
                      </td>
                      <td className=" text16 font500">
                        {bidDetails1.meal_required === "" ? "N/A" : bidDetails1.meal_required}
                        {/* Include */}
                      </td>
                    </tr>
                    <tr>
                      <td className='size-500 text16 font600'>Meals Type</td>
                      <td className=" text16 font500">
                        {bidDetails.meal_types === "" ? "N/A" : bidDetails.meal_types}
                        {/* Include */}
                      </td>
                      <td className=" text16 font500">
                        {bidDetails1?.meal_types === "" ? "N/A" : bidDetails1?.meal_types}
                        {/* Include */}
                      </td>
                    </tr>
                    <tr>
                      <td className='size-500 text16 font600'>Sightseeing</td>
                      <td className=" text16 font500">{bidDetails.sightseeing}</td>
                      <td className=" text16 font500">{bidDetails1.sightseeing}</td>
                    </tr>
                    <tr>
                      <td className='size-500 text16 font600'>Travel By</td>
                      <td className=" text16 font500">{bidDetails.travel_by}</td>
                      <td className=" text16 font500">{bidDetails1.travel_by}</td>
                    </tr>
                    <tr>
                      <td className='size-500 text16 font600'>Categories</td>
                      <td className=" text16 font500">
                        {bidDetails?.destination_category_name?.length > 0 && bidDetails?.destination_category_name.map(category => category.category_name).join(", ")}
                      </td>
                      <td className=" text16 font500">
                        {bidDetails1?.destination_category_name?.length > 0 && bidDetails1?.destination_category_name.map(category => category.category_name).join(", ")}
                      </td>
                    </tr>
                    <tr>
                      <td className='size-500 text16 font600'>Hotel Types</td>
                      <td className=" text16 font500">
                        {bidDetails?.hotel_types === "" ? "N/A" : bidDetails?.hotel_types}
                      </td>
                      <td className=" text16 font500">
                        {bidDetails1?.hotel_types === "" ? "N/A" : bidDetails1?.hotel_types}
                      </td>
                    </tr>
                    <tr>
                      <td className='size-500 text16 font600'>Room Sharing</td>
                      <td className=" text16 font500">
                        {bidDetails?.room_sharing === "" ? "N/A" : bidDetails?.room_sharing}
                      </td>
                      <td className=" text16 font500">
                        {bidDetails1?.room_sharing === "" ? "N/A" : bidDetails1?.room_sharing}
                      </td>
                    </tr>
                    <tr>
                      <td className='size-500 text16 font600'>Other Requirements </td>
                      <td className=" text16 font500">
                        <ActivityContentServices activity={bidDetails?.additional_requirement} charCount={150} />
                      </td>
                      <td className=" text16 font500">
                        <ActivityContentServices activity={bidDetails1?.additional_requirement} charCount={150} />
                      </td>
                    </tr>
                    <tr>
                      <td className='size-500 text16 font600'>Personal Care </td>
                      <td className=" text16 font500">{bidDetails?.personal_care}</td>
                      <td className=" text16 font500">{bidDetails1?.personal_care}</td>
                    </tr>
                    <tr>
                      <td className='size-500 text16 font600'>Other Services By Agency </td>
                      <td className=" text16 font500">
                        <ActivityContentOther activity={bidDetails?.other_services_by_agency} charCount={700} />
                      </td>
                      <td className=" text16 font500">
                        <ActivityContentOther activity={bidDetails1?.other_services_by_agency} charCount={700} />
                      </td>
                    </tr>
                    <tr>
                      <td colspan='3' style={{ background: "#09646D" }}>
                        <p className='w-100 text-white cmnp ps-2 font700 text20'>
                          <FontAwesomeIcon icon={faBrush} className="me-2" /> Services
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className='size-500 text16 font600'>
                        <FontAwesomeIcon icon={faCircleCheck} /> Included
                      </td>
                      <td>
                        <ul style={{ color: "#00B707" }}>
                          {bidDetails.include_services &&
                            bidDetails.include_services.map((ele) => {
                              return (
                                <>
                                  <li
                                    className=" text16 font500"
                                  >
                                    <ActivityContentServices activity={ele.include_services_value} charCount={150} />
                                  </li>
                                </>
                              )
                            })}
                        </ul>
                      </td>
                      <td>
                        <ul style={{ color: "#00B707" }}>
                          {bidDetails1.include_services &&
                            bidDetails1.include_services.map((ele) => {
                              return (
                                <>
                                  <li
                                    className=" text16 font500"
                                  >
                                    <ActivityContentServices activity={ele.include_services_value} charCount={150} />
                                  </li>
                                </>
                              )
                            })}
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td className='size-500 text16 font600'>
                        <FontAwesomeIcon icon={faCircleXmark} /> Excluded
                      </td>
                      <td>
                        <ul style={{ color: "#E50000" }}>
                          {bidDetails?.exclude_services &&
                            bidDetails?.exclude_services.map((ele) => {
                              return (
                                <>
                                  <li
                                    className=" text16 font500"
                                  >
                                    <ActivityContentServices activity={ele.exclude_services_value} charCount={150} />
                                  </li>
                                </>
                              )
                            })}
                        </ul>
                      </td>
                      <td>
                        <ul style={{ color: "#E50000" }}>
                          {bidDetails?.exclude_services && bidDetails1?.exclude_services?.map((ele) => {
                            return (
                              <>
                                <li
                                  className=" text16 font500"
                                >
                                  <ActivityContentServices activity={ele.exclude_services_value} charCount={150} />
                                </li>
                              </>
                            )
                          })}
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td colspan='3' style={{ background: "#09646D" }}>
                        <p className='w-100 text-white cmnp ps-2 text20 font700'>
                          <img
                            src='/vector1.png'
                            className='img-fluid me-2'
                            alt='vector'
                          />
                          Itinerary
                        </p>
                      </td>
                    </tr>

                    {intinerary && intinerary.map((item, index) => {
                      const bidDetailItinerary = bidDetails?.itineries?.[index];
                      const bidDetail1Itinerary = bidDetails1?.itineries?.[index];

                      return (
                        <tr key={index}>
                          <td className='size-500 text16 font600'>Day {index + 1}</td>
                          <td className="text16 font500">
                            {bidDetailItinerary && (
                              <div className="row h-100">
                                <div className="col-7">
                                  <div>
                                    <p><b>Title: </b>{bidDetailItinerary.title}</p>
                                  </div>
                                  <div>
                                    <p><b>Description: </b>
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: bidDetailItinerary.activity.length > 100
                                            ? bidDetailItinerary.activity.substring(0, 100) + "..."
                                            : bidDetailItinerary.activity
                                        }}
                                      ></span>
                                    </p>
                                  </div>
                                </div>
                                {bidDetailItinerary?.photo && (
                                  <div className="col-5">
                                    <img
                                      src={bidDetailItinerary.photo}
                                      alt="img"
                                      style={{ width: "100%", objectFit: "cover" }}
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                          </td>
                          <td className="text16 font500">
                            {bidDetail1Itinerary && (
                              <div className="row h-100">
                                <div className="col-7">
                                  <div>
                                    <p><b>Title: </b>{bidDetail1Itinerary.title}</p>
                                  </div>
                                  <div>
                                    <p><b>Description: </b>
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: bidDetail1Itinerary.activity.length > 100
                                            ? bidDetail1Itinerary.activity.substring(0, 100) + "..."
                                            : bidDetail1Itinerary.activity
                                        }}
                                      ></span>
                                    </p>
                                  </div>
                                </div>
                                {bidDetail1Itinerary?.photo && (
                                  <div className="col-5">
                                    <img
                                      src={bidDetail1Itinerary.photo}
                                      alt="img"
                                      style={{ width: "100%", objectFit: "cover" }}
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })}


                    <tr>
                      <td colspan='3' style={{ background: "#09646D" }}>
                        <p className='w-100 text-white cmnp ps-2 text20 font700'>
                          <FontAwesomeIcon icon={faHotel} className="me-2" />
                          Hotels
                        </p>
                      </td>
                    </tr>

                    {hotels && hotels.map((item, index) => {
                      const bidDetailHotel = bidDetails?.hotel_itienrary?.[index];
                      const bidDetail1Hotel = bidDetails1?.hotel_itienrary?.[index];

                      return (
                        <tr key={index}>
                          <td className='size-500 text16 font600'>
                            {/* Day {index + 1} */}
                          </td>
                          <td className="text16 font500">
                            <div className="h-100">
                              {bidDetailHotel && (
                                <>
                                  {bidDetailHotel.hotel_photo && (
                                    <div className="mb-2 d-flex justify-content-center">
                                      <Carousel style={{ height: "200px", width: "300px" }}>
                                        {bidDetailHotel.hotel_photo.map((e, photoIndex) => (
                                          <Carousel.Item key={photoIndex} interval={2000} style={{ position: "relative" }}>
                                            <img src={e} alt={`Slide ${photoIndex}`} className="inner-rooms-image" />
                                          </Carousel.Item>
                                        ))}
                                      </Carousel>
                                    </div>
                                  )}
                                  <div className="d-flex justify-content-between">
                                    {bidDetailHotel.hotel_name && <p><b>Name : </b>{bidDetailHotel.hotel_name}</p>}
                                    {bidDetailHotel.days && <p><b>Days : </b>{sortDays(bidDetailHotel.days)}</p>}
                                  </div>
                                  {bidDetailHotel.hotel_type && <p><b>Hotel Type : </b>{bidDetailHotel.hotel_type}</p>}
                                  {bidDetailHotel.hotel_address && <p><b>Address : </b>{bidDetailHotel.hotel_address}</p>}
                                  {(bidDetailHotel.hotel_city || bidDetailHotel.hotel_state) && (
                                    <p><b>Location : </b>{bidDetailHotel.hotel_city}{bidDetailHotel.hotel_state ? `, ${bidDetailHotel.hotel_state}` : ''}</p>
                                  )}
                                  {bidDetailHotel.hotel_description && (
                                    <p><b>Description : </b>
                                      <ActivityContentOther activity={bidDetailHotel.hotel_description.length > 100
                                        ? bidDetailHotel.hotel_description.substring(0, 100) + "..."
                                        : bidDetailHotel.hotel_description} charCount={200} />
                                    </p>
                                  )}
                                </>
                              )}
                            </div>
                          </td>
                          <td className="text16 font500">
                            <div className="h-100">
                              {bidDetail1Hotel && (
                                <>
                                  {bidDetail1Hotel.hotel_photo && (
                                    <div className="mb-2 d-flex justify-content-center">
                                      <Carousel style={{ height: "200px", width: "300px" }}>
                                        {bidDetail1Hotel.hotel_photo.map((e, photoIndex) => (
                                          <Carousel.Item key={photoIndex} interval={2000} style={{ position: "relative" }}>
                                            <img src={e} alt={`Slide ${photoIndex}`} className="inner-rooms-image" />
                                          </Carousel.Item>
                                        ))}
                                      </Carousel>
                                    </div>
                                  )}
                                  <div className="d-flex justify-content-between">
                                    {bidDetail1Hotel.hotel_name && <p><b>Name : </b>{bidDetail1Hotel.hotel_name}</p>}
                                    {bidDetail1Hotel.days && <p><b>Days : </b>{sortDays(bidDetail1Hotel.days)}</p>}
                                  </div>
                                  {bidDetail1Hotel.hotel_type && <p><b>Hotel Type : </b>{bidDetail1Hotel.hotel_type}</p>}
                                  {bidDetail1Hotel.hotel_address && <p><b>Address : </b>{bidDetail1Hotel.hotel_address}</p>}
                                  {(bidDetail1Hotel.hotel_city || bidDetail1Hotel.hotel_state) && (
                                    <p><b>Location : </b>{bidDetail1Hotel.hotel_city}{bidDetail1Hotel.hotel_state ? `, ${bidDetail1Hotel.hotel_state}` : ''}</p>
                                  )}
                                  {bidDetail1Hotel.hotel_description && (
                                    <p><b>Description : </b>
                                      <ActivityContentOther activity={bidDetail1Hotel.hotel_description.length > 100
                                        ? bidDetail1Hotel.hotel_description.substring(0, 100) + "..."
                                        : bidDetail1Hotel.hotel_description} charCount={200} />
                                    </p>
                                  )}
                                </>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}



                    {/* <tr>
                      <td colspan='3' style={{ background: "#09646D" }}>
                        <p className='w-100 text-white cmnp ps-2 text20 font700'>
                          <img
                            src='/vector1.png'
                            className='img-fluid me-2'
                            alt='vector'
                          />
                          Hotels
                        </p>
                      </td>
                    </tr>

                    {hotels && hotels.map((item, index) => (
                      <tr key={index}>
                        <td className='size-500 text16 font600'></td>
                        <td className=" text16 font500">
                          <div>
                            <p><b>Title: </b>{bidDetails?.itineries?.length > 0 && bidDetails?.itineries[index]?.title}</p>
                          </div>
                          <div>
                            <p><b>Description: </b>{bidDetails?.itineries?.length > 0 && bidDetails?.itineries[index]?.activity}</p>
                          </div>
                        </td>
                        <td className=" text16 font500">
                          <div>
                            <p><b>Title: </b>{bidDetails1?.itineries?.length > 0 && bidDetails1?.itineries[index]?.title}</p>
                          </div>
                          <div>
                            <p><b>Description: </b>{bidDetails1?.itineries?.length > 0 && bidDetails1?.itineries[index]?.activity}</p>
                          </div>
                        </td>
                      </tr>
                    ))} */}

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      )}

      <hr />
      <Footer />
    </>
  )
}

export default Compare_packega
