import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBell,
    faEnvelopeOpen,
    faUser,
} from "@fortawesome/free-solid-svg-icons";
import My_pannel from "./My_pannel";
import Header2 from "./Header2";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from "../BASE_URL";
import './css/HotelBooking.css'
import ProfileSection from "./SecondNavbar";

const ViewCarBooking = () => {
    const navigate = useNavigate();

    const [editedata, setEditedData] = useState("");

    const Call = async () => {
        const token = localStorage.getItem("vendorToken");
        const res = await fetch(`${BASE_URL}agency/profile`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });
        const data = await res.json();
        setEditedData(data?.data?.[0]);
    };

    useEffect(() => {
        Call();
    }, []);

    const { id } = useParams();

    const [bookingData, setBookingData] = useState({});

    const getBookedCar = async () => {
        const token = localStorage.getItem("vendorToken");
        const res = await fetch(`${BASE_URL}car_booking_syt/vendorBookedCarDetails?booked_car_id=${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });
        const data = await res.json();
        setBookingData(data.data[0]);

    };

    useEffect(() => {
        getBookedCar();
    }, []);

    const [bookingStatus, setBookingStatus] = useState();


    const handleNav = () => {
        navigate("/vendor/myprofile");
    };

    return (
        <>
            <Header2 />
            <div className="costum_container">
                <My_pannel />
                <ToastContainer />
                <div style={{ backgroundColor: "whitesmoke", minHeight: "100vh" }}>
                    <ProfileSection title="Car Bookings" />

                    <section className="booked_packega_margin p-4">
                      
                    </section>
                </div>
            </div>
        </>
    );
};

export default ViewCarBooking;
