import React, { useRef } from "react"
import { Link } from "react-router-dom"

import html2canvas from "html2canvas"
import jsPDF from "jspdf"

import {
  faIndianRupeeSign
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const formatDate = (dateString) => {
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return 'Invalid date format';
  }

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};


function Book_packega2({ details }) {

  const totalHotelPrice = details?.[0]?.hotel_itienrary?.reduce((sum, hotel) => {
    const selectedData = details?.[0]?.hotel_itinerary_array?.find((item) => item.hotel_itinerary_id === hotel._id);
    const selectedRoom = hotel.rooms.find((room) => room._id === selectedData?.room_id);
    const roomPrice = selectedRoom ? selectedRoom.room_type_price : 0;

    const breakfastPrice = selectedData?.breakfast ? hotel.breakfast_price : 0;
    const lunchPrice = selectedData?.lunch ? hotel.lunch_price : 0;
    const dinnerPrice = selectedData?.dinner ? hotel.dinner_price : 0;

    const totalPrice = roomPrice + breakfastPrice + lunchPrice + dinnerPrice;

    // Count number of days the hotel is booked
    const numOfDays = hotel.days ? hotel.days.split(",").length : 1;

    // Multiply the total price by the number of days
    return sum + totalPrice * numOfDays;
  }, 0);

  console.log(totalHotelPrice)

  const pdfRef = useRef()

  // const generatePDF = () => {
  //   const input = pdfRef.current;
  //   html2canvas(input).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF("p", "mm", "a4");
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = pdf.internal.pageSize.getHeight();
  //     const imgWidth = canvas.width;
  //     const imgHeight = canvas.height;
  //     const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
  //     const imgX = (pdfWidth - imgWidth * ratio) / 2;
  //     const imgY = 30;
  //     pdf.addImage(imgData, "PNG", imgX, imgY, imgWidth * ratio, imgHeight * ratio);
  //     pdf.save("payment_receipt.pdf");
  //   });
  // };

  const generatePDF = () => {
    const chatContainer = document.getElementById('chat-container');

    // Store the current height and overflow style
    const originalHeight = chatContainer.style.height;
    const originalOverflow = chatContainer.style.overflow;

    // Temporarily expand chat to full height and remove scroll
    chatContainer.style.height = 'auto';
    chatContainer.style.overflow = 'visible';

    html2canvas(chatContainer)
      .then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();

        const margin = 10; // Set your desired margin (in mm)
        const imgWidth = 210 - 2 * margin; // A4 page width (210mm) minus margins
        const pageHeight = 295; // A4 page height in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;

        let position = 0;

        // Add the first page with margins
        pdf.addImage(imgData, 'PNG', margin, position + margin, imgWidth, imgHeight);
        heightLeft -= pageHeight - margin;

        // Add extra pages if content overflows
        while (heightLeft > 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, 'PNG', margin, position + margin, imgWidth, imgHeight);
          heightLeft -= pageHeight - margin;
        }

        // Save the PDF
        pdf.save('chat.pdf');
      })
      .finally(() => {
        // Restore the original height and overflow style after the PDF is generated
        chatContainer.style.height = originalHeight;
        chatContainer.style.overflow = originalOverflow;
      });
  };


  const totalPaidAmount = details?.[0]?.payment?.reduce((sum, payment) => sum + payment.paid_amount, 0);

  const totalAmountPerAdult = details?.[0]?.price_per_person_adult + details?.[0]?.admin_margin_price_adult
  const totalAmountPerChild = details?.[0]?.price_per_person_child + details?.[0]?.admin_margin_price_child
  const totalAmountPerInfant = details?.[0]?.price_per_person_infant + details?.[0]?.admin_margin_price_infant

  return (
    <>
      <section className='booked_package_2'>
        <div className='costum_container'>
          <div
            className='new-vendor-container'
            style={{ backgroundColor: "#ffffff" }}
          >

            <div className='pt-2' id='chat-container' ref={pdfRef}>

              <div className="row">
                <div className="col-7">
                  <div className="row">
                    <div className="col-12">
                      <h6 className='booking-user-detail me-0 font-weight-bold font700 text18 d-flex align-items-center mb-3'>
                        <span>Booking Details</span>
                      </h6>
                    </div>
                    <div className="col-6">
                      <p className='text-capitalize text14 font400'>
                        <b className="text15 font600">Acount User</b> :{" "}
                        {details?.[0]?.customer?.[0]?.customer_detail?.[0]?.name}{" "}
                      </p>

                      <p className=" text14 font400">
                        <b className="text15 font600">Email</b> :{" "}
                        {details?.[0]?.customer?.[0]?.customer_detail?.[0]?.email_address}{" "}
                      </p>

                      <p className=" text14 font400">
                        <b className="text15 font600">Mobile no</b> : {details?.[0]?.customer?.[0]?.phone}{" "}
                      </p>

                      <p className='text-capitalize text14 font400'>
                        <b className="text15 font600">Departure</b> : {details?.[0]?.departure}{" "}
                      </p>

                      <p className='text-capitalize text14 font400'>
                        <b className="text15 font600">Destination</b> :{" "}
                        {details?.[0]?.destination || details?.[0]?.package_details[0].destination[0].destination_name}{" "}
                      </p>
                    </div>
                    <div className="col-6">
                      <p className='text-capitalize text14 font400'>
                        <b className="text15 font600">Adult</b> : {details?.[0]?.total_adult}{" "}
                      </p>

                      <p className='text-capitalize text14 font400'>
                        <b className="text15 font600">Children</b> : {details?.[0]?.total_child}{" "}
                      </p>

                      <p className='text-capitalize text14 font400'>
                        <b className="text15 font600">Infant</b> : {details?.[0]?.total_infant}{" "}
                      </p>
                      <p className='text-capitalize text14 font400'>
                        <b className="text15 font600">Total Travellers</b> : {details?.[0]?.total_person}{" "}
                      </p>
                      <p className='text-capitalize text14 font400'>
                        <b className="text15 font600">Booking Date</b> : {formatDate(details?.[0]?.bookdate)}{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-5">
                  <h6 className='booking-user-detail me-0 font-weight-bold font700 text18 d-flex align-items-center mb-3'>
                    <span>Payment Details</span>
                  </h6>
                  <p className='text-capitalize'>
                    <span className="text15 font600">Payment Method</span> :{" "}
                    <span className='text-uppercase text14 400'>
                      {details?.[0]?.payment_type_on_booking}
                    </span>
                  </p>
                  <p className='text-capitalize text14 400'>
                    <span className="text15 font600">Hotel & Food</span> :{" "}
                    <FontAwesomeIcon
                      className='me-1'
                      icon={faIndianRupeeSign}
                    />{totalHotelPrice}
                  </p>
                  <p className='text-capitalize text14 400'>
                    <span className="text15 font600">Total Payable Amount</span> :{" "}
                    <FontAwesomeIcon
                      className='me-1'
                      icon={faIndianRupeeSign}
                    />{details?.[0]?.payment?.[0]?.paid_amount?.toFixed()}
                  </p>
                  <p className='text-capitalize'>
                    <span className="text15 font600">Transaction Id</span> :{" "}
                    <span className='text-uppercase text14 font400'>
                      {details?.[0]?.payment?.[0]?.transaction_id}
                    </span>
                  </p>
                  <p className='text-capitalize'>
                    <span className="text15 font600">Paid Amount</span> :{" "}
                    <span className='text-uppercase text14 font400'>
                      <FontAwesomeIcon
                        className='me-1'
                        icon={faIndianRupeeSign}
                      />
                      {details?.[0]?.payment?.[0]?.paid_amount?.toFixed()}
                    </span>
                  </p>
                  <p className='text-capitalize'>
                    <span className="text15 font600">Payment Date</span> :{" "}
                    <span className='text-uppercase text14 font400'>
                      {formatDate(details?.[0]?.payment?.[0]?.payment_date)}
                    </span>
                  </p>
                  <p className='text-capitalize'>
                    <b>Payment Mode</b> :{" "}
                    <span className='text-uppercase'>
                      {details?.[0]?.payment?.[0]?.payment_mode}
                    </span>
                  </p>
                  <p className='text-capitalize'>
                    <b>Payment Status</b> :{" "}
                    <span className='text-uppercase'>
                      {details?.[0]?.payment_type}
                    </span>
                  </p>
                </div>
              </div>

              <div className='py-2'>
                <div className='cmn p-2 px-3'>
                  <div>
                    <table className="table table-bordered mt-2">
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col" className="text14 font600"></th>
                          <th scope="col" className="text14 font600">Price Per Person</th>
                          <th scope="col" className="text14 font600">Admin Margin</th>
                          <th scope="col" className="text14 font600">Final Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text13">Adult({details?.[0]?.total_adult})</td>
                          <td className="text13">{details?.[0]?.price_per_person_adult} * {details?.[0]?.total_adult} = <b>{details?.[0]?.price_per_person_adult * details?.[0]?.total_adult}</b></td>
                          <td className="text13">{details?.[0]?.admin_margin_price_adult}({details?.[0]?.admin_margin_percentage}%) * {details?.[0]?.total_adult} = <b>{details?.[0]?.admin_margin_price_adult * details?.[0]?.total_adult}</b></td>
                          <td className="text13">{totalAmountPerAdult * details?.[0]?.total_adult}</td>
                        </tr>
                        <tr>
                          <td className="text13">Children({details?.[0]?.total_child})</td>
                          <td className="text13">{details?.[0]?.price_per_person_child} * {details?.[0]?.total_child} = <b>{details?.[0]?.price_per_person_child * details?.[0]?.total_child}</b></td>
                          <td className="text13">{details?.[0]?.admin_margin_price_child}({details?.[0]?.admin_margin_percentage}%) * {details?.[0]?.total_child} = <b>{details?.[0]?.admin_margin_price_child * details?.[0]?.total_child}</b></td>
                          <td className="text13">{totalAmountPerChild * details?.[0]?.total_child}</td>
                        </tr>
                        <tr>
                          <td className="text13">Infant({details?.[0]?.total_infant})</td>
                          <td className="text13">{details?.[0]?.price_per_person_infant} * {details?.[0]?.total_infant} = <b>{details?.[0]?.price_per_person_infant * details?.[0]?.total_infant}</b></td>
                          <td className="text13">{details?.[0]?.admin_margin_price_infant}({details?.[0]?.admin_margin_percentage}%) * {details?.[0]?.total_infant} = <b>{details?.[0]?.admin_margin_price_infant * details?.[0]?.total_infant}</b></td>
                          <td className="text13">{totalAmountPerInfant * details?.[0]?.total_infant}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {details?.[0]?.payment_type_on_booking === "Partial Payment" && (
                <div>
                  <div className='px-xl-0 px-lg-4 px-md-3 px-sm-2 px-1 py-2'>
                    <div className='cmn p-2 px-3'>
                      <div>
                        <table className="table table-bordered mt-2">
                          <thead className="thead-dark">
                            <tr>
                              <th scope="col">Amount</th>
                              <th scope="col">Transaction Id</th>
                              <th scope="col">Payment Mode</th>
                              <th scope="col">Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {details && details?.[0]?.payment?.map((e) => (
                              <tr>
                                <td><FontAwesomeIcon
                                  className='me-1'
                                  icon={faIndianRupeeSign}
                                />{Math.round(e?.paid_amount)}</td>
                                <td>{e?.transaction_id}</td>
                                <td>{e?.payment_mode}</td>
                                <td>{formatDate(e?.payment_date)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        {details.map((ele, index) => (
                          <div className='row mt-4'>

                            <div className='col-6'>
                              <p className='text-capitalize'>
                                <b>Paid Amount</b> :{" "}
                                <FontAwesomeIcon
                                  className='me-1'
                                  icon={faIndianRupeeSign}
                                />{Math.round(totalPaidAmount)}
                              </p>
                            </div>

                            <div className='col-6'>
                              <p className='text-capitalize'>
                                <b>Remaining Amount</b> :{" "}
                                <FontAwesomeIcon
                                  className='me-1'
                                  icon={faIndianRupeeSign}
                                />{Math.round(Number(ele.total_amount) - totalPaidAmount)}
                              </p>
                            </div>
                          </div>
                        ))}

                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className='py-2 pt-3 m-3'>
              <Link
                variant='primary'
                className='ms-2 batann'
                onClick={generatePDF}
              >
                Download
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Book_packega2
