import React, { useEffect, useState } from "react";
import Header from "./Header";
import { Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faIndianRupeeSign,
  faStar,
  faLocationPin,
} from "@fortawesome/free-solid-svg-icons";
import "./css/index1.css";
import { BASE_URL } from "../BASE_URL";
import { useNavigate } from "react-router-dom";

function TopRateHotel(props) {
  const navigate = useNavigate();

  const [hotelSwiper, setHotelSwiper] = useState([]);
  const [filterCriteria, setFilterCriteria] = useState({
    sort: "",
    hotelName: "",
    priceRange: "",
  });

  const hotelList = async () => {
    const token = localStorage.getItem("userToken");
    const res = await fetch(`${BASE_URL}hotel_syt/user`, {
      method: "GET",
      headers: {
        // Authorization: token,
        "Content-Type": "application/json",
      },
    });
    const data = await res.json();
    setHotelSwiper(data.data);
  };

  useEffect(() => {
    hotelList();
  }, []);

  const handleNavigate = (hotelId) => {
    const token = localStorage.getItem("userToken");
    if (token) {
      navigate(`/hotel_details/${hotelId}`);
    } else {
      setShow(true)
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilterCriteria((prev) => ({ ...prev, [name]: value }));
  };

  const filteredHotels = hotelSwiper
    ?.filter((hotel) => {
      if (filterCriteria.hotelName) {
        return hotel.hotel_name
          .toLowerCase()
          .includes(filterCriteria.hotelName.toLowerCase());
      }
      return true;
    })
    ?.filter((hotel) => {
      if (filterCriteria.priceRange) {
        const price = parseInt(hotel.min_room_price);
        switch (filterCriteria.priceRange) {
          case "lessThan10k":
            return price < 10000;
          case "10kTo20k":
            return price >= 10000 && price <= 20000;
          case "20kTo40k":
            return price >= 20000 && price <= 40000;
          case "40kTo60k":
            return price >= 40000 && price <= 60000;
          case "60kTo80k":
            return price >= 60000 && price <= 80000;
          case "above80k":
            return price > 80000;
          default:
            return true;
        }
      }
      return true;
    })
    .sort((a, b) => {
      if (filterCriteria.sort === "highToLow") {
        return a.min_room_price - b.min_room_price;
      } else if (filterCriteria.sort === "lowToHigh") {
        return b.min_room_price - a.min_room_price;
      } else if (filterCriteria.sort === "popularity") {
        return b.hotel_review - a.hotel_review;
      }
      return 0;
    });

  const [show, setShow] = useState(false)

  return (
    <>
      <Header setShow={setShow} show={show} />

      <section>
        <div className="container-customes">
          <div className="pt-4 pb-2 text-center">
            <h2 className="mb-0 text22 font700">Book Your Perfect Stay with Start Your Tour</h2>
            {/* <p className="top_text text16">
              These are the top-rated hotels on our list for your Alberta
            </p> */}
          </div>

          <div className="row">
            <div
              className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-6 d-xl-block d-lg-none d-md-none d-sm-none d-none  text-start"
              style={{
                position: "sticky",
                top: "0px",
              }}
            >
              <div className="sort-list filter px-3 py-3">
                <div>
                  <ul className="list-none ps-0">
                    <li className="d-flex justify-content-between">
                      <h6 className="filter-headers text16 font700">Sort by</h6>
                      <div className="reset-filter-button">
                        <button className="text14" onClick={() => setFilterCriteria({
                          sort: "",
                          hotelName: "",
                          priceRange: "",
                        })}>RESET</button>
                      </div>
                    </li>
                    <li className="text14">
                      <input
                        type="radio"
                        name="sort"
                        value="highToLow"
                        className="input-margin"
                        checked={filterCriteria.sort === "highToLow"}
                        onChange={handleFilterChange}
                      />
                      High To Low
                    </li>
                    <li className="text14">
                      <input
                        type="radio"
                        name="sort"
                        value="lowToHigh"
                        className="input-margin"
                        checked={filterCriteria.sort === "lowToHigh"}
                        onChange={handleFilterChange}
                      />
                      Low To High
                    </li>
                    <li className="text14">
                      <input
                        type="radio"
                        name="sort"
                        value="popularity"
                        className="input-margin"
                        checked={filterCriteria.sort === "popularity"}
                        onChange={handleFilterChange}
                      />
                      Popularity
                    </li>
                  </ul>
                </div>
                <div className="mb-3 ">
                  <label htmlFor="" className="filter-headers mb-1 text16 font700">
                    Enter Hotel Name
                  </label>
                  <input
                    type="text"
                    name="hotelName"
                    value={filterCriteria.hotelName}
                    onChange={handleFilterChange}
                    className="px-2 py-1 focus-outline"
                    style={{
                      borderRadius: "10px",
                      border: "1px solid lightgrey",
                    }}
                  />
                </div>
                <div className="mb-3">
                  <h6 className="filter-headers text18 font700">Filters</h6>
                </div>
                <div>
                  <ul className="list-none ps-0">
                    <li>
                      <h6 className="filters-inner text16 font700">Hotel Price Range</h6>
                    </li>
                    <li className="text14">
                      <input
                        type="radio"
                        name="priceRange"
                        value="lessThan10k"
                        className="input-margin"
                        checked={filterCriteria.priceRange === "lessThan10k"}
                        onChange={handleFilterChange}
                      />
                      Less than 10,000
                    </li>
                    <li className="text14">
                      <input
                        type="radio"
                        name="priceRange"
                        value="10kTo20k"
                        className="input-margin"
                        checked={filterCriteria.priceRange === "10kTo20k"}
                        onChange={handleFilterChange}
                      />
                      10,000 to 20,000
                    </li>
                    <li className="text14">
                      <input
                        type="radio"
                        name="priceRange"
                        value="20kTo40k"
                        className="input-margin"
                        checked={filterCriteria.priceRange === "20kTo40k"}
                        onChange={handleFilterChange}
                      />
                      20,000 to 40,000
                    </li>
                    <li className="text14">
                      <input
                        type="radio"
                        name="priceRange"
                        value="40kTo60k"
                        className="input-margin"
                        checked={filterCriteria.priceRange === "40kTo60k"}
                        onChange={handleFilterChange}
                      />
                      40,000 to 60,000
                    </li>
                    <li className="text14">
                      <input
                        type="radio"
                        name="priceRange"
                        value="60kTo80k"
                        className="input-margin"
                        checked={filterCriteria.priceRange === "60kTo80k"}
                        onChange={handleFilterChange}
                      />
                      60,000 to 80,000
                    </li>
                    <li className="text14">
                      <input
                        type="radio"
                        name="priceRange"
                        value="above80k"
                        className="input-margin"
                        checked={filterCriteria.priceRange === "above80k"}
                        onChange={handleFilterChange}
                      />
                      Above 80,000
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-9">
              {filteredHotels &&
                [...filteredHotels]?.reverse()?.map((ele) => {
                  return (
                    <div key={ele._id} className="top-border p-4 mb-2">
                      <div className="row gx-5">
                        <div className="col-lg-4 col-md-5 col-sm-6 text-center d-flex align-items-center">
                          <img
                            src={ele.hotel_photo[0]}
                            className="img-fluid w-100"
                            alt="hotel"
                            style={{ height: "200px" }}
                          />
                        </div>
                        <div className="col-lg-8 col-md-7 col-sm-6 d-flex flex-column justify-content-start pe-4">
                          <div className="">
                            <div className="align-items-top top_hotels_name mt-sm-0 mt-2 d-flex justify-content-between mb-3">
                              <h3 className="text18 font700">{ele.hotel_name}</h3>
                              <button
                                onClick={() => handleNavigate(ele._id)}
                                className="check-availibility-button text-20 border-0 "
                              >
                                View Details
                              </button>
                            </div>
                          </div>
                          <div className="d-flex top_hotels_map">
                            <a href="" className="top-a text14">
                              <FontAwesomeIcon icon={faLocationPin} />{" "}
                              {ele.hotel_address},{ele.city},{ele.state}{" "}
                              <span style={{ color: "#B8B8B8" }}>|</span>
                            </a>
                            {/* <a href="" className="top-ab px-1 text16">
                              View Map
                            </a> */}
                          </div>
                          <div className="pt-3 top_hotels_price_night">
                            <p className="text18 font700" style={{ color: "#09646D" }}>
                              <FontAwesomeIcon
                                icon={faIndianRupeeSign}
                                className="me-1"
                              />
                              {Math.round(ele.min_room_price)} / Person
                            </p>
                          </div>
                          <div className="top_hotels_star pt-2">
                            {Array.from({ length: Math.floor(ele.hotel_review) }, (_, i) => (
                              <FontAwesomeIcon key={i} icon={faStar} className="top_font_crl" />
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TopRateHotel;
