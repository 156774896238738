import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 10, // Display all 14 slides on desktop
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 7, // Display 7 slides on tablet
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3, // Display 3 slides on mobile
  },
};

const HotelHighlightsCarousel = ({ hotelDetail }) => (
  <Carousel
    responsive={responsive}
    swipeable
    draggable
    infinite
    arrows
    keyBoardControl
    showDots={false} // Enable this if you want navigation dots
  >
    {hotelDetail?.Highlights?.length > 0 ? (
      hotelDetail.Highlights.map((e, index) => (
        <div key={index} className="text-center">
          <img
            src={e.icon}
            alt={`Highlight icon: ${e.title}`}
            className="mb-2"
            style={{ height: "40px", width: "40px" }}
          />
          <p className="font600">{e.title}</p>
        </div>
      ))
    ) : (
      <p className="text-center">No highlights available</p>
    )}
  </Carousel>
);

export default HotelHighlightsCarousel;
