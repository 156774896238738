import {
  faLocationPin,
  faTag,
  faUser,
  faIndianRupeeSign,
  faPlaneArrival,
  faPlaneDeparture
} from "@fortawesome/free-solid-svg-icons"
import { faCalendar } from "@fortawesome/free-regular-svg-icons"
import Box from "@mui/material/Box"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import My_pannel from "./My_pannel"
import Header2 from "./Header2"
import { useNavigate } from "react-router-dom"
import { BASE_URL } from "../BASE_URL"
import ProfileSection from "./SecondNavbar"

const formatDate = (dateString) => {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return 'Invalid date format';
  }
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };
  return date.toLocaleDateString('en-GB', options);
};

function MyBid(props) {
  const navigate = useNavigate()
  const [showPackageData, setShowPackageData] = useState([])
  const [details, setDetails] = useState([])
  const [tabsCount, setTabsCount] = useState({});
  const [value, setValue] = useState(0)
  const getBidPackage = async () => {
    const token = localStorage.getItem("vendorToken")
    const res = await fetch(`${BASE_URL}bidpackage/agencybid`, {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    })
    const data = await res.json()
    if (res.status === 200) {
      setShowPackageData(data.data)
      setDetails(data.data)
    }
  }

  useEffect(() => {
    getBidPackage()
  }, [])

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setDetails([]);

    const currentDate = new Date(); // Get the current date
    const packageData = showPackageData.filter((e) => {
      const endDate = new Date(e.end_date); // Convert end_date to Date object

      switch (newValue) {
        case 0: // All
          return true;
        case 1: // Booked
          return e.bid_status === "booked";
        case 2: // Submit
          return e.bid_status === "submit" && endDate >= currentDate;
        case 3: // Reject
          return e.bid_status === "reject";
        case 4: // Save
          return e.bid_status === "save" && endDate >= currentDate;
        case 5: // Expire
          return endDate < currentDate && (e.bid_status === "submit" || e.bid_status === "save");
        case 6: // Booked Another Package
          return e.bid_status === "booked another package";
        default:
          return false;
      }
    });

    setDetails(packageData); // Set the filtered data based on the selected tab
  };

  useEffect(() => {
    const calculateTabsCount = () => {
        const currentDate = new Date(); // Get current date

        const allCount = showPackageData?.length; // All
        const bookedCount1 = showPackageData.filter((e) => e.bid_status === "booked").length; // Booked
        const submitCount = showPackageData.filter((e) => e.bid_status === "submit" && new Date(e.end_date) >= currentDate).length; // Submit
        const rejectCount = showPackageData.filter((e) => e.bid_status === "reject").length; // Reject
        const saveCount = showPackageData.filter((e) => e.bid_status === "save" && new Date(e.end_date) >= currentDate).length; // Save
        const expireCount = showPackageData.filter((e) => new Date(e.end_date) < currentDate && (e.bid_status === "submit" || e.bid_status === "save")).length; // Expire
        const bookedCount = showPackageData.filter((e) => e.bid_status === "booked another package").length; // Booked Another Package

        setTabsCount({
            0: allCount,
            1: bookedCount1,
            2: submitCount,
            3: rejectCount,
            4: saveCount,
            5: expireCount,
            6: bookedCount,
        });
    };

    calculateTabsCount();
}, [showPackageData]);


  const getStatusColor = (status) => {
    switch (status) {
      case "submit":
        return "green"
      case "reject":
        return "red"
      default:
        return "#ffc107"
    }
  }

  return (
    <>
      <Header2 />
      <div className='costum_container'>
        <My_pannel />
        <div>
          <ProfileSection title="My Bid List" />
          <section className='py-2' style={{ marginBottom: "10px" }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                overflowX: "auto",
              }}
            >
              <Tabs value={value} onChange={handleChange} aria-label='basic tabs example' variant="scrollable">
                <Tab label={`All (${tabsCount[0] || 0})`}
                  sx={{
                    borderBottom: `4px solid ${value === 0 ? 'green' : 'transparent'}`,
                    color: `${value === 0 ? '#09646d' : '#09646d'}`,
                  }}
                />
                <Tab
                  label={`Booked (${tabsCount[1] || 0})`}
                  sx={{
                    borderBottom: `4px solid ${value === 1 ? 'green' : 'transparent'}`,
                    color: `${value === 1 ? '#09646d' : '#09646d'}`,
                  }}
                />
                <Tab
                  label={`Submit (${tabsCount[2] || 0})`}
                  sx={{
                    borderBottom: `4px solid ${value === 2 ? 'green' : 'transparent'}`,
                    color: `${value === 2 ? '#09646d' : '#09646d'}`,
                  }}
                />
                <Tab
                  label={`Reject (${tabsCount[3] || 0})`}
                  sx={{
                    borderBottom: `4px solid ${value === 3 ? 'green' : 'transparent'}`,
                    color: `${value === 3 ? '#09646d' : '#09646d'}`,
                  }}
                />
                <Tab
                  label={`Save (${tabsCount[4] || 0})`}
                  sx={{
                    borderBottom: `4px solid ${value === 4 ? 'green' : 'transparent'}`,
                    color: `${value === 4 ? '#09646d' : '#09646d'}`,
                  }}
                />
                <Tab
                  label={`Expire (${tabsCount[5] || 0})`} // New Expire tab
                  sx={{
                    borderBottom: `4px solid ${value === 5 ? 'green' : 'transparent'}`,
                    color: `${value === 5 ? '#09646d' : '#09646d'}`,
                  }}
                />
                <Tab
                  label={`Booked Another Package (${tabsCount[6] || 0})`} // New tab
                  sx={{
                    borderBottom: `4px solid ${value === 6 ? 'green' : 'transparent'}`,
                    color: `${value === 6 ? '#09646d' : '#09646d'}`,
                  }}
                />
              </Tabs>
            </Box>

            <div className='row gx-5 gy-4 ms-auto ms-sm-auto ps-lg-3 overflow-hidden  ps-sm-0 mt-0 my-bid-object-margin-end'>
              {details &&
                details.map((ele, i) => {
                  return (
                    <>
                      <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12' key={ele._id} >
                        <div className='row only_for_green_border p-xl-3 p-lg-3 p-md-3 p-sm-3 p-2 costum_requirments'
                          style={{ backgroundColor: "#ffffff", cursor: "pointer", height: "auto" }}
                          onClick={() => navigate(`/display/custom/details/${ele.custom_requirement_id}`)}>
                          {/* <div className='col-1 px-xl-2 px-lg-0 px-md-0 px-sm-0 costum_requirments_span'>
                            <span className='cmnp cmnbkg'>{i + 1}</span>
                          </div> */}
                          <div className='col-xl-12 col-lg-11 col-md-11 col-11 costum_requirments_center'>
                            <div className='d-flex align-items-center justify-content-between pb-1'>
                              <p className='cmnp ps-1 text-capitalize text16 font600'>
                                <b>Status : </b>
                                <span style={{ color: getStatusColor(ele.bid_status), marginLeft: "10px", }}>
                                  {ele.bid_status}
                                </span>
                              </p>
                              <p className='cmnp ps-1 text-capitalize text16 font600'>
                                <b>Post On : </b>
                                <span style={{ color: getStatusColor(ele.bid_status), marginLeft: "10px" }}>
                                  {formatDate(ele.createdAt)}
                                </span>
                              </p>
                            </div>
                            <div className='d-flex align-items-center pt-2 py-1'>
                              <FontAwesomeIcon icon={faUser} className='cmnclr text16' />
                              <p className='cmnp ps-1 text-capitalize text16'>
                                {ele.full_name}
                              </p>
                            </div>
                            <div className='d-flex align-item-center pt-2 py-1'>
                              <FontAwesomeIcon icon={faPlaneDeparture} className='cmnclr text16' />
                              <p className='cmnp ps-1 text-capitalize text16'>
                                <b>Departure : </b> {ele.departure}
                              </p>
                            </div>
                            <div className='d-flex align-item-center pt-2 py-1'>
                              <FontAwesomeIcon icon={faPlaneArrival} className='cmnclr text16' />
                              <p className='cmnp ps-1 text-capitalize text16'>
                                <b>Destination : </b> {ele.destination}
                              </p>
                            </div>
                            <div className='d-flex align-item-center pt-2 py-1'>
                              <FontAwesomeIcon icon={faCalendar} className='cmnclr text16' />
                              <p className='cmnp ps-1 text16'>
                                <b>Travel Between : </b>
                                {formatDate(ele.start_date)} to {formatDate(ele.end_date)}
                              </p>
                            </div>
                          </div>
                          <div className='for_order_only mt-2'>
                            <button href='' className='costum_requirments_footer_button text16 py-2'>
                              <FontAwesomeIcon icon={faTag} className='me-2 text16' />
                              My Bid : <FontAwesomeIcon icon={faIndianRupeeSign} className='' /> {ele?.total_amount}
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                })}
            </div>
          </section>
        </div>
      </div>
    </>
  )
}

export default MyBid
