import React, { createContext, useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
import { BASE_URL } from "../BASE_URL";
import { useAuthContext } from "./AuthContext";

const SocketContext = createContext();

// export const useSocket = () => useContext(SocketContext);

export const useSocket = () => {
    return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
    const { authUser } = useAuthContext();

    useEffect(() => {

        if (authUser) {
            const socket = io("https://start-your-tour-api-1.onrender.com", {
                query: {
                    userId: localStorage.getItem("mySytUserId"),
                },
            });

            setSocket(socket);
        }
    }, [authUser]);

    return (
        <SocketContext.Provider value={{ socket }}>
            {children}
        </SocketContext.Provider>
    );
};
