import {
    faCheck,
    faIndianRupeeSign,
    faStar,
    faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Carousel, Card, Col, Row, Badge } from "react-bootstrap";
import Header from "./Header";
import Footer from "./Footer";
import { BASE_URL } from "../BASE_URL";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function BookedCarDetails() {
    const [pDetails, setPDetails] = useState([]);
    const { id } = useParams();

    const CarDetails = async () => {
        const token = localStorage.getItem("userToken");
        const res = await fetch(
            `${BASE_URL}car_booking_syt/displayDetails?car_booking_id=${id}`,
            {
                method: "GET",
                headers: {
                    Authorization: token,
                    "Content-Type": "application/json",
                },
            }
        );
        const data = await res.json();
        setPDetails(data.data);
    };

    useEffect(() => {
        CarDetails();
    }, []);

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    return (
        <div>
            <ToastContainer />
            <Header />
            
            {pDetails.map((ele) => (
                <div key={ele._id} className="container-customes padding-costume-container py-4">
                    <Row>
                        {/* Left Column - Car Images and Overview */}
                        <Col lg={8} md={12} className="mb-4">
                            <Carousel interval={3000} className="mb-4">
                                {ele.vendor_car_details.photos.map((photo, index) => (
                                    <Carousel.Item key={index}>
                                        <img
                                            className="d-block w-100 rounded"
                                            src={photo}
                                            alt={`Car slide ${index + 1}`}
                                            style={{ height: "500px", objectFit: "cover" }}
                                        />
                                    </Carousel.Item>
                                ))}
                            </Carousel>

                            <Card className="mb-4">
                                <Card.Body>
                                    <h4 className="mb-3">Car Overview</h4>
                                    <Row>
                                        <Col md={6}>
                                            <p><strong>Car Name:</strong> {ele.vendor_car_details.car_details.car_name}</p>
                                            <p><strong>Model:</strong> {ele.vendor_car_details.car_details.model_number}</p>
                                            <p><strong>Fuel Type:</strong> {ele.vendor_car_details.car_details.fuel_type}</p>
                                            <p><strong>Seats:</strong> {ele.vendor_car_details.car_details.car_seats}</p>
                                        </Col>
                                        <Col md={6}>
                                            <p><strong>Registration:</strong> {ele.registration_number}</p>
                                            <p><strong>Color:</strong> {ele.color}</p>
                                            <p><strong>AC:</strong> {ele.AC ? "Yes" : "No"}</p>
                                            <p><strong>Insurance:</strong> {ele.insurance ? "Yes" : "No"}</p>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>

                        {/* Right Column - Booking Details */}
                        <Col lg={4} md={12}>
                            <Card className="mb-4">
                                <Card.Body>
                                    <h4 className="mb-3">Booking Details</h4>
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <h5 className="mb-0">Status:</h5>
                                        <Badge bg={ele.status === "booked" ? "success" : "warning"} pill>
                                            {ele.status.toUpperCase()}
                                        </Badge>
                                    </div>
                                    
                                    <p><strong>Transaction ID:</strong> {ele.transaction_id}</p>
                                    <p><strong>Booking Date:</strong> {formatDate(ele.createdAt)}</p>
                                    <p><strong>Total Amount:</strong> 
                                        <FontAwesomeIcon icon={faIndianRupeeSign} className="ms-1" />
                                        {ele.amount}
                                    </p>

                                    <hr />

                                    <h5 className="mb-3">Trip Details</h5>
                                    <p><strong>Pickup:</strong> {ele.pickup_address}</p>
                                    <p><strong>Drop:</strong> {ele.drop_address}</p>
                                    <p><strong>Pickup Date:</strong> {formatDate(ele.pickup_date)}</p>
                                    <p><strong>Pickup Time:</strong> {ele.pickup_time}</p>
                                    {ele.one_way_two_way && (
                                        <p><strong>Return Date:</strong> {formatDate(ele.return_date)}</p>
                                    )}

                                    <hr />

                                    <h5 className="mb-3">Price Details</h5>
                                    <p><strong>Price Type:</strong> {ele.price_type.toUpperCase()}</p>
                                    {ele.price_type === "km" ? (
                                        <p><strong>Rate:</strong> ₹{ele.price_per_km}/km</p>
                                    ) : (
                                        <p><strong>Rate:</strong> ₹{ele.price_per_day}/day</p>
                                    )}
                                </Card.Body>
                            </Card>

                            <Card>
                                <Card.Body>
                                    <h4 className="mb-3">Agency Details</h4>
                                    <p><strong>Agency Name:</strong> {ele.agency_details.agency_name}</p>
                                    <p><strong>Contact:</strong> {ele.agency_details.mobile_number}</p>
                                    <p><strong>Email:</strong> {ele.agency_details.email_address}</p>
                                    <p><strong>Address:</strong> {ele.agency_details.city}, {ele.agency_details.state}</p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            ))}
            
            <Footer />
        </div>
    );
}

export default BookedCarDetails;