import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Header2 from "./Header2";
import My_pannel from "./My_pannel";
import { day } from "../App";
import { BASE_URL } from "../BASE_URL";
import loader from "../images/loading.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddItineriesEdit = () => {
  const { id, BidId } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const hotelType = location?.state?.hotelType;

  const { dayNumber } = useContext(day);
  const [showModal, setShowModal] = useState(false);
  const [hotelList, setHotelList] = useState([]);
  const [selectedHotel, setSelectedHotel] = useState(null);
  console.log(selectedHotel)
  const [roomId, setRoomId] = useState(null);

  const [itineriesData, setItineriesData] = useState({
    day: "",
    title: "",
    photo: "",
    activity: "",
  });

  const [editorData, setEditorData] = useState('');
  const [photoPreview, setPhotoPreview] = useState(null);  // State for photo preview

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
  };

  const txt = (e) => {
    const { name, value } = e.target;
    setItineriesData({ ...itineriesData, [name]: value });
  };

  const [error, setError] = useState(null);

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size > 5 * 1024 * 1024) {
      setError("File size should be less than 5MB.");
      e.target.value = '';
      setItineriesData({ ...itineriesData, photo: "" });
      setPhotoPreview("")
    } else {
      setError(null);
      setItineriesData({ ...itineriesData, photo: file });
      setPhotoPreview(URL.createObjectURL(file));  // Set the preview URL
    }
  };

  const getItinerary = async () => {
    const token = localStorage.getItem("vendorToken");
    const res = await fetch(`${BASE_URL}itinerary/byid?_id=${id}`, {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    });
    const data = await res.json();

    console.log(data.data)

    setRoomId(data.data?.[0]?.room_id)

    const hotelId = localStorage.getItem("selectedHotelId")

    const allData = data?.data?.[0];

    const selected = hotelList?.find((e) => e._id === hotelId)

    if (hotelId) {
      setSelectedHotel(selected);
    } else {
      setSelectedHotel(data.data?.[0]?.hotel_itienrary?.[0]);
    }
    setItineriesData(data.data);
    setEditorData(data?.data?.[0]?.activity);
    setPhotoPreview(data?.data?.[0]?.photo);
    setItineriesData({
      ...itineriesData,
      day: allData?.day,
      title: allData?.title,
      photo: allData?.photo
    });
  };

  const [selectedRoom, setSelectedRoom] = useState(null);
  const [selectedMeals, setSelectedMeals] = useState({
    breakfast: false,
    breakfast_price: 0,
    lunch: false,
    lunch_price: 0,
    dinner: false,
    dinner_price: 0,
  });

  useEffect(() => {
    const meals = localStorage.getItem("selectedMeals");
    const roomId = localStorage.getItem("selectedRoomId");
    if (meals) {
      setSelectedMeals(JSON.parse(meals));
    }
    setSelectedRoom(roomId);
  }, []);

  const AddItineriesDataEdit = async () => {
    const token = localStorage.getItem("vendorToken");

    const { day, title, photo } = itineriesData;

    if (!day || !title || !editorData) {
      toast.error("Please fill all details!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    if (!photo) {
      toast.error("Please select image!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    if (hotelType && hotelType !== "Not Required" && !selectedHotel?._id) {
      toast.error("Please fill all details!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    if (day > 10) {
      toast.error("Please Enter Valid Day!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    const formData = new FormData();
    formData.append("day", itineriesData.day);
    formData.append("title", itineriesData.title);
    formData.append("activity", editorData);
    // Set hotel and room details if required
    if (hotelType !== "Not Required") {
      formData.append("hotel_itienrary_id", selectedHotel?._id);
      formData.append("room_id", selectedRoom); // Pass room ID
    }

    // Pass meal prices dynamically
    formData.append("breakfast_price", selectedMeals.breakfast ? selectedMeals.breakfast_price : 0);
    formData.append("lunch_price", selectedMeals.lunch ? selectedMeals.lunch_price : 0);
    formData.append("dinner_price", selectedMeals.dinner ? selectedMeals.dinner_price : 0);

    if (itineriesData.photo) {
      formData.append("photo", itineriesData.photo);
    }
    setLoading(true);
    const res = await fetch(
      `${BASE_URL}itinerary/biditinerary?bid_id=${BidId}&day=${dayNumber}`,
      {
        method: "PUT",
        headers: {
          Authorization: token,
        },
        body: formData,
      }
    );
    if (res.status === 200) {
      localStorage.removeItem("selectedRoomId")
      localStorage.removeItem("selectedMeals")
      localStorage.removeItem("selectedHotelId")
      setLoading(false);
      toast.success("Itinerary Updated Successfully!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      setTimeout(() => {
        setLoading(false);
        navigate(-1);
      }, 1000);
    } else {
      setLoading(false);
      toast.error(res?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const fetchItineraryHotel = async () => {
    const token = localStorage.getItem("vendorToken");
    const res = await fetch(`${BASE_URL}api/hotel_itienrary/displayAgencyById`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    const data = await res.json();
    setHotelList(data?.data);
    getItinerary();
  };

  useEffect(() => {
    fetchItineraryHotel();
  }, [id]);


  const handleNavigate = () => {
    navigate('/vendor/select-hotel', { state: { hotelId: selectedHotel?._id, roomId: roomId } });
  }

  return (
    <>
      <Header2 />
      <ToastContainer />
      <div className='costum_container'>
        <My_pannel />
        <section className='vendor_submit_packages'>
          <div className='costum_container'>
            <div className='p-3'>
              <div>
                <Row className='itinerary_padding green_border gy-2 gx-5 margin_left_right justify-content-center' style={{ backgroundColor: "#ffffff" }}>
                  <div className='col-12 mb-3'>
                    <div className='row'>
                      <div className='col-12'>
                        <span className='text-20 itinerary_header text20 font700'>
                          Day wise Itinerary plan
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='row d-flex justify-content-center'>
                    <div className='col-12' style={{ border: "1px solid grey", borderRadius: "15px" }}>
                      <div className='p-3'>
                        <div>
                          <div className='mb-2'>
                            <label htmlFor='text' className="text14 font600 mb-1">Day</label>
                            <input
                              type='text'
                              name='day'
                              onChange={txt}
                              value={itineriesData.day}
                              readOnly
                            />
                          </div>
                          <div className='mb-2'>
                            <label htmlFor='text' className="text14 font600 mb-1">Update Title</label>
                            <input
                              type='text'
                              name='title'
                              onChange={txt}
                              value={itineriesData.title}
                            />
                          </div>
                          <div className='mb-2'>
                            <label htmlFor='text' className="text14 font600 mb-1">Update Photo  ( File Size Max 5MB )</label>
                            <input type='file' name='photo' accept="image/*" onChange={handlePhotoChange} />
                            {error && <p className="mb-0 text12" style={{ color: 'red' }}>{error}</p>}
                            {photoPreview && (
                              <div style={{ marginTop: "10px" }}>
                                <img
                                  src={photoPreview}
                                  alt="Selected"
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                    borderRadius: "50%",
                                    objectFit: "cover"
                                  }}
                                />
                              </div>
                            )}
                          </div>
                          {hotelType === "Not Required" ? (
                            <></>
                          ) : (
                            <div className='mb-2'>
                              <label htmlFor='text' className="text14 font600 mb-1">Update Hotel</label>
                              <div className='d-flex align-items-center'>
                                <input
                                  type='text'
                                  name='select_hotel'
                                  onChange={txt}
                                  className='flex-grow-1'
                                  value={selectedHotel ? selectedHotel.hotel_name : ""}
                                  readOnly
                                />
                                <Button
                                  variant='primary'
                                  className='ms-2'
                                  style={{
                                    backgroundColor: "#09646d"
                                  }}
                                  onClick={handleNavigate}
                                // onClick={() => setShowModal(true)}
                                >
                                  +
                                </Button>
                              </div>
                            </div>
                          )}
                          <div className='mb-2 ckeditor-p-tag d-flex flex-column gap-1'>
                            <label htmlFor='text' className="text14 font600" style={{ textDecoration: "none", color: "black" }}>Update Description/Activity</label>
                            <CKEditor
                              editor={ClassicEditor}
                              data={editorData}
                              onChange={handleEditorChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div style={{ display: "flex", justifyContent: "end" }}>
                        {loading ? (
                          <button
                            className='m-3'
                            style={{
                              width: "100px",
                              height: "35px",
                              backgroundColor: "#09646d",
                              border: "none",
                              color: "white",
                              borderRadius: '10px'
                            }}
                          >
                            <img src={loader} alt="" className="h-75 spinner" />
                          </button>
                        ) : (
                          <button
                            className='m-3 check-availibility-button text-20 border-0'
                            onClick={AddItineriesDataEdit}
                          >
                            Submit
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </Row>

                {/* Modal */}
                <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                  <Modal.Body
                    style={{
                      maxHeight: '70vh',    // Sets the maximum height to 70% of the viewport height
                      overflowY: 'auto'     // Enables vertical scrolling
                    }}
                  >
                    <div>
                      <h5 className="mb-0 mt-2 ms-2">Select Hotel</h5>
                      <hr />
                      {hotelList && hotelList.map((e, index) => (
                        <div
                          key={index}
                          className="d-flex align-items-start mb-3 mx-4 gap-3 itinerary-hotel-select-list"
                        >
                          <input
                            type="radio"
                            name="hotel"
                            className="form-check-input mb-1"
                            id={e.hotel_name}
                            checked={selectedHotel && selectedHotel._id === e._id}
                            onChange={() => {
                              setSelectedHotel(e);
                              setShowModal(false);
                            }}
                          />
                          <label
                            htmlFor={e.hotel_name}
                            className="form-check-label"
                          >
                            {e.hotel_name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AddItineriesEdit;
