import React, { useState, useEffect, useContext } from "react"
import Hearer from "./Header"
import { Form, Modal, Row } from "react-bootstrap"
import "../Project/css/index1.css"
import "../Project/css/custom_package.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faStar,
  faUserXmark,
  faIndianRupeeSign
} from "@fortawesome/free-solid-svg-icons"

import { useLocation, useParams } from "react-router-dom"
import { bidContext } from "../App"
import { BASE_URL } from "../BASE_URL"
import Footer from "./Footer"
import { useRef } from "react"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { parse, formatISO } from 'date-fns';
import countries from "../CountryStateCity.json"
import { useNavigate } from "react-router-dom"
import Package_Details from "./package-details"

const formatDate = (dateString) => {
  // Split the date by '/'
  const [day, month, year] = dateString.split('/');

  // Rearrange to 'yyyy/mm/dd'
  return `${year}-${month}-${day}`;
};

function Custom_packega(props) {
  const [show, setShow] = useState(false)
  const [show1, setShow1] = useState(false)
  const [bidDetails, setBidDetails] = useState([])
  const { bidData, setBidData } = useContext(bidContext)
  const navigate = useNavigate()
  const [fullname, setFullname] = useState("")
  const [departure, setDeparture] = useState("")
  const [email, setEmail] = useState("")
  const [number, setNumber] = useState("")
  const [customeCity, setCustomeCity] = useState("")
  const [customeState, setCustomeState] = useState("")
  const [Adult, setAdult] = useState(bidDetails.total_adult)
  const [Child, setChild] = useState(bidDetails.total_child)
  const [Infant, setInfant] = useState(bidDetails.total_infant)
  const [Night, setNight] = useState(bidDetails.total_nights)
  const [Day, setDay] = useState(bidDetails.total_days)
  const [Meal, setMeal] = useState(bidDetails.meal_required)
  const [Mealtype, setMealtype] = useState(bidDetails.meal_types)
  const [Sightseeing, setSightseeing] = useState(bidDetails.siteseeing)
  const [Vehicle, setVehicle] = useState(bidDetails.travel_by)
  const [Hoteltype, setHoteltype] = useState(bidDetails.hotel_types)
  const [personalCare, setPersonalcare] = useState("")
  const [bookedInclude, setBookedInclude] = useState("")
  const [bookedExclude, setBookedExclude] = useState("")
  const [requirment, setRequirment] = useState("")
  const [itenarary1, setItenerary] = useState("")
  const [PriceperPerson, setPriceperPerson] = useState(bidDetails.price_per_person)
  const [category, setcategory] = useState(bidDetails.destination_category)
  const [CustomId, setCustomId] = useState(bidDetails.custom_requirement_id)
  const [BidId, setBidId] = useState(bidDetails._id)
  const [booking, setBooking] = useState("")
  const [margin, setMargin] = useState(null)
  const [arrivalDate, setArrivalDate] = useState("")
  const [endDate, setEndDate] = useState(null)
  
  const handleArrivalChange = (e) => {
    const selectedDate = new Date(e.target.value);
    const totalDays = bidDetails?.[0]?.total_days || 0;
    const totalNights = bidDetails?.[0]?.total_nights || 0;

    // Determine the larger value between total_days and total_nights
    const duration = Math.max(totalDays, totalNights);

    // Calculate the end date by adding the duration to the selectedDate
    const endDate = new Date(selectedDate);
    endDate.setDate(selectedDate.getDate() + duration);

    // Set the arrival and end dates
    setArrivalDate(e.target.value);
    setEndDate(endDate.toISOString().split('T')[0]); // Format as 'YYYY-MM-DD'
  };


  const currentDate = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(currentDate.getDate() + 1);
  const formattedTomorrow = tomorrow.toISOString().split("T")[0];

  const [load, setLoad] = useState("")

  const [profileData, setProfileData] = useState("")
  const [agencyName, setAgencyName] = useState("")
  const [agencyId, setAgencyId] = useState("")

  const [agencyAverageStar, setAgencyAverageStar] = useState(0);
  const [agencyTotalReview, setAgencyTotalReview] = useState(0);

  const selectedStates = countries?.find((e) => e?.name === "India")
  const [cities, setCities] = useState([])

  useEffect(() => {
    if (profileData) {

      const selectedCity = selectedStates?.states && selectedStates?.states?.find((e) => e?.name === profileData.state)
      setCities(selectedCity?.cities)

      setFullname(profileData.full_name)
      setEmail(profileData.email_address)
      setNumber(profileData.mobile_no)
      setCustomeCity(profileData.city)
      setCustomeState(profileData.state)
    }
  }, [profileData])

  const token = localStorage.getItem("userToken")
  const ShowPackage = async () => {
    const res = await fetch(`${BASE_URL}customrequirements`, {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    })
    const data = await res.json()

    let matchedObject = null

    data.data.forEach(item => {
      if (item.custom_requirement._id === CustomId) {
        matchedObject = item
      }
    })

    if (matchedObject) {
      setProfileData(matchedObject.custom_requirement)
    }
  }

  useEffect(() => {
    ShowPackage()
  }, [CustomId])

  const { id } = useParams()
  const { merchentId } = useParams()

  const handleName = (e) => {
    setFullname(e.target.value)
  }
  const handleEmail = (e) => {
    setEmail(e.target.value)
  }
  const handleNumber = (e) => {
    setNumber(e.target.value)
  }
  const handleState = (e) => {
    const selected = e.target.value
    setCustomeState(e.target.value)
    const selectedCity = selectedStates?.states && selectedStates?.states?.find((e) => e?.name === selected)
    setCities(selectedCity?.cities)
  }
  const handleCity = (e) => {
    setCustomeCity(e.target.value)
  }

  const handleAdultChange = (e) => setAdult(parseInt(e.target.value) || 0);
  const handleChildChange = (e) => setChild(parseInt(e.target.value) || 0);
  const handleInfantChange = (e) => setInfant(parseInt(e.target.value) || 0);

  const [privacy, setPrivacy] = useState([])

  const privacypolicies = async () => {
    const res = await fetch(`${BASE_URL}policy`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    const data = await res.json()
    setPrivacy(data.data[2])
  }

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const handleClose1 = () => setShow1(false)
  const handleShow1 = () => setShow1(true)

  const [dataSet, setDataSet] = useState(false);
  const [minLimit, setMinLimit] = useState(null)
  const [maxLimit, setMaxLimit] = useState(null)

  const DetailsBid = async () => {
    const res = await fetch(`${BASE_URL}bidpackage/biddetails?_id=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })

    const data = await res.json()

    if (data.code === 200) {
      const margin = data?.data?.[0]?.admin_margin?.margin_percentage / 100

      setArrivalDate(formatDate(data?.data?.[0]?.start_date1))
      setMinLimit(formatDate(data?.data?.[0]?.start_date1))
      setMaxLimit(formatDate(data?.data?.[0]?.end_date1))
      setMargin(margin)
      setLoad(data?.data?.start_date1)
      setBidDetails(data.data)
      setBooking(data.data[0])
      setAdult(data.data[0].total_adult)
      setChild(data.data[0].total_child)
      setInfant(data.data[0].Infant)
      setNight(data.data[0].total_nights)
      setDay(data.data[0].total_days)
      setMeal(data.data[0].meal_required)
      setMealtype(data.data[0].meal_types)
      setSightseeing(data.data[0].sightseeing)
      setVehicle(data.data[0].travel_by)
      setHoteltype(data.data[0].hotel_types)
      setPriceperPerson(data.data[0].price_per_person)
      setcategory(data.data[0].destination_category.split(","))
      setCustomId(data.data[0].custom_requirement_id)
      setBidId(data.data[0]._id)
      setPersonalcare(data?.data?.[0]?.personal_care)
      setBookedInclude(data?.data?.[0]?.include_services)
      setBookedExclude(data?.data?.[0]?.exclude_services)
      setRequirment(data?.data?.[0]?.additional_requirement)
      setItenerary(data?.data?.[0]?.itineries.map(ele => ele._id))
      setAgencyName(data?.data?.[0]?.agency_name)
      setAgencyId(data?.data?.[0]?.agency_id)
      setDataSet(true);
    }

  }

  // useEffect(() => {
  //   if (dataSet && merchentId) {
  //     LastSubmit();
  //     setDataSet(false); 
  //   }
  // }, [dataSet, merchentId]);

  const AgencyReviews = async () => {
    const res = await fetch(`${BASE_URL}review/agencyallreview?_id=${agencyId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
    })

    const data = await res.json()

    setAgencyAverageStar((data.metadata.average_star_rating)?.toFixed(1));
    setAgencyTotalReview(data.metadata.total_review);
  }

  useEffect(() => {
    if (agencyId) {
      AgencyReviews()
    }
  }, [agencyId])

  const totalPerson = Adult + Child + Infant

  useEffect(() => {

    const userDetails = async () => {
      const token = localStorage.getItem("token");
      const res = await fetch(
        `${BASE_URL}payment/payment_status?merchantTransactionId=${merchentId}`,
        {
          method: "GET",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await res.json();

      if (merchentId !== null && merchentId !== undefined && merchentId !== "") {
        LastSubmit(data?.data?.merchantTransactionId, data?.data?.paymentInstrument?.type)
      }
    };


    userDetails();
  }, [merchentId]);

  const LastSubmit = async (tranjectionId, paymentType) => {
    const token = localStorage.getItem("userToken");
    const bidDetails = JSON.parse(localStorage.getItem("bidDetails"));

    const updatedBidDetails = {
      ...bidDetails,
      transaction_id: tranjectionId,
      payment_type: paymentType,
    };

    const res = await fetch(`${BASE_URL}bookpackage`, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedBidDetails),
    });

    const data = await res.json();

    if (data.code === 200) {
      toast.success("Package Booked Successfully!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      navigate("/my_package");
      localStorage.removeItem("bidDetails");
      handleClose();
    }
  };


  const location = useLocation();

  const handlePrice = async () => {

    // Validation: Empty Fields
    if (departureCountry.trim() === "") {
      toast.error("Please Enter Departure Country!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      return;
    }
    if (departureState.trim() === "") {
      toast.error("Please Enter Departure State!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      return;
    }
    if (departureCity.trim() === "") {
      toast.error("Please Enter Departure City!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      return;
    }

    if (!arrivalDate) {
      toast.error("Please Select Destination Arrival Date!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      return;
    }

    navigate(`/booking-payment/${id}`);

    const bidData = {
      bid_package_id: BidId,
      custom_package_id: CustomId,
      total_adult: Adult,
      total_child: Child,
      total_infant: Infant,
      total_amount: grandTotal,
      price_per_person_infant: finalAmountPerInfant,
      price_per_person_child: finalAmountPerChild,
      price_per_person_adult: finalAmountPerAdult,
      meal: Meal,
      meal_type: Mealtype,
      totalnight: Night,
      totaldays: Day,
      hoteltype: Hoteltype,
      room_sharing: booking?.room_sharing,
      siteseeing: Sightseeing,
      total_person: totalPerson,
      departure: departure,
      category: category,
      transport_mode: Vehicle,
      approx_start_date: bidDetails?.[0]?.start_date1,
      approx_end_date: bidDetails?.[0]?.end_date1,
      othere_requirement: requirment,
      personal_care: personalCare,
      booked_include: bookedInclude,
      booked_exclude: bookedExclude,
      booked_itinerary: itenarary1,
      destination: profileData.destination,
      other_services_by_agency: bidDetails?.[0]?.other_services_by_agency,
      destination_arrival_date: arrivalDate,
      priceFromVendorAdult: bidDetails?.[0]?.price_per_person_adult,
      priceFromVendorChild: bidDetails?.[0]?.price_per_person_child,
      priceFromVendorInfant: bidDetails?.[0]?.price_per_person_infant,
      priceFromVendorTotal: grandTotalVendor,
      packageType: bidDetails?.[0]?.package_type,
      title: bidDetails?.[0]?.name,
      endDate: endDate,
      startDate: arrivalDate,
      margin: booking?.admin_margin,
      contact_number: number,
      email_id: email,
      user_name: fullname,
      city: customeCity,
      state: customeState,

      transport_include_exclude: "no",

      priceperperson: 0,
    };

    localStorage.setItem('bidDetails', JSON.stringify(bidData));
  }

  const handleReject = async (e) => {
    e.preventDefault()
    const token = localStorage.getItem("userToken")

    const res = await fetch(`${BASE_URL}bidpackage/updateBidStatus/${id}`, {
      method: "PUT",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        bid_status: "reject",
      }),
    })
    const data = await res.json()
    if (data.code === 200) {
      toast.success("Bid Reject Successfully!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      })
      DetailsBid()
      handleClose1()
      navigate("/custom_requirement")
    }
  }

  const overRef = useRef(null)
  const itenarary = useRef(null)
  const hotels = useRef(null)
  const services = useRef(null)

  const scrollTo = (section) => {
    let targetRef

    switch (section) {
      case "overview":
        targetRef = overRef
        break
      case "services":
        targetRef = services
        break
      case "itenarary":
        targetRef = itenarary
        break
      case "hotels":
        targetRef = hotels
        break
      case "privacy":
        targetRef = privacy
        break
      default:
        targetRef = null
    }

    if (targetRef) {
      window.scroll({
        top: targetRef.current.offsetTop,
        behavior: "smooth",
      })
    }
  }

  useEffect(() => {
    privacypolicies()
    DetailsBid()
  }, []);

  // const finalAmountPerAdult = bidDetails?.[0]?.price_per_person_adult + (bidDetails?.[0]?.price_per_person_adult * margin)
  // const finalAmountPerChild = bidDetails?.[0]?.price_per_person_child + (bidDetails?.[0]?.price_per_person_child * margin)
  // const finalAmountPerInfant = bidDetails?.[0]?.price_per_person_infant + (bidDetails?.[0]?.price_per_person_infant * margin)
  // const grandTotal = (finalAmountPerAdult * Number(bidDetails?.[0]?.total_adult)) + (finalAmountPerChild * Number(bidDetails?.[0]?.total_child)) + (finalAmountPerInfant * Number(bidDetails?.[0]?.Infant))
  // const grandTotalVendor = (bidDetails?.[0]?.price_per_person_adult * Number(bidDetails?.[0]?.total_adult)) + (bidDetails?.[0]?.price_per_person_child * Number(bidDetails?.[0]?.total_child)) + (bidDetails?.[0]?.price_per_person_infant * Number(bidDetails?.[0]?.Infant))

  const pricePerPersonAdult = bidDetails?.[0]?.price_per_person_adult || 0;
  const pricePerPersonChild = bidDetails?.[0]?.price_per_person_child || 0;
  const pricePerPersonInfant = bidDetails?.[0]?.price_per_person_infant || 0;
  const totalAdult = Number(bidDetails?.[0]?.total_adult) || 0;
  const totalChild = Number(bidDetails?.[0]?.total_child) || 0;
  const totalInfant = Number(bidDetails?.[0]?.Infant) || 0;
  const marginValue = margin || 0;

  // Calculating final amounts per person type
  const finalAmountPerAdult = pricePerPersonAdult + (pricePerPersonAdult * marginValue);
  const finalAmountPerChild = pricePerPersonChild + (pricePerPersonChild * marginValue);
  const finalAmountPerInfant = pricePerPersonInfant + (pricePerPersonInfant * marginValue);

  // Calculating grand total and grand total for the vendor
  const grandTotal = (finalAmountPerAdult * totalAdult) + (finalAmountPerChild * totalChild) + (finalAmountPerInfant * totalInfant);
  const grandTotalVendor = (pricePerPersonAdult * totalAdult) + (pricePerPersonChild * totalChild) + (pricePerPersonInfant * totalInfant);


  const [departureCountry, setDepartureCountry] = useState("")
  const [departureState, setDepartureState] = useState("")
  const [departureCity, setDepartureCity] = useState("")

  const [selectedDepartureStates, setSelectedDepartureStates] = useState([])
  const [selectedDepartureCities, setSelectedDepartureCities] = useState([])

  const handleDepartureCountry = (e) => {
    const selectedCountry = e.target.value
    setDepartureCountry(selectedCountry)

    const selectedCountryObject = countries?.find((e) => e?.name === selectedCountry)
    setSelectedDepartureStates(selectedCountryObject?.states)
  }

  const handleDepartureState = (e) => {
    const selectedState = e.target.value
    setDepartureState(selectedState)

    const selectedStateObject = selectedDepartureStates?.find((e) => e?.name === selectedState)
    setSelectedDepartureCities(selectedStateObject?.cities)
  }

  const handleDepartureCity = (e) => {
    setDepartureCity(e.target.value)
  }

  useEffect(() => {
    setDeparture(`${departureCity}, ${departureState}, ${departureCountry}`);
  }, [departureCity, departureState, departureCountry]);

  return (
    <>
      <Hearer />
      <ToastContainer />
      {bidDetails.map((ele, index) => {
        const mealRequired = ele.meal_required.split(",")
        return (
          <Package_Details
            key={index}
            despature={ele.departure}
            destination={profileData.destination}
            adult={ele.total_adult}
            scrollTo={scrollTo}
            overRef={overRef}
            services={services}
            handleClose1={handleClose1}
            show1={show1}
            itenarary={itenarary}
            hotels={hotels}
            handleShow1={handleShow1}
            bidData={bidData}
            handleReject={handleReject}
            handleShow={handleShow}
            privacy={privacy}
            Infant={ele.Infant}
            kids={ele.total_child}
            priceAdult={finalAmountPerAdult}
            priceChild={finalAmountPerChild}
            priceInfants={finalAmountPerInfant}
            total_traveller={
              parseInt(ele.total_adult) +
              parseInt(ele.total_child) +
              parseInt(ele.Infant)
            }
            totalDays={ele.total_days}
            totalNights={ele.total_nights}
            end_date={ele.end_date1}
            start_date={ele.start_date1}
            hotel_types={ele.hotel_types}
            room_sharing={ele.room_sharing}
            hotel_itienrary={ele.hotel_itienrary}
            meal={mealRequired}
            mealRequired={Meal || ""}
            meal_types={ele.meal_types || ""}
            sightseeing={Sightseeing}
            travel_by={ele.travel_by}
            include_services={ele.include_services}
            exclude_services={ele.exclude_services}
            itineries={ele.itineries}
            price_per_person={ele.price_per_person}
            total_amount={grandTotal}
            travel_item={ele.travel_by}
            bid_status={ele.bid_status}
            personal_care={profileData.personal_care}
            additional_req={profileData.additional_requirement}
            custom_requirement_status={ele.custom_requirement_status}
            agency_name={ele.agency_name}
            agencyAverageStar={agencyAverageStar}
            agencyTotalReview={agencyTotalReview}
            otherServicesByAgency={ele?.other_services_by_agency}
            packageName={ele?.name}
            packageType={ele?.package_type}
            destinationCategory={ele?.destination_categories_name}
            bidStatus={ele?.bid_status}
            appStartDate={profileData?.start_date1}
            appEndDate={profileData?.end_date1}
          />
        )
      })}

      <Modal
        show={show1}
        onHide={handleClose1}
        backdrop='static'
        keyboard={false}
        centered
      >
        <Modal.Body className='pt-5 pb-4'>
          <p className="text-center"><FontAwesomeIcon icon={faUserXmark} /></p>
          <h5 className='mb-4 text-center'>Are you sure you want to reject?</h5>
          <div className='d-flex justify-content-center'>
            <button
              className='reject-modal reject-modal-no'
              onClick={handleClose1}
            >
              No
            </button>
            <button
              className='reject-modal reject-modal-yes ms-2'
              onClick={handleReject}
            >
              Yes
            </button>
          </div>
        </Modal.Body>
      </Modal>


      {/* ----------------------modal--------------------- */}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div>
            <Row>
              <div className='col-md-12 col-12'>
                <form>
                  <div
                    style={{
                      background: "linear-gradient(#00363D, #005C63)",
                      color: "#fff",
                      borderRadius: "8px",
                    }}
                    className='p-1 d-flex justify-content-between align-items-center'
                  >
                    <h4 className="text18 font600 ms-2" style={{ color: "white" }}>{bidDetails?.[0]?.name}</h4>
                    <div>
                      <span className='cmnp textcm'>
                        <FontAwesomeIcon
                          icon={faIndianRupeeSign}
                          className=''
                        />{" "}
                        {Math.round(finalAmountPerAdult)}
                      </span>
                      <p className='cmnp pb-1 textcm'>
                        Per person
                      </p>
                      <div className=' textcm'>
                        <FontAwesomeIcon icon={faStar} className='inner_star' />
                        <FontAwesomeIcon icon={faStar} className='inner_star' />
                        <FontAwesomeIcon icon={faStar} className='inner_star' />
                        <FontAwesomeIcon icon={faStar} className='inner_star' />
                        <FontAwesomeIcon icon={faStar} className='inner_star' />
                      </div>
                    </div>
                  </div>
                  <div className='cmn p-1 mt-3 px-3'>
                    <p className='text-center text20 font700 mb-3 mt-1'>Booking Details</p>
                    <div>
                      <div className="row gx-2">
                        <div>
                          <p className="mb-2 text14 font600">Departure Address :</p>
                        </div>
                        <div className="col-4">
                          <p className="mb-1 text14 font600">Select Country</p>
                          <Form.Select
                            style={{
                              borderLeft: "none",
                              borderRight: "none",
                              borderTop: "none",
                              fontSize: "14px",
                            }}
                            value={departureCountry}
                            onChange={handleDepartureCountry}
                            className='mt-1 my-2 py-2'
                            name='Departure'
                          >
                            <option value="">Select Country</option>
                            {countries && countries?.map((e) => (
                              <option value={e?.name}>{e.name}</option>
                            ))}
                          </Form.Select>
                        </div>

                        <div className="col-4">
                          <p className="mb-1 text14 font600">Select State</p>
                          <Form.Select
                            style={{
                              borderLeft: "none",
                              borderRight: "none",
                              borderTop: "none",
                              fontSize: "14px",
                            }}
                            value={departureState}
                            onChange={handleDepartureState}
                            className='mt-1 my-2 py-2'
                            name='Departure'
                          >
                            <option value="">Select State</option>
                            {selectedDepartureStates && selectedDepartureStates?.map((e) => (
                              <option value={e?.name}>{e.name}</option>
                            ))}
                          </Form.Select>
                        </div>

                        <div className="col-4">
                          <p className="mb-1 text14 font600">Select City</p>
                          <Form.Select
                            style={{
                              borderLeft: "none",
                              borderRight: "none",
                              borderTop: "none",
                              fontSize: "14px",
                            }}
                            value={departureCity}
                            onChange={handleDepartureCity}
                            className='mt-1 my-2 py-2'
                            name='Departure'
                          >
                            <option value="">Select City</option>
                            {selectedDepartureCities && selectedDepartureCities?.map((e) => (
                              <option value={e?.name}>{e.name}</option>
                            ))}
                          </Form.Select>
                        </div>
                      </div>
                      {/* <p className="mb-1 text14 font600">Departure</p>
                      <Form.Control
                        type='text'
                        placeholder='Departure'
                        style={{
                          borderLeft: "none",
                          borderRight: "none",
                          borderTop: "none",
                          fontSize: "14px",
                        }}
                        value={departure}
                        onChange={(e) => setDeparture(e.target.value)}
                        className='mt-1 my-2 py-2'
                        name='email'
                      // readOnly
                      /> */}

                      <p className="mb-1 text14 font600">Fullname</p>
                      <Form.Control
                        type='text'
                        placeholder='Full Name'
                        style={{
                          borderLeft: "none",
                          borderRight: "none",
                          borderTop: "none",
                          fontSize: "14px",
                        }}
                        value={fullname}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^A-Za-z ]/g,
                            "",
                          )
                          handleName(e)
                        }}
                        className='mt-1 my-2 py-2'
                        name='fname'
                      // readOnly
                      />

                      <p className="mb-1 text14 font600">Emailid</p>
                      <Form.Control
                        type='text'
                        placeholder='Email id'
                        style={{
                          borderLeft: "none",
                          borderRight: "none",
                          borderTop: "none",
                          fontSize: "14px",
                        }}
                        value={email}
                        onChange={handleEmail}
                        className='mt-1 my-2 py-2'
                        name='email'
                      // readOnly
                      />

                      <p className="mb-1 text14 font600">Mobile No.</p>
                      <Form.Control
                        type='text'
                        placeholder='Mobile Number'
                        style={{
                          borderLeft: "none",
                          borderRight: "none",
                          borderTop: "none",
                          fontSize: "14px",
                        }}
                        value={number}
                        onInput={(e) => {
                          // Remove any non-digit characters
                          let value = e.target.value.replace(/[^0-9]/g, "");

                          // Limit to 10 digits
                          if (value.length > 10) {
                            value = value.substring(0, 10);
                          }

                          // Update state with the formatted value
                          // handleNumber(value);
                          setNumber(value);
                        }}
                        className='mt-1 my-2 py-2'
                        name='mobile'
                      // readOnly
                      />

                      {/* <div className="row gx-2">
                        <div className="col-6">
                          <p className="mb-1 text14 font600">Select State</p>
                          <Form.Select
                            style={{
                              borderLeft: "none",
                              borderRight: "none",
                              borderTop: "none",
                              fontSize: "14px",
                            }}
                            value={customeState}
                            onChange={handleState}
                            className='mt-1 my-2 py-2'
                            name='Departure'
                          >
                            <option value="">Select State</option>
                            {selectedStates && selectedStates?.states?.map((e) => (
                              <option value={e?.name}>{e.name}</option>
                            ))}
                          </Form.Select>
                        </div>

                        <div className="col-6">
                          <p className="mb-1 text14 font600">Select City</p>
                          <Form.Select
                            style={{
                              borderLeft: "none",
                              borderRight: "none",
                              borderTop: "none",
                              fontSize: "14px",
                            }}
                            value={customeCity}
                            onChange={handleCity}
                            className='mt-1 my-2 py-2'
                            name='Departure'
                          >
                            <option value="">Select City</option>
                            {cities && cities?.map((e) => (
                              <option value={e?.name}>{e.name}</option>
                            ))}
                          </Form.Select>
                        </div>
                      </div> */}

                      <div className="row gx-2">
                        <div className="col-4">
                          <p className="mb-1 text14 font600">Adults</p>
                          <Form.Control
                            type="number"
                            value={Adult}
                            onChange={handleAdultChange}
                            disabled
                            min="0"
                            style={{
                              borderLeft: "none",
                              borderRight: "none",
                              borderTop: "none",
                              fontSize: "14px",
                            }}
                            placeholder='Adult'
                            className='mt-1 my-2 py-2'
                            name='adult'
                          />
                        </div>
                        <div className="col-4">
                          <p className="mb-1 text14 font600">Childrens</p>
                          <Form.Control
                            type="number"
                            value={Child}
                            onChange={handleChildChange}
                            min="0"
                            style={{
                              borderLeft: "none",
                              borderRight: "none",
                              borderTop: "none",
                              fontSize: "14px",
                            }}
                            placeholder='Kids'
                            className='mt-1 my-2 py-2'
                            name='kids'
                            disabled
                          />
                        </div>
                        <div className="col-4">
                          <p className="mb-1 text14 font600">Infants</p>
                          <Form.Control
                            type="number"
                            value={Infant}
                            onChange={handleInfantChange}
                            min="0"
                            style={{
                              borderLeft: "none",
                              borderRight: "none",
                              borderTop: "none",
                              fontSize: "14px",
                            }}
                            placeholder='Infants'
                            className='mt-1 my-2 py-2'
                            name='infant'
                            disabled
                          />
                        </div>
                      </div>

                      <div className="row gx-2">
                        <div className="col-6">
                          <p className="mb-1 text14 font600">Destination Arrival Date</p>
                          <Form.Control
                            type="date"
                            value={arrivalDate}
                            onChange={handleArrivalChange}
                            min={formattedTomorrow}
                            max={maxLimit}
                            style={{
                              borderLeft: "none",
                              borderRight: "none",
                              borderTop: "none",
                              fontSize: "14px",
                            }}
                            placeholder='Infants'
                            className='mt-1 my-2 py-2'
                            name='infant'
                          />
                        </div>
                        <div className="col-6">
                          <p className="mb-1 text14 font600">End Date</p>
                          <Form.Control
                            type="date"
                            value={endDate}
                            disabled
                            style={{
                              borderLeft: "none",
                              borderRight: "none",
                              borderTop: "none",
                              fontSize: "14px",
                            }}
                            placeholder='Infants'
                            className='mt-1 my-2 py-2'
                            name='infant'
                          />
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className='text-center pt-2'>
                    <p
                      className='w-100 border-0 py-2 font700'
                      style={{
                        background: "#09646D",
                        color: "#fff",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }}
                      onClick={() => handlePrice(booking.total_amount)}
                    >
                      Proceed to Pay
                    </p>
                  </div>
                </form>
              </div>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      <hr />
      <Footer />
    </>
  )
}

export default Custom_packega