import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBell,
    faEnvelopeOpen,
    faUser,
    faTrash,
    faPlus,
    faRightFromBracket
} from "@fortawesome/free-solid-svg-icons";
import My_pannel from "./My_pannel";
import Header2 from "./Header2";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../BASE_URL";
import { Button, Form, Row } from "react-bootstrap";
import loader from "../images/loading.png";
import countries from "../CountryStateCity.json";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProfileSection from "./SecondNavbar";
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import Modal from "react-bootstrap/Modal"

const ItineraryHotel = () => {
    const { _id } = useParams();
    const navigate = useNavigate();

    const states = countries.find((e) => e?.name === "India");
    const [cities, setCities] = useState([]);

    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [formData, setFormData] = useState({
        hotel_name: "",
        hotel_address: "",
        hotel_city: "",
        hotel_state: "",
        hotel_type: "",
        hotel_description: "",
        other: "",
        hotel_photos: [],
    });

    const [description, setDescription] = useState("")

    const [images, setImages] = useState([]);
    const fileInputRef = useRef(null);

    const handleAddImageClick = () => {
        fileInputRef.current.click();
    };

    const handleImageChange = (event) => {
        const files = Array.from(event.target.files);
        const fileObjects = files.map(file => ({ file, url: URL.createObjectURL(file) }));
        setImages(prevImages => [...prevImages, ...fileObjects]);
    };

    const handleRemoveImage = (indexToRemove) => {
        setImages(prevImages => prevImages.filter((_, index) => index !== indexToRemove));
    };

    const [editedata, setEditedData] = useState("");

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "hotel_state") {
            const selectedCity = states?.states?.find((e) => e?.name === value);
            setCities(selectedCity?.cities);
        }

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const Call = async () => {
        const token = localStorage.getItem("vendorToken");
        const res = await fetch(`${BASE_URL}agency/profile`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });
        const data = await res.json();
        setEditedData(data?.data?.[0]);
    };


    useEffect(() => {
        Call();
    }, []);

    const handleDelete = async () => {
        const token = localStorage.getItem("vendorToken");
        const res = await fetch(`${BASE_URL}api/hotel_itienrary/delete/${_id}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });

        if (res.ok) {
            handleClose11();
            setTimeout(() => {
                navigate("/vendor/hotels");
            }, 1000);
            toast.success("Hotel Itinerary Deleted Successfully!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
        } else {

        }
    };

    const [show11, setShow11] = useState(false)

    const handleClose11 = () => setShow11(false)
    const handleShow11 = () => setShow11(true)

    const [roomModalShow, setRoomModalShow] = useState(false)

    const roomModalClose = () => setRoomModalShow(false)
    const roomModalOpen = () => setRoomModalShow(true)

    const [roomType, setRoomType] = useState("");
    const [roomPrice, setRoomPrice] = useState("");
    const [rooms, setRooms] = useState([]);

    const handleRoomFormChange = (e) => {
        const { name, value } = e.target;
        if (name === "room_type") setRoomType(value);
        if (name === "room_price") setRoomPrice(value);
    };

    // Handle form submission
    const handleAddRoomData = () => {
        if (!roomType || !roomPrice) {
            alert("Please enter room type and price");
            return;
        }

        const newRoom = {
            room_type: roomType,
            room_price: roomPrice
        };

        setRooms([...rooms, newRoom]); // Add new room to array
        setRoomType(""); // Reset input fields
        setRoomPrice("");

        roomModalClose();

    };

    const [meals, setMeals] = useState({
        breakfast: { checked: false, price: 0 },
        lunch: { checked: false, price: 0 },
        dinner: { checked: false, price: 0 }
    });

    const handleMealChange = (meal) => {
        setMeals((prev) => ({
            ...prev,
            [meal]: { ...prev[meal], checked: !prev[meal].checked, price: 0 }
        }));
    };

    const handlePriceChange = (meal, value) => {
        setMeals((prev) => ({
            ...prev,
            [meal]: { ...prev[meal], price: value }
        }));
    };


    const fetchHotelData = async () => {
        const token = localStorage.getItem("vendorToken");
        const res = await fetch(`${BASE_URL}api/hotel_itienrary/displayById/${_id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });
        const data = await res.json();

        if (data?.success) {
            const dt = data?.data;

            const selectedCity = states?.states?.find((e) => e?.name === dt?.hotel_state);
            setCities(selectedCity?.cities);

            setImages(dt?.hotel_photo.map(url => ({ url })));

            setFormData({
                hotel_name: dt.hotel_name,
                hotel_address: dt.hotel_address,
                hotel_city: dt.hotel_city,
                hotel_state: dt.hotel_state,
                hotel_type: dt.hotel_type,
                other: dt.other,
                hotel_photos: dt.hotel_photo,
            });

            setDescription(dt.hotel_description);

            // Set rooms from API data
            setRooms(dt?.rooms?.map(room => ({
                room_type: room.room_type,
                room_price: room.room_type_price,
                _id: room._id
            })));

            // Set meal prices and checkboxes
            setMeals({
                breakfast: { checked: dt.breakfast_price > 0, price: dt.breakfast_price || "" },
                lunch: { checked: dt.lunch_price > 0, price: dt.lunch_price || "" },
                dinner: { checked: dt.dinner_price > 0, price: dt.dinner_price || "" }
            });
        }
    };

    // Call fetchHotelData on mount if _id exists
    useEffect(() => {
        if (_id) {
            fetchHotelData();
        }
    }, [_id]);


    const handleSubmit = async (e) => {

        console.log(meals)

        if (!formData.hotel_name && !formData.hotel_address && !formData.hotel_city && !formData.hotel_state && !formData.hotel_type && !description && images?.length === 0) {
            toast.error("Please Fill All Fields!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }

        if (!formData.hotel_name) {
            toast.error("Please Enter Hotel Name!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }

        if (!formData.hotel_type) {
            toast.error("Please Select Hotel Type!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }

        if (!formData.hotel_address) {
            toast.error("Please Enter Hotel Address!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }

        if (!formData.hotel_state) {
            toast.error("Please Select State!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }

        if (!formData.hotel_city) {
            toast.error("Please Select City!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }

        if (rooms?.length < 1) {
            toast.error("Enter Rooms Details!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }

        if (!description) {
            toast.error("Please Enter Description!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }

        if (images?.length === 0) {
            toast.error("Please Select Hotel Images!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }

        setLoading(true);

        const token = localStorage.getItem("vendorToken");
        const submitData = new FormData();

        submitData.append("hotel_name", formData.hotel_name);
        submitData.append("hotel_address", formData.hotel_address);
        submitData.append("hotel_city", formData.hotel_city);
        submitData.append("hotel_state", formData.hotel_state);
        submitData.append("hotel_type", formData.hotel_type);
        submitData.append("hotel_description", description);
        submitData.append("other", formData.other);

        Object.entries(meals).forEach(([mealName, mealData]) => {
            submitData.append(`${mealName}`, mealData.checked ? "true" : "false"); // Pass true/false
            submitData.append(`${mealName}_price`, mealData.checked ? Number(mealData.price) : 0); // Pass price or 0
        });


        rooms.forEach((room, index) => {
            submitData.append(`rooms[${index}][room_type]`, room.room_type);
            submitData.append(`rooms[${index}][room_type_price]`, room.room_price);
        });

        // Append each image file individually
        if (_id) {
            // If _id exists, handle the update logic
            images.forEach(({ file, url }, index) => {
                if (file) {
                    submitData.append("hotel_photo", file); // Send file if available
                } else if (url) {
                    submitData.append("previmages", url); // Send URL if file is not available
                }
            });
        } else {
            // If _id does not exist, handle the create logic
            images.forEach(({ file }, index) => {
                submitData.append("hotel_photo", file); // Send file key only
            });
        }

        if (_id) {
            try {
                const res = await fetch(`${BASE_URL}api/hotel_itienrary/update/${_id}`, {
                    method: "PUT",
                    headers: {
                        Authorization: token,
                    },
                    body: submitData,
                });

                const result = await res.json();

                setLoading(false);
                if (result?.success) {
                    setTimeout(() => {
                        navigate("/vendor/hotels");
                    }, 1000);
                    toast.success("Hotel Itinerary Updated Successfully!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 1000,
                    });
                }
            } catch (error) {

            } finally {
                setLoading(false);
            }
        } else {
            try {
                const res = await fetch(`${BASE_URL}api/hotel_itienrary/create`, {
                    method: "POST",
                    headers: {
                        Authorization: token,
                    },
                    body: submitData,
                });

                const result = await res.json();

                setLoading(false);
                if (result?.success) {
                    setTimeout(() => {
                        navigate("/vendor/hotels");
                    }, 1000);
                    toast.success("Hotel Itinerary Added Successfully!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 1000,
                    });
                }
            } catch (error) {

            } finally {
                setLoading(false);
            }
        }
    };

    const handleDeleteRoom = (index) => {
        // Remove room at the specified index
        const updatedRooms = rooms.filter((room, i) => i !== index);
        setRooms(updatedRooms); // Assuming you're using state for rooms
    };


    return (
        <>
            <Header2 />
            <div className="costum_container bg-white">
                <My_pannel />
                <ToastContainer />
                <div style={{ backgroundColor: "white", height: "100vh" }}>
                    <ProfileSection title="Itinerary Hotels" />
                    <Row className="bg-white justify-content-center">
                        <div className="col-11">
                            <div className="inner_green_border py-3 mt-5 mb-4 px-4">
                                <div className="row">
                                    <div className="col-md-6 col-sm-12 col-12 margin_left_right">
                                        <p className="mb-1 vendoraddpackage-label text14 mt-2">
                                            Hotel name
                                        </p>
                                        <input
                                            type="text"
                                            name="hotel_name"
                                            id=""
                                            className="mb-2"
                                            style={{ width: "100%" }}
                                            value={formData.hotel_name}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="col-md-6 col-sm-12 col-12 margin_left_right">
                                        <p className="mb-1 vendoraddpackage-label text14 mt-2">
                                            Hotel Type
                                        </p>
                                        <select
                                            name="hotel_type"
                                            id=""
                                            className="mb-2"
                                            style={{ width: "100%" }}
                                            value={formData.hotel_type}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">select</option>
                                            <option value="5 star">5 star</option>
                                            <option value="4 star">4 star</option>
                                            <option value="3 star">3 star</option>
                                            <option value="2 star">2 star</option>
                                            <option value="1 star">1 star</option>
                                        </select>
                                    </div>
                                    <div className="col-md-6 col-sm-12 col-12">
                                        <p className="mb-1 vendoraddpackage-label text14 mt-2">Meals</p>
                                        <div className="meal-options" style={{ display: "flex", flexDirection: "column", gap: "10px", borderRadius: "8px" }}>
                                            {["breakfast", "lunch", "dinner"].map((meal) => (
                                                <div key={meal} className="meal-checkbox" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "8px", borderRadius: "6px", background: "#fff", boxShadow: "0 2px 4px rgba(0,0,0,0.1)" }}>
                                                    <div className="d-flex" style={{ alignItems: "center", gap: "10px" }}>
                                                        <input
                                                            type="checkbox"
                                                            id={meal}
                                                            checked={meals[meal].checked}
                                                            onChange={() => handleMealChange(meal)}
                                                            style={{ width: "18px", height: "18px", cursor: "pointer" }}
                                                        />
                                                        <label htmlFor={meal} className="text14" style={{ fontSize: "16px", fontWeight: "500", cursor: "pointer" }}>
                                                            {meal.charAt(0).toUpperCase() + meal.slice(1)}
                                                        </label>
                                                    </div>
                                                    <div>
                                                        {meals[meal].checked && (
                                                            <input
                                                                type="number"
                                                                className="meal-price-input"
                                                                placeholder="Enter Price"
                                                                value={meals[meal].price}
                                                                onChange={(e) => handlePriceChange(meal, e.target.value)}
                                                                style={{
                                                                    padding: "6px",
                                                                    width: "120px",
                                                                    borderRadius: "5px",
                                                                    border: "1px solid #ccc",
                                                                    outline: "none",
                                                                    fontSize: "14px",
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 col-12 margin_left_right">
                                        <p className="mb-1 vendoraddpackage-label text14 mt-2">
                                            Hotel address
                                        </p>
                                        <textarea
                                            name="hotel_address"
                                            id=""
                                            style={{
                                                width: "100%",
                                                height: "150px",
                                                padding: "10px",
                                            }}
                                            value={formData.hotel_address}
                                            onChange={handleInputChange}
                                        ></textarea>
                                        {/* <input
                                            type="text"
                                            name="hotel_address"
                                            id=""
                                            className="mb-2"
                                            style={{ width: "100%" }}
                                            value={formData.hotel_address}
                                            onChange={handleInputChange}
                                        /> */}
                                    </div>
                                    <div className="col-md-6 col-sm-12 col-12 margin_left_right">
                                        <div className="hotel-itinerary-state-city-grid">
                                            <div className="margin_left_right">
                                                <p className="mb-1 vendoraddpackage-label text14 mt-2">
                                                    Hotel state
                                                </p>
                                                <select
                                                    name="hotel_state"
                                                    id=""
                                                    className="mb-2"
                                                    style={{ width: "100%" }}
                                                    value={formData.hotel_state}
                                                    onChange={handleInputChange}
                                                >
                                                    <option value="">select</option>
                                                    {states?.states && states?.states.map((e) => (
                                                        <option value={e.name} key={e.name}>{e.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="margin_left_right">
                                                <p className="mb-1 vendoraddpackage-label text14 mt-2">
                                                    Hotel city
                                                </p>
                                                <select
                                                    name="hotel_city"
                                                    id=""
                                                    className="mb-2"
                                                    style={{ width: "100%" }}
                                                    value={formData.hotel_city}
                                                    onChange={handleInputChange}
                                                >
                                                    <option value="">select</option>
                                                    {cities && cities?.map((e) => (
                                                        <option value={e.name} key={e.name}>{e.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="hotel-itinerary-state-city-grid">
                                            <div>
                                                <p className="mb-1 font600 vendoraddpackage-label text14 mt-2">
                                                    Room Type
                                                </p>
                                            </div>
                                            <div>
                                                <div className="hotel-pictures-title-container">
                                                    <p className="mb-1 font600 vendoraddpackage-label text14 mt-2">
                                                        Price
                                                    </p>
                                                    <FontAwesomeIcon
                                                        icon={faPlus}
                                                        className="plus-icon"
                                                        onClick={roomModalOpen}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <hr className="mb-0" />

                                        {/* Display added rooms */}
                                        <div className="mt-1">
                                            {rooms.map((room, index) => (
                                                <div className="hotel-itinerary-state-city-grid" key={index}>
                                                    <div>
                                                        <p className="mb-1 vendoraddpackage-label text14 mt-2">
                                                            {index + 1}.{room?.room_type}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <div className="hotel-pictures-title-container">
                                                            <p className="mb-1 vendoraddpackage-label text14 mt-2">
                                                                {room?.room_price}
                                                            </p>
                                                            <FontAwesomeIcon
                                                                icon={faTrash}
                                                                style={{ color: "red" }}
                                                                className="mt-2"
                                                                onClick={() => handleDeleteRoom(index)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 col-12 margin_left_right">
                                        <p className="mb-1 vendoraddpackage-label text14 mt-2">
                                            Hotel description
                                        </p>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={description}
                                            onChange={(event, editor) => setDescription(editor.getData())}
                                        />
                                    </div>
                                    <div className="col-md-6 col-sm-12 col-12 margin_left_right">
                                        <p className="mb-1 vendoraddpackage-label text14 mt-2">
                                            Other
                                        </p>
                                        <textarea
                                            name="other"
                                            id=""
                                            style={{
                                                width: "100%",
                                                height: "150px",
                                                padding: "10px",
                                            }}
                                            value={formData.other}
                                            onChange={handleInputChange}
                                        ></textarea>
                                    </div>
                                    <div className="col-md-6 col-sm-12 col-12 margin_left_right mt-2">
                                        <section className="">
                                            <div className="hotel-pictures-container">
                                                <div className="hotel-pictures-title-container">
                                                    <p className="mb-1 vendoraddpackage-label text14 mt-2">
                                                        Hotel Pictures
                                                    </p>
                                                    <FontAwesomeIcon
                                                        icon={faPlus}
                                                        className="plus-icon"
                                                        onClick={handleAddImageClick}
                                                    />
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        ref={fileInputRef}
                                                        style={{ display: 'none' }}
                                                        onChange={handleImageChange}
                                                        multiple
                                                    />
                                                </div>
                                                <div className="hotel-pictures-section">
                                                    {images.map(({ url }, index) => (
                                                        <div key={index} className="hotel-pictures-item">
                                                            <img src={url} alt={`Hotel pic ${index + 1}`} className="hotel-image" />
                                                            <FontAwesomeIcon
                                                                icon={faTrash}
                                                                className="trash-icon"
                                                                onClick={() => handleRemoveImage(index)}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                    <div className="col-12">
                                        <div className="d-flex justify-content-end">
                                            {_id && (
                                                <button
                                                    className="check-availibility-button text-20 border-0 "
                                                    style={{
                                                        background: "red",
                                                        color: "white",
                                                    }}
                                                    onClick={handleShow11}
                                                >
                                                    Delete
                                                </button>
                                            )}
                                            {loading ? (
                                                <button
                                                    className="ms-2 check-availibility-button text-20 border-0 "
                                                    type="submit"
                                                    style={{
                                                        backgroundColor: "#155E75",
                                                    }}
                                                >
                                                    <img
                                                        src={loader}
                                                        alt=""
                                                        className="spinner"
                                                        style={{ height: "25px" }}
                                                    />
                                                </button>
                                            ) : (
                                                <button
                                                    className="ms-2 check-availibility-button text-20 border-0 "
                                                    style={{
                                                        backgroundColor: "#155E75",
                                                    }}
                                                    onClick={handleSubmit}
                                                >
                                                    Submit
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                </div>
            </div>

            <Modal
                show={show11}
                onHide={handleClose11}
                backdrop='static'
                keyboard={false}
                style={{ zIndex: "10101010" }}
                centered
            >
                <Modal.Body>
                    <div className='pt-3 pb-2 px-3 text-center'>
                        <FontAwesomeIcon
                            icon={faTrash}
                            className='mb-3'
                            style={{ fontSize: "30px" }}
                        />
                        <h5 className='text-center'>Are You Sure You Want To Delete?</h5>
                        <div className='logout-btn d-flex justify-content-center mt-3'>
                            <button
                                className='logout-btn-1'
                                variant='secondary'
                                onClick={handleClose11}
                            >
                                No
                            </button>
                            <button
                                className='logout-btn-2 ms-2'
                                variant='primary'
                                onClick={handleDelete}
                            >
                                Yes
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={roomModalShow}
                onHide={roomModalClose}
                backdrop='static'
                keyboard={false}
                style={{ zIndex: "10101010" }}
                centered
            >
                <Modal.Body>
                    <div className="pt-3 pb-2 px-3">
                        <div>
                            <h5 className="mb-4 font">Are You Sure You Want To Delete?</h5>
                        </div>

                        {/* Room Type Input Field */}
                        <div className="margin_left_right">
                            <p className="mb-1 vendoraddpackage-label text14 mt-2">Room Type</p>
                            <input
                                type="text"
                                name="room_type"
                                className="mb-2"
                                style={{ width: "100%" }}
                                value={roomType}
                                onChange={handleRoomFormChange}
                            />
                        </div>

                        {/* Price Input Field */}
                        <div className="margin_left_right">
                            <p className="mb-1 vendoraddpackage-label text14 mt-1">Room Price</p>
                            <input
                                type="number"
                                name="room_price"
                                className="mb-2"
                                style={{ width: "100%" }}
                                value={roomPrice}
                                onChange={handleRoomFormChange}
                                min="0"
                                step="1"  // Ensures only whole numbers
                                onKeyDown={(e) => {
                                    if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
                                        e.preventDefault(); // Prevent non-numeric characters like 'e', '-', '+'
                                    }
                                }}
                            />

                        </div>

                        {/* Buttons */}
                        <div className="logout-btn d-flex justify-content-center mt-3">
                            <button className="logout-btn-1 me-2" onClick={roomModalClose}>
                                Cancel
                            </button>
                            <button className="logout-btn-2" onClick={handleAddRoomData}>
                                Submit
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    );
};

export default ItineraryHotel;
