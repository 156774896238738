import {
  faArrowRight,
  faBus,
  faCar,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faIndianRupeeSign,
  faPlane,
  faTrain,
  faUser,
  faXmark,
  faBrush,
  faCalendar,
  faHandHoldingDroplet,
  faHandsHoldingCircle,
  faHandshakeAlt,
  faHotel,
  faLocationPinLock,
  faPaperclip,
  faStar,
  faStarHalfAlt,
  faUserDoctor,
  faUsers,
  faDoorClosed,
  faBowlFood,
  faCookie,
  faMountainCity,
  faPersonWalkingLuggage,
  faHouseFloodWaterCircleArrowRight,
  faPenToSquare
} from "@fortawesome/free-solid-svg-icons"
import { faXmarkCircle } from "@fortawesome/free-regular-svg-icons"
import { faTrainSubway } from "@fortawesome/free-solid-svg-icons"
import { faBinoculars } from "@fortawesome/free-solid-svg-icons"
import { faUtensils } from "@fortawesome/free-solid-svg-icons"
import { faTaxi } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Modal, Row } from "react-bootstrap"
import Header from "./Header"
import "./css/index1.css"
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-regular-svg-icons"
import { useState, useEffect } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { BASE_URL } from "../BASE_URL"
import Rating from "@mui/material/Rating"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useRef } from "react"
import Footer from "./Footer"
import DOMPurify from "dompurify"
import "./css/booked-package.css"
import ActivityContentOther from "./ActivityContentOther"
import ActivityContentServices from "./ActivityContentServices"
import ActivityContentItinerary from "./ActivityContentItinerary"
import ActivityContentHotel from "./ActivityContentHotel"
import StarRating from "./StarRating"
import PrivacyExtraData from "./PrivacyExtraData"

const rightDate = (dateString) => {
  const [year, month, day] = dateString?.split('-');
  return `${day}-${month}-${year}`;
};

const calculateAge = (dob) => {
  const birthDate = new Date(dob);
  const today = new Date();

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();

  // If the current month is before the birth month or it's the birth month but the current day is before the birth day, subtract 1 from the age
  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
};

const formatDate = (dateString) => {
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return 'Invalid date format';
  }

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};

function getYearFromDate(dateString) {
  const birthDate = new Date(dateString);
  const today = new Date();

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();
  const dayDifference = today.getDate() - birthDate.getDate();

  // Adjust age if the current date is before the birth date this year
  if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
    age--;
  }

  return age;
}

const Booked_package_fulDetail = () => {
  const { id } = useParams();
  const [showReview, setShowReview] = useState(false);
  const [review, setReview] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false); // State to track if the review was updated // State to track if the review was updated
  const [bookedData, setBookedData] = useState([]);
  const [booked, setBooked] = useState("");
  console.log(booked)
  const [reviewid, setReviewId] = useState("");
  const [agencyId, setAgencyId] = useState("");
  const [agencyAverageStar, setAgencyAverageStar] = useState(0);
  const [agencyTotalReview, setAgencyTotalReview] = useState(0);
  const [stars, setStars] = useState([]);
  const [paidAmount, setPaidAmount] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();

  const BookedPackage = async () => {
    const token = localStorage.getItem("userToken");
    const res = await fetch(`${BASE_URL}bookpackage/jaydev`, {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    });
    const data = await res.json();
    setBookedData(data.data);

    const matchingPackage = data?.data?.find((item) => item._id === id);

    const sumPaidAmount = matchingPackage?.payment.reduce((total, payment) => total + payment.paid_amount, 0);

    setPaidAmount(sumPaidAmount)
  };

  useEffect(() => {
    BookedPackage();
  }, []);

  useEffect(() => {
    const matchingPackage = bookedData.find((item) => item._id === id);
    if (matchingPackage) {
      setBooked(matchingPackage);
    }
  }, [bookedData, id]);

  const fetchReview = async () => {
    const token = localStorage.getItem("userToken");
    const res = await fetch(`${BASE_URL}review?book_package_id=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    const data = await res.json();
    if (data.data.length !== 0) {
      setReview(data.data[0]);
      setgotComment(data.data[0].comment_box);
      setgotRating(data.data[0].star);
      setReviewId(data.data[0]._id);
    }
  };

  useEffect(() => {
    setAgencyId(booked.agency_id);
    const currentDate = new Date();
    // if (booked && currentDate > new Date(booked.approx_start_date)) {
    setShowReview(true);
    fetchReview(); // Fetch review if the current date is past the start date
    // }
  }, [booked, id]);

  const AgencyReviews = async () => {
    const token = localStorage.getItem("userToken");

    const res = await fetch(`${BASE_URL}review/agencyallreview?_id=${agencyId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
    })

    const data = await res.json()
    setAgencyAverageStar((data.metadata.average_star_rating)?.toFixed(1));
    setAgencyTotalReview(data.metadata.total_review);

  }

  useEffect(() => {
    if (agencyId) {
      AgencyReviews()
    }
  }, [agencyId])

  useEffect(() => {
    const fullStars = Math.floor(agencyAverageStar);
    const hasHalfStar = agencyAverageStar % 1 !== 0;
    const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

    const starElements = [];
    for (let i = 0; i < fullStars; i++) {
      starElements.push(
        <FontAwesomeIcon
          key={`full-${i}`}
          icon={faStar}
          className='CuStom_package_icon'
        />
      );
    }
    if (hasHalfStar) {
      starElements.push(
        <FontAwesomeIcon
          key='half'
          icon={faStarHalfAlt}
          className='CuStom_package_icon'
        />
      );
    }
    for (let i = 0; i < emptyStars; i++) {
      starElements.push(
        <FontAwesomeIcon
          key={`empty-${i}`}
          icon={faStar}
          className='CuStom_package_icon'
          style={{ color: '#B8B8B8' }}
        />
      );
    }

    setStars(starElements);
  }, [agencyAverageStar]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setRating(gotrating);
    setComment(gotcomment);
    setShow(true);
  }
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState("");

  const [gotrating, setgotRating] = useState(null);
  const [gotcomment, setgotComment] = useState(null);

  const { transId } = useParams();


  const handleRatingChange = (event) => {
    setRating(event.target.value);
    setIsUpdated(true); // Enable update button when rating changes
  };


  const handleSubmit = async () => {
    const token = localStorage.getItem("userToken");
    if (!rating || !comment) {
      toast.error("Please fill all details!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    const res = await fetch(`${BASE_URL}review`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        book_package_id: id,
        star: rating,
        comment_box: comment,
      }),
    });

    const data = await res.json();
    handleClose();

    if (data.code === 200) {
      fetchReview()
      setReview(true)
      toast.success("Review submitted successfully!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });

    } else {
      toast.error(data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const handleUpdate = async () => {
    const token = localStorage.getItem("userToken");

    if (!comment || !rating) {
      toast.error("Please fill all details!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    const res = await fetch(`${BASE_URL}review/updatereview?_id=${reviewid}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        book_package_id: id,
        star: rating,
        comment_box: comment,
      }),
    });

    const data = await res.json();
    handleClose();

    if (data.code === 200) {
      fetchReview()
      setReview(true)
      toast.success("Review updated successfully!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });


    } else {
      toast.error(data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const overRef = useRef(null);
  const services = useRef(null);
  const itenarary = useRef(null);
  const hotels = useRef(null);
  const policy = useRef(null);

  const scrollTo = (section) => {
    let targetRef

    switch (section) {
      case "overview":
        targetRef = overRef
        break
      case "services":
        targetRef = services
        break
      case "itenarary":
        targetRef = itenarary
        break
      case "hotels":
        targetRef = hotels
        break
      case "policy":
        targetRef = policy
        break
      default:
        targetRef = null
    }

    if (targetRef) {
      window.scroll({
        top: targetRef.current.offsetTop,
        behavior: "smooth",
      })
    }
  }

  const [privacy, setPrivacy] = useState([]);

  const privacypolicies = async () => {
    const res = await fetch(`${BASE_URL}policy`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await res.json();
    setPrivacy(data.data[2]);
  };

  useEffect(() => {
    privacypolicies();
  }, []);

  const url = location.pathname;

  const [paymentAmount, setPaymentAmount] = useState(null)

  const handlePrice = (e) => {
    setPaymentAmount(e.target.value)
    const amount = e.target.value
    localStorage.setItem("partialPaymentAmount", amount)
  }

  const hadlePayment = async () => {
    const token = localStorage.getItem("userToken");
    const myId = localStorage.getItem("mySytUserId")

    if (!paymentAmount) {
      toast.error("Please Select Amount", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    // paymentAmount

    const res = await fetch(`${BASE_URL}payment/pay`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        MERCHANT_USER_ID: myId,
        mobileNumber: booked?.contact_number,
        amount: 1,
        link: url,
        package_id: booked?.package_details?.[0]?._id,
      }),
    });

    const data = await res.json();
    window.location.href = data?.data?.url
  }


  useEffect(() => {
    if (transId !== undefined && transId !== "" && booked) {
      InsertBooking();
    }
  }, [transId, booked]);

  const makePayment = async () => {
    const token = localStorage.getItem("userToken");
    const amount = localStorage.getItem("partialPaymentAmount")

    const res = await fetch(`${BASE_URL}payment/update/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        amount: 1,
        paid_amount: Number(amount),
        link: url,
      }),
    });

    const data = await res.json();

    if (data?.success) {
      localStorage.setItem("bookingId", data?.data?.booking_id);
      localStorage.setItem("paymentId", data?.data?.payment_id);
      window.location.href = data?.data?.url
    } else {
      toast.error(data?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    }
  }

  const hadleUpdatePayment = async () => {
    const token = localStorage.getItem("userToken");
    const amount = localStorage.getItem("partialPaymentAmount")

    const res = await fetch(`${BASE_URL}payment/update/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        paid_amount: Number(amount),
        link: url,
      }),
    });

    const data = await res.json();
    localStorage.removeItem("partialPaymentAmount")
  }

  const InsertBooking = async () => {

    const token = localStorage.getItem("userToken");
    const bookingId = localStorage.getItem("bookingId");
    const paymentId = localStorage.getItem("paymentId");

    // setLoading(true);

    const res = await fetch(`${BASE_URL}payment/updateBooking/${bookingId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        status: "booked",
        payment_id: paymentId,
        transaction_id: transId
      }),
    });

    const data = await res.json();

    if (data?.success) {
      localStorage.removeItem("bookingId")
      localStorage.removeItem("paymentId")
      localStorage.removeItem("paymentType")
      localStorage.removeItem("paymentType")
      localStorage.removeItem("finalPrice")
      setTimeout(() => {
        navigate(`/booking-full-detail/${id} `)
        BookedPackage();
      }, 2000);
    } else {
      localStorage.removeItem("bookingId")
      localStorage.removeItem("paymentId")
      localStorage.removeItem("paymentType")
      localStorage.removeItem("paymentType")
      localStorage.removeItem("finalPrice")
      // setLoading(false);
      toast.error(data?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    }
  };


  const [isBookingCancel, setIsBookingCancel] = useState(false)

  const handleCloseCancelModal = () => {
    setIsBookingCancel(false)
  }

  const [reason, setReason] = useState("")

  const handleChangeReason = (e) => {
    setReason(e.target.value)
  }

  return (
    <div>
      <Header />
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="py-4 px-3">
            <div>
              <div className="d-flex" style={{ gap: "15px" }}>
                <h5 className="text16 font600">Ratings</h5>
                <Rating
                  name="simple-controlled"
                  value={rating}
                  onChange={(e) => {
                    handleRatingChange(e);
                  }}
                />
              </div>
            </div>
            <div className="mt-3 mb-3">
              <h5 className="text16 font600">Comment</h5>
              <div>
                <textarea
                  name=""
                  id=""
                  cols="50"
                  rows="5"
                  className="ps-2 py-2"
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                    setIsUpdated(true); // Enable update button when comment changes
                  }}
                ></textarea>
              </div>
            </div>
            <div className="d-flex justify-content-center" style={{ gap: "10px" }}>
              <button onClick={handleClose} className="button-251 text16">
                Close
              </button>
              {review ? (
                <button
                  onClick={handleUpdate}
                  className="button-25 text16"
                  disabled={!isUpdated}
                >
                  Update
                </button>
              ) : (
                <button onClick={handleSubmit} className="button-25 text16">
                  Submit
                </button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {!booked && <p>loading...</p>}

      {booked && (
        <section className=''>
          <div className='container-customes padding-costume-container py-xl-4 py-sm-4 py-2'>
            <div className=''>
              <Row className=''>
                <div className='col-xl-8 col-lg-6 col-sm-12 col-12 m-0 posi-first'>
                  <div className='CuStom_package_image'>
                    <img
                      src='/C-packega.png'
                      className='booking-detail-header-image'
                      alt=''
                    />
                  </div>
                  <div className='mt-3 hover-buttons-media'>
                    <div className='me-3 mb-1'>
                      <button
                        class='button-47'
                        role='button'
                        onClick={() => scrollTo("overview")}
                      >
                        Overview
                      </button>
                    </div>
                    <div className='me-3 mb-1'>
                      <button
                        class='button-47'
                        role='button'
                        onClick={() => scrollTo("services")}
                      >
                        Services
                      </button>
                    </div>
                    <div className='me-3 mb-1'>
                      <button
                        class='button-47'
                        role='button'
                        onClick={() => scrollTo("itenarary")}
                      >
                        Itinerary
                      </button>
                    </div>
                    {booked?.hotel_itienrary?.length !== 0 && (
                      <div className='me-3 mb-1'>
                        <button
                          class='button-47'
                          role='button'
                          onClick={() => scrollTo("hotels")}
                        >
                          Hotels
                        </button>
                      </div>
                    )}
                    <div className='me-3 mb-1'>
                      <button
                        class='button-47'
                        role='button'
                        onClick={() => scrollTo("policy")}
                      >
                        Policy
                      </button>
                    </div>
                  </div>

                  <section className='mb-3 mt-3' ref={overRef}>
                    <div className=''>
                      <div>
                        <button className='w-100 over-btn text18 lh19'>
                          <img src='/c-icon.png' className='me-3' />
                          Overview
                        </button>
                      </div>
                    </div>
                  </section>

                  <section className='text-start mb-4'>
                    <div className=''>

                      <div className='amh_to_hima text-start'>
                        <div className='mb-2 text-start cmnp'>
                          <h6 className='cmntext text20 font700'>
                            <FontAwesomeIcon
                              icon={faHouseFloodWaterCircleArrowRight}
                              className='cmnclr'
                            />{" "}
                            Traveller Details
                          </h6>
                          {booked?.travel_details && booked?.travel_details?.map((e) => (
                            <div className="traveller-display-grid mt-3">
                              <b className="text14">
                                <FontAwesomeIcon
                                  icon={faUser}
                                  className='cmnclr'
                                />{" "}{e?.first_name} {e?.last_name}
                              </b>
                              <b className="text14">
                                {rightDate(e?.dob?.slice(0, 10))} ({calculateAge(e?.dob)} yrs)
                              </b>

                              <b className="text14">
                                {
                                  calculateAge(e?.dob) <= 2
                                    ? "Infant"
                                    : calculateAge(e?.dob) >= 2 && calculateAge(e?.dob) <= 12
                                      ? "Child"
                                      : calculateAge(e?.dob) >= 13
                                        ? "Adult"
                                        : ""
                                }
                              </b>

                              <b className="text14">{e?.gender}</b>
                            </div>
                          ))}
                        </div>
                      </div>

                      <Row className="mt-4">
                        <div className='col-12 col-xl-6 mb-3'>
                          <div className='amh_to_hima'>
                            <div>
                              <h6 className='pb-2 mb-0 cmntext text-capitalize font700 text18'>
                                {booked.departure?.split(',')[0]} to {booked.destination}
                              </h6>
                            </div>
                            <div className=''>
                              {/* <div className=''>
                                <div className=''>
                                  <div className='cmn px-2 px-sm-4 px-md-4 px-lg-4 px-xl-4 py-2'>
                                    <p className='cmnp py-1 media-for-booked-data-table d-flex '>
                                      <span className=" text16 font700">Travellers Detail</span>
                                    </p>
                                    {booked?.travel_details && booked?.travel_details?.map((e) => (
                                      <p className='cmnp py-1 media-for-booked-data-table d-flex text14 '>
                                        <FontAwesomeIcon
                                          icon={faUser}
                                          className='cmnclr'
                                        />{" "}
                                        <b>{e?.first_name} {e?.last_name} ({calculateAge(e?.dob)} yrs)</b>
                                        <span className="ms-auto">Male</span>
                                      </p>
                                    ))}
                                  </div>
                                </div>
                              </div> */}
                              <div className=''>
                                <div className=''>
                                  <div className='cmn px-2 px-sm-4 px-md-4 px-lg-4 px-xl-4 py-2'>
                                    <p className='cmnp py-1 media-for-booked-data-table text14'>
                                      <FontAwesomeIcon
                                        icon={faUser}
                                        className='cmnclr'
                                      />{" "}
                                      <b>Adult (12+yrs)</b> :{" "}
                                      {booked.total_adult}
                                    </p>
                                    <p className='cmnp py-1 media-for-booked-data-table text14'>
                                      <FontAwesomeIcon
                                        icon={faUser}
                                        className='cmnclr'
                                      />{" "}
                                      <b>Children(2 to 12 years)</b> :{" "}
                                      {booked.total_infant}
                                    </p>
                                    <p className='cmnp py-1 media-for-booked-data-table text14'>
                                      <FontAwesomeIcon
                                        icon={faUser}
                                        className='cmnclr'
                                      />{" "}
                                      <b>Infant (0 to 2 years)</b> :{" "}
                                      {booked.total_child}
                                    </p>
                                    <p className='cmnp py-1 media-for-booked-data-table text14'>
                                      <FontAwesomeIcon
                                        icon={faUsers}
                                        className='cmnclr'
                                      />{" "}
                                      <b>Total Travellers</b> :{" "}
                                      {parseInt(booked.total_adult) +
                                        parseInt(booked.total_child) +
                                        parseInt(booked.total_infant)}
                                    </p>
                                    {booked.personal_care && booked.personal_care !== "" && (
                                      <p className='cmnp py-1 media-for-booked-data-table text14'>
                                        <FontAwesomeIcon
                                          icon={faHandsHoldingCircle}
                                          className='cmnclr'
                                        />{" "}
                                        <b>Any Traveller Needs Personal Care?</b>{" "}
                                        :{" "}
                                        {booked.personal_care}
                                      </p>
                                    )}
                                    <p className='cmnp py-1 media-for-booked-data-table text14'>
                                      <FontAwesomeIcon
                                        icon={faCalendar}
                                        className='cmnclr'
                                      />{" "}
                                      <b>Total Days & Night</b> : {booked.totaldays}D/{booked.totalnight}N
                                    </p>
                                    {/* <FontAwesomeIcon
                                      icon={faHandsHoldingCircle}
                                      className='cmnclr'
                                    />{" "}
                                    <b className="text14">Sightseeing</b>{" "}
                                    :{" "} */}
                                    {/* {booked?.siteseeing} */}
                                    {/* <p className='cmnp py-1 media-for-booked-data-table text14'>
                                      <FontAwesomeIcon
                                        icon={faCalendar}
                                        className='cmnclr'
                                      />{" "}
                                      <b>Total Days/Night</b> :{" "}
                                      {booked.totaldays}/{booked.totalnight}
                                    </p> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className='mt-3 mb-2'>
                                <h6 className='cmntext font700 text18'>
                                  <FontAwesomeIcon
                                    icon={faCalendar}
                                    className='cmnclr'
                                  />{" "}
                                  Destination Arrival Date
                                </h6>
                              </div>
                              <div className='cmn'>
                                <p className='cmnp ms-4 py-2 text14'>
                                  {formatDate(booked?.destination_arrival_date)}
                                </p>
                              </div>
                            </div>
                            <div>
                              <div className='mt-3 mb-2'>
                                <h6 className='cmntext  text20 font700'>
                                  <FontAwesomeIcon
                                    icon={faCalendar}
                                    className='cmnclr'
                                  />{" "}
                                  Approx Departure Between{" "}
                                </h6>
                              </div>
                              <div className='cmn'>
                                <p className='cmnp ms-4 py-2 text14'>
                                  {rightDate(booked?.approx_start_date?.slice(0, 10))} to{" "}
                                  {rightDate(booked?.approx_end_date?.slice(0, 10))}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='col-12 col-xl-6 mb-3'>
                          <div className='amh_to_hima'>
                            <div>
                              <h6 className=' pb-2 mb-0 cmntext font700 text18'>
                                <FontAwesomeIcon
                                  icon={faHotel}
                                  className='cmnclr'
                                />{" "}
                                Hotel & Food
                              </h6>
                            </div>
                            <div className=''>
                              <div className=''>
                                <div className=''>
                                  <div className='cmn px-2 px-sm-4 px-md-4 px-lg-4 px-xl-4 py-2'>
                                    <p className='cmnp py-1 media-for-booked-data-table text14'>
                                      <FontAwesomeIcon
                                        icon={faHotel}
                                        className='cmnclr'
                                      />{" "}
                                      <b>Hotel Type</b> : {booked.hoteltype === "" ? "N/A" : booked.hoteltype}
                                    </p>
                                    {booked?.room_sharing !== "" && (
                                      <p className='cmnp py-1 media-for-booked-data-table text14'>
                                        <FontAwesomeIcon
                                          icon={faDoorClosed}
                                          className='cmnclr'
                                        />{" "}
                                        <b>Room Sharing</b> : {booked?.room_sharing}
                                      </p>
                                    )}
                                    <p className='cmnp py-1 media-for-booked-data-table text14'>
                                      <FontAwesomeIcon
                                        icon={faBowlFood}
                                        className='cmnclr'
                                      />{" "}
                                      <b>Meal</b> :{" "}
                                      {booked?.meal_type?.[0] !== "" ? booked?.meal?.toString()?.split(",")?.join(", ") : "N/A"}
                                    </p>
                                    <p className='cmnp py-1 media-for-booked-data-table text14'>
                                      <FontAwesomeIcon
                                        icon={faCookie}
                                        className='cmnclr'
                                      />{" "}
                                      <b>Meal Type</b> :{" "}
                                      {booked?.meal_type?.[0] !== "" ? booked.meal_type?.toString()?.split(",")?.join(", ") : "N/A"}
                                    </p>
                                    <p className='cmnp py-1 media-for-booked-data-table text14'>
                                      <FontAwesomeIcon
                                        icon={faMountainCity}
                                        className='cmnclr'
                                      />{" "}
                                      <b>Sightseeing</b> : {booked.siteseeing.charAt(0).toUpperCase() + booked.siteseeing.slice(1)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='mt-3 mb-2'>
                              {booked.transport_mode?.slice(0, 1)?.toLowerCase() === "t" && (
                                <>
                                  <h6 className='cmntext font700 text18'>
                                    <FontAwesomeIcon
                                      icon={faTrainSubway}
                                      className='cmnclr'
                                    />{" "}
                                    Travel By
                                  </h6>
                                </>
                              )}
                              {booked.transport_mode?.slice(0, 1)?.toLowerCase() === "f" && (
                                <>
                                  <h6 className='cmntext font700 text18'>
                                    <FontAwesomeIcon
                                      icon={faPlane}
                                      className='cmnclr'
                                    />{" "}
                                    Travel By
                                  </h6>
                                </>
                              )}
                              {booked.transport_mode?.slice(0, 1)?.toLowerCase() === "c" && (
                                <>
                                  <h6 className='cmntext font700 text18'>
                                    <FontAwesomeIcon
                                      icon={faTaxi}
                                      className='cmnclr'
                                    />{" "}
                                    Travel By
                                  </h6>
                                </>
                              )}
                            </div>
                            <div className='cmn'>
                              <p className='cmnp ms-4 py-2 text14'>
                                {booked.transport_mode}
                              </p>
                            </div>
                            <div className='mt-3 mb-2'>
                              <h6 className='cmntext font700 text18'>
                                <FontAwesomeIcon
                                  icon={faLocationPinLock}
                                  className='cmnclr'
                                />{" "}
                                Package Type
                              </h6>
                            </div>
                            <div className='cmn'>
                              <p className='cmnp ms-4 py-2 text14'>
                                {booked?.package_type}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Row>

                      <div className='amh_to_hima text-start mt-2'>
                        <div className='mb-2 text-start cmnp'>
                          <h6 className='cmntext font700 text18'>
                            <FontAwesomeIcon
                              icon={faHouseFloodWaterCircleArrowRight}
                              className='cmnclr'
                            />{" "}
                            Other Details
                          </h6>
                        </div>
                        <ActivityContentOther activity={booked?.othere_requirement} charCount={700} />
                        {/* <p className='cmnp py-2 text14'>{booked?.othere_requirement}</p> */}
                      </div>

                    </div>
                  </section>

                  <div className='details-service  mt-4' ref={services}>
                    <button className='mb-3 font700 text18 lh19'><FontAwesomeIcon className='me-3' icon={faBrush} /> Services</button>
                    <div className='media-services IE-border'>
                      <div className='row gx-0 padding-inside-row mx-2 mx-sm-4 mt-3 mb-4 '>

                        <div className='col-xl-6 col-12'>
                          <div className='hotel-Included me-0 me-sm-3'>
                            <h4 className="font700 text18 text-start">
                              <FontAwesomeIcon icon={faCircleCheck} />{" "}
                              Included
                            </h4>
                            <div class='content-green'>
                              {booked?.booked_include?.map((e) => {
                                return (
                                  <>
                                    <p className="text14 margin-bottom-p d-flex align-items-start mb-0">
                                      <FontAwesomeIcon icon={faCheck} className="text16 me-2" />
                                      <ActivityContentServices activity={e.include_services_value} charCount={150} />
                                      {/* <span className="mb-0" dangerouslySetInnerHTML={{ __html: e.include_services_value }}>
                                      </span> */}
                                    </p>
                                  </>
                                )
                              })}
                            </div>
                          </div>
                        </div>

                        <div className='col-xl-6 col-12'>
                          <div className='hotel-excluded ms-0 ms-sm-3'>
                            <h4 className="font700 text18 text-start">
                              <FontAwesomeIcon icon={faCircleXmark} />{" "}
                              Excluded
                            </h4>
                            <div className='content-red'>
                              {booked?.booked_exclude?.map((e1) => {
                                return (
                                  <>
                                    <p className="text14 margin-bottom-p d-flex align-items-start mb-0">
                                      <FontAwesomeIcon icon={faXmark} className="me-2 text16" />
                                      <ActivityContentServices activity={e1.exclude_services_value} charCount={150} />
                                      {/* <span dangerouslySetInnerHTML={{ __html: e1.exclude_services_value }}>
                                      </span> */}
                                    </p>
                                  </>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <section className='text-start' ref={itenarary}>
                    <div
                      className='details-service mt-4'
                      ref={itenarary}
                    >
                      <button className='mb-3 font700 text18 lh19'><FontAwesomeIcon
                        icon={faHandshakeAlt}
                        className='me-2'
                      />Itinerary</button>
                    </div>
                    <div className='amh_to_hima'>
                      {booked?.booked_itinerary && booked?.booked_itinerary.map((e3, i) => {
                        return (
                          <>
                            <div className="py-2">
                              <h6 className="cmntext mb-2 font700 text18">Day {i + 1}</h6>
                              <div className='cmn p-2'>

                                <Row className='align-items-start'>
                                  <div className='col-lg-9'>
                                    <div className='py-1'>
                                      <h6 className='cmnp cmnclr1 text14 font700'>Title</h6>
                                      <p className='cmnp cmnclr font-weight-bolder text14'>
                                        {e3.title}{" "}
                                      </p>
                                    </div>
                                    <div className='py-1'>
                                      <h6 className='cmnp cmnclr1 text14 font700'>Activity</h6>
                                      <ActivityContentItinerary activity={e3.activity} charCount={600} />
                                      {/* <p className='cmnp cmnclr font-weight-bolder text14'>
                                        <div
                                          style={{ textAlign: "justify" }}
                                          dangerouslySetInnerHTML={{
                                            __html: e3.activity,
                                          }}
                                        />
                                      </p> */}
                                    </div>
                                  </div>
                                  {e3.photo && <div className='col-lg-3'>
                                    <div className='text-start text-sm-end py-2'>
                                      <img src={e3.photo} className='img-fluid' style={{ height: "120px", width: "120px" }} />
                                    </div>
                                  </div>}
                                </Row>


                              </div>
                            </div>
                          </>
                        )
                      })}
                    </div>
                  </section>

                  {booked?.hotel_itienrary?.length !== 0 && (
                    <div
                      className='details-service mt-4'
                      ref={hotels}
                    >
                      <button className='mb-3 font700 text18 lh19'><FontAwesomeIcon
                        icon={faHotel}
                        className='me-2'
                      />Hotels</button>
                    </div>
                  )}

                  {booked?.hotel_itienrary?.length !== 0 && (
                    <section className=''>
                      <div className='hotels-section'>
                        <div className='last-hotels'>

                          {/* <!--------------------------- part-1 ---------------------------------------------> */}

                          <div className=''>
                            {booked?.hotel_itienrary &&
                              booked?.hotel_itienrary
                                ?.sort((a, b) => {
                                  const firstDayA = Math.min(...a.days.split(',').map(Number));
                                  const firstDayB = Math.min(...b.days.split(',').map(Number));
                                  return firstDayA - firstDayB;
                                })
                                ?.map((e, index) => {
                                  // Find matching itinerary data
                                  const matchedItinerary = booked?.hotel_itinerary_array?.find(
                                    (item) => item.hotel_itinerary_id === e._id
                                  );

                                  // Get available meals
                                  const meals = [];
                                  if (matchedItinerary?.breakfast) meals.push(`Breakfast: ₹${matchedItinerary.breakfast_price}`);
                                  if (matchedItinerary?.lunch) meals.push(`Lunch: ₹${matchedItinerary.lunch_price}`);
                                  if (matchedItinerary?.dinner) meals.push(`Dinner: ₹${matchedItinerary.dinner_price}`);

                                  // Get matching room details
                                  const matchedRoom = e.rooms.find((room) => room._id === matchedItinerary?.room_id);

                                  return (
                                    <div key={e._id} className={`cmn ${index !== booked?.hotel_itienrary?.length - 1 ? 'mb-4' : ''}`}>
                                      <div className='row gx-5 p-2 second-changes'>
                                        {/* Hotel Image Carousel */}
                                        <div className='col-xl-4 col-12'>
                                          <div id={`carouselExampleCaptions-${index}`} className="carousel slide" data-bs-ride="carousel">
                                            <div className="carousel-indicators">
                                              {e?.hotel_photo?.map((_, idx) => (
                                                <button
                                                  key={idx}
                                                  type="button"
                                                  data-bs-target={`#carouselExampleCaptions-${index}`}
                                                  data-bs-slide-to={idx}
                                                  className={idx === 0 ? "active" : ""}
                                                  aria-current={idx === 0 ? "true" : "false"}
                                                  aria-label={`Slide ${idx + 1}`}
                                                ></button>
                                              ))}
                                            </div>
                                            <div className="carousel-inner">
                                              {e?.hotel_photo?.map((ele, idx) => (
                                                <div key={idx} className={`carousel-item ${idx === 0 ? "active" : ""}`}>
                                                  <img
                                                    src={ele}
                                                    className="d-block"
                                                    alt="..."
                                                    style={{ width: "240px", height: "150px", objectFit: "cover", borderRadius: "7px" }}
                                                  />
                                                </div>
                                              ))}
                                            </div>
                                            <button className="carousel-control-prev" type="button" data-bs-target={`#carouselExampleCaptions-${index}`} data-bs-slide="prev">
                                              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                              <span className="visually-hidden">Previous</span>
                                            </button>
                                            <button className="carousel-control-next" type="button" data-bs-target={`#carouselExampleCaptions-${index}`} data-bs-slide="next">
                                              <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                              <span className="visually-hidden">Next</span>
                                            </button>
                                          </div>
                                        </div>

                                        {/* Hotel Info */}
                                        <div className='col-xl-8 col-12 first-change'>
                                          <div className='mb-md-2'>
                                            <span className='d-flex day1-2  text-left align-items-center'>
                                              <div className='day-1-2 py-1 font-changes'>
                                                <h6 className="text18 text-center font700">
                                                  Day {e?.days.split(',').map(Number).sort((a, b) => a - b).join(',')}
                                                </h6>
                                              </div>
                                              <div className='text-start font-changes'>
                                                <h6 className='mb-1 text18 font700'>{e?.hotel_name}</h6>
                                                <p className='mb-0 text-start text14'>
                                                  {e?.hotel_address}, {e?.hotel_city}, {e?.hotel_state}
                                                </p>
                                              </div>
                                            </span>
                                          </div>

                                          {/* Stars */}
                                          <div className='hotels-star'>
                                            {[...Array(5)].map((_, i) => (
                                              <FontAwesomeIcon key={i} icon={faStar} className='p-1' style={{ color: "yellow" }} />
                                            ))}
                                          </div>

                                          {/* Meals Information */}
                                          {meals.length > 0 && (
                                            <div className='text-start hotels-footer'>
                                              <p><strong>Meals Included:</strong> {meals.join(', ')}</p>
                                            </div>
                                          )}

                                          {/* Room Information */}
                                          {matchedRoom && (
                                            <div className='text-start hotels-footer'>
                                              <p><strong>Room Type:</strong> {matchedRoom.room_type} - ₹{matchedRoom.room_type_price}</p>
                                            </div>
                                          )}

                                          {/* Description */}
                                          <div className='text-start hotels-footer'>
                                            <p>
                                              <ActivityContentHotel activity={e?.hotel_description} charCount={400} />
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                          </div>

                        </div>
                      </div>
                    </section>
                  )}
                </div>

                <div className='col-xl-4 col-lg-6 col-sm-12 col-12 m-0 posi-second position_relative'>
                  <div className=' CuStom_package details_box media-border-none d-flex justify-content-center align-items-center hyhy p-3 '>
                    <div className='inner_CuStom_package w-100 pb-4'>
                      {booked?.package_agencyname !== null ? (
                        <p className='CuStom_package_header text-capitalize py-2 font700 text18'>
                          Agency : {booked?.package_agencyname}
                        </p>
                      ) : (
                        <p className='CuStom_package_header text-capitalize py-2 font700 text18'>
                          Agency : {booked?.agencyname}
                        </p>
                      )}
                      <div className='booking-detail-right-box'>
                        <p className='text-19 mb-1 font700 text18 mb-3'>{booked?.title}</p>
                        <div className="mb-2 d-flex align-items-center">
                          <p className='fw-bold pt-1 me-2 text14 mb-1'>
                            {booked?.destination}
                          </p>
                          <p className='mb-0'>
                            <button class='button-13' role='button'>
                              {booked?.totaldays}D/{booked?.totalnight}N
                            </button>
                          </p>
                        </div>
                        <div className="mb-3 d-flex align-items-center">
                          <p
                            className='text15 font700 mb-0 me-2'
                          >
                            Package Type :
                          </p>
                          <button
                            class='button-17 me-1'
                            role='button'
                          >
                            {booked?.package_type}
                          </button>
                        </div>
                        <div className='mb-4 d-flex align-items-center flex-wrap gap-2'>
                          <p
                            className='mb-0 text15 font700'
                          >
                            Package Best For :
                          </p>
                          {booked?.category?.map((e) => {
                            return (
                              <>
                                <button
                                  class='button-17 me-1'
                                  role='button'
                                >
                                  {e}
                                </button>
                              </>
                            )
                          })}
                        </div>
                        {/* <p className='mb-1 text14'>
                          <span className="font600 text14">Name</span><span className="mx-1 font600 text14">:</span>{booked?.user_name}
                        </p>
                        <p className='mb-1 text14'>
                          <span className="font600 text14">Email</span><span className="mx-1 font600 text14">:</span>{booked?.email_id}
                        </p>
                        <p className='mb-1 text14'>
                          <span className="font600 text14">Contact No.</span><span className="mx-1 font600 text14">:</span>{booked?.contact_number}
                        </p> */}
                        {/* <p className='mb-1 text14'>
                          <span className="font600 text14">Payment Status</span><span className="mx-1 font600 text14">:</span>{booked?.payment_type}
                        </p> */}
                        {/* {booked?.gst_address !== "" && (
                          <p className='mb-1 text14'>
                            <span className="font600 text14">Address</span><span className="mx-1 font600 text14">:</span>{booked?.gst_address}
                          </p>
                        )} */}

                        {/* <div className='d-flex'>
                          <p className=' mb-1 text14 font600'>
                            <span className="text14 font600">Total Amount</span>
                            <span className='ms-1 me-2 text14 font600'>:</span>
                            <FontAwesomeIcon
                              className='me-1'
                              icon={faIndianRupeeSign}
                            />
                            {booked.total_amount}
                          </p>
                        </div> */}

                        {/* <p className="mb-1 text14 font600">Adult : {booked?.total_adult} * <FontAwesomeIcon className='me-1 text14 font600' icon={faIndianRupeeSign} style={{ fontSize: "11px" }} />{booked?.price_per_person_adult + booked?.admin_margin_price_adult} = <FontAwesomeIcon className='me-1 text14 font600' icon={faIndianRupeeSign} style={{ fontSize: "11px" }} />{Number(booked?.total_adult) * (Number(booked?.price_per_person_adult) + booked?.admin_margin_price_adult)}</p>
                        {booked?.total_child !== 0 && (
                          <p className="mb-1 text14 font600">Children : {booked?.total_child} * <FontAwesomeIcon className='me-1 text14 font600' icon={faIndianRupeeSign} style={{ fontSize: "11px" }} />{booked?.price_per_person_child + booked?.admin_margin_price_child} = <FontAwesomeIcon className='me-1 text14 font600' icon={faIndianRupeeSign} style={{ fontSize: "11px" }} />{Number(booked?.total_child) * (Number(booked?.price_per_person_child) + booked?.admin_margin_price_child)}</p>
                        )}
                        {booked?.total_infant !== 0 && (
                          <p className="mb-2 text14 font600">Infants : {booked?.total_infant} * <FontAwesomeIcon className='me-1 text14 font600' icon={faIndianRupeeSign} style={{ fontSize: "11px" }} />{booked?.price_per_person_infant + booked?.admin_margin_price_infant} = <FontAwesomeIcon className='me-1 text14 font600' icon={faIndianRupeeSign} style={{ fontSize: "11px" }} />{Number(booked?.total_infant) * (Number(booked?.price_per_person_infant) + booked?.admin_margin_price_infant)}</p>
                        )} */}

                        {/* {booked.payment_type_on_booking !== "Partial Payment" && (
                          <div className='d-flex'>
                            <p className='mb-1 text14'>
                              <span className=" text14 font600">Transaction Id</span>
                              <span className='ms-1 me-2 text14 font600'>:</span>
                              {booked?.payment?.[0]?.transaction_id}
                            </p>
                          </div>
                        )} */}
                        {/* <div className='d-flex'>
                          <p className='mb-1 text14'>
                            <span className=" text14 font600">Payment Type</span>
                            <span className='ms-1 me-2 text14 font600'>:</span>
                            {booked.payment_type_on_booking}
                          </p>
                        </div> */}
                        <div className='CuStom_package_images gap-2'>
                          {booked?.hoteltype !== "" && (
                            <div className='text-center' style={{ width: "40px" }}>
                              <FontAwesomeIcon
                                icon={faHotel}
                                className='media-for-icon text20'
                              />
                              <p className="text12 font500 mt-1">{booked?.hoteltype}</p>
                            </div>
                          )}
                          {booked?.meal_type?.[0] !== "" && (
                            <div className='mx-2 text-center'>
                              <FontAwesomeIcon
                                icon={faUtensils}
                                style={{ color: "#868383", fontSize: "20px" }}
                                className=''
                              />
                              <p className="text12 font500 mb-0 mt-1">Meals</p>
                              <p className="text12 font500 mb-0">{Array.isArray(booked?.meal_type) ? booked?.meal_type.join(", ") : booked?.meal_type}</p>
                              <p className="text12 font500 mb-0">({booked?.meal?.[0]?.split(', ')?.map(meal => meal[0]).join(',')})</p>
                            </div>
                          )}

                          <div className='text-center'>
                            <FontAwesomeIcon
                              icon={faBinoculars}
                              className='media-for-icon text20'
                            />
                            <p className="text12 font500 mt-1 mb-0">Sightseeing</p>
                            <p className="text12 font500 mb-0">{booked?.siteseeing?.slice(0, 1)?.toUpperCase() + booked?.siteseeing?.slice(1)}</p>
                          </div>
                          <div className='mx-2 text-center'>
                            <div>
                              {booked.transport_mode?.slice(0, 1)?.toLowerCase() ===
                                "t" && (
                                  <>
                                    <FontAwesomeIcon
                                      icon={faTrainSubway}
                                      className='media-for-icon text20'
                                    />
                                  </>
                                )}
                              {booked.transport_mode?.slice(0, 1)?.toLowerCase() ===
                                "f" && (
                                  <>
                                    <FontAwesomeIcon
                                      icon={faPlane}
                                      className='media-for-icon text20'
                                    />
                                  </>
                                )}
                              {booked.transport_mode?.slice(0, 1)?.toLowerCase() ===
                                "b" && (
                                  <>
                                    <FontAwesomeIcon
                                      icon={faBus}
                                      className='media-for-icon text20'
                                    />
                                  </>
                                )}
                              {booked.transport_mode?.slice(0, 1)?.toLowerCase() === "c" && (
                                <>
                                  <FontAwesomeIcon
                                    icon={faTaxi}
                                    className='media-for-icon text20'
                                  />
                                </>
                              )}
                            </div>
                            <p style={{ width: "40px" }} className="text12 font500 mt-1">{booked.transport_mode}</p>
                          </div>
                        </div>

                        <hr />

                        <p className="mb-1 text14 font400 mt-2">
                          <span className="font600">Adult : </span>
                          {booked?.total_adult} * <FontAwesomeIcon className='me-1 text14 font500' icon={faIndianRupeeSign} style={{ fontSize: "11px" }} />{booked?.price_per_person_adult + booked?.admin_margin_price_adult}
                          =
                          <FontAwesomeIcon className='me-1 text14 font500' icon={faIndianRupeeSign} style={{ fontSize: "11px" }} />{Number(booked?.total_adult) * (Number(booked?.price_per_person_adult) + booked?.admin_margin_price_adult)}
                        </p>
                        {booked?.total_child !== 0 && (
                          <p className="mb-1 text14 font400"> <span className="font600">Children :</span> {booked?.total_child} * <FontAwesomeIcon className='me-1 text14 font500' icon={faIndianRupeeSign} style={{ fontSize: "11px" }} />{booked?.price_per_person_child + booked?.admin_margin_price_child} = <FontAwesomeIcon className='me-1 text14 font500' icon={faIndianRupeeSign} style={{ fontSize: "11px" }} />{Number(booked?.total_child) * (Number(booked?.price_per_person_child) + booked?.admin_margin_price_child)}</p>
                        )}
                        {booked?.total_infant !== 0 && (
                          <p className="mb-1 text14 font400"><span className="font600">Infants :</span> {booked?.total_infant} * <FontAwesomeIcon className='me-1 text14 font500' icon={faIndianRupeeSign} style={{ fontSize: "11px" }} />{booked?.price_per_person_infant + booked?.admin_margin_price_infant} = <FontAwesomeIcon className='me-1 text14 font500' icon={faIndianRupeeSign} style={{ fontSize: "11px" }} />{Number(booked?.total_infant) * (Number(booked?.price_per_person_infant) + booked?.admin_margin_price_infant)}</p>
                        )}
                        <p className='mb-1 text14'>
                          <span className="font600 text14">Booking Status</span><span className="mx-1 font600 text14">:</span>{booked?.status?.charAt(0)?.toUpperCase() + booked?.status?.slice(1)}
                        </p>
                        <div className='d-flex mb-1'>
                          <p className='mb-1 text14'>
                            <span className=" text14 font600">Booking Date</span>
                            <span className='ms-1 me-2 text14 font600'>:</span>
                            {formatDate(booked?.bookdate)}
                          </p>
                        </div>
                        <div className='d-flex mb-1'>
                          <p className='mb-1 text14'>
                            <span className=" text14 font600">GST Address</span>
                            <span className='ms-1 me-2 text14 font600'>:</span>
                            {booked?.gst_address},Ahmedabad,Gujarat
                          </p>
                        </div>
                        <div className="text14 text-end font600">
                          <a style={{ cursor: 'pointer', color: '#007bff', textDecoration: 'underline' }} onClick={() => setIsBookingCancel(true)} >
                            Cancel Booking
                          </a>
                        </div>
                        <hr />
                        <div
                          className=''
                          style={{
                            display: "grid",
                            gridAutoColumns: "1fr 1fr",
                          }}
                        >
                          {/* <p className='mb-0 text14'>
                            <span className=" text14 font600"> Total Reviews </span>{" "}
                            <span className='mx-1 text14 font600'>:</span> {agencyTotalReview}
                          </p> */}
                          <p className='mb-0 text14 font500'>
                            {" "}
                            <span className=" text14 font700"> Agency Reviews </span>{" "}
                            <span className='mx-1  text15 font700'>:</span> {agencyTotalReview}
                          </p>
                          <StarRating averageStarRating={agencyAverageStar || 0} />
                        </div>

                        <div className="" style={{ paddingRight: "4px" }}>
                          {showReview && (
                            <div className="row">
                              <div className="">
                                <p className="mb-0" style={{ fontSize: "14px" }}>
                                  {review ? (
                                    <span>
                                      <div className="mb-2 mt-2">
                                        <div className="text14 font700" style={{ marginRight: '5px' }}>
                                          My Review
                                          {/* <FontAwesomeIcon icon={faPenToSquare} className="text12 ms-2 booking-my-review-icon" style={{ cursor: "pointer" }} onClick={handleShow} /> */}
                                          <span className="text12 font500 ms-2" style={{ color: "#09646d", textDecoration: "underline", cursor: "pointer" }} onClick={handleShow}>Edit Review</span>
                                        </div>
                                        <div>
                                          <StarRating averageStarRating={gotrating || 0} />
                                          {/* {[...Array(5)].map((_, index) => (
                                            <FontAwesomeIcon
                                              key={index}
                                              icon={faStar}
                                              className='CuStom_package_icon'
                                              style={{ color: index < gotrating ? "#FFD700" : "#B8B8B8" }}
                                            />
                                          ))} */}
                                        </div>
                                      </div>
                                      <div className="font400" style={{ marginBottom: '5px', fontSize: "15px", margin: "5px", paddingX: "10px", paddingBottom: "5px" }}>{gotcomment}</div>
                                    </span>
                                  ) : (
                                    <div className="text14 text-end font600">
                                      <a style={{ cursor: 'pointer', color: '#007bff', textDecoration: 'underline' }} onClick={handleShow}>
                                        Submit Your Review
                                      </a>
                                    </div>
                                  )}
                                </p>
                              </div>
                            </div>
                          )}

                        </div>
                      </div>
                      {/* {booked.payment_type_on_booking === "Partial Payment" && ( */}
                      <>
                        <div className="partial-payment-header">
                          {booked.total_amount - paidAmount === 0 ? (
                            <p className="text20 font700">Payment Details</p>
                          ) : (
                            <p className="text20 font700">Complete Your Payment</p>
                          )}
                        </div>
                        <div className="partial-payment-body">
                          <div className='d-flex'>
                            <p className=' mb-1 text14 font500'>
                              <span className="text14 font600">Total Payable Amount</span>
                              <span className='ms-1 me-2 text14 font600'>:</span>
                              <FontAwesomeIcon
                                className='me-1'
                                icon={faIndianRupeeSign}
                              />
                              {booked.total_amount}
                            </p>
                          </div>
                          <div className='d-flex'>
                            <p className='mb-1 text14 font500'>
                              <span className=" text14 font600">Paid Amount</span>
                              <span className='ms-1 me-2 text14 font600'>:</span>
                              <FontAwesomeIcon
                                className='me-1'
                                icon={faIndianRupeeSign}
                              />{Math.round(booked.total_amount)}
                            </p>
                          </div>
                          {/* {paidAmount !== booked.total_amount && ( */}
                          <div className='d-flex'>
                            <p className='mb-1 text14 font500'>
                              <span className=" text14 font600">Remaining Amount</span>
                              <span className='ms-1 me-2 text14 font600'>:</span>
                              <FontAwesomeIcon
                                className='me-1'
                                icon={faIndianRupeeSign}
                              />{Math.round(booked.total_amount - paidAmount)}
                            </p>
                          </div>
                          {/* )} */}
                          <div className='d-flex'>
                            <p className='mb-1 text14 font500'>
                              <span className=" text14 font600">Payment Type</span>
                              <span className='ms-1 me-2 text14 font600'>:</span>
                              {booked.payment_type_on_booking}
                            </p>
                          </div>
                          <div className='d-flex'>
                            <p className='mb-1 text14 font500'>
                              <span className=" text14 font600">Payment Status</span>
                              <span className='ms-1 me-2 text14 font600'>:</span>
                              {booked.payment_type}
                            </p>
                          </div>
                          {/* <div className='d-flex'>
                            <p className='mb-1 text14 font500'>
                              <span className=" text14 font600">Payment Mode</span>
                              <span className='ms-1 me-2 text14 font600'>:</span>
                              {booked?.payment?.[0]?.payment_mode}
                            </p>
                          </div> */}
                          {/* )} */}
                        </div>

                        <div className="partial-payment-body-table mt-3">
                          <div className="partial-payment-table-header-payment">
                            <span>AMOUNT</span>
                            <span>TRANSACTION ID</span>
                            <span>DATE</span>
                          </div>
                          <hr className="mt-1 mb-2" />
                          {booked?.payment && booked?.payment?.map((e, index) => (
                            <div className="partial-payment-table-header-payment">
                              <p><FontAwesomeIcon
                                className='me-1'
                                icon={faIndianRupeeSign}
                              />{Math.round(e?.paid_amount)}</p>
                              <div>
                                <p className="mb-0">{e?.transaction_id}</p>
                                <p>({e?.payment_mode})</p>
                              </div>
                              <p>{formatDate(e?.payment_date)}</p>
                            </div>
                          ))}
                        </div>

                        {booked.total_amount - paidAmount === 0 ? (
                          <></>
                        ) : (
                          <div className="partial-payment-button">
                            {paidAmount < booked.total_amount / 2 ? (
                              <select value={paymentAmount} name="" id="" onChange={handlePrice}>
                                <option value="">Select</option>

                                <option value={(booked.total_amount - paidAmount) / 2}><FontAwesomeIcon
                                  className='me-1'
                                  icon={faIndianRupeeSign}
                                />{Math.round((booked.total_amount - paidAmount) / 2)}(35%)</option>

                                <option value={booked.total_amount - paidAmount}><FontAwesomeIcon
                                  className='me-1'
                                  icon={faIndianRupeeSign}
                                />{Math.round(booked.total_amount - paidAmount)} (70%)</option>
                              </select>
                            ) : (
                              <select value={paymentAmount} name="" id="" onChange={handlePrice}>
                                <option value="">Select</option>
                                <option value={booked.total_amount - paidAmount}><FontAwesomeIcon
                                  className='me-1'
                                  icon={faIndianRupeeSign}
                                />{Math.round(booked.total_amount - paidAmount)}(Remaining All Amount)</option>
                              </select>
                            )}
                            <button className="check-availibility-button text-20 border-0 " onClick={makePayment}>Pay</button>
                          </div>
                        )}
                      </>
                      {/* )} */}
                    </div>
                  </div>
                </div>

                <div className='col-12 posi-third' ref={policy}>
                  <div className='mt-5'>
                    {/* <div
                      dangerouslySetInnerHTML={{
                        __html: privacy.term_and_condition_content,
                      }}
                    /> */}
                    <PrivacyExtraData activity={privacy.term_and_condition_content} charCount={1200} />
                  </div>
                </div>
              </Row>
            </div>
          </div>
        </section>
      )}
      <hr />

      <Modal
        show={isBookingCancel}
        onHide={handleCloseCancelModal}
        backdrop='static'
        keyboard={false}
        style={{ zIndex: "10101010" }}
        centered
      >
        <Modal.Body>
          <div className='pt-3 pb-2 px-3'>
            <h5 className='text-center text-capitalize'>
              Why Wan't to cancel your booking?
            </h5>

            <div>
              <p className='mt-3'>Write Your Reason</p>
              <textarea
                name=''
                id=''
                rows='5'
                className='p-2'
                value={reason}
                style={{ width: "100%" }}
                onChange={handleChangeReason}
              />
            </div>

            <div className='logout-btn d-flex justify-content-center mt-3'>
              <button
                className='logout-btn-1'
                variant='secondary'
                onClick={handleCloseCancelModal}
              >
                No
              </button>
              <button
                className='logout-btn-2 ms-2'
                variant='primary'
                onClick={handleCloseCancelModal}
              >
                Yes
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Footer />
    </div>
  )
}

export default Booked_package_fulDetail