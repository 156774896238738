import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faEnvelopeOpen, faUser, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { BASE_URL } from "../BASE_URL";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import socket from "../context/Socket";

const formatDate = (dateString) => {
    // Create a Date object from the input date string
    const date = new Date(dateString);

    // Extract the day, month, and year in UTC from the date
    const day = String(date.getUTCDate()).padStart(2, '0'); // Ensure two digits
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getUTCFullYear();

    // Return the formatted date as "DD-MM-YYYY"
    return `${day}-${month}-${year}`;
};

const ProfileSection = ({ title }) => {

    const [notificationData, setNotificationData] = useState([]);

    useEffect(() => {
        // Listen for new notifications
        socket.on("newNotification", (data) => {
            setNotificationData((prevData) => [data?.agencyNotificationData, ...prevData]);
        });

        return () => {
            // Clean up the socket listener on component unmount
            socket.off("newNotification");
        };
    }, []);

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const navigate = useNavigate()

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleOptionClick = (option) => {
        if (option === "logout") {
            handleShow()
        } else if (option === "profile") {
            navigate("/vendor/myprofile")
        } else if (option === "changePassword") {
            navigate("/vendor/changepassword")
        }
        setDropdownOpen(false);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const [editedata, setEditedData] = useState("");

    const Call = async () => {
        const token = localStorage.getItem("vendorToken");
        const res = await fetch(`${BASE_URL}agency/profile`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });
        const data = await res.json();
        setEditedData(data?.data?.[0]);
    };

    useEffect(() => {
        Call();
    }, []);

    const [show, setShow] = useState(false)

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    const handleYesClick = () => {
        localStorage.removeItem("vendorToken")

        handleClose()

        navigate("/vendor/login")
    }

    const vendorToken = localStorage.getItem("vendorToken")

    useEffect(() => {
        if (!vendorToken) {
            navigate("/vendor/login")
        }
    }, [vendorToken])


    const [notification, setNotification] = useState(false)

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setNotification(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const fetchNotifications = async () => {
        const token = localStorage.getItem("vendorToken");
        const res = await fetch(`${BASE_URL}notification`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });
        const data = await res.json();
        setNotificationData(data?.data);
    };

    useEffect(() => {
        fetchNotifications();
    }, []);


    const loginHandle = async () => {

        try {
            const token = localStorage.getItem("vendorToken");
            const ids = notificationData?.map((notification) => notification._id);

            // Extract the array of _id from the notifications

            // Make the PUT API call with the array of _id
            const res = await fetch(`${BASE_URL}notification/update`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                body: JSON.stringify({ notificationIds: ids }), // Pass the array of _id
            });

            if (!res.ok) {
                throw new Error("Failed to update notifications");
            }

            const data = await res.json();

            fetchNotifications();
        } catch (err) {
            console.error("Error in loginHandle:", err);
        }
    };

    return (
        <section style={{ backgroundColor: "#fff" }}>
            <div className="costum_container">
                <div>
                    <div className="pt-2 align-items-center second_navbar">
                        <div className="ps-md-4 ps-sm-2 ps-2 pe-xl-5 pe-lg-5 pe-md-3 pe-sm-2 pe-2 align-items-center d-flex justify-content-between">
                            <div className="w-50 d-flex align-items-center">
                                <p className="mb-0" style={{ fontSize: "20px", fontWeight: "600" }}>{title}</p>
                            </div>
                            <div className="d-flex align-items-center" ref={dropdownRef}>
                                <span>
                                    <span style={{ position: "relative" }}>
                                        <FontAwesomeIcon
                                            icon={faBell}
                                            className="p-2 cmnclr cmnicon relative text16"
                                            onClick={() => {setNotification(!notification); loginHandle() }}
                                        />
                                        {notificationData?.filter((item) => item.status === "unread")?.length > 0 && (
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    top: "0",
                                                    right: "0",
                                                    transform: "translate(50%, -50%)",
                                                    backgroundColor: "red",
                                                    color: "white",
                                                    borderRadius: "50%",
                                                    padding: "4px 8px",
                                                    fontSize: "12px",
                                                    fontWeight: "bold",
                                                    zIndex: 1,
                                                }}
                                            >
                                                {notificationData?.filter((item) => item?.status === "unread")?.length}
                                            </span>
                                        )}
                                    </span>

                                    {notification && (
                                        <div className="notification-dropdown">
                                            <div>
                                                <h1 className="mb-3 text-center font700 text22">Notifications</h1>
                                                <hr />
                                            </div>
                                            <div className="dropdown-content">
                                                {notificationData && notificationData?.map((e, index) => (
                                                    <div key={index}>
                                                        <p className="notification-title text16" style={{ color: "black" }}>{e.title}</p>
                                                        <p className="notification-message mb-1" style={{ color: "black" }}>{e.text}</p>
                                                        <p className="notification-timestamp text12" style={{ color: "black" }}>
                                                            {e?.createdAt?.slice(11, 16)} <span className="text12" style={{ color: "black" }}>({formatDate(e?.createdAt)})</span>
                                                        </p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}

                                </span>



                                <div
                                    className="d-flex align-items-center cmn py-1 px-2 ms-2 position-relative"
                                    style={{ cursor: "pointer" }}
                                    onClick={toggleDropdown}
                                    ref={dropdownRef}
                                >
                                    <FontAwesomeIcon
                                        icon={faUser}
                                        className="cmnclr cmnicon p-2"
                                    />
                                    <p className="cmnp ps-1">{editedata?.full_name}</p>
                                    <FontAwesomeIcon
                                        icon={faCaretDown}
                                        className="ps-2"
                                    />
                                    {dropdownOpen && (
                                        <div className="dropdown-menu show position-absolute mt-5" style={{ top: "0px" }}>
                                            <button
                                                className="dropdown-item"
                                                onClick={() => handleOptionClick('profile')}>
                                                Profile
                                            </button>
                                            <button
                                                className="dropdown-item"
                                                onClick={() => handleOptionClick('changePassword')}>
                                                Change Password
                                            </button>
                                            <button
                                                className="dropdown-item"
                                                onClick={() => handleOptionClick('logout')}>
                                                Logout
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop='static'
                keyboard={false}
                centered
            >
                <Modal.Body className='p-5'>
                    <div>
                        <h4 className='text-center'>Are you Sure Want to Logout?</h4>
                    </div>
                    <div
                        className='d-flex justify-content-center'
                        style={{ gap: "10px", marginTop: "30px" }}
                    >
                        <button
                            style={{
                                width: "80px",
                                padding: "4px 0px",
                                backgroundColor: "lightgray",
                                borderRadius: "10px",
                                boxShadow: "none",
                                border: "none",
                                color: "grey",
                            }}
                            onClick={handleClose}
                        >
                            No
                        </button>
                        <button
                            style={{
                                width: "80px",
                                padding: "4px 0px",
                                backgroundColor: "#09646d",
                                borderRadius: "10px",
                                boxShadow: "none",
                                border: "none",
                                color: "white",
                            }}
                            onClick={handleYesClick}
                        >
                            Yes
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </section>
    );
};

export default ProfileSection;
