import {
    faArrowRight,
    faBus,
    faCar,
    faCheck,
    faChevronLeft,
    faChevronRight,
    faIndianRupeeSign,
    faPlane,
    faStar,
    faTrain,
    faUser,
    faXmark,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Form, Modal, Row } from "react-bootstrap"
import Header from "./Header"
import "./css/index1.css"
import { useState, useEffect } from "react"
import { useLocation, useParams } from "react-router-dom"
import { BASE_URL } from "../BASE_URL"
import { faTrainSubway } from "@fortawesome/free-solid-svg-icons"
import { faBinoculars } from "@fortawesome/free-solid-svg-icons"
import { faHotel } from "@fortawesome/free-solid-svg-icons"
import { faUtensils } from "@fortawesome/free-solid-svg-icons"
import { faTaxi } from "@fortawesome/free-solid-svg-icons"
import addressdata from "../CountryStateCity.json"
import { useRef } from "react"
import Footer from "./Footer"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useNavigate } from "react-router-dom"
import Destination1 from "./Destination1"

function CarDetails(props) {
    const overRef = useRef(null)

    const [show, setShow] = useState(false)
    const navigate = useNavigate();

    const [show1, setShow1] = useState(false)


    const handleClose1 = () => setShow1(false)
    const handleShow1 = () => setShow1(true)




    const { id } = useParams()
    const { merchentId } = useParams()
    const [pDetails, setPDetails] = useState([])

    const CarDetails = async () => {
        const res = await fetch(
            `${BASE_URL}vendor_car_syt/getById/${id}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            },
        )
        const data = await res.json()
        setPDetails(data.data)
    }

    const alertShow = () => {
        const token = localStorage.getItem("userToken")
        token ? handleShow1() : alert("please Login First")
    }

    useEffect(() => {
        CarDetails()
    }, [])




    const [fullname, setFullname] = useState("")
    const handleName = (e) => {
        setFullname(e.target.value)
    }

    const [departure1, setDeparture1] = useState("")
    const handleDeparture1 = (e) => {
        setDeparture1(e.target.value)
    }

    const [departure2, setDeparture2] = useState("")
    const handleDeparture2 = (e) => {
        setDeparture2(e.target.value)
    }

    const [email, setEmail] = useState("")
    const handleEmail = (e) => {
        setEmail(e.target.value)
    }

    const [number, setNumber] = useState("")
    const handleNumber = (e) => {
        setNumber(e.target.value)
    }
    const [gender, setGender] = useState("")
    const handleGender = (e) => {
        setGender(e.target.value)
    }


    const [customeCity1, setCustomeCity1] = useState("")
    const handleState1 = (e) => {
        setCustomeState1(e.target.value);
        setCustomeCity1("");
    }
    const [customeState1, setCustomeState1] = useState("")
    const handleCity1 = (e) => {
        setCustomeCity1(e.target.value)
    }

    const [customeCity2, setCustomeCity2] = useState("")
    const handleState2 = (e) => {
        setCustomeState2(e.target.value);
        setCustomeCity2("");
    }
    const [customeState2, setCustomeState2] = useState("")
    const handleCity2 = (e) => {
        setCustomeCity2(e.target.value)
    }

    const countries = addressdata.find((obj) => obj.name === "India");


    const [pickup_date, setStartDate] = useState("");
    const handleStartdatechange = (e) => setStartDate(e.target.value);

    const [pickup_time, setEndDate] = useState("");
    const handleEnddatechange = (e) => setEndDate(e.target.value);

    const [twoway, setTwoWay] = useState("")
    const handleTwoWay = (e) => {
        setTwoWay(e.target.value)
    }
    const [pricetype, setPriceType] = useState("")
    const handlePricetype = (e) => {
        setPriceType(e.target.value)
    }

    const [return_date, setReturnDate] = useState("");
    const handleReturnDatechange = (e) => setReturnDate(e.target.value);

    useEffect(() => {
        if (merchentId) {

            LastSubmit();
        }
        // }
    }, [merchentId]);


    const LastSubmit = async () => {
        // e.preventDefault();

        const token = localStorage.getItem("userToken");
        const res = await fetch(
            `${BASE_URL}payment/payment_status?merchantTransactionId=${merchentId}`,
            {
                method: "GET",
                headers: {
                    Authorization: token,
                    "Content-Type": "application/json",
                },
            }
        );
        const data = await res.json();

        // const token = localStorage.getItem("userToken")

        const bookingDetails = JSON.parse(localStorage.getItem("bookingDetail"));

        const updatedbookingDetails =
        {
            ...bookingDetails,
            transaction_id: data?.data?.merchantTransactionId,
            payment_type: data?.data?.paymentInstrument?.type,
        }
        // const token = localStorage.getItem("userToken");



        try {
            const res = await fetch(`${BASE_URL}car_booking_syt/car_booking`, {
                method: "POST",
                headers: {
                    Authorization: token,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(updatedbookingDetails),
            });

            const data = await res.json();

            if (data.success === true) {
                toast.success("Car Booked Successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 3000,
                });
                navigate("/my_package");
                handleClose1();
            } else {
                // Handle errors appropriately
                toast.error("Failed to book car. Please try again.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 3000,
                });
            }
        } catch (error) {
            // Handle fetch or network errors
            toast.error("An error occurred. Please try again later.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
        }
    };

    const location = useLocation();

    const handlePrice = async () => {

        if (
            !departure1 ||
            !customeState1 ||
            !customeCity1 ||
            !departure2 ||
            !customeState2 ||
            !customeCity2 ||
            !pickup_date ||
            !pickup_time ||
            !fullname ||
            !email ||
            !number ||
            !pricetype ||
            !twoway

        ) {
            toast.error("Enter All Details", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        }

        const myId = localStorage.getItem("my_id")
        const mobileno = number;
        // const amount = pDetails[0].price_per_person * (adult + child + infant)
        const amount = 1;
        const url = location.pathname;
        const token = localStorage.getItem("userToken")

        const res = await fetch(`${BASE_URL}payment/pay`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
            body: JSON.stringify({
                MERCHANT_USER_ID: myId,
                amount: amount,
                mobileNumber: mobileno,
                link: url,
                vendor_car_id: id,
            }),
        });

        const data = await res.json();

        const formData = {
            vendor_car_id: id,
            pickup_address: departure1 + ", " + customeState1 + ", " + customeCity1,
            drop_address: departure2 + ", " + customeState2 + ", " + customeCity2,
            one_way_two_way: twoway === "1" ? true : false,
            pickup_date: pickup_date,
            pickup_time: pickup_time,
            name: fullname,
            email: email,
            mobile_number: number,
            gender: gender,
            price_type: pricetype,
            car_condition: pDetails[0].car_condition,
            model_year: pDetails[0].model_year,
            insurance: pDetails[0].insurance,
            registration_number: pDetails[0].registration_number,
            color: pDetails[0].color,
            price_per_km: pDetails[0].price_per_km,
            price_per_day: pDetails[0].price_per_day,
            pincode: pDetails[0].pincode,
            city: pDetails[0].city,
            state: pDetails[0].state,
            outStateAllowed: pDetails[0].outStateAllowed,
            AC: pDetails[0].AC
        };

        if (twoway === "1") {
            formData.return_date = return_date;
        }

        localStorage.setItem('bookingDetail', JSON.stringify(formData));

        window.location.href = data?.data?.url

        // LastSubmit()
    }


    return (
        <div>
            <ToastContainer />
            <Header setShow={setShow} show={show} />
            {pDetails.map((ele) => {
                return (
                    <>
                        <section className='container-customes padding-costume-container py-xl-4 py-sm-4 py-2'>
                            <div className='row'>
                                <div className='col-xl-8 col-lg-6 col-sm-12 col-12 m-0 posi-first'>
                                    <div>
                                        <img
                                            // src="/bkg1.png"
                                            // src={packageData}
                                            src={ele.photos[0]}
                                            alt=''
                                            className='w-100 rounded h-md-50 meadia-for-package-details-img'
                                        />
                                    </div>
                                    <section>
                                        <div className=''>
                                            <div className='overview-btn mb-4' ref={overRef}>
                                                <button className='mb-3 mt-4'>Overview</button>
                                                <div className='mx-xl-0 mx-sm-0 mx-1'>
                                                    <span>Insurance </span>{ele.insurance ? "YES" : "NO"}
                                                </div>
                                                <div className='mx-xl-0 mx-sm-0 mx-1'>
                                                    <span>Registration Number: </span>{ele.registration_number}
                                                </div>
                                                <div className='mx-xl-0 mx-sm-0 mx-1'>
                                                    <span>Colour: </span> {ele.color}
                                                </div>
                                                <div className='mx-xl-0 mx-sm-0 mx-1'>
                                                    <span>AC: </span> {ele.AC ? "YES" : "NO"}
                                                </div>
                                                <div className='mx-xl-0 mx-sm-0 mx-1'>
                                                    <span>Number of Seats: </span>{ele.car_seats}
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                </div>
                                <div className='col-xl-4 col-lg-6 col-sm-12 col-12 m-0 posi-second'>
                                    <div className='CuStom_package package_details_2 book_now_card py-3 px-xl-3 px-sm-3 px-1 hyhy mb-3'>
                                        <div className='inner_packega'>
                                            <div className=''>
                                                <div className='border_bottom'>
                                                    <p className='text-19 mb-1'>{ele.car_details[0].car_name}</p>
                                                    <div className='d-flex justify-content-between'>
                                                        <div>
                                                            <p className='mb-0 mt-2'>
                                                                <button class='button-13' role='button'>
                                                                    {ele.car_details[0].fuel_type}
                                                                </button>
                                                            </p>
                                                            <p className='mb-2 ms-1 fw-bold'>
                                                                {ele.car_type}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <div className='for-18000 mb-2'>
                                                                <div className='d-flex justify-content-end'>
                                                                    <p className=''></p>
                                                                    <p className='ms-2 mb-0 price-per-person-media'>
                                                                        <FontAwesomeIcon
                                                                            className='pe-2'
                                                                            icon={faIndianRupeeSign}
                                                                        />
                                                                        {ele.price_per_km}
                                                                    </p>
                                                                    <div className='per-person-media text-end'>
                                                                        Per Km
                                                                    </div>
                                                                </div>
                                                                <div className='Per_Person text-end'>
                                                                    *Excluding applicable taxes
                                                                </div>
                                                            </div>
                                                            <div className='for-18000 mb-2'>
                                                                <div className='d-flex justify-content-end'>
                                                                    <p className=''></p>
                                                                    <p className='ms-2 mb-0 price-per-person-media'>
                                                                        <FontAwesomeIcon
                                                                            className='pe-2'
                                                                            icon={faIndianRupeeSign}
                                                                        />
                                                                        {ele.price_per_day}
                                                                    </p>
                                                                    <div className='per-person-media text-end'>
                                                                        Per Day
                                                                    </div>
                                                                </div>
                                                                <div className='Per_Person text-end'>
                                                                    *Excluding applicable taxes
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='For_Padd'>
                                                    <div className=' c-top-p text-center mt-3'>
                                                        <button
                                                            href=''
                                                            className='c_btn2 text-20 border-0'
                                                            onClick={alertShow}
                                                        >
                                                            Book Now
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Modal show={show1} onHide={handleClose1}>
                            <Modal.Body>
                                <div>
                                    <Row>
                                        <div>
                                            <form>
                                                <div className='cmn p-1'>
                                                    <p className='text-center'>Booking Details</p>
                                                    <div>

                                                        <Form.Control
                                                            type='text'
                                                            placeholder='Full Name'
                                                            style={{
                                                                borderLeft: "none",
                                                                borderRight: "none",
                                                                borderTop: "none",
                                                                fontSize: "10px",
                                                            }}
                                                            value={fullname}
                                                            // onChange={handleName}
                                                            onInput={(e) => {
                                                                e.target.value = e.target.value.replace(
                                                                    /[^A-Za-z ]/g,
                                                                    "",
                                                                )
                                                                handleName(e)
                                                            }}
                                                            className='mt-1 my-1'
                                                            name='fname'
                                                        // readOnly
                                                        />



                                                        <Form.Control
                                                            type='text'
                                                            placeholder='Email id'
                                                            style={{
                                                                borderLeft: "none",
                                                                borderRight: "none",
                                                                borderTop: "none",
                                                                fontSize: "10px",
                                                            }}
                                                            value={email}
                                                            onChange={handleEmail}
                                                            className='mt-1 my-1'
                                                            name='email'
                                                        // readOnly
                                                        />

                                                        <Form.Control
                                                            type='number'
                                                            placeholder='Mobile Number'
                                                            style={{
                                                                borderLeft: "none",
                                                                borderRight: "none",
                                                                borderTop: "none",
                                                                fontSize: "10px",
                                                            }}
                                                            value={number}
                                                            onChange={handleNumber}
                                                            className='mt-1 my-1'
                                                            name='mobile'
                                                        // readOnly
                                                        />
                                                        <select
                                                            style={{
                                                                border: "1px solid black",
                                                                fontSize: "10px",
                                                                width: "100%",
                                                                borderRadius: "0.325rem",
                                                                padding: ".375rem .75rem",

                                                            }}
                                                            value={gender}
                                                            onChange={handleGender}
                                                            className='mt-1 my-1'
                                                            name='mobile'
                                                        // readOnly
                                                        >
                                                            <option value="" disabled>Gender</option>
                                                            <option value="male">Male</option>
                                                            <option value="female">Female</option>
                                                        </select>
                                                        <div style={{ fontSize: "12px", marginTop: "10px" }}>Pick Up Address</div>
                                                        <Form.Control
                                                            type='text'
                                                            placeholder='Address'
                                                            style={{
                                                                borderLeft: "none",
                                                                borderRight: "none",
                                                                borderTop: "none",
                                                                fontSize: "10px",
                                                            }}
                                                            value={departure1}
                                                            onChange={handleDeparture1}
                                                            className='mt-1 my-1'
                                                            name='email'
                                                        // readOnly
                                                        />
                                                        <div className="row gx-2">
                                                            <div className="col-6">
                                                                <select
                                                                    id="state"
                                                                    name="state"
                                                                    style={{
                                                                        border: "1px solid black",
                                                                        fontSize: "10px",
                                                                        width: "100%",
                                                                        borderRadius: "0.325rem",
                                                                        padding: ".375rem .75rem",
                                                                    }}
                                                                    value={customeState1}
                                                                    onChange={handleState1}
                                                                    className='mt-1 my-1'
                                                                // readOnly
                                                                ><option value="" disabled selected>Select State</option>
                                                                    {countries.states &&
                                                                        countries.states.map((e) => {
                                                                            return (
                                                                                <option key={e.name} value={e.name}>{e.name}</option>
                                                                            )
                                                                        })}
                                                                </select>
                                                            </div>
                                                            <div className="col-6">
                                                                <select
                                                                    id="city"
                                                                    name="city"
                                                                    style={{
                                                                        border: "1px solid black",
                                                                        fontSize: "10px",
                                                                        width: "100%",
                                                                        borderRadius: "0.325rem",
                                                                        padding: ".375rem .75rem",
                                                                    }}
                                                                    value={customeCity1}
                                                                    onChange={handleCity1}
                                                                    className='mt-1 my-1'
                                                                // readOnly
                                                                ><option value="" disabled selected>Select City</option>
                                                                    {customeState1 &&
                                                                        countries.states
                                                                            .find((state) => state.name === customeState1)
                                                                            .cities.map((city) => (
                                                                                <option key={city.name} value={city.name}>
                                                                                    {city.name}
                                                                                </option>
                                                                            ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div style={{ fontSize: "12px", marginTop: "10px" }}>Drop Address</div>
                                                        <Form.Control
                                                            type='text'
                                                            placeholder='Address'
                                                            style={{
                                                                borderLeft: "none",
                                                                borderRight: "none",
                                                                borderTop: "none",
                                                                fontSize: "10px",
                                                            }}
                                                            value={departure2}
                                                            onChange={handleDeparture2}
                                                            className='mt-1 my-1'
                                                            name='email'
                                                        // readOnly
                                                        />
                                                        <div className="row gx-2">
                                                            <div className="col-6">
                                                                <select
                                                                    id="state"
                                                                    name="state"
                                                                    style={{
                                                                        border: "1px solid black",
                                                                        fontSize: "10px",
                                                                        width: "100%",
                                                                        borderRadius: "0.325rem",
                                                                        padding: ".375rem .75rem",
                                                                    }}
                                                                    value={customeState2}
                                                                    onChange={handleState2}
                                                                    className='mt-1 my-1'
                                                                // readOnly
                                                                ><option value="" disabled selected>Select State</option>
                                                                    {countries.states &&
                                                                        countries.states.map((e) => {
                                                                            return (
                                                                                <option key={e.name} value={e.name}>{e.name}</option>
                                                                            )
                                                                        })}
                                                                </select>
                                                            </div>
                                                            <div className="col-6">
                                                                <select
                                                                    id="city"
                                                                    name="city"
                                                                    style={{
                                                                        border: "1px solid black",
                                                                        fontSize: "10px",
                                                                        width: "100%",
                                                                        borderRadius: "0.325rem",
                                                                        padding: ".375rem .75rem",
                                                                    }}
                                                                    value={customeCity2}
                                                                    onChange={handleCity2}
                                                                    className='mt-1 my-1'
                                                                // readOnly
                                                                ><option value="" disabled selected>Select City</option>
                                                                    {customeState2 &&
                                                                        countries.states
                                                                            .find((state) => state.name === customeState2)
                                                                            .cities.map((city) => (
                                                                                <option key={city.name} value={city.name}>
                                                                                    {city.name}
                                                                                </option>
                                                                            ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="row gx-2">
                                                            <div className="col-6">
                                                                <div style={{ fontSize: "10px", marginTop: "10px" }}>Pickup Date:</div>
                                                                <Form.Control
                                                                    type='date'
                                                                    placeholder='State'
                                                                    style={{
                                                                        borderLeft: "none",
                                                                        borderRight: "none",
                                                                        borderTop: "none",
                                                                        fontSize: "10px",
                                                                    }}
                                                                    value={pickup_date}
                                                                    onChange={handleStartdatechange}
                                                                    className='mt-1 my-1'
                                                                    name='startdate'
                                                                // readOnly
                                                                />
                                                            </div>
                                                            <div className="col-6">
                                                                <div style={{ fontSize: "10px", marginTop: "10px" }}>Pick up Time:</div>
                                                                <Form.Control
                                                                    type='time'
                                                                    placeholder='City'
                                                                    style={{
                                                                        borderLeft: "none",
                                                                        borderRight: "none",
                                                                        borderTop: "none",
                                                                        fontSize: "10px",
                                                                    }}
                                                                    value={pickup_time}
                                                                    onChange={handleEnddatechange}
                                                                    className='mt-1 my-1'
                                                                    name='enddate'
                                                                // readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                        {/* <div className="row gx-2"> */}
                                                        <select
                                                            style={{
                                                                border: "1px solid black",
                                                                fontSize: "10px",
                                                                width: "100%",
                                                                borderRadius: "0.325rem",
                                                                padding: ".375rem .75rem",

                                                            }}
                                                            value={twoway}
                                                            onChange={handleTwoWay}
                                                            className='mt-1 my-1'
                                                            name='mobile'
                                                        // readOnly
                                                        >
                                                            <option value="" disabled>One Way / Two Way</option>
                                                            <option value="0">One Way</option>
                                                            <option value="1">Two Way</option>
                                                        </select>

                                                        {twoway === "1" &&
                                                            <div className="col-6">
                                                                <div style={{ fontSize: "10px" }}>Return Date:</div>
                                                                <Form.Control
                                                                    type='date'
                                                                    placeholder='State'
                                                                    style={{
                                                                        borderLeft: "none",
                                                                        borderRight: "none",
                                                                        borderTop: "none",
                                                                        fontSize: "10px",
                                                                    }}
                                                                    value={return_date}
                                                                    onChange={handleReturnDatechange}
                                                                    className='mt-1 my-1'
                                                                    name='returndate'
                                                                // readOnly
                                                                />
                                                            </div>
                                                        }
                                                        {/* </div> */}
                                                        <select
                                                            style={{
                                                                border: "1px solid black",
                                                                fontSize: "10px",
                                                                width: "100%",
                                                                borderRadius: "0.325rem",
                                                                padding: ".375rem .75rem",

                                                            }}
                                                            value={pricetype}
                                                            onChange={handlePricetype}
                                                            className='mt-1 my-1'
                                                            name='mobile'
                                                        // readOnly
                                                        >
                                                            <option value="" disabled>Select Price Type</option>
                                                            <option value="km">Price Per Km = {ele.price_per_km}</option>
                                                            <option value="day">Price Per Day = {ele.price_per_day}</option>
                                                        </select>

                                                    </div>
                                                </div>
                                                <div className='text-center pt-2'>
                                                    <p
                                                        className='w-100 border-0 py-1'
                                                        style={{
                                                            background: "#09646D",
                                                            color: "#fff",
                                                            borderRadius: "8px",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={handlePrice}
                                                    >
                                                        Proceed to Pay
                                                    </p>
                                                </div>
                                            </form>
                                        </div>
                                    </Row>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </>
                )
            })}

            <hr />
            <Footer />
        </div>
    )
}

export default CarDetails;
