/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-pascal-case */
import React, { useState, useContext, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../Project/css/index1.css";
import Header2 from "./Header2";
import { Form, Row, Button, Modal } from "react-bootstrap";
import My_pannel from "./My_pannel";
import { BASE_URL } from "../BASE_URL";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loader from "../images/loading.png";
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

const PackageItineraryUpdate = () => {

    const location = useLocation();

    const hotelType = "";

    const [roomDisplayId, setRoomDisplayId] = useState("")
    const [roomDisplayName, setRoomDisplayName] = useState("")
    const [roomDisplayPrice, setRoomDisplayPrice] = useState("")
    const [meals, setMeals] = useState("")

    const [loading, setLoading] = useState(false);
    const [hotelList, setHotelList] = useState([]);
    const [selectedHotel, setSelectedHotel] = useState(null);


    const navigate = useNavigate();

    const { packageId, _id } = useParams();

    const [editorData, setEditorData] = useState('');

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setEditorData(data);
    };

    const [title, setTitle] = useState("")
    const [day, setDay] = useState(1)
    const [photo, setPhoto] = useState(null)
    const [selectedImage, setSelectedImage] = useState(null)

    const getItinerary = async () => {
        const res = await fetch(`${BASE_URL}package/getPackageData?package_id=${packageId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        const data = await res.json();
        const foundItinerary = data?.data?.[0]?.Itinaries?.find((e) => e?._id === _id);

        const localHotelId = localStorage.getItem("selectedHotelId")
        const localMeals = localStorage.getItem("selectedMeals")
        const localRoomId = localStorage.getItem("selectedRoomId")

        setDay(foundItinerary?.day);
        setTitle(foundItinerary?.title);
        setEditorData(foundItinerary?.activity);
        setSelectedImage(foundItinerary?.photo);

        setSelectedHotel(foundItinerary?.hotel_itienrary_id);
        setHotelList(foundItinerary?.hotel_name);
        setRoomDisplayName(foundItinerary?.selected_rooms?.[0]?.room_type);
        setRoomDisplayPrice(foundItinerary?.selected_rooms?.[0]?.room_type_price);
        setRoomDisplayId(foundItinerary?.selected_rooms?.[0]?._id)

        const mealData = [];

        if (foundItinerary?.breakfast) {
            mealData.push({ name: "Breakfast", price: foundItinerary?.breakfast_price });
        }
        if (foundItinerary?.lunch) {
            mealData.push({ name: "Lunch", price: foundItinerary?.lunch_price });
        }
        if (foundItinerary?.dinner) {
            mealData.push({ name: "Dinner", price: foundItinerary?.dinner_price });
        }
        setMeals(mealData);
    };

    useEffect(() => {
        getItinerary();
    }, [_id]);

    const [error, setError] = useState(null);

    const handlePhotoChange = (e) => {
        const file = e.target.files[0];

        if (file && file.size > 5 * 1024 * 1024) {
            setError("File size should be less than 5MB.");
            e.target.value = ''; // Clear the input to reset the selection
        } else if (file) {
            setError(null); // Clear the error if the file is valid

            // Create a preview URL for the selected image
            const previewUrl = URL.createObjectURL(file);
            setSelectedImage(previewUrl);

            // Optionally, store the file for further processing (e.g., upload)
            setPhoto(file);
        }
    };


    const fileInputRef = useRef(null);
    const AddItineriesData = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem("vendorToken");

        if (!day || !title || !editorData) {
            toast.error("Please fill all details!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }

        if (!photo && !selectedImage) {
            toast.error("Please select an image!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }

        if (!hotelType || hotelType === "Not Required") {
            toast.error("Please fill all details!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }

        if (day > 10) {
            toast.error("Please enter a valid day!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }

        setLoading(true);

        const formData = new FormData();
        formData.append("title", title);
        formData.append("activity", editorData);
        formData.append("photo", photo);

        if (hotelType !== "Not Required") {
            formData.append("hotel_itinerary_id", selectedHotel);
            formData.append("room_id", roomDisplayId);
        }

        // Format Meal Data
        const formattedData = {
            breakfast_price: 0,
            lunch_price: 0,
            dinner_price: 0,
            breakfast: false,
            lunch: false,
            dinner: false
        };

        meals.forEach(({ name, price }) => {
            const keyPrice = `${name.toLowerCase()}_price`;
            const keyAvailability = name.toLowerCase();

            if (formattedData.hasOwnProperty(keyPrice)) {
                formattedData[keyPrice] = price;
            }
            if (formattedData.hasOwnProperty(keyAvailability)) {
                formattedData[keyAvailability] = true;
            }
        });

        // Append meal data to formData
        Object.entries(formattedData).forEach(([key, value]) => {
            formData.append(key, value);
        });

        if (hotelType === "Not Required") {
            formData.append("hotel_itinerary_id", "");
        } else {
            // formData.append("hotel_itinerary_id", selectedHotel1);
        }

        try {
            const res = await fetch(`${BASE_URL}itinerary?_id=${_id}`, {
                method: "PUT",
                headers: { Authorization: token },
                body: formData,
            });

            if (res.status === 200) {
                setHotelList("");
                toast.success("Itinerary added successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });

                setTitle("");
                getItinerary();
                setSelectedHotel(null);
                setEditorData("");
                setTimeout(() => {
                    navigate(-1);
                }, 1000);

                if (fileInputRef.current) {
                    fileInputRef.current.value = null;
                }
            } else {
                throw new Error("Failed to add itinerary");
            }
        } catch (error) {
            toast.error(error.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
        } finally {
            setLoading(false);
        }
    };

    const handleNavigate = () => {
        localStorage.setItem("updatedHotelId", hotelList)
        localStorage.setItem("updatedRoomId", roomDisplayId)
        localStorage.setItem("updatedMeals", JSON.stringify(meals))
        navigate('/vendor/select-itinerary-hotel')
    }

    return (
        <>
            <Header2 />
            <div className='costum_container'>
                <My_pannel />
                <ToastContainer />
                <section>
                    <div className='costum_container'>
                        <div className=' p-3'>
                            <div>
                                <Form>
                                    <Row
                                        className='itinerary_padding green_border gy-2 gx-5 margin_left_right justify-content-center'
                                        style={{ backgroundColor: "#ffffff" }}
                                    >
                                        <div className='col-12'>
                                            <div className='row mb-3'>
                                                <div className='col-12'>
                                                    <span className='text-20 itinerary_header text20 font700'>
                                                        Day wise Itinerary plan
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row d-flex justify-content-center'>
                                            <div
                                                className='col-12'
                                                style={{
                                                    border: "1px solid grey",
                                                    borderRadius: "15px",
                                                }}
                                            >
                                                <div className='p-3'>
                                                    <div>
                                                        <div className='mb-2'>
                                                            <label htmlFor='text' className="text14 font600 mb-1">Day</label>
                                                            <input
                                                                type='number'
                                                                name='day'
                                                                value={day}
                                                                readOnly
                                                            />
                                                        </div>
                                                        <div className='mb-2'>
                                                            <label htmlFor='text' className="text14 font600 mb-1">Add Title</label>
                                                            <input type='text' value={title} name='title' onChange={(e) => setTitle(e.target.value)} />
                                                        </div>
                                                        {hotelType && hotelType === "Not Required" ? (
                                                            <></>
                                                        ) : (
                                                            <div>
                                                                <div className="mb-2">
                                                                    <label htmlFor="text" className="text14 font600 mb-1">Select Hotel</label>
                                                                    <div className="d-flex align-items-center">
                                                                        <input
                                                                            type="text"
                                                                            name="select_hotel"
                                                                            className="flex-grow-1"
                                                                            value={hotelList}
                                                                            readOnly
                                                                        />
                                                                        <Button
                                                                            variant="primary"
                                                                            style={{
                                                                                backgroundColor: "#09646d",
                                                                                border: "none",
                                                                            }}
                                                                            className="ms-2"
                                                                            onClick={handleNavigate}
                                                                        >
                                                                            +
                                                                        </Button>
                                                                    </div>
                                                                </div>

                                                                {hotelType !== "Not Required" && (
                                                                    <div style={{ display: "flex", gap: "30px" }}>

                                                                        {/* Display Selected Room Details */}
                                                                        {roomDisplayName && (
                                                                            <div className="mb-2 p-2 border rounded bg-light">
                                                                                <h6 className="font600 mb-1" style={{ color: "#09646d" }}>Room Details</h6>
                                                                                <p className="mb-1"><strong>Room:</strong> {roomDisplayName}</p>
                                                                                <p className="mb-1"><strong>Price:</strong> {roomDisplayPrice}</p>
                                                                            </div>
                                                                        )}

                                                                        {/* Display Selected Meals */}
                                                                        {meals && Array.isArray(meals) && meals.length > 0 && (
                                                                            <div className="mb-2 p-2 border rounded bg-light">
                                                                                <h6 className="font600 mb-1" style={{ color: "#09646d" }}>Meal Details</h6>
                                                                                {meals.map((meal, index) => (
                                                                                    <p key={index} className="mb-1">
                                                                                        <strong>{meal.name}:</strong> {meal.price}
                                                                                    </p>
                                                                                ))}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )}


                                                            </div>
                                                        )}
                                                        <div className='mb-3 d-flex gap-4 mt-3'>
                                                            <div className="mb-1">
                                                                <img
                                                                    src={selectedImage || 'defaultImageURL'}
                                                                    alt="Selected Preview"
                                                                    style={{ height: "60px", width: "60px", borderRadius: "50%" }}
                                                                />
                                                            </div>
                                                            <div>
                                                                <label htmlFor='text' className="text14 font600 mb-1">Update Photo ( File Size Max 5MB )</label>
                                                                <input
                                                                    type="file"
                                                                    name="photo"
                                                                    accept="image/*"
                                                                    onChange={handlePhotoChange}
                                                                    className="w-full"
                                                                />
                                                            </div>
                                                            {error && <p className="mb-0 text12" style={{ color: 'red' }}>{error}</p>}
                                                        </div>
                                                        <div className='mb-2 ckeditor-p-tag d-flex flex-column gap-1'>
                                                            <a className="text14 font600" style={{ textDecoration: "none", color: "black" }}>Description/Activity</a>
                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                data={editorData}
                                                                onChange={handleEditorChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ display: "flex", justifyContent: "end" }}>
                                                    {loading ? (
                                                        <button
                                                            className='m-3'
                                                            style={{
                                                                width: "105px",
                                                                height: "32px",
                                                                backgroundColor: "#09646d",
                                                                border: "none",
                                                                color: "white",
                                                                borderRadius: '10px'
                                                            }}
                                                        >
                                                            <img src={loader} alt="" className="h-75 spinner" />
                                                        </button>
                                                    ) : (
                                                        <button
                                                            className='m-3 check-availibility-button text-20 border-0'
                                                            // style={{
                                                            //   width: "160px",
                                                            //   height: "40px",
                                                            //   backgroundColor: "#09646d",
                                                            //   border: "none",
                                                            //   color: "white",
                                                            // }}
                                                            onClick={AddItineriesData}
                                                        >
                                                            Submit
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                </Form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* Modal */}
        </>
    );
};

export default PackageItineraryUpdate