import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { BASE_URL } from "../BASE_URL";
import userSocket from "../context/UserSocket";

const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
        return "";
    }
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
};

function Notifications() {
    const [yuvraj, setYuvraj] = useState([])
    // console.log(yuvraj)

    const fetchNotifications = async () => {
        try {
            const token = localStorage.getItem("userToken");
            const res = await fetch(`${BASE_URL}notification/customer`, {
                method: "GET",
                headers: {
                    Authorization: token,
                    "Content-Type": "application/json",
                },
            });

            if (!res.ok) throw new Error("Failed to fetch notifications");

            const data = await res.json();
            console.log(data.data)
            setYuvraj(data?.data);
        } catch (err) {

        }
    };

    useEffect(() => {
        fetchNotifications();
    }, []);

    const [newNotification, setNewNotification] = useState("");

    useEffect(() => {
        setYuvraj((prev) => [newNotification, ...prev]);
    }, [newNotification]);

    useEffect(() => {
        userSocket?.on("newNotification", (newMessage) => {

            setYuvraj((prev) => [newMessage, ...prev]);
        });

        return () => userSocket?.off("newNotification");
    }, [userSocket]);

    const loginHandle = async () => {

        try {
            const token = localStorage.getItem("userToken");
            const ids = yuvraj?.map((notification) => notification._id);

            // Extract the array of _id from the notifications

            // Make the PUT API call with the array of _id
            const res = await fetch(`${BASE_URL}notification/update`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                body: JSON.stringify({ notificationIds: ids }), // Pass the array of _id
            });

            if (!res.ok) {
                throw new Error("Failed to update notifications");
            }

            const data = await res.json();

            fetchNotifications();
        } catch (err) {
            console.error("Error in loginHandle:", err);
        }
    };

    useEffect(() => {
        loginHandle();
    }, []);



    return (
        <>
            <Header />
            <section>
                <div className="container-customes px-sm-5 px-2">
                    <div className="pt-3">
                        <h1
                            className="text-center booked-package-list-media text32 font800"
                            style={{ color: "#09646d" }}
                        >
                            Notifications
                        </h1>
                    </div>

                    {yuvraj && yuvraj.length > 0 ? (
                        yuvraj?.map((notification) => (
                            <div
                                key={notification._id}
                                className="notification-card border rounded p-3 my-3 shadow-sm"
                                style={{ borderColor: "#09646d", backgroundColor: "#f1fafa" }}
                            >
                                <h5
                                    className="notification-title font-weight-bold mb-2"
                                    style={{ color: "#09646d" }}
                                >
                                    {notification.title}
                                </h5>
                                <p className="notification-text mb-1">{notification.text}</p>
                                <p className="notification-date text-muted small">
                                    {`Date: ${formatDate(notification.date_and_time)}`}
                                </p>
                            </div>
                        ))
                    ) : (
                        <p className="text-center text-muted mt-5">
                            No notifications available.
                        </p>
                    )}
                </div>
            </section>
            <Footer />
        </>
    );
}

export default Notifications;
