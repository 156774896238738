import React, { createContext, useContext, useState } from "react";

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [triggered, setTriggered] = useState(false);

  const fetchData = () => {
    setTriggered(true); // Signal that fetchData has been called
  };

  return (
    <DataContext.Provider value={{ fetchData, triggered }}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => useContext(DataContext);
