import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faEnvelopeOpen, faUser } from "@fortawesome/free-solid-svg-icons";
import My_pannel from "./My_pannel";
import Header2 from "./Header2";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { BASE_URL } from "../BASE_URL";
import Button from "react-bootstrap/Button";
import ProfileSection from "./SecondNavbar";

const CarCard = ({ car }) => {
    const navigate = useNavigate();

    const handleViewCar = () => {
        navigate(`/vendor/mycars/${car._id}`);
    };


    const cardStyle = {
        // display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#fff',
        border: '1px solid #ddd',
        borderRadius: '8px',
        // margin: '10px',
        padding: '15px',
        width: "100%",
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
    };

    const imageContainerStyle = {
        display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'center',
        height: '200px',
        overflow: 'hidden',
        borderBottom: '1px solid #ddd'
    };

    const imageStyle = {
        width: '100%',
        height: 'auto'
    };

    const detailsStyle = {
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    };

    const infoStyle = {
        marginBottom: '10px'
    };

    const nameStyle = {
        fontSize: '18px',
        fontWeight: 'bold',
        marginBottom: '5px'
    };

    const textStyle = {
        fontSize: '14px',
        marginBottom: '5px'
    };

    const buttonStyle = {
        alignSelf: 'flex-end',
        padding: '10px 20px',
        backgroundColor: '#155E75',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        transition: 'background-color 0.3s'
    };

    const buttonHoverStyle = {
        backgroundColor: '#0d4c5b'
    };

    return (
        <div style={cardStyle}>
            <div style={imageContainerStyle}>
                <img src={car.photos[0] ? car.photos[0] : '/car-placeholder.png'} style={imageStyle} alt="photos" />
            </div>
            <div style={detailsStyle}>
                <div style={infoStyle}>
                    <div style={nameStyle}>{car.car_details[0]?.car_name}</div>
                    <div style={textStyle}>Model: {car.car_details[0]?.model_number}</div>
                    <div style={textStyle}>Year: {car.model_year}</div>
                    <div style={textStyle}>Price Per Km: {car.price_per_km}</div>
                    <div style={textStyle}>Price Per Day: {car?.min_price_per_day}</div>
                </div>
                <button style={{ ...buttonStyle, ...buttonHoverStyle }} onClick={handleViewCar}>View</button>
            </div>
        </div>
    );
};

const MyCars = () => {
    const navigate = useNavigate();

    const [editedata, setEditedData] = useState("");

    const Call = async () => {
        const token = localStorage.getItem("vendorToken");
        const res = await fetch(`${BASE_URL}agency/profile`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });
        const data = await res.json();
        setEditedData(data?.data?.[0]);
    };

    useEffect(() => {
        Call();
    }, []);

    const handleNav = () => {
        navigate("/vendor/myprofile");
    };

    const handleAddCar = () => {
        navigate(`/vendor/mycars/Addcar`)
    }

    const [cars, setCars] = useState([]);
    const [error, setError] = useState(null);

    const fetchCars = async () => {
        try {
            const token = localStorage.getItem("vendorToken");
            const response = await fetch(`${BASE_URL}vendor_car_syt/getAll/`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }); // Replace with your actual API endpoint
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();

            setCars(data?.data || []);

        } catch (error) {
            setError(error.message);
        }
    };

    useEffect(() => {
        fetchCars();
    }, []);

    const containerStyle = {
        display: 'grid',
        gridTemplateColumns: "1fr 1fr 1fr 1fr",
        flexWrap: 'wrap',
        gap: "20px"
        // justifyContent: 'space-around'
    };

    return (
        <>
            <Header2 />
            <div className="costum_container">
                <My_pannel />
                <div style={{ backgroundColor: "white", minHeight: "100vh" }}>
                    <ProfileSection title="My Cars" />

                    <section className="new-vendor-container">
                        <div style={{ display: "flex", justifyContent: "end" }}>
                            <button className="check-availibility-button text-20 border-0 mb-4" onClick={handleAddCar}>
                                Add Car
                            </button>
                        </div>
                        {/* <div className="">
                            <Button
                                variant="primary"
                                className="ms-2"
                                style={{
                                    backgroundColor: "#155E75",
                                    padding: "8px 40px",
                                }}
                                onClick={handleAddCar}
                            >
                                Add Car
                            </Button>
                        </div> */}
                        {/* <hr style={{ borderBottom: "3px solid #09646D" }}></hr> */}
                        <div style={containerStyle}>
                            {
                                cars.length !== 0 ? cars.map((car) => (
                                    <CarCard key={car._id} car={car} />
                                )) : <div>No Cars Added</div>
                            }
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
};

export default MyCars;
