import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../BASE_URL";
import Header2 from "./Header2";
import My_pannel from "./My_pannel";
import ProfileSection from "./SecondNavbar";

const getStatus = (approx_start_date, approx_end_date) => {
  const currentDate = new Date();
  const startDate = new Date(approx_start_date);
  const endDate = new Date(approx_end_date);

  if (currentDate > endDate) {
    return "Completed";
  } else if (currentDate >= startDate && currentDate <= endDate) {
    return "Ongoing";
  } else if (currentDate < startDate) {
    return "Upcoming";
  }
};

const rightDate = (dateString) => {
  const [year, month, day] = dateString?.split("-");
  return `${day}-${month}-${year}`;
};

function Booked_packega() {
  const [bookData, setBookData] = useState([]);
  const navigate = useNavigate();

  const displayBookPackage = async () => {
    const token = localStorage.getItem("vendorToken");
    const res = await fetch(`${BASE_URL}bookpackage/bookpackageforagency_jaydev`, {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    });
    const data = await res.json();
    setBookData(data.data);
  };

  useEffect(() => {
    displayBookPackage();
  }, []);

  const handleNavigate = (packageId) => {
    navigate(`/vendor/Book-packega/details/${packageId}`);
  };

  const getStatusBackgroundColor = (status) => {
    switch (status) {
      case "Completed":
        return "#09646d";
      case "Ongoing":
        return "#f0ad4e";
      case "Upcoming":
        return "#5bc0de";
      default:
        return "#cccccc";
    }
  };

  return (
    <>
      <Header2 />
      <div className="costum_container">
        <My_pannel />
        <div style={{ backgroundColor: "white" }}>
          <ProfileSection title="Booked Package List" />
          <section className="new-vendor-container">
            {bookData.length === 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "60vh",
                  color: "gray",
                  fontWeight: 700,
                  fontSize: "22px",
                }}
              >
                No Booking Data
              </div>
            ) : (
              <div className="row">
                {bookData.map((ele) => (
                  <div className="col-sm-6 col-12" key={ele._id}>
                    <section className="pt-2 pb-3">
                      <div>
                        <Row className="mx-auto">
                          <div className="col-xl-12 col-lg-6 col-sm-12 col-12 m-0 p-0">
                            <div className="inner_CuStom_package">
                              <div className="booking-list-grid h-100 w-full px-3 py-3">
                                <img
                                  src={ele?.packageDetails?.[0]?.photo}
                                  alt="img"
                                  className="booking-list-header-image"
                                />
                                <div className="h-auto w-100">
                                  <div className="CuStom_package_content my_package_content">
                                    <div className="d-flex justify-content-between">
                                      <p className="mb-xl-2 mb-lg-0 text14">
                                        <span className="text18 font600">{ele?.title}</span>
                                      </p>
                                    </div>
                                    <p className="mb-xl-2 mb-lg-0 text14">
                                      <span className="text14 font600">Booking Id</span> : {ele?._id}
                                    </p>
                                    <p className="mb-xl-2 mb-lg-0 text14">
                                      <span className="text14 font600">Package Type</span> :{" "}
                                      {ele?.packageDetails?.length > 0 ? "Ready Package" : "Bid Package"}
                                    </p>
                                    <p className="mb-xl-2 mb-lg-0 text14">
                                      <span className="text14 font600">Booking Date</span> :{" "}
                                      {rightDate(ele?.bookdate?.slice(0, 10))}
                                    </p>
                                    <p className="mb-xl-2 mb-lg-0 text14">
                                      <span className="text14 font600">Trip Duration</span> :{" "}
                                      {ele?.approx_start_date
                                        ? rightDate(ele.approx_start_date)
                                        : "N/A"}{" "}
                                      to{" "}
                                      {ele?.approx_end_date
                                        ? rightDate(ele.approx_end_date)
                                        : "N/A"}
                                    </p>
                                    <div className="d-flex justify-content-between mb-sm-2 mb-0 mt-3">
                                      <button
                                        className="button-9091"
                                        style={{
                                          boxShadow: "none",
                                          backgroundColor: getStatusBackgroundColor(
                                            getStatus(ele?.approx_start_date, ele?.approx_end_date)
                                          ),
                                          color: "white",
                                        }}
                                      >
                                        {getStatus(ele?.approx_start_date, ele?.approx_end_date)}
                                      </button>
                                      <button
                                        className="button-9090"
                                        onClick={() => handleNavigate(ele?._id)}
                                        style={{ boxShadow: "none" }}
                                      >
                                        View Details
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Row>
                      </div>
                    </section>
                  </div>
                ))}
              </div>
            )}
          </section>
        </div>
      </div>
    </>
  );
}

export default Booked_packega;
