import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faEnvelopeOpen,
  faUser,
  faPlus,
  faTrash,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Header2 from "./Header2";
import My_pannel from "./My_pannel";
import { BASE_URL } from "../BASE_URL";
import "./css/Hotels.css";
import { Button, Modal, Form } from 'react-bootstrap';
import MapComponent from "./MapComponent";
import RoomCard from "./RoomCard";
import addressdata from "../CountryStateCity.json"
import { Policy } from "@mui/icons-material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProfileSection from "./SecondNavbar";


function AddCar() {
  const navigate = useNavigate();

  const [carList, setCarList] = useState([])

  const getCarData = async () => {
    const token = localStorage.getItem("vendorToken");

    try {
      const response = await fetch(`${BASE_URL}car_syt`, {
        method: 'GET',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const car = data.data;
      setCarList(car)

    } catch (error) {
      console.error('Error fetching car details:', error);

    }
  };

  useEffect(() => {
    getCarData();
  }, []);

  const [carData, setCarData] = useState({
    car_id: "",
    car_condition: "",
    model_year: "",
    insurance: "",
    registration_number: "",
    color: "",
    pincode: "",
    city: "",
    state: "",
    outStateAllowed: "",
    AC: "",
    price_per_km: "",
    price_per_day: "",
  });

  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedInsurance, setSelectedInsurance] = useState("");
  const [selectedOutStateAllowed, setSelectedOutStateAllowed] = useState("");
  const [selectedAC, setSelectedAC] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "state") {
      setSelectedState(value);
      setSelectedCity("");
    } else if (name === "city") {
      setSelectedCity(value);
    } else if (name === "insurance") {
      setSelectedInsurance(value);
    } else if (name === "outStateAllowed") {
      setSelectedOutStateAllowed(value);
    } else if (name === "AC") {
      setSelectedAC(value);
    } else {
      setCarData({
        ...carData,
        [name]: value,
      });
    }
  };

  const countries = addressdata.find((obj) => obj.name === "India");

  const [images, setImages] = useState([]);
  const fileInputRef = useRef(null);

  const handleAddImageClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    const fileObjects = files.map(file => ({ file, url: URL.createObjectURL(file) }));
    setImages(prevImages => [...prevImages, ...fileObjects]);
  };

  const handleRemoveImage = (indexToRemove) => {
    setImages(prevImages => prevImages.filter((_, index) => index !== indexToRemove));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!carData.car_id) {
      toast.error("Car Name is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }

    if (!carData.car_condition) {
      toast.error("Car condition is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }
    const d = new Date();
    let year = d.getFullYear();
    if (!carData.model_year || carData.model_year > year || carData.model_year < 1900) {
      toast.error("Model year is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }
    if (selectedInsurance === "") {
      toast.error("Insurance status is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }
    if (!carData.registration_number) {
      toast.error("Registration number is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }
    if (!carData.color) {
      toast.error("Car color is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }
    if (!carData.pincode || carData.pincode.length !== 6) {
      toast.error("Pincode is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }
    if (!selectedState) {
      toast.error("State is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }
    if (!selectedCity) {
      toast.error("City is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }
    if (selectedOutStateAllowed === "") {
      toast.error("Out state allowed status is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }
    if (selectedAC === "") {
      toast.error("AC status is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }
    if (images.length < 3) {
      toast.error("At least 3 car images are required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }
    if (carData.price_per_km === "" || !carData.price_per_km) {
      toast.error("price per kilometer is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }
    if (carData.price_per_day === "" || !carData.price_per_day) {
      toast.error("price per day is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }
    const formData = new FormData();
    formData.append("car_id", carData.car_id);
    formData.append("car_condition", carData.car_condition);
    formData.append("model_year", carData.model_year);
    formData.append("insurance", selectedInsurance);
    images.forEach(({ file }, index) => {
      formData.append("photos", file);
    });
    formData.append("registration_number", carData.registration_number);
    formData.append("color", carData.color);
    formData.append("price_per_km", carData.price_per_km);
    formData.append("price_per_day", carData.price_per_day);
    formData.append("pincode", carData.pincode);
    formData.append("state", selectedState);
    formData.append("city", selectedCity);
    formData.append("outStateAllowed", selectedOutStateAllowed);
    formData.append("AC", selectedAC);

    const token = localStorage.getItem("vendorToken");
    const res = await fetch(`${BASE_URL}vendor_car_syt`, {
      method: "POST",
      headers: {
        Authorization: token,
      },
      body: formData,
    });

    const data = await res.json();
    if (res.ok) {
      toast.success("Car Added", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      // navigate to another page if needed
      navigate('/vendor/mycars');
    } else {
      alert(`Failed to register car: ${data.message}`);
    }
  };




  return (
    <>
      <Header2 />
      <div className="costum_container">
        <My_pannel />
        <ToastContainer />
        <div >

          <ProfileSection title="My Cars" />

          <section className="hotel_section_margin p-4">
            <h5>Add Car Details</h5>
            <div className="hotel-details-container">
              <div className="form-group-container">
                <div className="form-group">
                  {/* <label htmlFor="hotel_type">Hotel Type</label> */}
                  <select
                    id="car_id"
                    name="car_id"
                    value={carData.car_id}
                    onChange={handleChange}
                    required
                  >
                    <option value="" disabled selected>Select Car</option>
                    {carList.length !== 0 && carList.map(
                      ele =>
                      (
                        <option value={ele._id}>{ele.car_name}</option>
                      )
                    )}
                  </select>
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    id="car_condition"
                    name="car_condition"
                    value={carData.car_condition}
                    onChange={handleChange}
                    required
                    placeholder="Car Condition"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    id="color"
                    name="color"
                    value={carData.color}
                    onChange={handleChange}
                    required
                    placeholder="Car Colour"
                  />
                </div>
              </div>
              <div className="form-group-container">
                <div className="form-group">
                  <input
                    type="number"
                    id="model_year"
                    name="model_year"
                    value={carData.model_year}
                    onChange={handleChange}
                    required
                    placeholder="Model Year"
                  />
                </div>
                <div className="form-group">
                  <select
                    id="insurance"
                    name="insurance"
                    value={selectedInsurance}
                    onChange={handleChange}
                    required
                    placeholder="Insurance"
                  >
                    <option value="" disabled selected>Select Insurance</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </select>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    id="registration_number"
                    name="registration_number"
                    value={carData.registration_number}
                    onChange={handleChange}
                    required
                    placeholder="Registration Number"
                  />
                </div>
              </div>
              <div className="form-group-container">
                <div className="form-group">
                  <input
                    type="number"
                    id="pincode"
                    name="pincode"
                    value={carData.pincode}
                    onChange={handleChange}
                    required
                    placeholder="Pincode"
                  />
                </div>
                <div className="form-group">
                  <select
                    id="state"
                    name="state"
                    value={selectedState}
                    onChange={handleChange}
                    required
                    placeholder="Choose State"
                  >
                    <option value="" disabled selected>Select State</option>
                    {countries.states &&
                      countries.states.map((e) => {
                        return (
                          <option key={e.name} value={e.name}>{e.name}</option>
                        )
                      })}
                  </select>
                </div>
                <div className="form-group">
                  <select
                    id="city"
                    name="city"
                    value={selectedCity}
                    onChange={handleChange}
                    required
                    placeholder="Choose City"
                  >
                    <option value="" disabled selected>Select City</option>
                    {selectedState &&
                      countries.states
                        .find((state) => state.name === selectedState)
                        .cities.map((city) => (
                          <option key={city.name} value={city.name}>
                            {city.name}
                          </option>
                        ))}
                  </select>
                </div>
              </div>
              <div className="form-group-container">

                <div className="form-group">
                  <select
                    id="outStateAllowed"
                    name="outStateAllowed"
                    value={selectedOutStateAllowed}
                    onChange={handleChange}
                    required
                    placeholder="Out State Allowed"
                  >
                    <option value="" disabled selected>Select Out State Allowed</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </select>
                </div>
                <div className="form-group">
                  <select
                    id="AC"
                    name="AC"
                    value={selectedAC}
                    onChange={handleChange}
                    required
                    placeholder="AC"
                  >
                    <option value="" disabled selected>Select AC</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </select>
                </div>
              </div>
              <div className="form-group-container">
                <div className="form-group">
                  <input
                    type="number"
                    id="price_per_km"
                    name="price_per_km"
                    value={carData.price_per_km}
                    onChange={handleChange}
                    required
                    placeholder="Price Per Kilometer"
                  >
                  </input>
                </div>
                <div className="form-group">
                  <input
                    type="number"
                    id="price_per_day"
                    name="price_per_day"
                    value={carData.price_per_day}
                    onChange={handleChange}
                    required
                    placeholder="Price Per Day"
                  >
                  </input>
                </div>
              </div>
              <div className="form-group-container">
                <label htmlFor="car_photo" className="car-photo-label">Car Photos</label>
                <FontAwesomeIcon icon={faPlus} className="plus-icon" onClick={handleAddImageClick} />
                <input
                  type="file"
                  id="car_photo"
                  name="car_photo"
                  multiple
                  accept="image/*"
                  onChange={handleImageChange}
                  ref={fileInputRef}
                  style={{ display: "none" }}
                />
                <div className="hotel-pictures-section">
                  {images.map((image, index) => (
                    <div key={index} className="hotel-pictures-item">
                      <img src={image.url} alt={`car_photo_${index}`} className="hotel-image" />
                      <FontAwesomeIcon icon={faTrash} onClick={() => handleRemoveImage(index)} className="trash-icon" />
                    </div>
                  ))}
                </div>
              </div>
              <div className="form-group-container">
                <button type="submit" className="submit-button" style={{ marginTop: "10px" }} onClick={handleSubmit}>
                  Add Car
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default AddCar;
