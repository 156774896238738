import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faEnvelopeOpen, faUser, faPenToSquare, faPlus } from "@fortawesome/free-solid-svg-icons";
import My_pannel from "./My_pannel";
import Header2 from "./Header2";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../BASE_URL";
import { Button, Row } from "react-bootstrap";
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import ProfileSection from "./SecondNavbar";

const ItineraryHotelList = () => {
    const navigate = useNavigate();

    const [editedata, setEditedData] = useState("");
    const [hotelList, setHotelList] = useState([]);

    const Call = async () => {
        const token = localStorage.getItem("vendorToken");
        const res = await fetch(`${BASE_URL}agency/profile`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });
        const data = await res.json();
        setEditedData(data?.data?.[0]);
    };

    const fetchItineraryHotel = async () => {
        const token = localStorage.getItem("vendorToken");
        const res = await fetch(`${BASE_URL}api/hotel_itienrary/displayAgencyById`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });
        const data = await res.json();
        setHotelList(data?.data);
    };

    useEffect(() => {
        Call();
        fetchItineraryHotel();
    }, []);

    const handleNav = () => {
        navigate("/vendor/myprofile");
    };

    return (
        <>
            <Header2 />
            <div className="costum_container bg-white">
                <My_pannel />
                <div style={{ backgroundColor: "white" }}>
                    <ProfileSection title="Itinerary Hotels" />

                    <section className="new-vendor-container">
                        <div className="d-flex justify-content-end">
                            <button
                                className="check-availibility-button text-20 border-0 mb-2 me-0"
                                onClick={() => navigate("/vendor/addhotels")}
                            >
                                Add Hotels
                            </button>
                        </div>
                        <div className="mt-0">
                            {hotelList.length !== 0 ? (
                                <div className="row">
                                    {hotelList && [...hotelList]?.reverse().map((e, index) => (
                                        <div key={index} className="col-sm-6 col-12 mt-3">
                                            <div className="inner_green_border py-3 px-4 hotel-card-relative">
                                                <div className="row gap-1">
                                                    {/* Hotel Images Carousel */}
                                                    <div className="col-sm-5 col-12">
                                                        <div id={`carouselExampleCaptions-${index}`} className="carousel slide" data-bs-ride="carousel">
                                                            <div className="carousel-indicators">
                                                                {e?.hotel_photo?.map((_, idx) => (
                                                                    <button
                                                                        key={idx}
                                                                        type="button"
                                                                        data-bs-target={`#carouselExampleCaptions-${index}`}
                                                                        data-bs-slide-to={idx}
                                                                        className={idx === 0 ? "active" : ""}
                                                                        aria-current={idx === 0 ? "true" : "false"}
                                                                        aria-label={`Slide ${idx + 1}`}
                                                                    ></button>
                                                                ))}
                                                            </div>
                                                            <div className="carousel-inner">
                                                                {e?.hotel_photo?.map((ele, idx) => (
                                                                    <div key={idx} className={`carousel-item ${idx === 0 ? "active" : ""}`}>
                                                                        <img src={ele} className="d-block hotel-itinerary-img-carousel w-100" alt="..." />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <button className="carousel-control-prev" type="button" data-bs-target={`#carouselExampleCaptions-${index}`} data-bs-slide="prev">
                                                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                <span className="visually-hidden">Previous</span>
                                                            </button>
                                                            <button className="carousel-control-next" type="button" data-bs-target={`#carouselExampleCaptions-${index}`} data-bs-slide="next">
                                                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                                <span className="visually-hidden">Next</span>
                                                            </button>
                                                        </div>
                                                    </div>

                                                    {/* Hotel Details */}
                                                    <div className="col-sm-6 col-12">
                                                        <div>
                                                            <h4 className="mb-1 font600 text18">
                                                                {e?.hotel_name} <span className="fs-5">({e?.hotel_type})</span>
                                                            </h4>
                                                            <p className="mb-1 text14">{e?.hotel_address}</p>
                                                            <p className="mb-1 text14">{e?.hotel_city}, {e?.hotel_state}</p>

                                                            {/* Hotel Description */}
                                                            {/* {e?.hotel_description && (
                                                                <p className="mb-2 text14" dangerouslySetInnerHTML={{ __html: e.hotel_description }}></p>
                                                            )} */}

                                                            {/* Display Meals Prices */}
                                                            <div className="mt-2">
                                                                <h5 className="text16 font600">Meal Prices:</h5>
                                                                <ul>
                                                                    {e?.breakfast_price && <li>Breakfast: ₹{e.breakfast_price}</li>}
                                                                    {e?.lunch_price && <li>Lunch: ₹{e.lunch_price}</li>}
                                                                    {e?.dinner_price && <li>Dinner: ₹{e.dinner_price}</li>}
                                                                </ul>
                                                            </div>

                                                            {/* Display Rooms Data */}
                                                            {e?.rooms?.length > 0 && (
                                                                <div className="mt-2">
                                                                    <h5 className="text16 font600">Room Types:</h5>
                                                                    <ul>
                                                                        {e.rooms.map((room, idx) => (
                                                                            <li key={idx}>
                                                                                {room.room_type}: ₹{room.room_type_price}
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Edit Button */}
                                                    <div className="hotel-card-absolute">
                                                        <FontAwesomeIcon
                                                            icon={faPenToSquare}
                                                            onClick={() => navigate(`/vendor/addhotels/${e?._id}`)}
                                                            style={{ cursor: "pointer" }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            ) :
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "60vh",
                                        color: "gray",
                                        fontWeight: 700,
                                        fontSize: "22px",
                                    }}
                                >
                                    No Hotel Added
                                </div>
                            }
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
};

export default ItineraryHotelList;
