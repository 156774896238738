/* eslint-disable react/jsx-pascal-case */
import React, { createContext, useState } from "react"
import "./App.css"
import Index from "./Project/index"
import Destination from "./Project/Destination"
import Hotel_Details from "./Project/Hotel_Details2"
import Hotel_Booking from "./Project/Hotel_Booking"
import Blog from "./Project/Blog"
import Blog1 from "./Project/Blog1"
import { Route, Routes } from "react-router-dom"
import Destination1 from "./Project/Destination1"
import Top_rate_hotel from "./Project/Top_rate_hotel"
import Custom_packega from "./Project/Custom_packega"
import My_pannel from "./Vendor/My_pannel"
import C_packegga from "./Project/C_packegga"
import Compare_packega from "./Project/Compare_packega"
import Side_navbar from "./Vendor/Side_navbar"
import Navbar_side from "./Vendor/Navbar_side"
import LogiPage from "./Vendor/Logi-page"
import Registation_vandor from "./Vendor/Registation_vandor"
import Registation__vandor2 from "./Vendor/Registation__vandor2"
import Registation__vandor3 from "./Vendor/Registation__vandor3"
import Display_custom from "./Vendor/Display_custom"
import Submit_package_form from "./Vendor/Submit_package_form"
import Display_submit from "./Vendor/Display_submit"
import Book_packega_details from "./Vendor/Book_packega_details"
import Booked_packega from "./Vendor/Booked_packega"
import Requirements_details from "./Vendor/Requirements_details"
import Forgot_password from "./Project/Forgot_password"
import Contact from "./Project/Contact"
import Packega_details from "./Project/Packega_details"
import Modal from "./Project/Modal"
import Practic from "./Project/Section_modal"
import Model from "./Project/Model"
import Profile from "./Project/Profile"
import Boocking from "./Project/Boocking"
import Signup from "./Project/Signup"
import RegistrationVendor1 from "./Vendor/Demo"
import Compare_packega_2 from "./Project/Compare_packega"
import Submitted_Package_Details from "./Vendor/Submitted_Package_Details"
import Header2 from "./Vendor/Header2"
import User_tab from "./Vendor/User_tab"
import Home_page from "./Vendor/Home_page"
import AddItineries from "./Vendor/AddItineries"
import My_package from "./Project/My_package"
import AddItineriesEdit from "./Vendor/AddItineriesEdit"
import AddHotel from "./Vendor/AddHotel"
import Vandor_Registation from "./Vendor/Vandor_Registation"
import Aboutus from "./Project/Aboutus"
import TermsAndCondition from "./Project/TermsAndCondition"
import Faqs from "./Project/Faqs"
import Cancellation from "./Project/Cancellation"
import Privacy from "./Privacy"
import VendorChangePass from "./Vendor/VendorChangePass"
import VendorForgotPassword from "./Vendor/VendorForgotPassword"
import VerifyOtp from "./Vendor/VerifyOtp"
import NewPassword from "./Vendor/NewPassword"
import Editmyprofile from "./Vendor/Editmyprofile"
import PersonalDetail from "./Vendor/PersonalDetail"
import Editagencydetail from "./Vendor/Editagencydetail"
import EditagencyGst from "./Vendor/EditagencyGst"
import PersonalDetailForm from "./Vendor/PersonalDetailForm"
import AgencyDetailForm from "./Vendor/AgencyDetailForm"
import GstEditForm from "./Vendor/GstEditForm"
import Booked_package_fulDetail from "./Project/Booked_package_fulDetail"
import Career from "./Project/Career"
import Otp from "./Project/Otp"
import MyBid from "./Vendor/MyBid"
import Reviews from "./Vendor/Reviews"
import MyHotels from "./Vendor/MyHotels"
import EditHotel from "./Vendor/EditHotel"
import HotelBookings from "./Vendor/HotelBookings"
import ViewHotelBooking from "./Vendor/ViewHotelBooking"
import { SocketProvider } from "./context/SocketContext"
import MyPackages from "./Vendor/MyPackages"
import AddMyPackage from "./Vendor/AddMyPackage"
import ViewMyPackage from "./Vendor/ViewMyPackage"
import EditMyPackage from "./Vendor/EditMyPackage"
import MyCars from "./Vendor/MyCars"
import EditCar from "./Vendor/EditCar"
import AddCar from "./Vendor/AddCar"
import Tripclap from "./Project/WebScrap/Tripclap"
import Thomascook from "./Project/WebScrap/Thomascook"
import Flamingo from "./Project/WebScrap/Flamingo"
import Sotc from "./Project/WebScrap/Sotc"
import Kesari from "./Project/WebScrap/Kesari"
import Akshar from "./Project/WebScrap/Akshar"
import Easymytrip from "./Project/WebScrap/Easymytrip"
import SpecificDetail from "./Project/WebScrap/SpecificDetail"
import CarList from "./Project/CarList"
import CarDetails from "./Project/CarDetails"
import CarBookings from "./Vendor/CarBookings "
import ViewCarBooking from "./Vendor/ViewCarBooking "
import BookedCarDetails from "./Project/BookedCarDetails"
import ScrollToTop from "./context/ScroolToTop"
import ItineraryHotel from "./Vendor/ItineraryHotel"
import ItineraryHotelList from "./Vendor/ItineraryHotelList"
import BookingPayment from "./Project/BookingPayment"
import AdminPackageBooking from "./Project/AdminPackageBooking"
import Inquiries from "./Vendor/Inquiries"
import BookingHotel from "./Project/HotelBooking"
import PrePurchaseHotel from "./Vendor/PrePurchaseHotel"
import PrePurchaseHotelDetail from "./Vendor/PrePurchaseHotelDetail"
import HotelDetailById from "./Vendor/VendorhotelDetailById"
import Selecthotel from "./Vendor/Selecthotel"
import AddNewPackage from "./Vendor/InsertPackage"
import EditNewPackage from "./Vendor/EditNewPackage"
import AddPackageItineries from "./Vendor/PackageItinerary"
import PackageItineraryUpdate from "./Vendor/PackageItineraryUpdate"
import SplashPage from "./Project/SplashPage"
import SplashPagePackage from "./Project/PackageSplashScreen"
import Notifications from "./Project/Notifications"
import SelectItineraryHotel from "./Vendor/SelectItineraryHotel"
import CarDetail from "./Project/CarDetail"
import AllCars from "./Project/AllCars"

export const dataContext = createContext()
export const bidContext = createContext()
export const categoryName = createContext()
export const day = createContext()
export const ids = createContext()
export const hotelName = createContext()
export const Itineries = createContext()
export const rangeContext = createContext()
export const monthContext = createContext()

function App() {
  const [info, setInfo] = useState("")
  const [bidData, setBidData] = useState("")
  const [categoryData, setCategoryData] = useState("")
  const [dayNumber, setDayNumber] = useState("")
  const [hotel, setHotel] = useState("")
  const [id, setId] = useState("")
  const [rangeData, setRangeData] = useState("")
  const [monthData, setMonthData] = useState("")

  return (
    <>
      <SocketProvider>
        <dataContext.Provider value={{ info, setInfo }}>
          <bidContext.Provider value={{ bidData, setBidData }}>
            <categoryName.Provider value={{ categoryData, setCategoryData }}>
              <day.Provider value={{ dayNumber, setDayNumber }}>
                <hotelName.Provider value={{ hotel, setHotel }}>
                  <ids.Provider value={{ id, setId }}>
                    <rangeContext.Provider value={{ rangeData, setRangeData }}>
                      <monthContext.Provider value={{ monthData, setMonthData }}>
                        <ScrollToTop />
                        <Routes>
                          <Route path='/' element={<Index />} />
                          <Route path='/user/registration' element={<Signup />} />
                          <Route path='/blog' element={<Blog />} />
                          <Route path='/blog1/:id' element={<Blog1 />} />
                          <Route path='/otp-verify' element={<Otp />} />
                          <Route
                            path='/destination1/:id'
                            element={<Destination1 />}
                          />
                          <Route
                            path='/destination/:id'
                            element={<Destination />}
                          />
                          <Route
                            path='/packega_details/:id/:merchentId?'
                            element={<Packega_details />}
                          />
                          <Route
                            path='/custom_requirement'
                            element={<C_packegga />}
                          />
                          <Route
                            path='/compare_packega'
                            element={<Compare_packega_2 />}
                          />
                          <Route
                            path='/custome_package/:id/:merchentId?'
                            element={<Custom_packega />}
                          />
                          <Route
                            path='/booking-payment/:id/:merchentId?'
                            element={<BookingPayment />}
                          />
                          {/* <Route
                            path='/booking-done/:bookingId?'
                            element={<SplashPagePackage />}
                          /> */}
                          <Route
                            path='/booking-done/:merchantId?'
                            element={<SplashPage />}
                          />
                          <Route
                            path='/booking-hotel-payment/:id/:merchentId?'
                            element={<BookingHotel />}
                          />
                          <Route
                            path='/top_rate_hotel'
                            element={<Top_rate_hotel />}
                          />
                          <Route
                            path='/hotel_details/:id/:merchentId?'
                            element={<Hotel_Details />}
                          />
                          <Route
                            path='/vendor/hotel-details/:id'
                            element={<HotelDetailById />}
                          />
                          <Route
                            path='/hotel_booking/:id'
                            element={<Hotel_Booking />}
                          />
                          <Route
                            path='/view_cars'
                            element={<CarList />}
                          />
                          <Route
                            path='/all-cars'
                            element={<AllCars />}
                          />
                          <Route
                            path='/car-details/:id/:merchentId?'
                            element={<CarDetails />}
                          />
                          <Route
                            path='/booked-car-details/:id/'
                            element={<BookedCarDetails />}
                          />
                          <Route path='/my_package'
                            element={<My_package />}
                          />
                          <Route path='/profile'
                            element={<Profile />}
                          />
                          <Route path='/my-boocking/:id'
                            element={<Boocking />}
                          />
                          <Route path='/notifications'
                            element={<Notifications />}
                          />
                          <Route path='/package-booking/:id/:transId?'
                            element={<AdminPackageBooking />}
                          />
                          <Route
                            path='/booking-full-detail/:id/:transId?'
                            element={<Booked_package_fulDetail />}
                          />
                          <Route path='/contactus' element={<Contact />} />
                          <Route path='/aboutus' element={<Aboutus />} />
                          <Route path='/career' element={<Career />} />
                          <Route
                            path='/payment-policy'
                            element={<TermsAndCondition />}
                          />
                          <Route path='/faqs' element={<Faqs />} />
                          <Route
                            path='/cancellation-policy'
                            element={<Cancellation />}
                          />
                          <Route path='/privacy-policy' element={<Privacy />} />
                          <Route
                            path='/vendor/registration'
                            element={<Registation_vandor />}
                          />
                          <Route
                            path='/vandor/registration'
                            element={<Vandor_Registation />}
                          />
                          <Route
                            path='/vendor/registration2'
                            element={<Registation__vandor2 />}
                          />
                          <Route
                            path='/vendor/changepassword'
                            element={<VendorChangePass />}
                          />
                          <Route
                            path='/vendor/forgotpassword'
                            element={<VendorForgotPassword />}
                          />
                          <Route
                            path='/vendor/registration3'
                            element={<Registation__vandor3 />}
                          />
                          <Route path='/vendor/my-bid' element={<MyBid />} />
                          <Route path='/vendor/otp' element={<VerifyOtp />} />
                          <Route
                            path='/vendor/newpassword'
                            element={<NewPassword />}
                          />
                          <Route
                            path='/display/custom'
                            element={<Display_custom />}
                          />
                          <Route path='/vendor/login' element={<LogiPage />} />
                          <Route
                            path='/vendor/home-page'
                            element={<Home_page />}
                          />
                          <Route
                            path='/display/custom/details/:id'
                            element={<Requirements_details />}
                          />
                          <Route
                            path='/vendor/Submit_package_form/:id'
                            element={<Submit_package_form />}
                          />
                          <Route
                            path='/vendor/Submit-form'
                            element={<Submitted_Package_Details />}
                          />
                          <Route
                            path='/vendor/mypackages'
                            element={<MyPackages />}
                          />
                          <Route
                            path='/vendor/add-new-package/:_id?'
                            element={<AddNewPackage />}
                          />
                          <Route
                            path='/vendor/edit-package/:id'
                            element={<EditNewPackage />}
                          />
                          <Route
                            path='/vendor/select-itinerary-hotel'
                            element={<SelectItineraryHotel />}
                          />
                          <Route
                            path='/vendor/displaymypackage/:id'
                            element={<ViewMyPackage />}
                          />
                          <Route
                            path='/vendor/addmypackage'
                            element={<AddMyPackage />}
                          />
                          <Route
                            path='/vendor/editmypackage/:id'
                            element={<EditMyPackage />}
                          />
                          <Route
                            path='/vendor/Booked-packega'
                            element={<Booked_packega />}
                          />
                          <Route
                            path='/vendor/Reviews'
                            element={<Reviews />}
                          />
                          <Route
                            path='/vendor/addhotels/:_id?'
                            element={<ItineraryHotel />}
                          />
                          <Route
                            path='/vendor/hotels'
                            element={<ItineraryHotelList />}
                          />
                          <Route
                            path='/vendor/myhotels'
                            element={<MyHotels />}
                          />
                          <Route
                            path='/vendor/select-hotel'
                            element={<Selecthotel />}
                          />
                          <Route
                            path='/vendor/pre-purchase-hotel'
                            element={<PrePurchaseHotel />}
                          />
                          <Route
                            path='/vendor/pre-purchase-hotel-detail/:hotelId'
                            element={<PrePurchaseHotelDetail />}
                          />
                          <Route
                            path='/vendor/myhotels/Addhotels'
                            element={<AddHotel />}
                          />
                          <Route
                            path='/vendor/myhotels/:hotelId'
                            element={<EditHotel />}
                          />
                          <Route
                            path='/vendor/hotelbookings'
                            element={<HotelBookings />}
                          />
                          <Route
                            path='/vendor/inquiries'
                            element={<Inquiries />}
                          />
                          <Route
                            path='/vendor/hotelbookings/:id'
                            element={<ViewHotelBooking />}
                          />
                          <Route
                            path='/vendor/mycars'
                            element={<MyCars />}
                          />
                          <Route
                            path='/vendor/carbookings'
                            element={<CarBookings />}
                          />
                          <Route
                            path='/vendor/carbookings/:id'
                            element={<ViewCarBooking />}
                          />
                          <Route
                            path='/vendor/mycars/Addcar'
                            element={<AddCar />}
                          />
                          <Route
                            path='/vendor/mycars/:carId'
                            element={<EditCar />}
                          />
                          <Route
                            path='/vendor/Book-packega/details/:id'
                            element={<Book_packega_details />}
                          />
                          <Route
                            path='/vendor/add-itineries/:id?/:BidId?'
                            element={<AddItineries />}
                          />
                          <Route
                            path='/vendor/add-package-itineries/:packageId?'
                            element={<AddPackageItineries />}
                          />
                          <Route
                            path='/vendor/update-package-itineries/:packageId?/:_id'
                            element={<PackageItineraryUpdate />}
                          />
                          <Route
                            path='/vendor/add-itineriesEdit/:id/:BidId'
                            element={<AddItineriesEdit />}
                          />
                          <Route
                            path='/vendor/myprofile'
                            element={<Editmyprofile />}
                          />
                          <Route
                            path='/vendor/personaldetails'
                            element={<PersonalDetail />}
                          />
                          <Route
                            path='/vendor/editagencydetail'
                            element={<Editagencydetail />}
                          />
                          <Route
                            path='/vendor/EditagencyGst'
                            element={<EditagencyGst />}
                          />
                          <Route
                            path='/vendor/EditPersonalDetail'
                            element={<PersonalDetailForm />}
                          />
                          <Route
                            path='/vendor/AgencyEdit'
                            element={<AgencyDetailForm />}
                          />
                          <Route
                            path='/vendor/GstEditForm'
                            element={<GstEditForm />}
                          />
                          <Route
                            path='/ws/Tripclap'
                            element={<Tripclap />}
                          />
                          <Route
                            path='/ws/Thomascook'
                            element={<Thomascook />}
                          />
                          <Route
                            path='/ws/Flamingo'
                            element={<Flamingo />}
                          />
                          <Route
                            path='/ws/Sotc'
                            element={<Sotc />}
                          />
                          <Route
                            path='/ws/Kesari'
                            element={<Kesari />}
                          />
                          <Route
                            path='/ws/Akshar'
                            element={<Akshar />}
                          />
                          <Route
                            path='/ws/Easymytrip'
                            element={<Easymytrip />}
                          />
                          <Route
                            path='/ws/specificdetail'
                            element={<SpecificDetail />}
                          />
                          <Route
                            path='/car-detail'
                            element={<CarDetail />}
                          />
                        </Routes>
                      </monthContext.Provider>
                    </rangeContext.Provider>
                  </ids.Provider>
                </hotelName.Provider>
              </day.Provider>
            </categoryName.Provider>
          </bidContext.Provider>
        </dataContext.Provider>
      </SocketProvider>,
    </>
  )
}

export default App
